import React, {useState, useEffect, useCallback, useRef, useMemo} from 'react';
import {View, FlatList, ActivityIndicator, TextInput, TouchableOpacity, Dimensions} from 'react-native';
import EStyleSheet from "react-native-extended-stylesheet";
import {useDebouncedCallback} from 'use-debounce';
import {CancelToken} from "axios";
import {CommonActions} from '@react-navigation/native';

import AppText from "../../../components/AppText";
import MessagesService from "../../../services/MessagesService";
import Avatar from "../../../components/Avatar";
import {AVATAR_MESSENGER} from "../../../config";
import {basicStyles} from "../../../styles/basic";
import PencilSvg from "../../../components/svgs/PencilSvg";
import StarRangeInputField from "../../../components/StarRangeInputField";
import {formatDate} from "../../../utilities/methods";
import AppButton from "../../../components/AppButton";
import LinkButton from "../../../components/LinkButton";
import ChevronLeftBlueSvg from "../../../components/svgs/ChevronLeftBlueSvg";
import {inputStyles} from "../../../styles/input";

function MessageFeedCustomerInfo({organizationId, messageFeedId, setBlockModalActive, messageFeedBlocked, updateSelectedMessageInList, setCustomerInfoActive}) {
    const [initialized, setInitialized] = useState(false);
    const [messageFeed, setMessageFeed] = useState({});
    const [contactNameInputFocused, setContactNameInputFocused] = useState(false);
    const [contactNameInputHovered, setContactNameInputHovered] = useState(false);
    const [jobs, setJobs] = useState([]);
    const [page, setPage] = useState(0);
    const [isLastPage, setIsLastPage] = useState(false);
    const [jobNotesExpanded, setJobNotesExpanded] = useState([]);
    const perPage = 20;
    const fetchDataRequestSource = useRef(null);
    const updateDataRequestSource = useRef(null);

    const screenWidth = useMemo(() => {
        return Dimensions.get('window').width;
    }, [])

    const updateMessageFeed = useCallback(() => {
        // cancel any pending request
        //note: assigning this to a variable because I can't figure out why it's throwing an eslint error about unused expression
        let derp = updateDataRequestSource.current?.cancel('Request Canceled');
        // create new cancel token
        updateDataRequestSource.current = CancelToken.source();

        MessagesService.updateMessageFeed(organizationId, messageFeedId, messageFeed, updateDataRequestSource.current?.token)
            .then(({messageFeed}) => {
                updateSelectedMessageInList(messageFeed);
            });
    }, [messageFeedId, organizationId, messageFeed, updateSelectedMessageInList]);

    const [updateMessageFeedDebounce] = useDebouncedCallback(() => {
        updateMessageFeed();
    }, 500);

    useEffect(() => {
        MessagesService.getMessageFeed(organizationId, messageFeedId)
            .then(({messageFeed}) => {
                setMessageFeed(messageFeed);
            });
    }, [messageFeedId, organizationId]);

    useEffect(() => {
        // cancel any pending request
        //note: assigning this to a variable because I can't figure out why it's throwing an eslint error about unused expression
        let derp = fetchDataRequestSource.current?.cancel('Request Canceled');
        // create new cancel token
        fetchDataRequestSource.current = CancelToken.source();

        MessagesService.getMessageFeedJobs(organizationId, messageFeedId, page, perPage, fetchDataRequestSource.current?.token)
            .then(jobs => {
                setIsLastPage(jobs.length < perPage);
                setJobs(current => {
                    return [
                        ...(page === 0 ? [] : current),
                        ...jobs
                    ];
                });
            }).catch(e => {
            if (!e.__CANCEL__) {
                console.log('Error fetching message feeds: ', e);
            }
        });

        return () => {
            let derp = fetchDataRequestSource.current?.cancel('Request Canceled');
        };
    }, [organizationId, messageFeedId, page]);

    useEffect(() => {
        setInitialized(false);
    }, [messageFeedId, setInitialized]);

    useEffect(() => {
        if(initialized) {
            updateMessageFeedDebounce();
        } else if (messageFeed.hasOwnProperty('message_feed_contact_name')) {
            setInitialized(true);
        }
    }, [messageFeed.message_feed_contact_name]);

    if (messageFeed.message_feed_id) {
        return (
            <View style={basicStyles.flexScale}>
                <View style={[styles.customerInfoTop]}>
                    <Avatar
                        source={{uri: AVATAR_MESSENGER}}
                        width={40}
                        style={{marginRight: 20}}
                    />
                    <View style={[basicStyles.flexScale]}>
                        {
                            ((contactNameInputHovered || screenWidth <= 1000) && !contactNameInputFocused )?
                                <PencilSvg style={styles.pencilIcon}/>
                                : null
                        }
                        <TextInput
                            style={[styles.contactNameTextInput, contactNameInputFocused ? styles.contactNameTextInputFocused : {}]}
                            placeholder="Contact Name"
                            value={messageFeed.message_feed_contact_name || ''}
                            onChangeText={value => {
                                setMessageFeed(current => {
                                    return {
                                        ...current,
                                        message_feed_contact_name: value
                                    };
                                });
                            }}
                            onFocus={() => {
                                setContactNameInputFocused(true);
                            }}
                            onBlur={() => {
                                setContactNameInputFocused(false);
                            }}
                            onMouseEnter={() => {
                                setContactNameInputHovered(true);
                            }}
                            onMouseLeave={() => {
                                setContactNameInputHovered(false);
                            }}
                        />
                        <AppText style={[styles.contactPhoneNumber]}>
                            {messageFeed.message_feed_phone_number}
                        </AppText>
                    </View>
                </View>
                <TouchableOpacity
                    style={styles.backButton}
                    onPress={() => {
                    setCustomerInfoActive(false);
                }}
                >
                    <ChevronLeftBlueSvg/>
                    <AppText style={styles.backButtonText}>
                        Back
                    </AppText>
                </TouchableOpacity>
                <AppText style={[styles.feedBackHeading]}>
                    Job History
                </AppText>
                {
                    jobs.length ?
                    <FlatList
                        style={[basicStyles.flexScale]}
                        data={jobs}
                        keyExtractor={({job_id}) => job_id}
                        renderItem={({item: job}) => {
                            return (
                                <View style={[styles.jobContainer]}>
                                    <LinkButton action={() => {
                                        return CommonActions.navigate('Jobs', {
                                            screen: 'JobIndexEditNavigation',
                                            params: {
                                                screen: 'JobEdit',
                                                params: {
                                                    id: job.job_id,
                                                },
                                            },
                                        });
                                    }}>
                                        <View
                                            style={[
                                                inputStyles.inputWrapper,
                                                basicStyles.flexRow,
                                                basicStyles.alignContentCenter,
                                                styles.jobFieldWrapper,
                                            ]}>
                                            <View style={basicStyles.flexScale}>
                                                <AppText style={[inputStyles.inputLabel, styles.jobFieldLabel]}>
                                                    Job ID
                                                </AppText>
                                                <AppText style={[inputStyles.input, styles.jobFieldContent]} numberOfLines={1}>
                                                    {job.job_number}
                                                </AppText>
                                            </View>
                                        </View>
                                    </LinkButton>
                                    <View
                                        style={[
                                            inputStyles.inputWrapper,
                                            basicStyles.flexRow,
                                            basicStyles.alignContentCenter,
                                            styles.jobFieldWrapper,
                                        ]}>
                                        <View style={basicStyles.flexScale}>
                                            <AppText style={[inputStyles.inputLabel, styles.jobFieldLabel]}>
                                                Date
                                            </AppText>
                                            <AppText style={[inputStyles.input, styles.jobFieldContent]} numberOfLines={1}>
                                                {formatDate(job.job_timestamp)}
                                            </AppText>
                                        </View>
                                    </View>
                                    <View
                                        style={[
                                            inputStyles.inputWrapper,
                                            basicStyles.flexRow,
                                            basicStyles.alignContentCenter,
                                            styles.jobFieldWrapper,
                                        ]}>
                                        <View style={basicStyles.flexScale}>
                                            <AppText style={[inputStyles.inputLabel, styles.jobFieldLabel]}>
                                                Product Type
                                            </AppText>
                                            <AppText style={[inputStyles.input, styles.jobFieldContent]} numberOfLines={1}>
                                                {job.job_product_type}
                                            </AppText>
                                        </View>
                                    </View>
                                    <View
                                        style={[
                                            inputStyles.inputWrapper,
                                            basicStyles.flexRow,
                                            basicStyles.alignContentCenter,
                                            styles.jobFieldWrapper,
                                        ]}>
                                        <View style={basicStyles.flexScale}>
                                            <AppText style={[inputStyles.inputLabel, styles.jobFieldLabel]}>
                                                Product Brand
                                            </AppText>
                                            <AppText style={[inputStyles.input, styles.jobFieldContent]} numberOfLines={1}>
                                                {job.job_product_brand}
                                            </AppText>
                                        </View>
                                    </View>
                                    {
                                        !jobNotesExpanded.includes(job.job_number) && job.jobNumberJobNotes.length > 0 && job.jobNumberJobNotes[0].job_notes ?
                                            <TouchableOpacity onPress={() => {
                                                setJobNotesExpanded(current => {
                                                    current.push(job.job_number);
                                                        return [...current];
                                                })
                                            }}>
                                                <View
                                                    style={[
                                                        inputStyles.inputWrapper,
                                                        basicStyles.flexRow,
                                                        basicStyles.alignContentCenter,
                                                        styles.jobFieldWrapper,
                                                    ]}>
                                                    <View style={basicStyles.flexScale}>
                                                        <AppText style={[inputStyles.inputLabel, styles.jobFieldLabel]}>
                                                            Note @ {formatDate(job.jobNumberJobNotes[0].job_timestamp, 'MM/DD/YYYY hh:mm')}
                                                        </AppText>
                                                        <AppText style={[inputStyles.input, styles.jobFieldContent]} numberOfLines={1}>
                                                            {job.jobNumberJobNotes[0].job_notes}
                                                        </AppText>
                                                    </View>
                                                </View>
                                            </TouchableOpacity>
                                            :
                                        null
                                    }
                                    {
                                        jobNotesExpanded.includes(job.job_number) ?

                                            <View
                                                style={[
                                                    inputStyles.inputWrapper,
                                                    basicStyles.alignContentCenter,
                                                    styles.jobFieldWrapper,
                                                ]}>
                                                {
                                                    job.jobNumberJobNotes.map(jobNote => {
                                                        return (
                                                            <View
                                                                key={jobNote.job_id}
                                                                style={[basicStyles.flexScale, styles.jobNotesInnerWrapper]}
                                                            >
                                                                <AppText
                                                                    style={[inputStyles.inputLabel, styles.jobFieldLabel]}>
                                                                    Note @ {formatDate(job.jobNumberJobNotes[0].job_timestamp, 'MM/DD/YYYY hh:mm')}
                                                                </AppText>
                                                                <AppText
                                                                    style={[inputStyles.input, styles.jobFieldContent]}>
                                                                    {jobNote.job_notes}
                                                                </AppText>
                                                            </View>
                                                        );
                                                    })
                                                }
                                            </View>
                                            : null
                                    }
                                    {
                                        job.review_timestamp ?
                                            <>
                                                <StarRangeInputField
                                                    wrapperStyle={[styles.jobFieldWrapper]}
                                                    labelStyle={[styles.jobFieldLabel]}
                                                    inputStyle={[styles.jobFieldContent]}
                                                    label="Overall Rating"
                                                    value={job.review_rating}
                                                    disabled={true}
                                                />
                                                <View
                                                    style={[
                                                        inputStyles.inputWrapper,
                                                        basicStyles.flexRow,
                                                        basicStyles.alignContentCenter,
                                                        styles.jobFieldWrapper,
                                                    ]}>
                                                    <View style={basicStyles.flexScale}>
                                                        <AppText style={[inputStyles.inputLabel, styles.jobFieldLabel]}>
                                                            Review
                                                        </AppText>
                                                        <AppText style={[inputStyles.input, styles.jobFieldContent]} numberOfLines={1}>
                                                            {job.review_text}
                                                        </AppText>
                                                    </View>
                                                </View>
                                                <View
                                                    style={[
                                                        inputStyles.inputWrapper,
                                                        basicStyles.flexRow,
                                                        basicStyles.alignContentCenter,
                                                        styles.jobFieldWrapper,
                                                    ]}>
                                                    <View style={basicStyles.flexScale}>
                                                        <AppText style={[inputStyles.inputLabel, styles.jobFieldLabel]}>
                                                            Review Response
                                                        </AppText>
                                                        <AppText style={[inputStyles.input, styles.jobFieldContent]} numberOfLines={1}>
                                                            {job.client_response || 'No Feedback Yet'}
                                                        </AppText>
                                                    </View>
                                                </View>
                                            </>
                                            : null
                                    }
                                </View>
                            );
                        }}
                        onEndReachedThreshold={0.1}
                        onEndReached={() => {
                            if (!isLastPage) {
                                setPage(current => current + 1);
                            }
                        }}
                    />
                        :
                        <View style={[basicStyles.flexScale]}>
                            <AppText style={[styles.noJobsText]}>
                                No Jobs Found For Contact
                            </AppText>
                        </View>
                }
                <View style={[styles.blockContactContainer]}>
                    <AppButton
                        label={`${messageFeedBlocked ? 'Unblock' : 'Block'} Contact`}
                        theme="transRed"
                        action={() => {
                            setBlockModalActive(true);
                        }}
                    />
                </View>
            </View>
        );
    }

    return (
        <View style={[basicStyles.flexCenterContent, basicStyles.flexScale]}>
            <ActivityIndicator size="large"/>
        </View>
    );
}

export default MessageFeedCustomerInfo;

const styles = EStyleSheet.create({
    customerInfoTop: {
        padding: 15,
        flexDirection: 'row',
        alignItems: 'flex-start',
        borderBottomWidth: 1,
        borderBottomColor: '#E8EDF6',
    },
    contactNameTextInput: {
        fontSize: 16,
        width: '100%',
        marginBottom: 3,
        fontFamily: 'SourceSansPro-SemiBold',
        cursor: 'pointer',
    },
    contactNameTextInputFocused: {
        cursor: 'auto'
    },
    contactPhoneNumber: {
        fontSize: 12,
        fontFamily: 'SourceSansPro-Light',
        color: '#9EABCC',
        textDecorationLine: "underline",
        textDecorationStyle: "solid",
        textDecorationColor: "#9EABCC"
    },
    pencilIcon: {
        position: 'absolute',
        top: 3,
        left: -13
    },
    jobContainer: {
        paddingRight: 15,
        paddingLeft: 15,
        marginBottom: 24,
    },
    jobFieldWrapper: {
        backgroundColor: 'transparent',
        marginBottom: 3,
        padding: 12,
        height: 'auto',
        minHeight: 'auto'
    },
    jobNotesInnerWrapper: {
        minHeight: 'auto', //idk why but this is needed or text can cutoff
        marginBottom: 15
    },
    jobFieldLabel: {
        fontSize: 10
    },
    jobFieldContent: {
        fontSize: 12,
        minHeight: 'auto'
    },
    blockContactContainer: {
        padding: 15
    },
    feedBackHeading: {
        fontSize: 16,
        marginTop: 16,
        marginBottom: 16,
        fontFamily: 'SourceSansPro-SemiBold',
        paddingLeft: 15,
        paddingRight: 15,
    },
    noJobsText: {
        color: 'rgba(158, 171, 204, 1)',
        textAlign: 'center',
        paddingRight: 15,
        paddingLeft: 15,
    },
    backButton: {
        flexDirection: 'row',
        alignItems: 'center',
        display: 'none',
        paddingRight: 15,
        paddingLeft: 15,
        marginTop: 10
    },
    backButtonText: {
        color: '#0065DB',
        marginLeft: 5
    },
    '@media (max-width: 1000)': {
        backButton: {
            display: 'flex'
        }
    },
});
