import React, {forwardRef, createRef} from 'react';
import {View, Switch} from 'react-native';

import AppText from './AppText';
import {inputStyles} from '../styles/input';
import {basicStyles} from '../styles/basic';

const ToggleField = forwardRef((
	{
		label,
		value = '',
    onChange = () => {},
		wrapperStyle = {},
		labelStyle = {},
		inputStyle = {},
		disabled = false,
		actionButton = null,
		...rest
	},
	ref,
) => {
	if (!ref) {
		ref = createRef();
	}

	const ActionButton = () => {
		return actionButton;
	};

	return (
		<View
			style={[
				inputStyles.inputWrapper,
				basicStyles.flexRow,
				basicStyles.alignContentCenter,
				wrapperStyle,
			]}
		>

			<View style={basicStyles.flexScale}>
				<AppText style={[inputStyles.inputLabel, labelStyle]}>
					{label}
				</AppText>
        <Switch
          style={{marginTop: 6}}
          trackColor={{ false: "#DEDEDE", true: "#0065db" }}
          thumbColor={value ? "#467aff" : "#EAEAEA"}
          activeThumbColor='#467aff'
          onValueChange={onChange}
          value={value}
		  disabled={disabled}
		  onClick={(e) => {
			  e.stopPropagation();
		  }}
        />
			</View>
			{
				actionButton ?
					<ActionButton/>
					: null
			}
		</View>
	);
});

export default ToggleField;
