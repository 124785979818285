import React from 'react';
import {View} from 'react-native';
import {createStackNavigator} from '@react-navigation/stack';

import JobsList from './JobsList';
import JobSingle from './JobSingle';
import {basicStyles} from '../../styles/basic';

const Stack = createStackNavigator();

function JobIndexEditNavigation(props) {
	return (
		<View style={basicStyles.flexScale}>
			<Stack.Navigator
				backBehavior="history"
				screenOptions={{
					header: () => null,
					cardStyle: {
						backgroundColor: '#F8FAFD'
					}
				}}
				initialRouteName="JobsList"
			>
				<Stack.Screen
					name={"JobsList"}
					component={JobsList}
					options={{
						title: "Jobs"
					}}
					initialParams={{organizationId: props.route.params?.organizationId}}
					/>
				<Stack.Screen
					name={"JobEdit"}
					component={JobSingle}
					options={{
						title: "Jobs"
					}}
					initialParams={{organizationId: props.route.params?.organizationId}}
				/>
			</Stack.Navigator>
		</View>
	);
}

export default JobIndexEditNavigation;
