import React, {useState, useEffect, useCallback, useRef, useMemo} from 'react';
import {View, TouchableOpacity, ActivityIndicator, Image, TextInput, ScrollView} from 'react-native';
import EStyleSheet from "react-native-extended-stylesheet";
import {CancelToken} from "axios";

import AppText from "../../../components/AppText";
import MessagesService from "../../../services/MessagesService";
import {basicStyles} from "../../../styles/basic";
import {formatDate, formatPhoneNumber, getDateDifferenceInSeconds} from "../../../utilities/methods";
import MessageFeedResolvedSvg from "../../../components/svgs/MessageFeedResolvedSvg";
import MessageFeedUnresolvedSvg from "../../../components/svgs/MessageFeedUnresolvedSvg";
import {AVATAR_MESSENGER, AVATAR_PATH_ORG, AVATAR_PATH_USER, MESSAGE_ATTACHMENT_FILE_PATH} from "../../../config";
import Avatar from "../../../components/Avatar";
import AttachmentsSvg from "../../../components/svgs/AttachmentsSvg";
import ScriptsSvg from "../../../components/svgs/ScriptsSvg";
import NotesSvg from "../../../components/svgs/NotesSvg";
import EmojisSvg from "../../../components/svgs/EmojisSvg";
import BlueCircleArrowSvg from "../../../components/svgs/BlueCircleArrowRightSvg";
import AutoExpandingTextInput from "../../../components/AutoExpandingTextInput";
import BlockModal from "./BlockModal";
import MessageFeedCustomerInfo from "./MessageFeedCustomerInfo";
import CreateNewNoteModal from "./CreateNewNoteModal";
import ActionIcon from "./ActionIcon";
import RemoveSvg from "../../../components/svgs/RemoveSvg";
import EmojiSelector from "./EmojiSelector";
import InfoSvg from "../../../components/svgs/InfoSvg";
import {navigate} from "../../../utilities/navigation";
import ChevronLeftBlueSvg from "../../../components/svgs/ChevronLeftBlueSvg";
import ScriptModal from "./ScriptModal";

function MessageFeed({organizationId, messageFeedId, updateSelectedMessageInList}) {
    const [messageFeedState, setMessageFeedState] = useState({
        messageFeed: {},
        messageFeedItems: [],
        usersTyping: [],
    });
    const [newMessageBody, setNewMessageBody] = useState('');
    const [newMessageAttachments, setNewMessageAttachments] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmittingMessage, setIsSubmittingMessage] = useState(false);
    const fetchDataRequestSource = useRef(null);
    const resolveRequestSource = useRef(null);
    const fetchTimeout = useRef(null);
    const [blockModalActive, setBlockModalActive] = useState(false);
    const [noteModalOpen, setNoteModalOpen] = useState(false);
    const messageInputRef = useRef(null);
    const [emojisActive, setEmojisActive] = useState(false);
    const [customerInfoActive, setCustomerInfoActive] = useState(false);
    const [scriptModalActive, setScriptModalActive] = useState(false);
    const typingTimeout = useRef(null);
    const typingRequestSource = useRef(null);

    const scrollViewRef = useRef(null);

    const updateMessageFeed = useCallback((messageFeed, messages, scrollToBottom = false, usersTyping = []) => {
        updateSelectedMessageInList(messageFeed);
        const messageFeedItems = [
            ...messages,
            ...messageFeed.notes,
            ...messageFeed.statuses
        ].sort((itemA, itemB) => {
            let timestampA;
            switch (itemA.feedItemType) {
                case 'message':
                    timestampA = itemA.message_created_timestamp;
                    break;
                case 'note':
                    timestampA = itemA.message_feed_note_timestamp;
                    break;
                case 'status':
                    timestampA = itemA.message_feed_status_timestamp;
                    break;
            }

            let timestampB;
            switch (itemB.feedItemType) {
                case 'message':
                    timestampB = itemB.message_created_timestamp;
                    break;
                case 'note':
                    timestampB = itemB.message_feed_note_timestamp;
                    break;
                case 'status':
                    timestampB = itemB.message_feed_status_timestamp;
                    break;
            }

            return getDateDifferenceInSeconds(timestampA, timestampB);
        });

        setMessageFeedState({
            messageFeed,
            messageFeedItems,
            usersTyping: usersTyping.filter(v => v)
        });

        if(scrollToBottom) {
            scrollViewRef.current.scrollToEnd({animated: false});
        }
    }, [setMessageFeedState, messageFeedId, fetchTimeout.current]);

    const setupForSubmit = useCallback((messageSubmission = false) => {
        setIsSubmitting(true);
        if (messageSubmission) {
            setIsSubmittingMessage(true);
        }
        if (fetchTimeout.current) {
            clearTimeout(fetchTimeout.current);
            fetchTimeout.current = null;
        }
    }, [setIsSubmitting, fetchTimeout.current])

    const fetchData = useCallback((scrollToBottom = false, force = false) => {
        if (!isSubmitting || force) {
            if(fetchDataRequestSource.current) {
                fetchDataRequestSource.current.cancel('Request Canceled');
            }
            // create new cancel token
            fetchDataRequestSource.current = CancelToken.source();

            //Clear existing timeout
            clearTimeout(fetchTimeout.current);

            MessagesService.getMessageFeed(organizationId, messageFeedId, fetchDataRequestSource.current?.token, {messages: true, typing: true})
                .then(({messageFeed, messages, usersTyping}) => {
                    updateMessageFeed(messageFeed, messages, scrollToBottom, usersTyping)
                    fetchTimeout.current = setTimeout(fetchData, 15000);
                })
                .catch(e => {
                    clearTimeout(fetchTimeout.current);
                    if (!e.__CANCEL__) {
                        console.log('Error fetching message feeds: ', e);
                    }
                    // fetchTimeout.current = setTimeout(fetchData, 15000);
                });
        }
    }, [organizationId, messageFeedId, isSubmitting, fetchDataRequestSource.current, fetchTimeout.current]);

    const setFeedTyping = useCallback((typing) => {
        if (!isSubmitting) {
            let derp = typingRequestSource.current?.cancel('Request Canceled');
            // create new cancel token
            typingRequestSource.current = CancelToken.source();

            MessagesService.setUserTyping(organizationId, messageFeedId, typing, typingRequestSource.current?.token)
                .then((response) => {
                    if(typing) {
                        // Refresh typing every 15s
                        typingTimeout.current = setTimeout(function() {setFeedTyping(true);}, 15000);
                    }
                })
                .catch(e => {
                    clearTimeout(typingTimeout.current);
                    if (!e.__CANCEL__) {
                        console.log('Error setting typing: ', e);
                    }
                })
        }
    }, [typingRequestSource.current, typingTimeout.current]);

    const resolveMessageFeed = useCallback(() => {
        if (!isSubmitting) {
            setupForSubmit()

            if(fetchDataRequestSource.current) {
                fetchDataRequestSource.current.cancel('Request Canceled');
            }

            if(resolveRequestSource.current) {
                resolveRequestSource.current.cancel('Request Canceled');
            }

            //Clear existing timeout
            clearTimeout(fetchTimeout.current);

            resolveRequestSource.current = CancelToken.source();

            MessagesService.resolveMessageFeed(organizationId, messageFeedId, resolveRequestSource.current?.token)
                .then(({messageFeed, messages}) => {
                    setIsSubmitting(false);
                    updateMessageFeed(messageFeed, messages);
                    fetchTimeout.current = setTimeout(fetchData, 15000);
                })
                .catch(e => {
                    setIsSubmitting(false);
                    if (!e.__CANCEL__) {
                        console.log('Error fetching resolving message feed: ', e);
                    }
                });
        }

    }, [messageFeedId, organizationId, isSubmitting, setupForSubmit, fetchData, fetchTimeout.current, fetchDataRequestSource.current]);

    const toggleMessageFeedBlocked = useCallback(() => {
        if (!isSubmitting) {
            setupForSubmit()

            if(fetchDataRequestSource.current) {
                fetchDataRequestSource.current.cancel('Request Canceled');
            }

            //Clear existing timeout
            clearTimeout(fetchTimeout.current);

            MessagesService.toggleMessageFeedBlocked(organizationId, messageFeedId)
                .then(({messageFeed, messages}) => {
                    setIsSubmitting(false);
                    updateMessageFeed(messageFeed, messages, true);
                    fetchTimeout.current = setTimeout(fetchData, 15000);
                });
        }

    }, [messageFeedId, organizationId, isSubmitting, setupForSubmit, fetchData, fetchTimeout.current, fetchDataRequestSource.current]);

    const submitMessageFeedNote = useCallback((note) => {
        if (!isSubmitting) {
            setupForSubmit()

            if(fetchDataRequestSource.current) {
                fetchDataRequestSource.current.cancel('Request Canceled');
            }

            //Clear existing timeout
            clearTimeout(fetchTimeout.current);

            MessagesService.addMessageFeedNote(organizationId, messageFeedId, note)
                .then(({messageFeed, messages}) => {
                    setIsSubmitting(false);
                    updateMessageFeed(messageFeed, messages, true);
                    fetchTimeout.current = setTimeout(fetchData, 15000);
                });
        }

    }, [messageFeedId, organizationId, isSubmitting, setupForSubmit, fetchData, fetchTimeout.current, fetchDataRequestSource.current]);

    const submitNewMessage = useCallback(() => {
        if (!isSubmitting) {
            setupForSubmit(true);

            if(fetchDataRequestSource.current) {
                fetchDataRequestSource.current.cancel('Request Canceled');
            }

            //Clear existing timeout
            clearTimeout(fetchTimeout.current);

            MessagesService.sendNewMessage(organizationId, messageFeedId, newMessageBody, newMessageAttachments)
                .then(({messageFeed, messages}) => {
                    setNewMessageBody('');
                    setNewMessageAttachments([]);
                    setIsSubmitting(false);
                    setIsSubmittingMessage(false);
                    updateMessageFeed(messageFeed, messages, true);
                    fetchTimeout.current = setTimeout(fetchData, 15000);
                }).finally(() => {
                    //note: for some reason this wasn't working in the .then callback.
                    if(emojisActive) {
                        setEmojisActive(false);
                    }
            });
        }

    }, [messageFeedId, organizationId, isSubmitting, newMessageBody, newMessageAttachments, fetchData, fetchTimeout.current, fetchDataRequestSource.current]);

    const handleAttachmentImageUpload = useCallback(async (results) => {
        let file = results[0];
        let formData = new FormData();
        formData.append('image', file);

        let fileName = null;
        try {
            fileName = await MessagesService.uploadImage(organizationId, formData);
            setNewMessageAttachments(current => {
                return [
                    ...current,
                    fileName
                ];
            });
        } catch (error) {
            console.log('uploadError: ', error);
        }
    }, [organizationId]);

    const removeAttachment = useCallback((attachment) => {
        setNewMessageAttachments(current => {
            const attachmentIndex = current.indexOf(attachment);
            current.splice(attachmentIndex, 1);

            return [...current];
        })
    }, [newMessageAttachments])

    useEffect(() => {
        setMessageFeedState({
            messageFeed: {},
            messageFeedItems: [],
            usersTyping: []
        });
        setIsSubmitting(false);
        fetchData(true, true);
        return () => {
            setIsSubmitting(false);
            if(fetchDataRequestSource.current) {
                fetchDataRequestSource.current.cancel();
            }
            if(resolveRequestSource.current) {
                resolveRequestSource.current.cancel();
            }
        };
    }, [organizationId, messageFeedId]);

    useEffect(() => {
        if(newMessageBody.length > 0 || newMessageAttachments.length > 0
            || emojisActive || scriptModalActive || noteModalOpen
        ) {
            clearTimeout(typingTimeout.current);
            typingTimeout.current = setTimeout(function() {setFeedTyping(true);}, 5000);
        } else {
            clearTimeout(typingTimeout.current);
            typingTimeout.current = setTimeout(function() {setFeedTyping(false);}, 5000);
        }
    }, [newMessageBody, newMessageAttachments, emojisActive, scriptModalActive, noteModalOpen]);

    if (messageFeedState.messageFeed.message_feed_id) {
        return (
            <View style={[styles.messageFeedWrapper]}>
                <View style={[styles.messageFeedContainer]}>
                    <TouchableOpacity
                        style={[styles.messageFeedStatusContainer, (messageFeedState.messageFeed.message_feed_resolved == 1 ? styles.messageFeedResolved : {})]}
                        onPress={resolveMessageFeed}
                    >
                        <AppText style={[styles.messageFeedStatusText]}>
                            {
                                messageFeedState.messageFeed.message_feed_resolved == 1 ?
                                    'This conversation has been resolved'
                                    :
                                    'Mark this task as resolved'
                            }
                        </AppText>
                        {
                            messageFeedState.messageFeed.message_feed_resolved == 1 ?
                                <MessageFeedResolvedSvg color="#FFFFFF"/>
                                :
                                <MessageFeedUnresolvedSvg color="#FFFFFF"/>
                        }
                    </TouchableOpacity>
                    <View style={[styles.customerInfoTop]}>
                        <TouchableOpacity
                            style={styles.mobileBackToListAction}
                            onPress={() => {
                                navigate('MessageFlow', {messageFeedId: null})
                            }}
                        >
                            <ChevronLeftBlueSvg/>
                        </TouchableOpacity>
                        <Avatar
                            source={{uri: AVATAR_MESSENGER}}
                            width={40}
                            style={{marginRight: 20}}
                        />
                        <View style={[basicStyles.flexScale]}>
                            <TextInput
                                style={[styles.contactNameTextInput]}
                                placeholder="Contact Name"
                                value={messageFeedState.messageFeed.message_feed_contact_name || ''}
                                disabled={true}
                            />
                            <AppText style={[styles.contactPhoneNumber]}>
                                {messageFeedState.messageFeed.message_feed_phone_number}
                            </AppText>
                        </View>
                        <TouchableOpacity onPress={() => {
                            setCustomerInfoActive(true);
                        }}>
                            <InfoSvg/>
                        </TouchableOpacity>
                    </View>
                    <ScrollView
                        ref={scrollViewRef}
                        style={[styles.messagesContainer]}>
                        {
                            messageFeedState.messageFeedItems.map((messageFeedItem) => {
                                if(messageFeedItem.feedItemType === 'message') {
                                    const type = messageFeedItem.message_bound_type;
                                    const isInbound = type === 'inbound';
                                    const isOutbound = type === 'outbound';
                                    const typeStyles = isInbound ? inboundStyles : outboundStyles;
                                    let messageSender;
                                    let avatarUri = AVATAR_MESSENGER;
                                    if (isInbound) {
                                        messageSender = messageFeedState.messageFeed.message_feed_contact_name ? messageFeedState.messageFeed.message_feed_contact_name : formatPhoneNumber(messageFeedState.messageFeed.message_feed_phone_number_formatted);
                                    } else if (isOutbound) {
                                        if (messageFeedItem.user_organization_id) {
                                            messageSender = `${messageFeedItem.profile_first_name} ${messageFeedItem.profile_last_name}`;
                                            avatarUri = messageFeedItem.user_avatar_file ? AVATAR_PATH_USER + messageFeedItem.user_avatar_file : AVATAR_MESSENGER;
                                        } else {
                                            messageSender = messageFeedState.messageFeed.organization_title;
                                            avatarUri = messageFeedState.messageFeed.organization_thumbnail ? AVATAR_PATH_ORG + messageFeedState.messageFeed.organization_thumbnail : AVATAR_MESSENGER;
                                        }
                                    }

                                    return (
                                        <View key={`message-${messageFeedItem.message_id}`}
                                              style={[styles.messageSlot, typeStyles.messageSlot]}>
                                            <View style={[styles.messageWrapper]}>
                                                <View style={[styles.messageInnerWrapper]}>
                                                    {
                                                        isInbound ?
                                                            <Avatar
                                                                source={{uri: avatarUri}}
                                                                width={40}
                                                                style={{marginRight: 20}}
                                                            />
                                                            : null
                                                    }
                                                    <View style={[styles.messageContentWrapper]}>
                                                        <View
                                                            style={[styles.messageBodyWrapper, typeStyles.messageBodyWrapper]}>
                                                            <AppText
                                                                style={[styles.messageText, typeStyles.messageText]}>
                                                                {messageFeedItem.message_body}
                                                            </AppText>
                                                        </View>
                                                        {
                                                            messageFeedItem.attachments.length ?
                                                                <View style={[styles.messageAttachments]}>
                                                                    {
                                                                        messageFeedItem.attachments.map(attachment => {
                                                                            return (
                                                                                <TouchableOpacity
                                                                                    key={attachment.message_attachment_id}
                                                                                    style={styles.messageAttachmentWrapper}
                                                                                    onPress={() => {
                                                                                        window.open(attachment.message_attachment_external_path ? attachment.message_attachment_external_path : `${MESSAGE_ATTACHMENT_FILE_PATH}${attachment.message_attachment_file_name}`, '_blank');
                                                                                    }}
                                                                                >
                                                                                    <Image
                                                                                        style={[styles.messageAttachment]}
                                                                                        source={attachment.message_attachment_external_path ? attachment.message_attachment_external_path : `${MESSAGE_ATTACHMENT_FILE_PATH}${attachment.message_attachment_file_name}`}
                                                                                    />
                                                                                </TouchableOpacity>
                                                                            );
                                                                        })
                                                                    }
                                                                </View>
                                                                : null
                                                        }
                                                        <View
                                                            style={[basicStyles.flexRow, basicStyles.justifyContentSpaceBetween]}>
                                                            <AppText
                                                                style={[styles.messageInfoText, typeStyles.messageInfoText, {marginRight: 10}]}>
                                                                {messageSender}
                                                            </AppText>
                                                            <AppText
                                                                style={[styles.messageInfoText, typeStyles.messageInfoText]}>
                                                                {formatDate(messageFeedItem.message_created_timestamp, 'MM/DD/YYYY hh:mm')}
                                                            </AppText>
                                                        </View>
                                                    </View>
                                                    {
                                                        isOutbound ?
                                                            <Avatar
                                                                source={{uri: avatarUri}}
                                                                width={40}
                                                                style={{marginLeft: 20}}
                                                            />
                                                            : null
                                                    }
                                                </View>
                                            </View>
                                        </View>
                                    );
                                } else if (messageFeedItem.feedItemType === 'status') {
                                    return (
                                        <View
                                            key={`status-${messageFeedItem.message_feed_status_id}`}
                                            style={[styles.resolvedContentContainer]}>
                                            {
                                                messageFeedItem.message_feed_resolved == 1 ?
                                                <MessageFeedResolvedSvg/>
                                                :
                                                <MessageFeedUnresolvedSvg/>
                                            }
                                            <AppText style={[styles.resolvedText]}>
                                                {messageFeedItem.message_feed_resolved == 1 ? 'Resolved ' : 'Reopened '}
                                                by {messageFeedItem.profile_first_name} {messageFeedItem.profile_last_name} on {formatDate(messageFeedItem.message_feed_status_timestamp)} @ {formatDate(messageFeedItem.message_feed_status_timestamp, 'hh:mm a')}
                                            </AppText>
                                        </View>
                                    );
                                } else if(messageFeedItem.feedItemType === 'note') {
                                    return (
                                        <View
                                            key={`note-${messageFeedItem.message_feed_note_id}`}
                                            style={[styles.noteWrapperOuter]}
                                        >
                                            <View>
                                                <AppText>
                                                    Note
                                                    by {`${messageFeedItem.profile_first_name} ${messageFeedItem.profile_last_name}`} on {formatDate(messageFeedItem.message_feed_note_timestamp)} @ {formatDate(messageFeedItem.message_feed_note_timestamp, 'hh:mm a')}:
                                                </AppText>
                                                <AppText style={[
                                                    styles.noteContent,
                                                    messageFeedItem.message_feed_note_is_block == 1 ? styles.blockNote : {},
                                                    messageFeedItem.message_feed_note_is_unblock == 1 ? styles.unblockNote : {}
                                                ]}>
                                                    {messageFeedItem.message_feed_note_content}
                                                </AppText>
                                            </View>
                                        </View>
                                    );
                                }
                            })
                        }
                    </ScrollView>
                    <View style={[styles.messageFeedBottom]}>
                        <ActivityIndicator style={[
                            styles.submittingNewMessageIndicator,
                            isSubmittingMessage ? {} : {opacity: 0}
                        ]} size="large"/>
                        <View style={[styles.usersTypingMessage]}>
                            {messageFeedState.usersTyping.length > 0 && (
                                <AppText>{messageFeedState.usersTyping.join(', ')} {messageFeedState.usersTyping.length > 1 ? "are" : "is"} typing...</AppText>
                            )}
                        </View>
                        <View style={[styles.messageInputWrapper]}
                              onClick={() => {
                                  messageInputRef.current.focus();
                              }}
                        >
                            <View style={[styles.messageInputWrapperInner]}>
                                <AutoExpandingTextInput
                                    onKeyPress={(event) => {
                                        if (event.ctrlKey && event.key === 'Enter') {
                                            submitNewMessage();
                                        }
                                    }}
                                    ref={messageInputRef}
                                    placeholder="Message"
                                    style={[styles.messageInput]}
                                    onChangeText={value => {
                                        setNewMessageBody(value);
                                    }}
                                    value={newMessageBody}
                                />
                                <TouchableOpacity onPress={() => {
                                    submitNewMessage();
                                }}>
                                    <BlueCircleArrowSvg width={40}/>
                                </TouchableOpacity>
                            </View>
                            <EmojiSelector
                                active={emojisActive}
                                onClose={() => {
                                    setEmojisActive(false);
                                }}
                                callback={(emoji) => {
                                    setNewMessageBody(current => {
                                        return current + emoji;
                                    });
                                }}
                            />
                            {
                                newMessageAttachments.length > 0 ?
                                    <View style={[styles.messageAttachments]}>
                                        {
                                            newMessageAttachments.map(messageAttachment => {
                                                return (
                                                    <TouchableOpacity
                                                        key={messageAttachment}
                                                        style={[styles.messageAttachmentWrapper]}
                                                        onPress={() => {
                                                            window.open(`${MESSAGE_ATTACHMENT_FILE_PATH}${messageAttachment}`, '_blank');
                                                        }}
                                                    >
                                                        <Image
                                                            style={[styles.messageAttachment]}
                                                            source={`${MESSAGE_ATTACHMENT_FILE_PATH}${messageAttachment}`}
                                                        />
                                                        <TouchableOpacity
                                                            style={[styles.removeAttachmentAction]}
                                                            onPress={(e) => {
                                                                e.stopPropagation();
                                                                removeAttachment(messageAttachment);
                                                            }}
                                                        >
                                                            <RemoveSvg width={15} height={15}/>
                                                        </TouchableOpacity>
                                                    </TouchableOpacity>
                                                );
                                            })
                                        }
                                    </View>
                                    : null
                            }
                        </View>
                        <View style={[styles.actionsContainer]}>
                            <ActionIcon
                                action={handleAttachmentImageUpload}
                                svg={<AttachmentsSvg/>}
                                label="Attach Image or file"
                                forUpload={true}
                            />
                            <ActionIcon
                                action={() => {
                                    console.log('scripts pressed');
                                    setScriptModalActive(true);
                                }}
                                svg={<ScriptsSvg/>}
                                label="Scripts"
                            />
                            <ActionIcon
                                action={() => {
                                    console.log('Notes pressed');
                                    setNoteModalOpen(true);
                                }}
                                svg={<NotesSvg/>}
                                label="Make a private note"
                            />
                            <ActionIcon
                                action={() => {
                                    console.log('Emojis pressed');
                                    setEmojisActive(!emojisActive);
                                }}
                                svg={<EmojisSvg/>}
                                label="Emojis"
                            />
                        </View>
                    </View>
                </View>
                <View style={[
                    styles.messageFeedCustomerInfoContainer,
                    customerInfoActive ? styles.messageFeedCustomerInfoContainerActive : {}
                ]}>
                    <MessageFeedCustomerInfo
                        organizationId={organizationId}
                        messageFeedId={messageFeedId}
                        setBlockModalActive={setBlockModalActive}
                        messageFeedBlocked={messageFeedState.messageFeed.message_feed_blocked == 1}
                        updateSelectedMessageInList={updateSelectedMessageInList}
                        setCustomerInfoActive={setCustomerInfoActive}
                    />
                </View>
                {
                    blockModalActive ?
                        <BlockModal
                            messageFeed={messageFeedState.messageFeed}
                            handleSubmit={() => {
                                toggleMessageFeedBlocked();
                                setBlockModalActive(false);
                            }}
                            handleClose={() => {
                                setBlockModalActive(false);
                            }}
                            messageFeedBlocked={messageFeedState.messageFeed.message_feed_blocked == 1}
                        />
                        : null
                }
                {
                    noteModalOpen ?
                        <CreateNewNoteModal
                            messageFeed={messageFeedState.messageFeed}
                            handleSubmit={(note) => {
                                submitMessageFeedNote(note);
                                setNoteModalOpen(false);
                            }}
                            handleClose={() => {
                                setNoteModalOpen(false);
                            }}
                        />
                        : null
                }
                <ScriptModal
                    active={scriptModalActive}
                    organizationId={organizationId}
                    onClose={() => {
                        setScriptModalActive(false);
                    }}
                    onSubmit={output => {
                        setNewMessageBody(output);
                    }}
                />
            </View>
        );
    }

    return (
        <View style={[basicStyles.flexCenterContent, basicStyles.flexScale,]}>
            <ActivityIndicator size="large"/>
        </View>
    );
}

export default MessageFeed;

const styles = EStyleSheet.create({
    messageFeedWrapper: {
        width: '100%',
        flex: 1,
        flexDirection: 'row'
    },
    messageFeedContainer: {
        flex: 1,
        borderRightWidth: 1,
        borderRightColor: 'rgb(226, 233, 244)',
        backgroundColor: '#f8fafd',
    },
    messageFeedCustomerInfoContainer: {
        width: '33.333%',
        backgroundColor: '#f8fafd'
    },
    messageFeedStatusContainer: {
        flex: 'auto',
        flexGrow: 0,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: 8,
        paddingBottom: 8,
        paddingRight: 24,
        paddingLeft: 24,
        backgroundColor: '#F04438',
    },
    messageFeedStatusText: {
        color: '#FFF',
        fontFamily: 'SourceSansPro-light'
    },
    messageFeedResolved: {
        backgroundColor: '#12B76A'
    },
    messagesContainer: {
        padding: 24,
        flex: 1
    },
    messageSlot: {
        marginBottom: 20
    },
    messageWrapper: {
        maxWidth: '85%'
    },
    messageInnerWrapper: {
        flexDirection: 'row',
        alignItems: 'flex-start'
    },
    messageContentWrapper: {
        flex: 1
    },
    messageBodyWrapper: {
        borderRadius: 8,
        flex: 1,
        padding: 8,
        marginBottom: 5
    },
    messageText: {
        textWrap: 'wrap',
    },
    messageInfoText: {
        fontFamily: 'SourceSansPro-light'
    },
    resolvedContentContainer: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        marginTop: 10,
        marginBottom: 10,
    },
    resolvedText: {
        marginLeft: 5
    },
    messageFeedBottom: {
        padding: 24
    },
    usersTypingMessage: {
        paddingLeft: 24,
        paddingRight: 76,
        position: 'absolute',
        bottom: '90%'
    },
    actionsContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    messageInputWrapper: {
        backgroundColor: '#FFFFFF',
        paddingLeft: 20,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 5,
        borderWidth: 1,
        borderColor: '#E2E9F4',
        borderRadius: 30,
        width: '100%',
        marginBottom: 10,
    },
    messageInputWrapperInner: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%'
    },
    messageAttachments: {
        marginTop: 10,
        flexDirection: 'row',
        width: '100%',
    },
    messageAttachmentWrapper: {
        position: 'relative',
        marginRight: 10,
    },
    messageAttachment: {
        width: 44,
        height: 44,
        borderWidth: 1,
        borderColor: '#E2E9F4',
        borderRadius: 6,
    },
    messageInput: {
        flex: 1,
        marginLeft: 10,
        marginRight: 10,
    },
    removeAttachmentAction: {
        position: 'absolute',
        right: -7,
        top: -4
    },
    submittingNewMessageIndicator: {
        position: 'absolute',
        bottom: '90%',
        right: 39
    },
    widthThird: {
        width: '33.333%'
    },
    noteWrapperOuter: {
        width: '100%',
        alignItems: 'center',
        marginTop: 35,
        marginBottom: 15,
        borderWidth: 1,
        borderColor: 'rgb(226, 233, 244)',
        backgroundColor: '#FFF',
        padding: 6
    },
    noteContent: {
        fontFamily: 'SourceSansPro-Light',
        color: '#9EABCC',
    },
    blockNote: {
        color: '#F04438'
    },
    unblockNote: {
        color: '#12B76A'
    },
    customerInfoTop: {
        padding: 15,
        flexDirection: 'row',
        alignItems: 'flex-start',
        borderBottomWidth: 1,
        borderBottomColor: '#E8EDF6',
        display: 'none'
    },
    contactNameTextInput: {
        fontSize: 16,
        width: '100%',
        marginBottom: 3,
        fontFamily: 'SourceSansPro-SemiBold',
    },
    contactPhoneNumber: {
        fontSize: 12,
        fontFamily: 'SourceSansPro-Light',
        color: '#9EABCC',
        textDecorationLine: "underline",
        textDecorationStyle: "solid",
        textDecorationColor: "#9EABCC"
    },
    mobileBackToListAction: {
        marginRight: 5,
        padding: 5,
        marginTop: 12,
        display: 'none'
    },
    '@media (max-width: 1000)': {
        messageFeedCustomerInfoContainer: {
            width: '100%',
            position: 'absolute',
            left: '100%',
            top: 34,
            bottom: 0,
        },
        messageFeedCustomerInfoContainerActive: {
            transform: [{translateX: '-100%'}]
        },
        customerInfoTop: {
            display: 'flex'
        }
    },
    '@media (max-width: 550)': {
        mobileBackToListAction: {
            display: 'flex'
        },
    }
});

const inboundStyles = EStyleSheet.create({
    messageSlot: {
        alignItems: 'flex-start',
    },
    messageBodyWrapper: {
        backgroundColor: '#DCDCDB'
    },
    messageText: {
        color: '#333333'
    },
    messageInfoText: {
        color: '#333333'
    }
});

const outboundStyles = EStyleSheet.create({
    messageSlot: {
        alignItems: 'flex-end',
    },
    messageBodyWrapper: {
        backgroundColor: '#0065DB'
    },
    messageText: {
        color: '#F8FAFD'
    },
    messageInfoText: {
        color: '#0065DB'
    }
});
