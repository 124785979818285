import React from "react";
import {useCallback, useEffect, useRef} from 'react';
import {View, StyleSheet} from 'react-native';
import ReactDOM from 'react-dom';

function Modal({
                   active = false,
                   onClose = () => {},
                   children = null,
                   style = {},
                   portalElementId = 'overlay-portal',
                   closeOnOverlayClick = true
               }) {
    const containerNode = useRef(document.getElementById(portalElementId));

    /**
     * Handle Close
     */

    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);


    /**
     * Trap Modal Clicks
     */

    const handleModalClick = useCallback((e) => {
        e.stopPropagation();
    }, []);

    /**
     * Prevent Body Scroll
     */

    useEffect(() => {
        document.body.style.overflowY = active ? 'hidden' : 'scroll';
    }, [active]);

    /**
     * Re-enable scroll on unmount
     */
    useEffect(() => {
        return () => {
            document.body.style.overflowY = 'scroll';
        };
    }, []);

    const modal = (
        <View style={[styles.overlay, !active ? styles.inactive : {}, style]} onClick={() => {
            if(closeOnOverlayClick) {
                handleClose();
            }
        }}>
            <View onClick={handleModalClick}>
                {children}
            </View>
        </View>
    );

    return ReactDOM.createPortal(modal, containerNode.current);
}

export default Modal;

const styles = StyleSheet.create({
    overlay: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(155, 168, 202, .7)',
        zIndex: 1
    },
    inactive: {
        display: 'none'
    }
});
