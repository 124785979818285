import React, {forwardRef, createRef} from 'react';
import {TextInput, View, ActivityIndicator, StyleSheet} from 'react-native';

import AppText from './AppText';
import {inputStyles} from '../styles/input';
import {basicStyles} from '../styles/basic';

const AppTextInput = forwardRef((
	{
		label,
		onChangeText,
		onSubmitEditing,
		value = '',
		blurOnSubmit = false,
		autoCapitalize = 'none',
		returnKeyType = 'next',
		outline = false,
		secureTextEntry = false,
		wrapperStyle = {},
		labelStyle = {},
		inputStyle = {},
		actionButton = null,
		loading = false,
		...rest
	},
	ref,
) => {
	if (!ref) {
		ref = createRef();
	}

	const ActionButton = () => {
		return actionButton;
	};

	return (
		<View
			style={[
				inputStyles.inputWrapper,
				basicStyles.flexRow,
				basicStyles.alignContentCenter,
				wrapperStyle,
			]}
			onClick={() => ref.current.focus()}
			onPress={() => ref.current.focus()}>

			<View style={basicStyles.flexScale}>
				<AppText style={[inputStyles.inputLabel, labelStyle]}>
					{label}
				</AppText>
				<TextInput
					ref={ref}
					value={value}
					style={[inputStyles.input, inputStyle]}
					blurOnSubmit={blurOnSubmit}
					autoCapitalize={autoCapitalize}
					returnKeyType={returnKeyType}
					onChangeText={onChangeText}
					onSubmitEditing={onSubmitEditing} // where something like () => secondInput.focus() goes
					outline={outline}
					secureTextEntry={secureTextEntry}
					{...rest}
				/>
			</View>
			{
				actionButton ?
					<ActionButton/>
					: null
			}
			{
				loading ?
					<ActivityIndicator style={styles.loadingIndicator} color="#467AFF"/>
					: null
			}
		</View>
	);
});

export default AppTextInput;

const styles = StyleSheet.create({
	loadingIndicator: {
		position: 'absolute',
		top: 5,
		right: 5,
		width: 24,
		height: 24
	}
});
