import * as React from "react"
import Svg, { G, Circle, Path } from "react-native-svg"

function DownloadSvg(props) {
	return (
		<Svg width={35} height={35} viewBox="0 0 35 35" {...props}>
			<G data-name="Group 287" transform="rotate(-90 475.5 763)">
				<Circle
					data-name="Ellipse 2"
					cx={17.5}
					cy={17.5}
					r={17.5}
					transform="translate(1203.5 287.5)"
					fill="#f3f5fa"
				/>
				<Path
					data-name="Path 397"
					d="M1223.265 310.331v-2.823h4.235v-4.235h-4.235v-2.824l-4.941 4.941zm-6.353-9.882h-1.412v9.882h1.412z"
					fill="#0b2774"
					opacity={0.187}
				/>
			</G>
		</Svg>
	)
}

export default DownloadSvg;
