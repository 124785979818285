import React, {createRef, useState} from 'react';
import DatePicker from 'react-datepicker';
import {View} from 'react-native';

import {inputStyles} from '../styles/input';
import {basicStyles} from '../styles/basic';
import AppText from './AppText';

function AppDatePicker({label, selectedDate, onChange, popperMods, wrapperStyle = {}, labelStyle = {}, alterZIndexOnFocus = false}) {
	const [inputFocused, setInputFocused] = useState(false);
	const ref = createRef();
	const popperModifiers = popperMods ? popperMods : {
		offset: {
			enabled: true,
			offset: "5px, 10px"
		},
		preventOverflow: {
			enabled: true,
			escapeWithReference: false,
			boundariesElement: "viewport"
		}
	};

	return (
		<View
			style={[
				inputStyles.inputWrapper,
				basicStyles.flexRow,
				basicStyles.alignContentCenter,
				{height: 60},
				inputFocused && alterZIndexOnFocus ? {zIndex: 1} : {},
				wrapperStyle,
			]}
			onClick={() => {
				if (!inputFocused) {
					ref.current.input.focus();
				}
			}}
			onPress={() => {
				if (!inputFocused) {
					ref.current.input.focus();
				}
			}}
		>
			<View style={[basicStyles.flexScale, basicStyles.flexRow, basicStyles.justifyContentSpaceBetween, basicStyles.alignContentCenter]}>
				<AppText style={[inputStyles.inputLabel, labelStyle, {marginRight: 10, marginBottom: 0}]}>
					{label}:
				</AppText>

				<DatePicker
					ref={ref}
					selected={selectedDate}
					onChange={value => {
						setInputFocused(false);
						onChange(value);
					}}
					onFocus={() => setInputFocused(true)}
					onBlur={() => setInputFocused(false)}
					dateFormat="dd MMMM yyyy"
					popperModifiers={popperModifiers}
				/>
			</View>
		</View>
	);
}

export default AppDatePicker;
