import React, {useState} from 'react';
import {StyleSheet, TouchableOpacity, View} from "react-native";

import AppText from "../../../components/AppText";
import UploadOverlayHidden from "../../../components/UploadOverlayHidden";

function ActionIcon({svg, label, action, forUpload = false}) {
    const [hovered, setHovered] = useState(false);

    return (
        <View
            style={styles.actionIconWrapper}
        >
            <TouchableOpacity
                onPress={forUpload ? () => null : action}
                style={styles.actionIconInnerWrapper}
                onMouseEnter={() => {
                    setHovered(true);
                }}
                onMouseLeave={() => {
                    setHovered(false);
                }}>
                {svg}
                {
                    forUpload ?
                        <UploadOverlayHidden
                            callback={action}
                        />
                        :
                        null
                }
            </TouchableOpacity>
            {
                hovered ?
                    <View style={styles.actionTextContainer}>
                        <AppText style={styles.actionText}>
                            {label}
                        </AppText>
                    </View>
                    : null
            }
        </View>
    );
}

export default ActionIcon;

const styles = StyleSheet.create({
    actionTextContainer: {
        position: 'absolute',
        top: -36,
        left: 0,
        padding: 8,
        borderWidth: 1,
        borderColor: '#E2E9F4',
        borderRadius: 8,
        backgroundColor: '#FFFFFF',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.1,
        shadowRadius: 9.11,
    },
    actionText: {
        color: '#3CA6F5',
        fontSize: 12,
        whiteSpace: 'nowrap',
    },
    actionIconWrapper: {
        marginRight: 10,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 1,
        borderRadius: 8,
        borderColor: '#3CA6F5',
        position: 'relative'
    },
    actionIconInnerWrapper: {
        height: 44,
        minWidth: 44,
        padding: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },
});
