import React from "react"
import Svg, {Path, Defs, LinearGradient, Stop, G, Circle} from "react-native-svg"

function ViewSvg(props) {
	return (
		<Svg width={30.447} height={30.447} viewBox="0 0 30.447 30.447" {...props}>
			<Defs>
				<LinearGradient
					id="prefix__a"
					x1={-0.195}
					y1={0.5}
					x2={1.173}
					y2={0.5}
					gradientUnits="objectBoundingBox"
				>
					<Stop offset={0} stopColor="#1b8ce9" />
					<Stop offset={1} stopColor="#467aff" />
				</LinearGradient>
			</Defs>
			<G data-name="Group 958" transform="translate(-1117.437 -454.437)">
				<Circle
					data-name="Ellipse 32"
					cx={15.223}
					cy={15.223}
					transform="translate(1117.437 454.437)"
					fill="url(#prefix__a)"
					r={15.223}
				/>
				<Path
					data-name="Path 338"
					d="M1132.194 464.32a8.236 8.236 0 00-7.66 5.223 8.228 8.228 0 0015.32 0 8.236 8.236 0 00-7.66-5.223zm0 8.7a3.482 3.482 0 113.482-3.482 3.483 3.483 0 01-3.482 3.482zm0-5.571a2.089 2.089 0 102.089 2.089 2.086 2.086 0 00-2.089-2.084z"
					fill="#fff"
				/>
			</G>
		</Svg>
	)
}

export default ViewSvg;
