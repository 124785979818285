import React from 'react';
import {View} from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

function PageContentContainer({style = {}, children, ...rest}) {
	return (
		<View style={[styles.pageContentContainer, style]} {...rest}>
			{children}
		</View>
	);
}

export default PageContentContainer;

const styles = EStyleSheet.create({
	pageContentContainer: {
		paddingTop: 70,
		paddingLeft: 45,
		paddingRight: 45,
		paddingBottom: 35,
	},
	'@media (max-width: 768)': {
		pageContentContainer: {
			paddingTop: 40,
			paddingLeft: 20,
			paddingRight: 20,
			paddingBottom: 25,
		}
	}
});
