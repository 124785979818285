import Http from "../utilities/Http";
import {API_ROOT} from "../config";

export default {
	async getTimeZones() {
		let response = await Http().get(API_ROOT + '/time-zones');

		return response.data.timeZones;
	}
};
