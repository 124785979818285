import {notificationActionTypes} from "./notificationActions";

const initialState = {
    notifications: [],
    notificationIndex: 0,
    lastNotification: null,
    lastNotificationTimestamp: null,
};

const notificationReducer = (state = initialState, action) => {
    let newState;
    switch (action.type) {
        case notificationActionTypes.CYCLE_NOTIFICATION:
            if (state.lastNotification === action.payload && (Date.now() - state.lastNotificationTimestamp < 3000)) {

                return state;
            }
            newState = {...state};
            newState.lastNotification = action.payload;
            newState.lastNotificationTimestamp = Date.now();
            newState.notifications.push({notification: action.payload, key: newState.notificationIndex});
            newState.notificationIndex++;
            return newState;
        case notificationActionTypes.REMOVE_NOTIFICATION:
            newState = {...state};
            newState.notifications.splice(action.notificationIndex, 1);
            return newState;
        default:
            return state;
    }
};

export default notificationReducer;
