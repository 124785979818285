import React, {useEffect, useState} from 'react';
import {View} from 'react-native';
import {geocodeByAddress, getLatLng} from 'react-places-autocomplete';
import GoogleMapReact from 'google-map-react';
import MapIconSvg from './svgs/MapIconSvg';

function GeocodedAddressMap({
	address,
	height = 287,
	width = 850,
	wrapperStyle = {},
	onGoogleApiLoaded = ({map, maps}) => {},
}) {
	const [latLang, setLatLang] = useState({lat: null, lng: null});
	const [location, setLocation] = useState(null);

	useEffect(() => {
		if (address && address.trim()) {
			geocodeByAddress(address).then(results => {
				if (results.length > 0) {
					setLocation(results[0].geometry.location);
					getLatLng(results[0]).then(latLng => {
						setLatLang(latLng);
					});
				}
			});
		}
	}, [address]);

	return (
		<View style={{
			height,
			width,
			...wrapperStyle,
		}}>
			{
				!isNaN(latLang.lat) && !isNaN(latLang.lng) ?
					<GoogleMapReact
						defaultZoom={11}
						center={latLang}
						onGoogleApiLoaded={onGoogleApiLoaded}
					>
						<MapIconSvg
							lat={latLang.lat}
							lng={latLang.lng}
						/>
					</GoogleMapReact>
					: null
			}
		</View>
	);
}

export default GeocodedAddressMap;
