import React, {useState} from 'react';
import {navigate} from '../utilities/navigation';
import {View} from 'react-native';
import AppText from './AppText';
import TouchView from "./TouchView";
import EStyleSheet from 'react-native-extended-stylesheet';


function Pagination({screenName, style = {}, pageCount = 1, pageIndex = 1, paginationRange = [1]}) {
	const [isSmall, setIsSmall] = useState(false);

	const updatePaginationResults = (i) => {
		if(i == 'next') {
			pageIndex = (parseInt(pageIndex) + 1)
		} else if(i == 'last') {
			pageIndex = (parseInt(pageIndex) - 1);
		} else {
			pageIndex = i;
		}

		navigate(screenName, {
			currentPage: pageIndex
		})
	};

	const getPaginationItem = (index) => {
		return (
			<TouchView
				key={('pagination-' + index)}
				action={() => {
					updatePaginationResults(index)
				}}
			>
				<AppText style={[styles.paginationItem, (index == pageIndex ? styles.selectedPaginationItem : null)]}>{index}</AppText>
			</TouchView>
		)
	};

	let getPaginationItems = () => {
		var result = [];
		var i = paginationRange[0];
		var lastPagination = paginationRange[(paginationRange.length - 1)];

		for(i; i <= lastPagination; i++) {
			result.push(getPaginationItem(i));
		}


		if(lastPagination < pageCount) {
			var lastPage = getPaginationItem(pageCount);
			var lastPageContainer = (<View key={('pagination-last')} style={{flexDirection:'row', 'alignItems': 'flex-end'}}><AppText style={{marginBottom: 5}}>...</AppText>{lastPage}</View>);

			result.push(lastPageContainer);
		}

		return result;
	};


	const handleLayout = ({nativeEvent}) => {
		const {width} = nativeEvent.layout;
		setIsSmall(width <= 550);
	};

	const getDesktopLayout = () => {
		return (
			<View style={styles.layoutContainer}>
				<TouchView
					style={[styles.button]}
					action={() => {
						if(pageIndex != 1) {
							updatePaginationResults('last')
						}
					}}
				>
					<AppText style={[(pageIndex == 1 ? styles.inactive : null)]}>Last</AppText>
				</TouchView>
				<View style={styles.pageNumberContainer}>
					{

						getPaginationItems()
					}
				</View>
				<TouchView
					style={[styles.button]}
					action={() => {
						if(pageIndex < pageCount) {
							updatePaginationResults('next')}
					}
					}
				>
					<AppText style={[(pageIndex >= pageCount  ? styles.inactive : null)]}>Next</AppText>
				</TouchView>
			</View>
		);
	};

	const getMobileLayout = () => {
		return (
			<View style={styles.layoutContainerMobile}>
				<View style={styles.pageNumberContainer}>
					{

						getPaginationItems()
					}
				</View>
				<View style={styles.arrows}>
					<TouchView
						style={[styles.button]}
						action={() => {
							if(pageIndex != 1) {
								updatePaginationResults('last')
							}
						}}
					>
						<AppText style={[(pageIndex == 1 ? styles.inactive : null)]}>Last</AppText>
					</TouchView>
					<TouchView
						style={[styles.button]}
						action={() => {
							if(pageIndex < pageCount) {
								updatePaginationResults('next')}
						}
						}
					>
						<AppText style={[(pageIndex >= pageCount  ? styles.inactive : null)]}>Next</AppText>
					</TouchView>
				</View>
			</View>
		);
	};


	return (
		<View style={[styles.paginationContainer, style]} onLayout={handleLayout}>
			{
				!isSmall ? getDesktopLayout() : getMobileLayout()
			}
		</View>
	);
}

export default Pagination;

const styles = EStyleSheet.create({
	layoutContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		width:'100%',
	},
	layoutContainerMobile: {
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%'
	},
	paginationContainer: {
		flexDirection: 'row',
		paddingTop: 20,
		paddingLeft: 45,
		paddingRight: 45,
		paddingBottom: 20,
		backgroundColor: '#FFF',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	pageNumberContainer: {
		flexDirection: 'row'
	},
	button: {
		borderWidth: 1,
		borderColor: '#E2E9F4',
		borderRadius: 5,
		paddingTop: 5,
		paddingBottom: 5,
		paddingRight: 10,
		paddingLeft: 10
	},
	paginationItem: {
		paddingTop: 5,
		paddingBottom: 5,
		paddingRight: 10,
		paddingLeft: 10,
		marginLeft: 5,
		marginRight: 5,
		textAlign: 'center',
		justifyContent: 'center'
	},
	selectedPaginationItem: {
		backgroundColor: '#F8FAFD',
		borderRadius: 15
	},
	inactive: {
		color: '#E2E9F4'
	},
	'@media (max-width: 550)': {
		paginationContainer: {
			paddingTop: 0,
			paddingBottom: 0,
			paddingRight: 0,
			paddingLeft: 0
		},
		pageNumberContainer: {
			height: 90,
			width: '100%',
			alignItems: 'center',
			justifyContent: 'center',
			borderBottomColor: '#E2E9F4',
			borderBottomWidth: 1,
			padding:0
		},
		arrows: {
			flexDirection: 'row',
			height: 125,
			alignItems: 'center',
			justifyContent: 'center'
		},
		button: {
			marginLeft: 5,
			marginRight: 5,
		}
	}
});
