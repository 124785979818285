import React from 'react';
import {View, StyleSheet} from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

import AppText from './AppText';

function AccountSetupHeader({label, items, currentIndex, ...rest}) {
	return (
		<View style={styles.container} {...rest}>
			<AppText style={{color: 'white', fontSize: 22, fontFamily: 'SourceSansPro-Bold'}}>
				{label}
			</AppText>
			<View style={styles.itemsContainer}>
				{
					items.map((item, itemIndex) => {
						return (
							<View
								key={itemIndex}
								style={[
									styles.itemContainer,
								]}
							>
								<AppText style={itemIndex === currentIndex ? styles.itemTextCurrent : styles.itemText}>
									{item}
								</AppText>
								<View style={[styles.dot, ...(itemIndex === currentIndex ? [styles.dotCurrent] : [])]}/>
							</View>
						);
					})
				}
			</View>
			<View style={styles.itemsContainerMobile}>
				<AppText style={styles.itemText}>Step </AppText>
				<AppText style={styles.itemTextCurrent}>{currentIndex}/{items.length}</AppText>
			</View>
		</View>
	);
}

export default AccountSetupHeader;

const styles = EStyleSheet.create({
	itemContainer: {
		paddingBottom: 20,
		alignItems: 'center',
	},
	itemTextCurrent: {
		fontFamily: 'SourceSansPro-Bold',
		color: '#FFFFFF',
	},
	itemText: {
		color: 'rgba(255,255,255,.57)',
	},
	dot: {
		width: 9,
		height: 9,
		borderRadius: 9 / 2,
		borderWidth: 1,
		borderColor: 'rgba(255,255,255,.57)',
		position: 'absolute',
		bottom: 0,
	},
	dotCurrent: {
		borderColor: '#FFFFFF',
		backgroundColor: '#FFFFFF',
	},
	container: {
		height: 80,
		backgroundColor: '#2886ef',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingLeft: 80,
		paddingRight: 80,
	},
	itemsContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: 460,

	},
	itemsContainerMobile: {
		display: 'none'
	},
	'@media (max-width: 875)': {
		container: {
			height: 120,
			flexDirection: 'column',
			justifyContent: 'center',
		},
		itemsContainer: {
			marginTop: 15,
		}
	},
	'@media (max-width: 500)': {
		itemsContainer: {
			display: 'none',
		},
		itemsContainerMobile: {
			display: 'flex',
			flexDirection: 'row',
			marginTop: 15,
		}
	}
});
