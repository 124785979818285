import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {ScrollView, View} from 'react-native';

import StoreFrontsService from '../../services/StoreFrontsService';
import {AVATAR_PATH_STORE_FRONT, AVATAR_DEFAULT, AVATAR_PATH_ORG} from '../../config';
import PageTop from '../../components/PageTop';
import PageContentContainer from '../../components/PageContentContainer';
import ActionRowWithAvatar from '../../components/ActionRowWithAvatar';
import {basicStyles} from '../../styles/basic';
import AppText from '../../components/AppText';
import {textStyles} from '../../styles/text';
import Avatar from '../../components/Avatar';
import {CommonActions} from '@react-navigation/native';
import AppButton from '../../components/AppButton';
import OrganizationsService from '../../services/OrganizationsService';
import Pagination from "../../components/Pagination";
import EStyleSheet from 'react-native-extended-stylesheet';

function StoreFrontsList(props) {
	const [state, setState] = useState({
		storeFronts: [],
		organization: {...props.auth.organization},
		initialized: false,
		pageCount: 1,
		paginationRange: [1]
	});
	const {navigate} = props.navigation;
	const perPage = 10;

	let currentPage = props.route?.params?.currentPage ? parseInt(props.route.params.currentPage) : 1;

	useEffect(() => {

		const getData = async () => {
			let orgData;
			if (props.route?.params?.organizationId) {
				orgData = await OrganizationsService.getOrganization(props.route?.params?.organizationId);
			}
			const storeFrontsData = await StoreFrontsService.getStoreFrontsPaginated((props.route?.params?.organizationId || props.auth.organization.organization_id), perPage, currentPage);

			currentPage = storeFrontsData.currentPage;

			setState(prevState => {
				return Object.assign({}, prevState, {
					storeFronts: storeFrontsData.storeFronts,
					initialized: true,
					...(orgData ? {organization: orgData} : {}),
					pageCount: storeFrontsData.pageCount,
					paginationRange: storeFrontsData.paginationRange
				});
			});
		};
		getData();
	}, [props.auth.organization.organization_id, props.route?.params?.organizationId, currentPage]);

	const getStoreFrontIcon = storeFront => {
		if (storeFront.store_front_thumbnail) {
			return AVATAR_PATH_STORE_FRONT + storeFront.store_front_thumbnail;
		} else if (storeFront.organization_thumbnail) {
			return AVATAR_PATH_ORG + storeFront.organization_thumbnail;
		}

		return AVATAR_DEFAULT;
	};

	return (
		<ScrollView contentContainerStyle={basicStyles.flexScale}>
			{
				state.initialized ?
					<>
						<PageTop style={[
							basicStyles.flexRow,
							basicStyles.alignContentCenter,
							basicStyles.justifyContentSpaceBetween,
						]}>
							<View style={[
								basicStyles.flexRow,
								basicStyles.alignContentCenter,
								basicStyles.justifyContentSpaceBetween,
								styles.headerContainer
							]}>
								<View style={[
									basicStyles.flexRow,
									basicStyles.alignContentCenter,
									basicStyles.justifyContentSpaceBetween,
								]}>
									<Avatar
										source={{uri: state.organization.organization_thumbnail ? AVATAR_PATH_ORG + state.organization.organization_thumbnail : AVATAR_DEFAULT}}
										width={66}
										style={{marginRight: 20}}
									/>
									<AppText style={[textStyles.pageTitle, textStyles.semiBold]}>
										{state.organization.organization_title} Store Fronts
									</AppText>
								</View>
								<AppButton
									label="Add Store Front"
									action={() => navigate('StoreFrontAdd', {
										organizationId: props.route?.params?.organizationId
									})}
									style={styles.headerAppButton}
								/>
							</View>
						</PageTop>
						<PageContentContainer>
							{
								state.storeFronts.map(storeFront => {
									return (
										<ActionRowWithAvatar
											key={storeFront.store_front_id}
											action={CommonActions.navigate('StoreFrontEdit', {
												id: storeFront.store_front_id,
											})}
											icon={
												<Avatar
													source={{uri: getStoreFrontIcon(storeFront)}}
													width={66}
												/>
											}
											title={storeFront.store_front_title}
											slot1={{
												label: 'StoreFront ID',
												value: storeFront.store_front_number,
											}}
										/>
									);
								})
							}
						</PageContentContainer>
					</>
					: null
			}
			<Pagination screenName="StoreFrontsList" pageCount={state.pageCount} pageIndex={currentPage} paginationRange={state.paginationRange} />
		</ScrollView>
	);
}

const mapStateToProps = (state) => {
	const {auth} = state;
	return {auth};
};

export default connect(mapStateToProps)(StoreFrontsList);

const styles = EStyleSheet.create({
	headerContainer: {
		width: '100%'
	},
	'@media (max-width: 720)': {
		headerContainer: {
			flexDirection: 'column',
			justifyContent: 'flex-start',
			alignItems: 'flex-start'
		},
		headerAppButton: {
			marginTop: 15,
		}
	},
	'@media (max-width: 400)': {
		headerAppButton: {
			width: '100%'
		}
	}
});