import {miscellaneousActionTypes} from './miscellaneousActions';

const initialState = {
	spreadsheetFilterOpen: false,
	pageClicked: false,
	processingIndicatorActive: false,
	isJobSinglePage: false
};

const miscellaneousReducer = (state = initialState, action) => {
	switch(action.type) {
		case miscellaneousActionTypes.SET_SPREADSHEET_FILTER_OPEN:
			return Object.assign({}, state, {spreadsheetFilterOpen: action.payload});
		case miscellaneousActionTypes.SET_PAGE_CLICKED:
			return Object.assign({}, state, {pageClicked: action.payload});
		case miscellaneousActionTypes.SET_PROCESSING_INDICATOR_ACTIVE:
			return Object.assign({}, state, {processingIndicatorActive: action.payload});
		case miscellaneousActionTypes.SET_IS_JOB_SINGLE_PAGE:
			return Object.assign({}, state, {isJobSinglePage: action.payload});
		default:
			return state;
	}
};

export default miscellaneousReducer;
