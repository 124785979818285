import {authActionTypes} from "./authActions";

const initialState = {
    token: null,
    user: {},
    organization: null,
    organizationRoleId: null,
    permissions: [],
    initialized: false,
    realToken: null,
    realOrganizationId: null,
    realOrganizationRoleId: null,

};

const authReducer = (state = initialState, action) => {

    switch (action.type) {
        case authActionTypes.SET_USER:
            let userData = Object.assign({}, state.user, action.payload);

            return Object.assign({}, state, {
                user: userData
            });

        case authActionTypes.SET_TOKEN:
            return Object.assign({}, state, {
                token: action.payload
            });

        case authActionTypes.SET_ORGANIZATION:
            return Object.assign({}, state, {
                organization: action.payload
            });

        case authActionTypes.SET_ORGANIZATION_ROLE_ID:
            return Object.assign({}, state, {
                organizationRoleId: action.payload
            });

        case authActionTypes.RESET_STATE:
            return Object.assign({}, initialState);

        case authActionTypes.SET_INITIALIZED:
            return Object.assign({}, state, {initialized: action.payload});

        case authActionTypes.SET_PERMISSIONS:
            return Object.assign({}, state, {permissions: action.payload});

        case authActionTypes.EMULATE_USER:
            return Object.assign(
                {},
                state,
                {
                    token: action.payload.token,
                    user: action.payload.user,
                    organization: action.payload.organization,
                    organizationRoleId: action.payload.organizationRoleId,
                    permissions: action.payload.permissions,
                    realToken: state.token,
                    realOrganizationId: state.organization.organization_id,
                    realOrganizationRoleId: state.organizationRoleId,
                }
            );

        case authActionTypes.STOP_USER_EMULATION:
            return Object.assign(
                {},
                state,
                {
                    token: action.payload.token,
                    user: action.payload.user,
                    organization: action.payload.organization,
                    organizationRoleId: action.payload.organizationRoleId,
                    permissions: action.payload.permissions,
                    realToken: null,
                    realOrganizationId: null,
                    realOrganizationRoleId: null,
                }
            );

        case authActionTypes.SET_REAL_TOKEN:
            return Object.assign({}, state, {realToken: action.payload});

        case authActionTypes.SET_REAL_ORGANIZATION_ID:
            return Object.assign({}, state, {realOrganizationId: action.payload});

        case authActionTypes.SET_REAL_ORGANIZATION_ROLE_ID:
            return Object.assign({}, state, {realOrganizationRoleId: action.payload});

        default:
            return state;
    }
};

export default authReducer;
