export const notificationActionTypes = {
    CYCLE_NOTIFICATION: 'CYCLE_NOTIFICATION',
    REMOVE_NOTIFICATION: 'REMOVE_NOTIFICATION',
};

export const cycleNotification = notification => (
    {
        type: notificationActionTypes.CYCLE_NOTIFICATION,
        payload: notification
    }
);

export const removeNotification = notificationIndex => (
    {
        type: notificationActionTypes.REMOVE_NOTIFICATION,
        payload: notificationIndex
    }
);
