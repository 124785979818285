import React from 'react';
import {View} from 'react-native';
import {connect} from 'react-redux';

import {createSubNavigator} from '../../components/SubNavigator';
import UserProfileIndexEditNavigation from './UserProfileIndexEditNavigation';
import UserProfileNotifications from './UserProfileNotifications';
import {basicStyles} from '../../styles/basic';

const SubNavigator = createSubNavigator();

function UserProfileSettings(props) {

    return (
        <View style={basicStyles.flexScale}>
            <SubNavigator.Navigator backBehavior="history" initialRouteName="UserProfileIndexEditNavigation">
                <SubNavigator.Screen
                    name="UserProfileIndexEditNavigation"
                    component={UserProfileIndexEditNavigation}
                    options={{
                        title: 'User Settings'
                    }}
                />
                <SubNavigator.Screen
                    name="UserProfileNotifications"
                    component={UserProfileNotifications}
                    options={{
                        title: 'Alerts & Notifications'
                    }}
                />
            </SubNavigator.Navigator>
        </View>
    );
}


const mapStateToProps = (state) => {
    const {auth} = state;
    return {auth};
};

export default connect(mapStateToProps)(UserProfileSettings);
