import React, {useState, useEffect} from 'react';
import {View, ScrollView, StyleSheet} from 'react-native';

import AppText from '../components/AppText';
import ErrorMiniSvg from '../components/svgs/ErrorMiniSvg';
import ErrorSvg from '../components/svgs/ErrorSvg';
import SuccessSvg from '../components/svgs/SuccessSvg';
import ChevronDownSvg from '../components/svgs/ChevronDownSvg';
import ContentSection from '../components/ContentSection';
import {basicStyles} from '../styles/basic';
import TouchView from '../components/TouchView';
import AppButton from '../components/AppButton';
import BatchQueueService from "../services/BatchQueueService";

function BatchQueueImportResults(props) {
    const {navigate} = props.navigation;
    const [isMobile, setIsMobile] = useState(false);
    const [columnWidth, setColumnWidth] = useState('100%');
    const [borderStyle, setBorderStyle] = useState({borderRightWidth: 1,borderRightColor: '#E2E9F4'});

    const {organizationId, batchQueueId} = props.route.params;

    const [state, setState] = useState({
        displayColumns: [],
        errors: [],
        successes: []
    });



    const handleLayout = ({nativeEvent}) => {
        const {width} = nativeEvent.layout;

        if(width > 920) {
            setColumnWidth(((1 / (state.displayColumns.length + 1)) * 100) + '%');
            setBorderStyle({borderRightWidth: 1,borderRightColor: '#E2E9F4'})
        } else {
            setColumnWidth('50%');
            setBorderStyle({borderBottomWidth: 1,borderBottomColor: '#E2E9F4'})
        }

        if(width > 385) {
            setIsMobile(false);
        } else {
            setIsMobile(true);
        }
    };

    useEffect(() => {
        BatchQueueService.getBatchQueue(organizationId, batchQueueId)
            .then(data => {
                setState(prevState => {
                    return {
                        ...prevState,
                        displayColumns: data.batchQueue.displayColumns,
                        errors: data.batchQueue.errors,
                        successes: data.batchQueue.successes
                    }
                });
            })
    }, [organizationId, batchQueueId]);


    return (
        <ScrollView contentContainerStyle={[
            basicStyles.flexScale,
        ]} onLayout={handleLayout}>
            <View style={[styles.pagePadding]}>
                <View style={styles.overviewSection}>
                    <View style={[
                        basicStyles.justifyContentSpaceBetween,
                        basicStyles.alignContentCenter,
                        (!isMobile ? {
                                flexDirection: 'row'
                            } : {
                                width: '100%',
                                alignItems: 'flex-start',
                            }
                        )
                    ]}>
                        <AppText>
                            Import Successful
                        </AppText>
                        {/*<View style={[basicStyles.flexRow, basicStyles.alignContentCenter, (isMobile ? {width: '100%', justifyContent: 'space-between'} : {})]}>*/}
                        {/*	<AppText style={{fontSize: 16, color: 'rgba(0,0,0,.48)', marginRight: 20}}>*/}
                        {/*		{successes.length} items added*/}
                        {/*	</AppText>*/}
                        {/*</View>*/}
                    </View>
                    <View style={[
                        basicStyles.flexRow,
                        basicStyles.alignContentCenter,
                        basicStyles.justifyContentFlexStart,
                    ]}>
                        <ErrorMiniSvg/>
                        <AppText style={{
                            fontFamily: 'SourceSansPro-Bold',
                            fontSize: 16,
                            marginRight: 5,
                            marginLeft: 5,
                        }}>
                            {state.errors.length}
                        </AppText>
                        <AppText style={{
                            fontSize: 16,
                        }}>
                            Errors
                        </AppText>
                    </View>
                </View>

                {
                    state.errors.length ?
                        <>
                            <View style={[
                                basicStyles.flexRow,
                                basicStyles.alignContentCenter,
                                basicStyles.justifyContentSpaceBetween,
                                styles.sectionHeader,
                            ]}>
                                <View style={[
                                    basicStyles.flexRow,
                                    basicStyles.alignContentCenter,
                                ]}>
                                    <ErrorSvg/>
                                    <AppText style={styles.sectionHeading}>
                                        Errors
                                    </AppText>
                                    <AppText style={styles.sectionCount}>
                                        {state.errors.length}
                                    </AppText>
                                </View>
                                <TouchView style={{padding: 10}}>
                                    <ChevronDownSvg color="#000000" opacity={1}/>
                                </TouchView>
                            </View>
                            {
                                state.errors.map((error, index) => {
                                    return (
                                        <ContentSection
                                            key={index}
                                            style={[
                                                basicStyles.flexRow,
                                                {marginBottom: (index === state.errors.length - 1 ? 70 : 10)},
                                                {flexWrap: 'wrap'}
                                            ]}>
                                            <View style={[
                                                styles.rowSection,
                                                {width: columnWidth},
                                                borderStyle
                                            ]}>
                                                <AppText style={styles.rowSectionLabel}>
                                                    Error Type
                                                </AppText>
                                                <AppText>
                                                    {error.job ? 'Survey' : 'Job'}
                                                </AppText>
                                            </View>

                                            {
                                                state.displayColumns.map(column => {
                                                    return (
                                                        <React.Fragment key={column.key}>
                                                            <View style={[
                                                                styles.rowSection,
                                                                {width: columnWidth},
                                                                borderStyle
                                                            ]}>
                                                                <AppText style={styles.rowSectionLabel}>
                                                                    {column.label}
                                                                </AppText>
                                                                <AppText>
                                                                    {error.job ? error.job[column.key] : error[column.key]}
                                                                </AppText>
                                                            </View>
                                                        </React.Fragment>
                                                    );
                                                })
                                            }

                                            <View style={[
                                                styles.rowSection,
                                                {width: columnWidth},
                                                borderStyle
                                            ]}>
                                                <AppText style={styles.rowSectionLabel}>
                                                    Upload Status
                                                </AppText>
                                                <AppText style={styles.error}>
                                                    {error.error}
                                                </AppText>
                                            </View>
                                        </ContentSection>
                                    );
                                })
                            }
                        </>
                        : null
                }

                {
                    state.successes.length ?
                        <>
                            <View style={[
                                basicStyles.flexRow,
                                basicStyles.alignContentCenter,
                                basicStyles.justifyContentSpaceBetween,
                                styles.sectionHeader,
                            ]}>
                                <View style={[
                                    basicStyles.flexRow,
                                    basicStyles.alignContentCenter,
                                ]}>
                                    <SuccessSvg/>
                                    <AppText style={styles.sectionHeading}>
                                        Successful Imports
                                    </AppText>
                                    <AppText style={styles.sectionCount}>
                                        {state.successes.length}
                                    </AppText>
                                </View>
                                <TouchView style={{padding: 10}}>
                                    <ChevronDownSvg color="#000000" opacity={1}/>
                                </TouchView>
                            </View>
                            {
                                state.successes.map((success, index) => {
                                    return (
                                        <ContentSection
                                            key={index}
                                            style={[
                                                basicStyles.flexRow,
                                                {marginBottom: 10},
                                                {flexWrap: 'wrap'}
                                            ]}>

                                            {
                                                state.displayColumns.map(column => {
                                                    return (
                                                        <React.Fragment key={column.key}>
                                                            <View style={[
                                                                styles.rowSection,
                                                                {width: columnWidth},
                                                                borderStyle
                                                            ]}>
                                                                <AppText style={styles.rowSectionLabel}>
                                                                    {column.label}
                                                                </AppText>
                                                                <AppText>
                                                                    {success[column.key]}
                                                                </AppText>
                                                            </View>
                                                        </React.Fragment>
                                                    );
                                                })
                                            }

                                            <View style={[
                                                styles.rowSection,
                                                {width: columnWidth},
                                                borderStyle
                                            ]}>
                                                <AppText style={styles.rowSectionLabel}>
                                                    Upload Status
                                                </AppText>
                                                <AppText>
                                                    Import Successful
                                                </AppText>
                                            </View>
                                        </ContentSection>
                                    );
                                })
                            }
                        </>
                        : null
                }

                <View style={[basicStyles.flexCenterContent, {marginTop: 50}]}>
                    {
                        props.route.params.type === 'jobs'
                            ?
                            <AppButton
                                label="All Jobs"
                                action={() => {
                                    navigate('JobIndexEditNavigation', {
                                        screen: 'JobsList',
                                    });
                                }}
                            />
                            :
                            null
                    }
                </View>
            </View>
        </ScrollView>
    );
}

export default BatchQueueImportResults;

const styles = StyleSheet.create({
    pagePadding: {
        paddingTop: 50,
        paddingBottom: 50,
        paddingRight: 35,
        paddingLeft: 35,
    },
    overviewSection: {
        backgroundColor: 'rgba(0,216,149,.1)',
        borderRadius: 5,
        padding: 15,
        marginBottom: 50,
    },
    overviewHeading: {
        fontFamily: 'SourceSansPro-SemiBold',
        fontSize: 18,
    },
    sectionHeading: {
        fontFamily: 'SourceSansPro-SemiBold',
        fontSize: 20,
        marginRight: 10,
        marginLeft: 10,
    },
    sectionCount: {
        color: '#9A9A9A',
        fontSize: 16,
    },
    sectionHeader: {
        paddingBottom: 30,
        borderBottomWidth: 1,
        borderBottomColor: '#E2E9F4',
        marginBottom: 25,
    },
    rowSection: {
        padding: 15,
    },
    rowSectionLabel: {
        fontSize: 12,
        color: '#9BA8CA',
    },
    error: {
        color: 'red',
    },
});
