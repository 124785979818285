import React from 'react';
import {View, StyleSheet} from 'react-native';
import {CommonActions, useRoute} from '@react-navigation/native';

import LinkButton from './LinkButton';
import SiteIconSvg from './svgs/SiteIconSvg';
import AppText from './AppText';
import TouchView from './TouchView';
import {navigate} from "../utilities/navigation";

function UnauthenticatedHeader(props) {
	const route = useRoute();

	return (
		<View style={styles.header} {...props}>
			<TouchView
				style={{flexDirection: 'row', alignItems: 'center'}}
				action={() => {
					if(route.name == 'PasswordReset') {
						navigate('Login');
					}
				}}
			>
				<SiteIconSvg style={{marginRight: 10}}/>
				<AppText>FLUID</AppText>
			</TouchView>
			{/*<LinkButton*/}
			{/*	action={() => CommonActions.navigate('Login')}*/}
			{/*	style={styles.contactLink}*/}
			{/*>*/}
			{/*	<AppText>Contact Us</AppText>*/}
			{/*</LinkButton>*/}
		</View>
	);
}

export default UnauthenticatedHeader;

const styles = StyleSheet.create({
	header: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		paddingRight: 60,
		paddingLeft: 60,
		paddingTop: 15,
		paddingBottom: 15,
		backgroundColor: '#FFFFFF',
		borderBottomWidth: 1,
		borderBottomColor: '#E2E9F4',
		width: '100%'
	},
	contactLink: {
		padding: 10,
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 4,
		borderColor: '#0065DB',
		borderWidth: 1,
	},
});
