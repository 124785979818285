import React from 'react';
import {Animated, StyleSheet} from 'react-native';

function AppText({style, ...rest}) {
	return (
		<Animated.Text
			style={[styles.appText, style]}
			{...rest}
		>
			{rest.children}
		</Animated.Text>
	);
}

export default AppText;

const styles = StyleSheet.create({
	appText: {
		fontFamily: 'SourceSansPro-Regular'
	}
});
