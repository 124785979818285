import React, {createRef, useState} from 'react';
import {View, TextInput} from 'react-native';

import SearchSvg from './svgs/SearchSvg';
import AppText from "./AppText";
import EStyleSheet from "react-native-extended-stylesheet";

function SearchBarCollapsed({style = {}, inputStyle = {}, value = null, ...props}) {
	const [active, setActive] = useState(false);
	const inputRef = createRef();

	return (
		<View
			style={[
				styles.wrapper,
				(active ? styles.activeWrapper : {}),
				style
			]}
		>
			<View style={[(active ? styles.activeBackground : {display: 'none'})]}></View>
			<SearchSvg
	            style={[{cursor: 'pointer'}]} fill="#0065DB"
	            onClick={() => {
		            setActive(!active);
		            if(!active) {
			            inputRef.current.focus();
		            }
	            }}
			/>
			<AppText
				style={[
					styles.searchPreview,
					(value && !active ? styles.searchPreviewActive : {}),
					{textOverflow: 'ellipsis', whiteSpace: 'nowrap'}
				]}
				onClick={() => {
					setActive(!active);
					if(!active) {
						inputRef.current.focus();
					}
				}}
			>
				{value}
			</AppText>
			<TextInput
				ref={inputRef}
	            style={[
					styles.input,
		            (active ? styles.inputActive : {}),
		            inputStyle,
		            {outline: 'none'}
				]}
				value={value}
				{...props}
			/>
		</View>
	);
}

export default SearchBarCollapsed;

const styles = EStyleSheet.create({
	wrapper: {
		backgroundColor: '#FFFFFF',
		flexDirection: 'row',
		alignItems: 'center',
		height: 40,
		paddingLeft: 13,
		paddingRight: 13,
		borderWidth: 1,
		borderColor: '#E2E9F4',
		borderRadius: 20,
	},
	input: {
		// flex: 1,
		transition: 'width 0.2s ease-out',
		height: 40,
		paddingLeft: 20,
		paddingRight: 20,
	},
	searchPreview: {
		maxWidth: 0,
		transition: 'max-width 0.2s ease-out',
		overflow: 'hidden',
		// marginLeft: 10,
	},
	'@media (min-width: 750)': {
		input: {
			opacity: 0,
			width: 60,
			position: 'absolute',
			bottom: -50,
			left: -1,
			borderWidth: 1,
			borderColor: '#E2E9F4',
			borderRadius: 20,
			borderTopLeftRadius: 0,
			backgroundColor: '#FFFFFF',

		},
		inputActive: {
			width: 250,
			opacity: 1,
		},
		activeWrapper: {
			borderBottomLeftRadius: 0,
			borderBottomRightRadius: 0,
		},
		searchPreviewActive: {
			maxWidth: 100,
			marginLeft: 10,
		},
		activeBackground: {
			boxSizing: 'content-box',
			position: 'absolute',
			width: '100%',
			height: 11,
			backgroundColor: '#FFFFFF',
			left: -1,
			bottom: -11,
			zIndex: 1,
			borderColor: '#E2E9F4',
			borderLeftWidth: 1,
			borderRightWidth: 1,
		},
	},
	'@media (max-width: 1100)': {
	},
	'@media (max-width: 750)': {
		wrapper: {
			order: 1,
			marginTop: 15
		},
		input: {
			position: 'relative',
		},
		searchPreview: {
			display: 'none',
		},
		activeBackground: {
			display: 'none',
		}
	}
});
