import React from 'react';
import {View, StyleSheet, Dimensions} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import ScreenOverlay from './ScreenOverlay';
import ContentSection from './ContentSection';
import AppText from './AppText';
import AppButton from './AppButton';
import {basicStyles} from '../styles/basic';
import {destroyWarning, setIndestructible} from '../store/warning/warningActions';

function Warning(props) {
	const {
		confirmAction,
		confirmDestroy = true,
		confirmAction2 = null,
		confirm2Destroy = true,
		rejectionAction,
		rejectDestroy = true,
		confirmLabel,
		confirmLabel2 = null,
		rejectLabel,
		warningMessage,
		warningSubMessage,
		active,
		indestructible,
		replaceContent,
		wrapperStyle = {},
	} = props.warning;

	const {destroyWarning, setIndestructible} = props;

	const confirm = () => {
		confirmAction();
		if (indestructible) {
			setIndestructible(false);
		} else {
			if (confirmDestroy) {
				destroyWarning();
			}
		}
	};

	const confirm2 = () => {
		confirmAction2();
		if (indestructible) {
			setIndestructible(false);
		} else {
			if (confirm2Destroy) {
				destroyWarning();
			}
		}
	};

	const reject = () => {
		rejectionAction();
		if (indestructible) {
			setIndestructible(false);
		} else {
			if (rejectDestroy) {
				destroyWarning();
			}
		}
	};

	if (active) {
		return (
			<ScreenOverlay>
				<ContentSection style={[wrapperStyle, styles.container, {maxHeight: Dimensions.get('window').height}]}>
					{
						!replaceContent ?
							<>
								<View style={styles.topSection}>
									<AppText style={styles.warningMessage}>
										{warningMessage}
									</AppText>
								</View>
								<View style={styles.bottomSection}>
									{
										warningSubMessage ?
											<AppText>
												{warningSubMessage}
											</AppText>
											: null
									}
									<View style={[basicStyles.flexRow, basicStyles.flexCenterContent]}>
										<AppButton
											label={confirmLabel}
											action={() => confirm()}
										/>

										{
											confirmLabel2 && confirmAction2 ?
												<AppButton
													label={confirmLabel2}
													action={() => confirm2()}
													style={{marginLeft: 10}}
												/>
												: null
										}


										{
											rejectLabel && reject ?
												<AppButton
													label={rejectLabel}
													action={() => reject()}
													theme="transBlue"
													style={{marginLeft: 10}}
												/>
												: null
										}
									</View>
								</View>
							</>
							:
							null
					}
					{replaceContent}
				</ContentSection>
			</ScreenOverlay>
		);
	}

	return (<></>);
}

const mapStateToProps = (state) => {
	const {warning} = state;
	return {warning};
};

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		destroyWarning,
		setIndestructible,
	}, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Warning);

const styles = StyleSheet.create({
	container: {
		maxWidth: 680,
	},
	topSection: {
		borderBottomWidth: 1,
		borderBottomColor: '#E2E9F4',
		justifyContent: 'center',
		alignItems: 'center',
		paddingTop: 60,
		paddingRight: 60,
		paddingLeft: 60,
		paddingBottom: 30,
	},
	bottomSection: {
		justifyContent: 'center',
		alignItems: 'center',
		paddingTop: 30,
		paddingRight: 60,
		paddingLeft: 60,
		paddingBottom: 30,
	},
	warningMessage: {
		fontSize: 28,
		textAlign: 'center',
		fontFamily: 'SourceSansPro-Light',
	},
});
