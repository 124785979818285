import React, {useCallback, useState} from 'react';
import SearchBar from './SearchBar';
import {ActivityIndicator, StyleSheet, TouchableWithoutFeedback, View} from 'react-native';
import moment from 'moment';

import {basicStyles} from '../styles/basic';
import AppDatePicker from './AppDatePicker.web';
import PageTop from './PageTop';
import AppButton from "./AppButton";
import FilterIconSvg from "./svgs/FilterIconSvg";
import AppText from "./AppText";
import EStyleSheet from "react-native-extended-stylesheet";
import CalendarSvg from "./svgs/CalendarSvg";
import SearchBarCollapsed from "./SearchBarCollapsed";
import AppPickerInput from "./AppPickerInput";
import AppDateRangePicker from "./AppDateRangePicker";
import DatePicker from "react-datepicker";

function ReportsSearchSection({
	onChangeSearch = () => {},
	searchValue,
	startDateValue,
	endDateValue,
	onChangeDates = () => {},
	largeContainer = false,
}) {

	return (
		<View style={[
			styles.header,
			(largeContainer ? {} : styles.notLargeContainerHeader)
			]
		}>
			<SearchBarCollapsed
				placeholder="Search"
				onChangeText={(value) => onChangeSearch(value)}
				value={searchValue}
				inputStyle={(searchValue ? {minWidth: 50} : {})}
			/>
			<AppDateRangePicker
				startDateValue={startDateValue}
				endDateValue={endDateValue}
				onChangeDates={onChangeDates}
			/>
		</View>
	);
}

export default ReportsSearchSection;

const styles = EStyleSheet.create({
	header: {
		zIndex: 1,
		elevation: 1,
		overflow: 'visible',
		flexDirection: 'row',
	},
	'@media (max-width: 1100)': {
		header: {
		},
		notLargeContainerHeader: {
			marginTop: 15,
			order: 1,
			flexBasis: '100%'
		}
	},
	'@media (max-width: 750)': {
		header: {
			flexDirection: 'column'
		}
	},
});