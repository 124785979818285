import React from 'react';
import {View} from 'react-native';
import {createStackNavigator} from '@react-navigation/stack';
import {connect} from 'react-redux';

import OrganizationsList from './OrganizationsList';
import ClientPartnerSingleNavigation from './ClientPartnerSingleNavigation';
import OrganizationSingle from './OrganizationSingle';
import ClientCommissions from './ClientCommissions';
import {basicStyles} from '../../styles/basic';
import {ORGANIZATION_TYPE_IDS} from '../../config';

const Stack = createStackNavigator();

function ClientsPartnersStackNavigation(props) {
	let organizationTypeId;
	if(props.route.name === 'Clients') {
		organizationTypeId = ORGANIZATION_TYPE_IDS.CLIENT;
	} else if (props.route.name === 'Partners') {
		organizationTypeId = ORGANIZATION_TYPE_IDS.PARTNER;
	}

	return (
		<View style={basicStyles.flexScale}>
			<Stack.Navigator
				backBehavior="history"
				screenOptions={{
					header: () => null,
					cardStyle: {
						backgroundColor: '#F8FAFD'
					}
				}}
				initialRouteName="OrganizationList"
			>
				<Stack.Screen
					name="OrganizationList"
					component={OrganizationsList}
					initialParams={{organizationTypeId}}
					options={{
						title: "Organizations"
					}}
				/>
				<Stack.Screen
					name="ClientPartnerSingleNavigation"
					component={ClientPartnerSingleNavigation}
					initialParams={{organizationTypeId}}
					options={{
						title: "Organizations"
					}}
				/>
				<Stack.Screen
					name={"ClientPartnerAdd"}
					component={OrganizationSingle}
					initialParams={{organizationTypeId}}
					options={{
						title: "Organizations"
					}}
				/>
				<Stack.Screen
					name={"ClientCommissions"}
					component={ClientCommissions}
					options={{
						title: "Organizations"
					}}
				/>
			</Stack.Navigator>
		</View>
	);
}


const mapStateToProps = (state) => {
	const {auth} = state;
	return {auth};
};

export default connect(mapStateToProps)(ClientsPartnersStackNavigation);
