import React, {useState, useEffect, useRef, useCallback} from 'react';
import {View, StyleSheet, ScrollView, TextInput, Platform, Linking, Image} from 'react-native';
import {Clipboard} from '@react-native-community/clipboard';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import Axios from 'axios';

import SurveysService from '../services/SurveysService';
import {basicStyles} from '../styles/basic';
import UnauthenticatedHeader from '../components/UnauthenticatedHeader';
import Avatar from '../components/Avatar';
import {AVATAR_DEFAULT, AVATAR_PATH_ORG, AVATAR_PATH_SURVEY_WEBSITE, AVATAR_PATH_USER} from '../config';
import AppText from '../components/AppText';
import ContentSection from '../components/ContentSection';
import {getUnauthenticatedScreenMaxHeight} from '../utilities/methods';
import StarRangeInput from '../components/StarsRangeInput';
import AppButton from '../components/AppButton';
import SurveyWebsitesService from '../services/SurveyWebsitesService';
import ChevronRightNavySvg from '../components/svgs/ChevronRightNavySvg';
import TouchView from '../components/TouchView';
import GeocodedAddressMap from '../components/GeocodedAddressMap';
import EStyleSheet from 'react-native-extended-stylesheet';
import {setProcessingIndicatorActive} from '../store/miscellaneous/miscellaneousActions';
import SingleClickButton from '../components/SingleClickButton';
import ScreenOverlay from '../components/ScreenOverlay';
import CloseSvg from '../components/svgs/CloseSvg';

function Survey(props) {
    const [survey, setSurvey] = useState({surveyQuestions: [], organization_id: null});
    const [surveyWebsites, setSurveyWebsites] = useState([]);
    const [surveyStarted, setSurveyStarted] = useState(false);
    const [surveyComplete, setSurveyComplete] = useState(false);
    const [textCopied, setTextCopied] = useState(false);
    const [redirectOptions, setRedirectionOptions] = useState([]);
    const [redirectOptionsInitialized, setRedirectionOptionsInitialized] = useState(false);
    const [postResponseCopy, setPostResponseCopy] = useState('');
    const [postSurveyRedirect, setPostSurveyRedirect] = useState('');
    const [invalidFields, setInvalidFields] = useState([]);
    const [showTerms, setShowTerms] = useState(false);
    const [termsContent, setTermsContent] = useState(null);
    const [googleRedirectState, setGoogleRedirectState] = useState({
        onLoad: null,
        onError: null,
        source: null,
        ready: false
    });
    const scrollViewRef = useRef();
    const firstRun = useRef(true);


    const {setProcessingIndicatorActive} = props;

    const redirectPriorityList = [
        'google',
        // 'facebook'
    ];

    const updateSurveyResponseAnswer = (surveyQuestionIndex, value) => {
        let surveyQuestions = survey.surveyQuestions;
        surveyQuestions[surveyQuestionIndex].survey_response_answer_text = value;
        setSurvey({
            ...survey,
            surveyQuestions,
        });

        if (surveyQuestions[surveyQuestionIndex].surveyQuestionRedirects.length) {
            for (let surveyQuestionRedirect of surveyQuestions[surveyQuestionIndex].surveyQuestionRedirects.filter(surveyQuestionRedirect => surveyQuestionRedirect.store_front_id == survey.store_front_id || ! surveyQuestionRedirect.store_front_id)) {
                if (surveyQuestionRedirect.survey_question_redirect_value && surveyQuestionRedirect.survey_question_redirect_url) {
                    if (value == surveyQuestionRedirect.survey_question_redirect_value) {
                        setPostSurveyRedirect(surveyQuestionRedirect.survey_question_redirect_url);
                        return;
                    }
                }
            }
            setPostSurveyRedirect(null);
        }
    };

    const validateSurvey = () => {
        let invalidFields = [];
        let invalidQuestions = [];
        survey.surveyQuestions.forEach((e, i) => {
            if(e.survey_question_required == '1') {
                if(!e.survey_response_answer_text) {
                    invalidFields.push(i);
                    invalidQuestions.push(e.survey_question_text);
                }
            }
        });

        if(invalidFields.length > 0) {
            alert('The following fields are required: \n\n' + invalidQuestions.join('\n\n'));
            setInvalidFields(invalidFields);
            return false;
        } else {
            setInvalidFields([]);
            return true;
        }
    };

    const submitSurvey = async () => {

        let isValid = validateSurvey();

        if(isValid) {
            await SurveysService.updateSurveyResponse(props.route.params.surveyResponseCode, survey);
            if (postSurveyRedirect) {
                window.location = postSurveyRedirect;
            }
            scrollViewRef.current.scrollTo({y: 0, animated: false});
            setSurveyComplete(true);
        }

        return isValid;
    };

    const copyReviewText = () => {
        Clipboard.setString(postResponseCopy);
        setTextCopied(true);
    };

    const openExternalLink = (url) => {
        if (Platform.OS === 'web') {
            window.open(url, '_blank');
        } else {
            Linking.canOpenURL(url).then(supported => {
                if (supported) {
                    return Linking.openURL(url);
                }
            });
        }
    };

    useEffect(() => {
        setProcessingIndicatorActive(true);
    }, []);

    useEffect(() => {
        const getSurvey = async () => {
            let surveyResponseData = await SurveysService.getSurveyResponse(props.route.params.surveyResponseCode);
            setSurvey(surveyResponseData);
            if (surveyResponseData.survey_response_responded_timestamp !== null) {
                setSurveyComplete(true);
            }
        };
        getSurvey();
    }, [props.route]);

    useEffect(() => {
        if (survey.organization_id) {
            const getSurveyWebsites = async () => {
                let surveyWebsiteData = await SurveyWebsitesService.getOrganizationSurveyWebsites(survey.organization_id);
                if (survey.store_front_id) {
                    let storeFrontSurveyWebsiteData = await SurveyWebsitesService.getStoreFrontWebsites(survey.store_front_id);
                    if (storeFrontSurveyWebsiteData.length > 0) {
                        function combineSurveyWebsites(array1, array2) {
                            let a = array1.concat(array2).concat();
                            for (var i = 0; i < a.length; ++i) {
                                for (var j = i + 1; j < a.length; ++j) {
                                    if (a[i].survey_website_id === a[j].survey_website_id)
                                        a.splice(j--, 1);
                                }
                            }

                            return a;
                        }

                        surveyWebsiteData = combineSurveyWebsites(storeFrontSurveyWebsiteData, surveyWebsiteData);
                    }

                }
                if (surveyWebsiteData.length <= 0) {
                    setProcessingIndicatorActive(false);
                }
                setSurveyWebsites(surveyWebsiteData);
            };
            getSurveyWebsites();
        }
    }, [survey.organization_id]);

    useEffect(() => {
        if (surveyWebsites.length) {
            for (let surveyWebsite of surveyWebsites) {
                if (surveyWebsite.organization_survey_website_redirect == 1) {
                    if (redirectPriorityList.indexOf(surveyWebsite.survey_website_token) >= 0) {
                        setRedirectionOptions((prevRedirectionOptions) => {
                            let redirectOptions = [...prevRedirectionOptions];
                            redirectOptions.push(surveyWebsite);
                            return redirectOptions;
                        })
                    }
                } else if (surveyWebsite.store_front_survey_website_redirect == 1) {
                    if (redirectPriorityList.indexOf(surveyWebsite.survey_website_token) >= 0) {
                        setRedirectionOptions((prevRedirectionOptions) => {
                            let redirectOptions = [...prevRedirectionOptions];
                            redirectOptions.push(surveyWebsite);
                            return redirectOptions;
                        })
                    }
                }
            }

            setRedirectionOptionsInitialized(true);
        }
    }, [surveyWebsites])

    useEffect(() => {
        if (redirectOptionsInitialized && redirectOptions.length > 0) {
            attemptRedirects(0);
        } else if (redirectOptionsInitialized) {
            setProcessingIndicatorActive(false);
        }
    }, [redirectOptions, redirectOptionsInitialized]);

    // fetch terms and conditions from fluid.services site
    useEffect(() => {
        Axios.get('https://fluid.services/terms-of-service').then((response) => {
            const html = response.data;

            const parser = new DOMParser();
            const doc = parser.parseFromString(html, 'text/html');
            const content = doc.querySelector('.x-text.e61-4.m1p-3');
            setTermsContent(content?.outerHTML);
        });
    }, []);

    function attemptRedirects(redirectOptionsIndex) {
        let redirectOptionsOrdered = redirectOptions.sort((a, b) => {
            return redirectPriorityList.indexOf(a.survey_website_token) - redirectPriorityList.indexOf(b.survey_website_token);
        });

        switch (redirectOptionsOrdered[redirectOptionsIndex].survey_website_token) {
            case 'google':
                setGoogleRedirectState({
                    onLoad: () => {
                        //todo: use timeout if option to cancel redirect implemented
                        // setTimeout(() => {
                        //     window.location = redirectOptionsOrdered[redirectOptionsIndex].organization_survey_website_url;
                        // }, 6000);
                        window.location = redirectOptionsOrdered[redirectOptionsIndex].organization_survey_website_url || redirectOptionsOrdered[redirectOptionsIndex].store_front_survey_website_url;
                    },
                    onError: () => {
                        if (redirectOptions.length > redirectOptionsIndex + 1) {
                            attemptRedirects(redirectOptionsIndex + 1);
                        } else {
                            setProcessingIndicatorActive(false);
                        }
                    },
                    source: 'https://accounts.google.com/CheckCookie?continue=https%3A%2F%2Fwww.google.com%2Fintl%2Fen%2Fimages%2Flogos%2Faccounts_logo.png',
                    ready: true
                });
                return;
            case 'facebook':
                break;
            default:
                break;
        }
    }

    let content;
    if (surveyComplete) {
        content = (
            <ContentSection style={{maxWidth: 850, width: '100%'}}>
                <View style={[
                    basicStyles.alignContentCenter,
                    styles.headerContainer,
                    styles.surveyCompleteHeaderContainer
                ]}>
                    <AppText style={[styles.organizationTitle, {marginRight: 0, marginLeft: 0}]}>
                        Thank You! Just One More Step!
                    </AppText>
                    <AppText style={styles.surveyCompleteHeaderCopy}>
                        Sharing Your Review on other websites helps our business grow! We would love if you could share your experience on one of the platforms linked below. Just copy the text below,
                        follow the link, and share your experience!
                    </AppText>
                </View>

                <View style={[
                    basicStyles.alignContentCenter,
                    styles.surveyCompleteContent
                ]}>
                    <View style={[
                        basicStyles.alignContentCenter,
                    ]}>
                        <View style={{
                            width: '100%',
                            borderWidth: 1,
                            borderStyle: 'dotted',
                            borderColor: '#0065DB',
                            marginBottom: 30,
                            padding: 35,
                            borderRadius: 5,
                        }}>
                            <AppText style={{fontSize: 16, color: '#9BA8CA'}}>
                                {postResponseCopy}
                            </AppText>
                        </View>
                        <AppButton
                            label={textCopied ? 'Review Copied!' : 'Copy Review to Clipboard'}
                            action={() => copyReviewText()}
                            style={styles.copyReviewToClipboard}
                        />
                    </View>

                </View>
                <View style={[
                    basicStyles.alignContentCenter,
                    styles.surveyCompleteShare
                ]}>
                    {
                        surveyWebsites.map(surveyWebsite => {
                            return (
                                <TouchView
                                    key={surveyWebsite.survey_website_id}
                                    action={() => openExternalLink(surveyWebsite.organization_survey_website_url || surveyWebsite.store_front_survey_website_url)}
                                    style={[
                                        {
                                            backgroundColor: surveyWebsite.survey_website_primary_color,
                                            paddingTop: 15,
                                            paddingBottom: 15,
                                            paddingRight: 20,
                                            paddingLeft: 20,
                                            borderRadius: 5,
                                            width: '100%',
                                            marginBottom: 10,
                                            shadowColor: '#000',
                                            shadowOffset: {
                                                width: 0,
                                                height: 3,
                                            },
                                            shadowOpacity: 0.1,
                                            shadowRadius: 9.11,
                                        },
                                        basicStyles.flexRow,
                                        basicStyles.alignContentCenter,
                                    ]}>
                                    <View style={[basicStyles.flexScale, basicStyles.flexRow, basicStyles.alignContentCenter]}>
                                        <Avatar
                                            source={{uri: AVATAR_PATH_SURVEY_WEBSITE + surveyWebsite.survey_website_icon}}
                                            width={66}
                                            style={{backgroundColor: '#FFFFFF', marginRight: 40}}
                                        />
                                        <View>
                                            <AppText style={{fontSize: 22, fontFamily: 'SourceSansPro-Bold', color: '#FFFFFF', marginBottom: 6}}>
                                                {surveyWebsite.survey_website_title}
                                            </AppText>
                                            <AppText style={{fontSize: 16, fontFamily: 'SourceSansPro-Light', color: '#FFFFFF'}}>
                                                Share Review
                                            </AppText>
                                        </View>
                                    </View>
                                    <View style={basicStyles.clickableIndicator}>
                                        <ChevronRightNavySvg/>
                                    </View>
                                </TouchView>
                            );
                        })
                    }
                </View>
            </ContentSection>
        );
    } else if (surveyStarted) {
        content = (
            <ContentSection style={styles.surveyStartedContainer}>
                {
                    survey.surveyQuestions.map((surveyQuestion, index) => {
                        return (
                            <View style={[styles.questionContainer, (invalidFields.includes(index) ? styles.requiredFieldContainer : {})]} key={surveyQuestion.survey_question_id}>
                                <View style={styles.questionInfo}>
                                    <AppText style={[(invalidFields.includes(index) ? styles.requiredFieldText : {display:'none'})]}>*Required</AppText>
                                    <AppText style={styles.questionText}>
                                        {surveyQuestion.survey_question_text}
                                    </AppText>
                                    {
                                        surveyQuestion.survey_question_sub_text ?
                                            <AppText style={styles.questionSubText}>
                                                {surveyQuestion.survey_question_sub_text}
                                            </AppText>
                                            : null
                                    }
                                </View>
                                <View style={styles.questionAnswer}>
                                    {
                                        {
                                            'text': <TextInput
                                                value={surveyQuestion.survey_response_answer_text || ''}
                                                onChangeText={(value) => {
                                                    updateSurveyResponseAnswer(index, value);
                                                    if (surveyQuestion.survey_question_post_response_copy == 1) {
                                                        setPostResponseCopy(value);
                                                    }
                                                }}
                                                placeholder={surveyQuestion.survey_question_placeholder_text || surveyQuestion.survey_question_text}
                                                style={{
                                                    borderWidth: 1,
                                                    borderColor: '#E2E9F4',
                                                    padding: 25,
                                                    borderRadius: 5,
                                                    width: '100%',
                                                }}
                                            />,
                                            'text-long': <TextInput
                                                value={surveyQuestion.survey_response_answer_text || ''}
                                                onChangeText={(value) => {
                                                    updateSurveyResponseAnswer(index, value);
                                                    if (surveyQuestion.survey_question_post_response_copy == 1) {
                                                        setPostResponseCopy(value);
                                                    }
                                                }}
                                                placeholder={surveyQuestion.survey_question_placeholder_text || surveyQuestion.survey_question_text}
                                                style={{
                                                    borderWidth: 1,
                                                    borderColor: '#E2E9F4',
                                                    padding: 25,
                                                    borderRadius: 5,
                                                    width: '100%',
                                                }}
                                                multiline={true}
                                                numberOfLines={4}
                                            />,
                                            'range': <StarRangeInput
                                                value={surveyQuestion.survey_response_answer_text || 0}
                                                onChange={(value) => updateSurveyResponseAnswer(index, value)}
                                            />,
                                        }[surveyQuestion.survey_question_type_token]
                                    }
                                </View>
                            </View>
                        );
                    })
                }
                <View style={[basicStyles.flexCenterContent, styles.surveyStartedFooter]}>
                    <AppText style={{fontSize: 12, fontFamily: 'SourceSansPro-Light', marginBottom: 30}}>
                        By clicking submit survey you agree to the fluid <AppText onClick={() => setShowTerms(true)} style={{textDecoration: 'underline', cursor: 'pointer', whiteSpace: 'nowrap'}}>local terms and conditions</AppText>
                    </AppText>
                    <SingleClickButton
                        label="Submit Survey"
                        action={() => submitSurvey()}
                        style={styles.surveyStartedSubmit}
                    />
                </View>
            </ContentSection>
        );
    } else {
        content = (
            <ContentSection style={{maxWidth: 850, width: '100%'}}>
                <View style={[
                    basicStyles.flexRow,
                    basicStyles.alignContentCenter,
                    styles.headerContainer,
                    styles.inviteHeaderContainer
                ]}>
                    <Avatar
                        source={{uri: survey.user_avatar_file ? AVATAR_PATH_USER + survey.user_avatar_file : AVATAR_DEFAULT}}
                        width={172}
                        style={styles.inviteHeaderAvatar}
                    />
                    <AppText style={styles.inviteHeaderCopy}>
                        <AppText style={[styles.organizationTitle, {marginRight: 0, marginLeft: 0}]}>{survey.profile_first_name}</AppText> has invited you to share your recent experience
                        with {survey.organization_title}
                    </AppText>
                </View>
                <View style={[
                    basicStyles.flexCenterContent,
                    styles.inviteContentContainer,
                ]}>
                    <AppText style={{fontSize: 20, fontFamily: 'SourceSansPro-Light', textAlign: 'center', marginBottom: 25}}>
                        We would really love your feedback! Please fill out the brief survey below and click the submit button
                    </AppText>
                    <AppButton
                        label="Start Survey!"
                        action={() => {
                            scrollViewRef.current.scrollTo({y: 0, animated: false});
                            setSurveyStarted(true);
                        }}
                        style={styles.startSurvey}
                    />
                    <GeocodedAddressMap
                        address={survey.google_place_city + ' ' + survey.google_place_state}
                        wrapperStyle={{marginBottom: 30, marginTop: 60}}
                    />
                    <AppText style={{fontSize: 12, fontFamily: 'SourceSansPro-Light', textAlign: 'center'}}>
                        Approximate Location of Service, Fluid Local anonymously posts the results of your survey in order to help other customers choose a quality business. We do NOT disclose your
                        full name, address, or exact location.
                    </AppText>
                </View>
            </ContentSection>
        );
    }

    return (
        <View>
            <UnauthenticatedHeader/>
            <ScrollView
                ref={scrollViewRef}
                style={[{maxHeight: getUnauthenticatedScreenMaxHeight()}, styles.outerContainer]}
                contentContainerStyle={[{paddingBottom: 90, backgroundColor: '#F8FAFD', minHeight: getUnauthenticatedScreenMaxHeight()}, basicStyles.alignContentCenter]}>
                {
                    survey.survey_response_id ?
                        <>
                            <View style={[basicStyles.flexRow, basicStyles.flexCenterContent, {marginTop: 50, marginBottom: 50, flexWrap: 'wrap'}]}>
                                {
                                    !surveyStarted ?
                                        <AppText style={styles.outerContainerHeaderCopy}>
                                            Welcome to the
                                        </AppText>
                                        : null
                                }
                                <Avatar width={38} source={{uri: survey.organization_thumbnail ? AVATAR_PATH_ORG + survey.organization_thumbnail : AVATAR_DEFAULT}}/>
                                <AppText style={styles.organizationTitle}>
                                    {survey.organization_title}
                                </AppText>
                                <AppText style={styles.surveyTitle}>
                                    {survey.survey_title}
                                </AppText>
                            </View>
                            {content}
                        </>
                        : null
                }
            </ScrollView>
            <ScreenOverlay onClick={() => setShowTerms(false)} style={showTerms ? styles.termsContainerActive : styles.termsContainerInactive}>
                <ContentSection style={[styles.termsModalContent, showTerms ? styles.termsModalContentActive : styles.termsModalContentInactive]}>
                    <CloseSvg onClick={() => setShowTerms(false)} style={styles.termsCloseButton} />
                    <ScrollView style={styles.termsModalScrollContainer}>
                        <div dangerouslySetInnerHTML={{__html: termsContent}} />
                    </ScrollView>
                </ContentSection>
            </ScreenOverlay>
            {
                googleRedirectState.ready ?
                    <Image
                        source={googleRedirectState.source}
                        onLoad={googleRedirectState.onLoad}
                        onError={googleRedirectState.onError}
                    />
                    : null
            }
        </View>
    );
}

const mapDispatchToProps = dispatch => (
    bindActionCreators({
        setProcessingIndicatorActive
    }, dispatch)
);

export default connect(null, mapDispatchToProps)(Survey);

const styles = EStyleSheet.create({
    outerContainer: {
        paddingLeft: 35,
        paddingRight: 35
    },
    outerContainerHeaderCopy: {
        fontSize: 28,
        fontFamily: 'SourceSansPro-Light',
        marginRight: 10,
    },
    headerContainer: {
        paddingTop: 55,
        paddingBottom: 45,
        paddingRight: 100,
        paddingLeft: 100,
        borderBottomWidth: 1,
        borderBottomColor: '#E2E9F4',
    },
    organizationTitle: {
        color: '#0065DB',
        fontFamily: 'SourceSansPro-Bold',
        fontSize: 28,
        marginLeft: 10,
        marginRight: 10,
    },
    surveyTitle: {
        fontFamily: 'SourceSansPro-Light',
        fontSize: 28,
    },
    questionContainer: {
        paddingLeft: 20,
        borderBottomWidth: 1,
        borderBottomColor: '#E2E9F4',
        paddingBottom: 45,
        marginBottom: 45,
        flexDirection: 'row',
        alignItems: 'center',
    },
    requiredFieldContainer: {
        borderWidth: 1,
        borderColor: 'red',
        borderBottomColor: 'red',
    },
    requiredFieldText: {
        position: 'absolute',
        top: -20,
        left: -20,
        color: 'red'
    },
    questionInfo: {
        width: '40%',
        alignItems: 'flex-start',
        paddingRight: 10,
    },
    questionAnswer: {
        width: '60%',
        alignItems: 'flex-start',
        paddingLeft: 10,
    },
    questionText: {
        fontSize: 16,
    },
    questionSubText: {
        color: '#9BA8CA',
        marginTop: 10,
    },
    inviteHeaderAvatar: {
        borderWidth: 18,
        marginRight: 30
    },
    inviteHeaderCopy: {
        fontSize: 28,
        fontFamily: 'SourceSansPro-Light'
    },
    inviteContentContainer: {
        paddingLeft: 80,
        paddingRight: 80,
        paddingTop: 50,
        paddingBottom: 50,
    },
    surveyStartedContainer: {
        padding: 75,
        maxWidth: 850,
        width: '100%'
    },
    surveyStartedFooter: {
        paddingTop: 46,
        paddingBottom: 46,
        paddingRight: 30,
        paddingLeft: 30,
    },
    surveyCompleteContent: {
        paddingTop: 55,
        paddingBottom: 45,
        paddingRight: 100,
        paddingLeft: 100,
        borderBottomWidth: 1,
        borderBottomColor: '#E2E9F4',
    },
    surveyCompleteHeaderCopy: {
        fontSize: 28,
        fontFamily: 'SourceSansPro-Light'
    },
    surveyCompleteShare: {
        paddingTop: 55,
        paddingBottom: 45,
        paddingRight: 100,
        paddingLeft: 100,
        width: '100%',
    },
    termsContainerActive: {
        opacity: 1,
        transition: 'opacity 0.15s ease-out',
        pointerEvents: 'all',
    },
    termsContainerInactive: {
        opacity: 0,
        transition: 'opacity 0.15s ease-out 0.15s',
        pointerEvents: 'none'
    },
    termsModalContent: {
        padding: 48,
        width: '100%',
        maxWidth: 1200,
        maxHeight: '70vh',
        fontFamily: 'SourceSansPro-Regular',
    },
    termsModalContentActive: {
        transform: 'scale(1)',
        transformOrigin: '50% 50%',
        opacity: 1,
        transition: 'transform 0.2s ease-out, opacity 0.15s',
    },
    termsModalContentInactive: {
        transform: 'scale(0.85)',
        transformOrigin: '50% 50%',
        opacity: 0,
        transition: 'transform 0.2s ease-out, opacity 0.15s',
    },
    termsModalScrollContainer: {
        padding: 18,
    },
    termsCloseButton: {
        position: 'absolute',
        width: 12,
        height: 12,
        top: 24,
        right: 24,
        cursor: 'pointer',
    },
    '@media (max-width: 1248)': {
        termsModalContent: {
            maxWidth: 'calc(100vw - 48px)',
        }
    },
    '@media (max-width: 768)': {
        outerContainerHeaderCopy: {
            fontSize: 20,
        },
        organizationTitle: {
            fontSize: 20,
        },
        surveyTitle: {
            fontSize: 20,
        },
        inviteHeaderCopy: {
            fontSize: 20,
        },
        surveyStartedContainer: {
            paddingLeft: 0,
            paddingRight: 0
        },
        questionInfo: {
            paddingLeft: 20,
            paddingRight: 10,
        },
        questionAnswer: {
            paddingLeft: 10,
            paddingRight: 30,
        },
        surveyCompleteHeaderContainer: {
            paddingLeft: 30,
            paddingRight: 30,
            textAlign: 'center',
        },
        surveyCompleteHeaderCopy: {
            fontSize: 20,
            marginTop: 10
        },
        surveyCompleteContent: {
            paddingLeft: 30,
            paddingRight: 30,
        },
        surveyCompleteShare: {
            paddingLeft: 30,
            paddingRight: 30,
        },
        termsModalContent: {
            paddingHorizontal: 18,
        },
    },
    '@media (max-width: 700)': {
        inviteHeaderContainer: {
            paddingLeft: 50,
            paddingRight: 50
        },
        inviteContentContainer: {
            paddingLeft: 50,
            paddingRight: 50,
        }
    },
    '@media (max-width: 600)': {
        inviteHeaderContainer: {
            flexDirection: 'column'
        },
        inviteHeaderCopy: {
            textAlign: 'center'
        },
        inviteHeaderAvatar: {
            marginRight: 0,
            marginBottom: 30
        },
        surveyStartedContainer: {
            paddingTop: 55,
            paddingBottom: 55,
        },
        questionContainer: {
            flexDirection: 'column',
            alignItems: 'flex-start'
        },
        questionInfo: {
            width: '100%',
            paddingBottom: 15,
            paddingRight: 30,
        },
        questionAnswer: {
            width: '100%',
            paddingLeft: 20,
        },
        termsModalContent: {
            paddingHorizontal: 0,
        }
    },
    '@media (max-width: 500)': {
        inviteHeaderContainer: {
            paddingLeft: 30,
            paddingRight: 30
        },
        inviteContentContainer: {
            paddingLeft: 30,
            paddingRight: 30,
        },
        startSurvey: {
            width: '100%'
        },
        surveyStartedFooter: {
            textAlign: 'center',
        },
        surveyStartedSubmit: {
            width: '100%'
        }
    },
    '@media (max-width: 450)': {
        outerContainer: {
            paddingLeft: 20,
            paddingRight: 20
        },
        surveyStartedFooter: {
            paddingLeft: 20,
            paddingRight: 20,
        },
        surveyCompleteHeaderContainer: {
            paddingLeft: 20,
            paddingRight: 20,
        },
        surveyCompleteContent: {
            paddingLeft: 20,
            paddingRight: 20,
        },
        surveyCompleteShare: {
            paddingLeft: 20,
            paddingRight: 20,
        },
        copyReviewToClipboard: {
            width: '100%',
        }
    }
});
