import React, {useEffect, useMemo} from 'react';
import {View, Dimensions} from 'react-native';
import {connect} from 'react-redux';

import Jobs from './Jobs';
import TeamMembers from './TeamMembers';
import Integrations from './Integrations';
import Reports from './Reports';
import AccountSettings from './AccountSettings';
import UserProfileSettings from './UserProfileSettings';
import {createMainNavigator} from '../components/MainNavigator';
import {CLIENT_ACCOUNT_OWNER_ROLE_ID, ORGANIZATION_TYPE_IDS} from '../config';
import ClientsPartnersStackNavigation from './Organizations/ClientsPartnersStackNavigation';
import {bindActionCreators} from 'redux';
import {setOrganization, setOrganizationRoleId, setToken, setUser} from '../store/auth/authActions';
import ClientsCommissions from './Organizations/ClientsCommissions';
import CouponsStackNavigation from './Coupons/CouponsStackNavigation';
import UserEmulationIndicator from "../components/UserEmulationIndicator";
import FluidAdminIndicator from "../components/FluidAdminIndicator";
import Footer from "../components/Footer";
import MessageFlow from "./MessageFlow";

const MainNavigator = createMainNavigator();

function Authenticated(props) {
    const {token, organization, organizationRoleId} = props.auth;
    const {navigate} = props.navigation;
    let initialRouteName;
    if (organization?.organization_type_id == ORGANIZATION_TYPE_IDS.CLIENT) {
        initialRouteName = 'Jobs';
    } else {
        initialRouteName = 'Clients';
    }

    const screenWidth = useMemo(() => {
        return Dimensions.get('window').width;
    }, [])

    useEffect(() => {
        if (props.auth.initialized) {
            if (!props.auth?.token || !props.auth?.organization?.organization_id || !props.auth?.organizationRoleId) {
                navigate('Unauthenticated', {
                    screen: 'Login',
                    params: {
                        redirect: props.route?.params?.redirect,
                    }
                });
            }
        }
    }, [props.auth.initialized, props.auth.token, props.auth.organization?.organization_id, props.auth.organizationRoleId, navigate]);

    return (
        <>
            <View>
                <UserEmulationIndicator/>
                <FluidAdminIndicator/>
            </View>
            <View style={{flex: 1}}>
                {
                    token && organization && organizationRoleId ?
                        <MainNavigator.Navigator backBehavior="history" initialRouteName={initialRouteName}>
                            {
                                organization.organization_type_id == ORGANIZATION_TYPE_IDS.CLIENT ? //Client organizations
                                    <MainNavigator.Screen
                                        name="Jobs"
                                        component={Jobs}
                                        options={{
                                            navIcon: 'post',
                                            headerTitle: 'Jobs',
                                        }}
                                    />
                                    : null
                            }
                            {
                                organization.organization_type_id == ORGANIZATION_TYPE_IDS.FLUID || organization.organization_type_id == ORGANIZATION_TYPE_IDS.PARTNER ? //Fluid organizations
                                    <MainNavigator.Screen
                                        name={'Clients'}
                                        component={ClientsPartnersStackNavigation}
                                        options={{
                                            headerTitle: 'Clients',
                                            navIcon: 'client-accounts',
                                            defaultRoute: 'OrganizationList'
                                        }}
                                    />
                                    : null
                            }
                            {
                                organization.organization_type_id == ORGANIZATION_TYPE_IDS.FLUID ? //Fluid organizations
                                    <>
                                        <MainNavigator.Screen
                                            name={'Partners'}
                                            component={ClientsPartnersStackNavigation}
                                            options={{
                                                headerTitle: 'Partners',
                                                navIcon: 'partner-accounts',
                                                defaultRoute: 'OrganizationList'
                                            }}
                                        />
                                        <MainNavigator.Screen
                                            name="Coupons"
                                            component={CouponsStackNavigation}
                                            options={{
                                                headerTitle: 'Coupons',
                                                navIcon: 'payment',
                                            }}
                                        />
                                    </>
                                    : null
                            }
                            {
                                props.auth.permissions.includes('manage_account_users') ?
                                    <MainNavigator.Screen
                                        name="TeamMembers"
                                        component={TeamMembers}
                                        options={{
                                            navIcon: 'team',
                                            headerTitle: 'Team',
                                        }}
                                    />
                                    : null
                            }
                            {
                                organization
                                && (organization.organization_type_id != ORGANIZATION_TYPE_IDS.FLUID && organization.organization_type_id != ORGANIZATION_TYPE_IDS.PARTNER)
                                && props.auth.permissions.includes('access_plugin_data') ? //Fluid organizations
                                    <>
                                        <MainNavigator.Screen
                                            name="Integrations"
                                            component={Integrations}
                                            options={{
                                                navIcon: 'integrations',
                                                headerTitle: 'Integrations',
                                            }}
                                        />
                                    </>
                                    : null
                            }
                            {
                                organization.organization_type_id == ORGANIZATION_TYPE_IDS.PARTNER ?
                                    <MainNavigator.Screen
                                        name="ClientsCommissions"
                                        component={ClientsCommissions}
                                        options={{
                                            navIcon: 'payment',
                                            headerTitle: 'Payments',
                                        }}
                                    />
                                    :
                                    null
                            }
                            {
                                organization.organization_type_id != ORGANIZATION_TYPE_IDS.PARTNER ?
                                    <MainNavigator.Screen
                                        name="Reports"
                                        component={Reports}
                                        options={{
                                            navIcon: 'reports',
                                            headerTitle: 'Reports',
                                            organization: organization,
                                        }}
                                    />
                                    :
                                    null
                            }
                            {
                                organization.organization_message_flow_active == 1 &&
                                    organization.organization_textline_enabled &&
                                    organization.organization_textline_token &&
                                    organization.organization_textline_group_uuid
                                    ?
                                    <MainNavigator.Screen
                                        name="MessageFlow"
                                        component={MessageFlow}
                                        options={{
                                            navIcon: 'messages',
                                            hideHeader: screenWidth > 630,
                                        }}
                                        initialParams={{organizationId: organization.organization_id}}
                                    />
                                    : null
                            }
                            {
                                organization.organization_type_id == ORGANIZATION_TYPE_IDS.CLIENT
                                && props.auth.organizationRoleId == CLIENT_ACCOUNT_OWNER_ROLE_ID
                                && props.auth.permissions.includes('manage_subscription') ? //Client organizations

                                    <MainNavigator.Screen
                                        name="AccountSettings"
                                        component={AccountSettings}
                                        options={{
                                            navIcon: 'settings',
                                            headerTitle: 'Account Settings',
                                        }}
                                        initialParams={{organizationId: organization.organization_id}}
                                    />
                                    :
                                    null
                            }
                            {
                                <MainNavigator.Screen
                                    name="UserProfileSettings"
                                    component={UserProfileSettings}
                                    options={{
                                        navIcon: 'profile',
                                        headerTitle: 'User Profile Settings'
                                    }}
                                    initialParams={{organizationId: organization.organization_id}}
                                />
                            }
                        </MainNavigator.Navigator>
                        : null
                }
            </View>
            <Footer/>
        </>
    );
}


const mapStateToProps = (state) => {
    const {auth} = state;
    return {auth};
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
        setToken,
        setUser,
        setOrganization,
        setOrganizationRoleId,
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Authenticated);
