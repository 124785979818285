import * as React from "react"
import Svg, { Path } from "react-native-svg"

function PencilSvg(props) {
        return (
            <Svg
                width={12}
                height={12}
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                    <Path
                        d="M6.45 3.427L8.57 5.55l-4.95 4.95H1.5V8.376l4.95-4.95zm.707-.707l1.06-1.06a.5.5 0 01.708 0l1.414 1.414a.5.5 0 010 .707l-1.06 1.06-2.122-2.12z"
                        fill="#9EABCC"
                    />
            </Svg>
        )
}

export default PencilSvg
