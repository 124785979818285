import React, {useState, useEffect} from 'react';
import {View, ScrollView, StyleSheet} from 'react-native';
import moment from 'moment';

import {basicStyles} from '../../styles/basic';
import OrganizationsService from '../../services/OrganizationsService';
import PageTop from '../../components/PageTop';
import Avatar from '../../components/Avatar';
import {AVATAR_DEFAULT, AVATAR_PATH_ORG} from '../../config';
import AppText from '../../components/AppText';
import {textStyles} from '../../styles/text';
import ContentSection from '../../components/ContentSection';
import PageContentContainer from '../../components/PageContentContainer';
import {connect} from 'react-redux';
import ActionRowWithAvatar from '../../components/ActionRowWithAvatar';
import {CommonActions} from '@react-navigation/native';
import AppBarChart from '../../components/AppBarChart';
import {getScreenMaxHeight} from '../../utilities/methods';

function ClientsCommissions(props) {
	const [organizations, setOrganizations] = useState([]);
	const [invoices, setInvoices] = useState([]);

	useEffect(() => {
		const getData = async () => {
			let orgsData = await OrganizationsService.getOrganizations(props.auth.organization.organization_id);
			setOrganizations(orgsData);
			let invoicesData = await OrganizationsService.getPartnerInvoices(props.auth.organization.organization_id);
			setInvoices(invoicesData);
		};

		getData();
	}, [props.auth.organization.organization_id]);

	function invoicePaymentReducer(invoiceA, invoiceB) {
		let value1, value2;
		if (typeof invoiceA === 'object') {
			value1 = invoiceA.amount_paid;
		} else {
			value1 = invoiceA;
		}
		if (typeof invoiceB === 'object') {
			value2 = invoiceB.amount_paid;
		} else {
			value2 = invoiceB;
		}
		return value1 + value2;
	}

	const commissionRate = props.auth.organization.organization_partner_commission_rate / 100;

	function checkInvoiceForMoment(invoice, momentObj) {
		return moment.unix(invoice.period_start).isAfter(momentObj.startOf('month')) &&
			moment.unix(invoice.period_start).isBefore(momentObj.endOf('month'));
	}

	const twelveMonthsAgo = moment().subtract(12, 'months');
	const twoMonthsAgo = moment().subtract(2, 'months');
	const oneMonthAgo = moment().subtract(1, 'months');
	const currentMonth = moment();

	const commissions2MonthsAgo = commissionRate * invoices.filter(invoice => {
		return checkInvoiceForMoment(invoice, twoMonthsAgo);
	}).reduce(invoicePaymentReducer, 0);
	const commissions1MonthsAgo = commissionRate * invoices.filter(invoice => {
		return checkInvoiceForMoment(invoice, oneMonthAgo);
	}).reduce(invoicePaymentReducer, 0);
	const commissionsCurrentMonths = commissionRate * invoices.filter(invoice => {
		return checkInvoiceForMoment(invoice, currentMonth);
	}).reduce(invoicePaymentReducer, 0);

	return (
		<ScrollView contentContainerStyle={[basicStyles.flexScale, {maxHeight: getScreenMaxHeight()}]}>
			<PageTop style={[
				basicStyles.flexRow,
				basicStyles.alignContentCenter,
				basicStyles.justifyContentSpaceBetween,
			]}>
				<AppText style={[textStyles.pageTitle, textStyles.semiBold]}>
					Commissions By Client
				</AppText>
			</PageTop>
			<PageContentContainer>
				<AppText style={[textStyles.pageSubTitle, textStyles.semiBold]}>
					Income Chart
				</AppText>
				<ContentSection style={[styles.sectionPadding, {marginBottom: 70, padding: 24}]}>
					<AppBarChart
						data={[
							{
								label: twoMonthsAgo.format('MMMM'),
								value: commissions2MonthsAgo / 100,
							},
							{
								label: oneMonthAgo.format('MMMM'),
								value: commissions1MonthsAgo / 100,
							},
							{
								label: currentMonth.format('MMMM'),
								value: commissionsCurrentMonths / 100,
							},
						]}
					/>
				</ContentSection>
				<AppText style={[textStyles.pageSubTitle, textStyles.semiBold]}>
					Accounts
				</AppText>
				{
					organizations.map(organization => {
						const currentMonthCommissions = commissionRate * invoices.filter(invoice => {
							return invoice.customer === organization.stripe_customer_id && checkInvoiceForMoment(invoice, currentMonth);
						}).reduce(invoicePaymentReducer, 0);
						const twelveMonthCommissions = commissionRate * invoices.filter(invoice => {
							return invoice.customer === organization.stripe_customer_id &&
								moment.unix(invoice.period_start).isAfter(twelveMonthsAgo.startOf('month'));
						}).reduce(invoicePaymentReducer, 0);
						const allTimeCommissions = commissionRate * invoices.filter(invoice => {
							return invoice.customer === organization.stripe_customer_id;
						}).reduce(invoicePaymentReducer, 0);

						return (
							<ActionRowWithAvatar
								key={organization.organization_id}
								action={CommonActions.navigate('Clients', {
									screen: 'ClientCommissions',
									params: {
										organizationId: organization.organization_id
									}
								})}
								icon={
									<Avatar
										source={{uri: organization.organization_thumbnail ? AVATAR_PATH_ORG + organization.organization_thumbnail : AVATAR_DEFAULT}}
										width={66}
									/>
								}
								title={organization.organization_title}
								slot1={{
									label: 'This Month',
									value: '$' + (currentMonthCommissions / 100),
								}}
								slot2={{
									label: 'Year to Date',
									value: '$' + (twelveMonthCommissions / 100),
								}}
								slot3={{
									label: 'All Time',
									value: '$' + (allTimeCommissions / 100),
								}}
							/>
						);
					})
				}
			</PageContentContainer>
		</ScrollView>
	);
}

const mapStateToProps = (state) => {
	const {auth} = state;
	return {auth};
};

export default connect(mapStateToProps)(ClientsCommissions);

const styles = StyleSheet.create({
	commissions: {
		fontFamily: 'SourceSansPro-Bold',
		fontSize: 16,
	},
});
