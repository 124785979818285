import React, {useCallback, useEffect, useState} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import {connect, useDispatch} from "react-redux";
import {navigate} from "../../utilities/navigation";
import { setWarning } from '../../store/warning/warningActions';
import SubscriptionTypesService from "../../services/SubscriptionTypesService";
import {basicStyles} from "../../styles/basic";
import OrganizationsService from "../../services/OrganizationsService";
import AdditionalServicesService from "../../services/AdditionalServicesService";
import PageTop from "../../components/PageTop";
import AppText from "../../components/AppText";
import {textStyles} from "../../styles/text";
import PageContentContainer from "../../components/PageContentContainer";
import SubscriptionSelector from "../../components/SubscriptionSelector";
import AdditionalServiceSelector from "../../components/AdditionalServiceSelector";
import SubscriptionSelectorsContainer from "../../components/SubscriptionSelectorsContainer";
import {FLUID_ADMIN_ROLE_ID, FREE_PLAN_FEATURES} from '../../config';
import AppButton from "../../components/AppButton";
import Modal from "../../components/Modal";
import AppTextInput from "../../components/AppTextInput";
import ContentSection from "../../components/ContentSection";

function ManageSubscription(props) {
    const dispatch = useDispatch();
    const [organization, setOrganization] = useState({});
    const [subscriptionTypes, setSubscriptionTypes] = useState([]);
    const [additionalServices, setAdditionalServices] = useState([]);
    const [currentSubscriptionTypeId, setCurrentSubscriptionTypeId] = useState(null);
    const [currentAddOnServices, setCurrentAddOnServices] = useState([]);
    const [editingSubscription, setEditingSubscription] = useState(false);
    const [subscriptionId, setSubscriptionId] = useState(null);

    const organizationId = props.route.params.organizationId;

    const handleCloseModal = useCallback(() => {
        setSubscriptionId(organization?.stripe_subscription_id);
        setEditingSubscription(false);
    }, [organization]);

    const handleSaveModal = useCallback(() => {
        updateOrganization('stripe_subscription_id', subscriptionId);
        updateSubscriptionId();
        setEditingSubscription(false);
    }, [organization, subscriptionId]);

    function updateOrganization(prop, value) {
        setOrganization({...organization, [prop]: value});
    }

    function updateSubscription() {
        const asyncUpdateSubscription = async () => {
            try {
                let orgData = await OrganizationsService.updateSubscription(
                    organizationId,
                    organization.subscription_type_id
                );
                setOrganization(orgData);
                setCurrentSubscriptionTypeId(orgData.subscription_type_id)
                setCurrentAddOnServices([...orgData.additionalServices]);
            } catch (error) {
                if (error.response?.data.type === 'no-payment-method') {
                    dispatch(setWarning({
                        confirmAction: () => {
                            navigate('PaymentSettings');
                        },
                        confirmLabel: 'Add Payment Method',
                        rejectLabel: 'Cancel',
                        warningMessage: error.response.data.message,
                        active: true
                    }))
                }
            }
            
        };

        asyncUpdateSubscription();
    }

    function updateSubscriptionId() {
        const asyncUpdateSubscriptionId = async () => {
            try {
                let orgData = await OrganizationsService.updateSubscriptionID(
                    organizationId,
                    subscriptionId
                );
                setOrganization(orgData);
            } catch (error) {
                console.log(error);
            }
        };

        asyncUpdateSubscriptionId();
    }

    async function createAddOnService(additionalService) {
        try {
            let orgData = await OrganizationsService.createAddOnService(
                organizationId,
                additionalService.additional_service_id
            );
            setOrganization(orgData);
            setCurrentSubscriptionTypeId(orgData.subscription_type_id)
            setCurrentAddOnServices([...orgData.additionalServices]);
        } catch (error) {
            if (error.response?.data.type === 'no-payment-method') {
                dispatch(setWarning({
                    confirmAction: () => {
                        navigate('PaymentSettings');
                    },
                    confirmLabel: 'Add Payment Method',
                    rejectLabel: 'Cancel',
                    warningMessage: error.response.data.message,
                    active: true
                }))
            }
        }
    }

    async function removeAddOnService(additionalService) {
         try {
            let orgData = await OrganizationsService.removeAddOnService(
                organizationId,
                additionalService.additional_service_id
            );
            setOrganization(orgData);
            setCurrentSubscriptionTypeId(orgData.subscription_type_id)
            setCurrentAddOnServices([...orgData.additionalServices]);
        } catch (error) {
            if (error.response?.data.type === 'no-payment-method') {
                dispatch(setWarning({
                    confirmAction: () => {
                        navigate('PaymentSettings');
                    },
                    confirmLabel: 'Ok',
                    rejectLabel: 'Cancel',
                    warningMessage: error.response.data.message,
                    active: true
                }))
            }
        }
    }

    useEffect(() => {
        const getOrganization = async () => {
            const orgData = await OrganizationsService.getOrganization(organizationId);
            setOrganization(orgData);
            setCurrentSubscriptionTypeId(orgData.subscription_type_id)
            setCurrentAddOnServices([...orgData.additionalServices]);
            setSubscriptionId(orgData.stripe_subscription_id);
        };

        getOrganization();
    }, [organizationId]);

    const getSubscriptionTypes = useCallback(async () => {
        return SubscriptionTypesService.getSubscriptionTypes(props.auth.permissions.includes('access_non_onboarding_subscription_types') ? 0 : 1);
    }, []);

    const getAdditionalServices = useCallback(async () => {
        return AdditionalServicesService.getAdditionalServices();
    }, []);

    useEffect(() => {
        getSubscriptionTypes().then(results => setSubscriptionTypes(results));
        getAdditionalServices().then(results => setAdditionalServices(results));
    }, []);

    return (
        <ScrollView contentContainerStyle={basicStyles.flexScale}>
            <PageTop style={[styles.header]}>
                <View
                    style={[basicStyles.flexRow, basicStyles.justifyContentSpaceBetween]}
                >
                    <AppText style={[textStyles.pageTitle, textStyles.semiBold, styles.headerText]}>
                        Fluid Local Subscriptions
                    </AppText>
                    {props.auth.organizationRoleId == FLUID_ADMIN_ROLE_ID && (
                        <AppButton
                            label="Edit Subscription ID"
                            action={() => setEditingSubscription(true)}
                        />
                    )}
                </View>
            </PageTop>
            <PageContentContainer>
                <SubscriptionSelectorsContainer style={{maxWidth: '100%'}}>
                    <SubscriptionSelector
                        subscriptionType={{
                            subscription_type_title: 'Fluid Local Free',
                            subscription_type_cents: '0',
                            subscription_type_max_users: 1,
                            subscription_type_features: FREE_PLAN_FEATURES
                        }}
                        active={!organization.subscription_type_id}
                        onSelect={() => updateOrganization('subscription_type_id', null)}
                        onDeselect={() => updateOrganization('subscription_type_id', currentSubscriptionTypeId)}
                        onConfirm={() => {
                            updateSubscription();
                        }}
                        confirmLabel="Confirm Update"
                        deselectDisabled={!currentSubscriptionTypeId}
                        confirmDisabled={!currentSubscriptionTypeId}
                    />
                    {
                        subscriptionTypes.map((subscriptionType) => {
                            return (
                                <SubscriptionSelector
                                    key={subscriptionType.subscription_type_id}
                                    active={organization.subscription_type_id === subscriptionType.subscription_type_id}
                                    subscriptionType={subscriptionType}
                                    onSelect={() => updateOrganization('subscription_type_id', subscriptionType.subscription_type_id)}
                                    onDeselect={() => updateOrganization('subscription_type_id', currentSubscriptionTypeId)}
                                    onConfirm={() => {
                                        updateSubscription();
                                    }}
                                    confirmLabel="Confirm Update"
                                    deselectDisabled={currentSubscriptionTypeId === subscriptionType.subscription_type_id}
                                    confirmDisabled={currentSubscriptionTypeId=== subscriptionType.subscription_type_id}
                                />
                            );
                        })
                    }
                </SubscriptionSelectorsContainer>
            </PageContentContainer>
            {!!additionalServices.length
                && organization.organization_display_add_ons !== '0'
                && organization.partnerOrganization?.organization_display_add_ons !== '0'
                && (
                <>
                    <PageTop style={[styles.header]}>
                        <AppText style={[textStyles.pageTitle, textStyles.semiBold, styles.headerText]}>
                            Add-On Services
                        </AppText>
                    </PageTop>
                        <PageContentContainer>
                            <SubscriptionSelectorsContainer style={{maxWidth: '100%', justifyContent: 'flex-start'}}>
                            {
                                additionalServices.map((additionalService) => {
                                    return (
                                        <AdditionalServiceSelector
                                            key={additionalService.additional_service_id}
                                            active={!!organization?.additionalServices?.find(service => service.additional_service_id === additionalService.additional_service_id)}
                                            additionalService={additionalService}
                                            onSelect={() => updateOrganization('additionalServices', [...organization.additionalServices, {...additionalService}])}
                                            onDeselect={() => updateOrganization('additionalServices', organization.additionalServices.filter(service => service.additional_service_id !== additionalService.additional_service_id))}
                                            onConfirm={() => {
                                                createAddOnService(additionalService);
                                            }}
                                            confirmLabel="Confirm Update"
                                            deselectDisabled={!!currentAddOnServices.find(item => item.additional_service_id === additionalService.additional_service_id)}
                                            confirmDisabled={!!currentAddOnServices.find(item => item.additional_service_id === additionalService.additional_service_id)}
                                            showRemove={!!currentAddOnServices.find(item => item.additional_service_id === additionalService.additional_service_id)}
                                            onRemove={() => {
                                                removeAddOnService(additionalService);
                                            }}
                                        />
                                    );
                                })
                            }
                        </SubscriptionSelectorsContainer>
                    </PageContentContainer>
                </>
            )}
            <Modal
                active={editingSubscription}
                onClose={handleCloseModal}
            >
                <ContentSection
                    style={[styles.contentWrapper]}
                    onClick={e => {
                        e.stopPropagation();
                    }}
                >
                    <ScrollView>
                        <AppText style={[textStyles.pageTitle, {marginBottom: 10}]}>
                            Edit Stripe Subscription ID
                        </AppText>
                        <AppTextInput
                            label="Stripe Subscription ID"
                            value={subscriptionId}
                            onChangeText={value => {
                                setSubscriptionId(value);
                            }}
                            wrapperStyle={styles.inputWrapper}
                        />
                        <View style={[basicStyles.flexRow, basicStyles.justifyContentCenter]}>
                            <AppButton
                                label="Cancel"
                                action={handleCloseModal}
                                theme="transBlue"
                                style={{marginRight: 10}}
                            />
                            <AppButton
                                label="Save"
                                action={handleSaveModal}
                            />
                        </View>
                    </ScrollView>
                </ContentSection>
            </Modal>
        </ScrollView>
    );
}

const mapStateToProps = (state) => {
    const {auth} = state;
    return {auth};
};

export default connect(mapStateToProps)(ManageSubscription);

const styles = StyleSheet.create({
    subscriptionTypesContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    header: {
        borderTopWidth: 1,
        borderTopColor: 'rgb(226, 233, 244)',
    },
    contentWrapper: {
        padding: 32,
        width: 600,
        maxWidth: '100%',
        maxHeight: '100vh',
    },
    inputWrapper: {
        marginTop: 50,
        marginBottom: 50
    },
});
