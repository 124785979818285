import {StyleSheet} from 'react-native';

export const basicStyles = StyleSheet.create({
	flexScale: {
		flex: 1,
	},
	padding20: {
		padding: 20,
	},
	widthThird: {
		width: '33.333%',
	},
	widthTwoThird: {
		width: '66.666%',
	},
	widthHalf: {
		width: '50%',
	},
	flexCenterContent: {
		justifyContent: 'center',
		alignItems: 'center',
	},
	flexRow: {
		flexDirection: 'row',
	},
	alignContentCenter: {
		alignItems: 'center',
	},
	alignContentStart: {
		alignItems: 'flex-start',
	},
	justifyContentSpaceBetween: {
		justifyContent: 'space-between',
	},
	justifyContentSpaceAround: {
		justifyContent: 'space-around',
	},
	justifyContentFlexStart: {
		justifyContent: 'flex-start',
	},
	justifyContentFlexEnd: {
		justifyContent: 'flex-end',
	},
	justifyContentCenter: {
		justifyContent: 'center',
	},
	flexWrap: {
		flexWrap: 'wrap',
	},
	button: {
		borderWidth: 1,
		borderRadius: 5,
		width: 130,
		height: 40,
		justifyContent: 'center',
		alignItems: 'center',
	},
	uploadButton: {
		borderColor: '#0065DB',
	},
	removeButton: {
		borderColor: '#EA0024',
	},
	inputContainerPaddingVertical: {
		paddingTop: 25,
		paddingBottom: 25,
	},
	inputContainerPaddingHorizontal: {
		paddingLeft: 15,
		paddingRight: 15,
	},
	inputWrapper: {
		paddingLeft: 15,
		paddingRight: 15,
		marginBottom: 18,
	},
	overlayHidden: {
		opacity: 0,
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		width: '100%',
	},
	clickableIndicator: {
		justifyContent: 'center',
		alignItems: 'center',
		width: 36,
		height: 35,
		borderRadius: 35 / 2,
		backgroundColor: '#F3F5FA',
	},
	contentSection: {
		backgroundColor: '#FFFFFF',
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 3,
		},
		shadowOpacity: 0.1,
		shadowRadius: 9.11,
		borderWidth: 1,
		borderColor: '#E2E9F4',
		borderRadius: 5,
	},
	spreadSheetWrapper: {
		paddingLeft: 30,
		paddingRight: 30,
		paddingTop: 35,
		paddingBottom: 40,
	},
	accountSetupInfoBar: {
		height: 80,
		backgroundColor: '#2886ef',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	},
	sortRow: {
		paddingTop: 40,
		paddingBottom: 35,
		zIndex: 1,
		elevation: 1,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	actionRowColumn: {
		flex: 1,
		height: 40,
	},
	columnLabel: {
		fontSize: 16,
		color: '#9BA8CA',
	},
	columnValue: {
		fontSize: 16,
	},
	sectionHeader: {
		width: '100%',
		borderBottomWidth: 1,
		borderBottomColor: '#E2E9F4',
		marginBottom: 35,
		flexDirection: 'row'
	}
});
