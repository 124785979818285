import Http from "../utilities/Http";
import {API_ROOT} from "../config";

export default {
    async getSurveyResponses(organizationId, search = null, order = null, orderBy = null, completedOnly = true, completedAfterDate = null, completedBeforeDate = null, perPage = null, page = null, paginated = 1) {
        let response = await Http().get(API_ROOT + '/organizations/' + organizationId + '/survey-responses', {
            params: {
                search,
                order,
                orderBy,
                completedOnly: completedOnly ? 1 : 0,
                completedAfterDate,
                completedBeforeDate,
                perPage,
                page,
                paginated
            }
        });

        return response.data;
    }
};
