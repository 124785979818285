import React, {useState, useMemo} from 'react';
import {View, Dimensions} from 'react-native';
import EStyleSheet from "react-native-extended-stylesheet";

import AppText from "../../../components/AppText";
import AppButton from "../../../components/AppButton";
import {basicStyles} from "../../../styles/basic";
import {textStyles} from "../../../styles/text";
import ScreenOverlay from "../../../components/ScreenOverlay";
import ContentSection from "../../../components/ContentSection";
import AppTextInput from "../../../components/AppTextInput";

function CreateNewNoteModal({handleClose, handleSubmit}) {
    const [noteContent, setNoteContent] = useState('');

    const screenWidth = useMemo(() => {
        return Dimensions.get('window').width
    }, [])

    return (
        <ScreenOverlay
            style={{position: 'fixed', left: screenWidth >= 630 ? 80 : 0, width: 'auto'}}
            onClick={() => {
            handleClose();
        }}>
            <ContentSection
                style={[styles.contentWrapper]}
                onClick={e => {
                    e.stopPropagation();
                }}
            >
                <AppText style={[textStyles.pageTitle, {marginBottom: 10}]}>
                    Create a new Private Note
                </AppText>
                <AppTextInput
                    wrapperStyle={styles.wrapperStyle}
                    multiline={true}
                    numberOfLines={5}
                    label="Enter Note Content"
                    onChangeText={value => {
                        setNoteContent(value)
                    }}
                    value={noteContent}
                />
                <View style={[basicStyles.flexRow, basicStyles.justifyContentFlexEnd, {marginTop: 20}]}>
                    <AppButton
                        label="Cancel"
                        action={handleClose}
                        style={{marginRight: 10}}
                        theme="transBlue"
                    />
                    <AppButton
                        label="Confirm"
                        action={() => {
                            handleSubmit(noteContent);
                        }}
                        style={{marginRight: 10}}
                        disabled={!noteContent}
                    />
                </View>
            </ContentSection>
        </ScreenOverlay>
    );
}

export default CreateNewNoteModal;

const styles = EStyleSheet.create({
    contentWrapper: {
        padding: 32,
        width: 600,
        maxWidth: '100%'
    },
    wrapperStyle: {
        height: 'auto'
    }
});

