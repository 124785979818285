import React, {forwardRef, useRef, useImperativeHandle, useState, useEffect} from 'react';
import {TextInput, View, StyleSheet} from 'react-native';

const AutoExpandingTextInput =
    forwardRef(({
                    style = [],
                    onChangeText = value => value,
                    value = '',
                    ...props
                },
                ref) => {
        const inputRef = useRef(null)
        const hiddenDivRef = useRef(null)
        const [inputHeight, setInputHeight] = useState(18);

        useImperativeHandle(ref, // forwarded ref
            function () {
                return {
                    focus() {
                        inputRef.current.focus()
                    },
                    blur() {
                        inputRef.current.blur()
                    },
                } // the forwarded ref value
            }, []);

        useEffect(() => {
            if(hiddenDivRef.current) {
                setInputHeight(hiddenDivRef.current.clientHeight);
            }
        }, [value])

        return (
            <View style={[styles.inputWrapper, ...style]}>
                <TextInput
                    ref={inputRef}
                    value={value}
                    style={[{height: inputHeight}, styles.input]}
                    multiline={true}
                    onChangeText={value => {
                        onChangeText(value);
                    }}
                    {...props}
                />
                <div ref={hiddenDivRef} style={{
                    minHeight: 18,
                    fontFamily: 'SourceSansPro-Regular',
                    whiteSpace: 'pre-line', // Allow line breaks
                    wordWrap: 'break-word', // Break long words
                    fontSize: 14,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    width: '100%', // Set width to match text input
                    visibility: 0,
                    pointerEvents: 'none',
                    opacity: 0,
                    maxWidth: '100%',
                }}>
                    {value || ' '}{value.split('\n').at(-1) === '' ? ' ' : null}
                </div>
            </View>
        );
    })

export default AutoExpandingTextInput;

const styles = StyleSheet.create({
    inputWrapper: {
        flex: 1,
        position: 'relative'
    },
    input: {
        width: '100%',
        outlineWidth: 0,
        fontFamily: 'SourceSansPro-Regular'
    }
});
