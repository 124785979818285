import React from 'react';
import {Image} from 'react-native';

function Avatar({source, width, style = {}}) {
	return (
		<Image
			style={[
				{
					width: width,
					height: width,
					borderWidth: 1,
					borderColor: '#E2E9F4',
					borderRadius: width / 2,

				},
				style,
			]}
			source={source}
		/>
	);
}

export default Avatar;
