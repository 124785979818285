import React from 'react';
import {ActivityIndicator, StyleSheet, View, Text} from 'react-native';
import ScreenOverlay from './ScreenOverlay';
import {basicStyles} from '../styles/basic';
import AppText from './AppText';
import {connect} from 'react-redux';

function ProcessingIndicatorOverlay(props) {
	const {processingIndicatorActive} = props.miscellaneous;

	if (processingIndicatorActive) {
		return (
			<ScreenOverlay>
				<View style={basicStyles.flexRow}>
					<Text>
						<ActivityIndicator size="large" color="#FFFFFF"/>
					</Text>
					<AppText style={styles.processingText}>Processing</AppText>
				</View>
			</ScreenOverlay>
		);
	}

	return (<></>);
}


const mapStateToProps = (state) => {
	const {miscellaneous} = state;
	return {miscellaneous};
};

export default connect(mapStateToProps)(ProcessingIndicatorOverlay);

const styles = StyleSheet.create({
	processingText: {
		color: '#FFFFFF',
		fontFamily: 'SourceSansPro-SemiBold',
		fontSize: 20,
		marginLeft: 20,
	},
});
