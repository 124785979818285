import React from 'react';
import {View} from 'react-native';

import StarSvg from './svgs/StarSvg';
import {basicStyles} from '../styles/basic';
import TouchView from './TouchView';

function StarRangeInput({value, onChange = () => {}, range = 5, disabled = false, starSize = 22}) {
	let stars = [];


	for (let i = 0; i <= range - 1; i++) {
		const rangeValue = i+1;
		stars.push(
			<TouchView action={() => onChange(rangeValue)} key={i} disabled={disabled}>
				{
					rangeValue <= value ?
						<StarSvg fill="#0065DB" style={{marginRight: 5}} size={starSize}/>
						:
						<StarSvg style={{marginRight: 5}} size={starSize}/>
				}
			</TouchView>,
		);
	}

	return (
		<View style={[basicStyles.flexRow, basicStyles.alignContentCenter]}>
			{stars}
		</View>
	);
}

export default StarRangeInput;
