export const reviewResponseActionTypes = {
	SET_REVIEW_RESPONSE: 'SET_REVIEW_RESPONSE',
	DESTROY_REVIEW_RESPONSE: 'DESTROY_REVIEW_RESPONSE',
	SET_AI_GENERATED: 'SET_AI_GENERATED',
};

export const setReviewResponse = reviewResponse => (
	{
		type: reviewResponseActionTypes.SET_REVIEW_RESPONSE,
		payload: reviewResponse,
	}
);

export const destroyReviewResponse = () => (
	{
		type: reviewResponseActionTypes.DESTROY_REVIEW_RESPONSE,
	}
);

export const setAiGenerated = aiGenerated => (
	{
		type: reviewResponseActionTypes.SET_AI_GENERATED,
		payload: aiGenerated,
	}
);
