import * as React from "react"
import Svg, { Path } from "react-native-svg"

function StarSvg({fill = '#d0dbeb', size = 22, ...rest}) {
	return (
		<Svg width={size} height={size} viewBox="0 0 22.454 21.331" {...rest}>
			<Path
				data-name="Path 432"
				d="M11.227 17.144l6.938 4.188-1.841-7.893 6.13-5.31-8.072-.685L11.227 0 8.072 7.443 0 8.128l6.13 5.31-1.841 7.893z"
				fill={fill}
			/>
		</Svg>
	)
}

export default StarSvg;
