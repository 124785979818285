import {Animated} from 'react-native';

const fullScreenStackTransitionCardStyleInterpolator = ({current, next, index, closing, layouts, inverted}) => {
	return {
		cardStyle: {
			transform: [// Translation for the animation of the current card
				{   //Move in from right
					translateX: Animated.multiply(current.progress.interpolate({
						inputRange: [0, 1],
						outputRange: [layouts.screen.width, 0],
						extrapolate: 'clamp'
					}), inverted)
				}, // Translation for the animation of the card on top of this
				{   // Move out to left
					translateX: next ? (Animated.multiply(next.progress.interpolate({
						inputRange: [0, 1],
						outputRange: [0, layouts.screen.width * -1],
						extrapolate: 'clamp'
					}), inverted)) : 0
				}]
		}
	};
};

export default fullScreenStackTransitionCardStyleInterpolator
