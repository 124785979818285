import React, {useEffect, useState} from 'react';
import {ScrollView, StyleSheet, Text, View} from 'react-native';

import {basicStyles} from '../../styles/basic';
import PageTop from "../../components/PageTop";
import AppText from "../../components/AppText";
import PageContentContainer from "../../components/PageContentContainer";
import {connect} from "react-redux";
import {CommonActions} from "@react-navigation/native";
import BackBar from "../../components/BackBar";
import Avatar from "../../components/Avatar";
import {AVATAR_DEFAULT, AVATAR_PATH_ORG, AVATAR_PATH_USER} from "../../config";
import {getUserDisplayName} from "../../utilities/methods";
import {textStyles} from "../../styles/text";
import ContentSection from "../../components/ContentSection";
import AppTextInput from "../../components/AppTextInput";
import {inputStyles} from "../../styles/input";
import AppButton from "../../components/AppButton";
import AuthService from "../../services/AuthService";
import EStyleSheet from 'react-native-extended-stylesheet';

function UserProfileChangePassword(props) {

	const [state, setState] = useState({
		user: {...props.auth.user},
		organization: {...props.auth.organization},
		currentPassword: '',
		updatedPassword: '',
		updatedPasswordConfirm: '',
		responseMessage: '',
		initialized: false,
	});

	const updateState = (prop, value) => {
		setState(prevState => {
			return Object.assign({}, prevState, {
				[prop]: value
			});
		});
	};

	async function updatePassword () {
		updateState('responseMessage', '');

		if(state.updatedPassword != state.updatedPasswordConfirm) {
			updateState('responseMessage', 'The new passwords do not match.');
			return;
		}
		const result = await AuthService.changePassword(state.user.user_email, state.currentPassword, state.updatedPassword, state.updatedPasswordConfirm);

		if(result.success == true) {
			updateState('responseMessage', 'Your password has been updated!');
		} else {
			updateState('responseMessage', 'The email and password were invalid.');
		}
	};

	return (
		<ScrollView contentContainerStyle={basicStyles.flexScale}>
			<BackBar
				action={CommonActions.navigate('UserProfile')}
				label="Back To Profile"
			/>
			<PageTop style={[basicStyles.flexRow, basicStyles.justifyContentSpaceBetween, basicStyles.alignContentCenter]}>
				<View style={styles.header}>
					<View style={styles.headerUser}>
						<Avatar
							source={{uri: state.user.user_avatar_file ? AVATAR_PATH_USER + state.user.user_avatar_file : AVATAR_DEFAULT}}
							width={50}
						/>
						<AppText style={styles.userName}>
							{
								getUserDisplayName(state.user)
							}
						</AppText>
					</View>
					<View style={styles.headerTeam}>
						<Avatar
							source={{uri: state.organization.organization_thumbnail ? AVATAR_PATH_ORG + state.organization.organization_thumbnail : AVATAR_DEFAULT}}
							width={30}
						/>
						<AppText style={styles.teamMemberName}>
							{state.organization.organization_title}
						</AppText>
					</View>
				</View>
			</PageTop>
			<PageContentContainer style={styles.content}>
				<>
					<View style={[styles.headerTeam, styles.headerTeamMobile]}>
						<Avatar
							source={{uri: state.organization.organization_thumbnail ? AVATAR_PATH_ORG + state.organization.organization_thumbnail : AVATAR_DEFAULT}}
							width={30}
						/>
						<AppText style={styles.teamMemberName}>
							{state.organization.organization_title}
						</AppText>
					</View>
					<AppText style={[textStyles.pageSubTitle, textStyles.semiBold]}>
						Password
					</AppText>
					<AppText style={[textStyles.pageSubTitle]}>{state.responseMessage}</AppText>
					<ContentSection
						style={[
							styles.passwordSection,
							basicStyles.inputContainerPaddingVertical,
							basicStyles.inputContainerPaddingHorizontal
						]}>
						<View style={[
							basicStyles.flexRow,
							basicStyles.justifyContentSpaceBetween,
							styles.inputContainer
						]}>
							<AppTextInput
								wrapperStyle={[styles.passwordField]}
								label="Current Password"
								onChangeText={value => updateState('currentPassword', value)}
								secureTextEntry={true}
								value={state.currentPassword || ''}
							/>
							<AppTextInput
								wrapperStyle={[styles.passwordField]}
								label="New Password"
								onChangeText={value => updateState('updatedPassword', value)}
								secureTextEntry={true}
								value={state.updatedPassword || ''}
							/>
						</View>
						<View style={[
							basicStyles.flexRow,
							basicStyles.justifyContentSpaceBetween,
							styles.inputContainer
						]}>
							<AppTextInput
								wrapperStyle={[styles.passwordField]}
								label="Confirm New Password"
								onChangeText={value => updateState('updatedPasswordConfirm', value)}
								secureTextEntry={true}
								value={state.updatedPasswordConfirm || ''}
							/>
							<View style={[styles.passwordField]}>
								<AppButton
									style={[styles.passwordSubmit]}
									label="Change Password"
									theme="green"
									action={updatePassword}
								/>
							</View>
						</View>
					</ContentSection>
				</>
			</PageContentContainer>
		</ScrollView>
	);
}

const mapStateToProps = (state) => {
	const {auth} = state;
	return {auth};
};

export default connect(mapStateToProps)(UserProfileChangePassword);

const styles = EStyleSheet.create({
	header: {
		flex:1,
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'space-between',
	},
	headerUser: {
		flexDirection: 'row',
		alignItems: 'center'
	},
	userName: {
		color: '#000',
		fontSize: 28,
		fontFamily: 'SourceSansPro-SemiBold',
		paddingLeft: 10
	},
	headerTeam: {
		flexDirection: 'row',
		alignItems: 'center',
		borderColor: '#E2E9F4',
		borderWidth: 1,
		borderRadius: 8,
		padding: 20
	},
	teamMemberName: {
		fontSize: 16,
		fontFamily: 'SourceSansPro-Regular',
		color: '#8F95A5',
		paddingLeft: 10
	},
	content: {
		paddingTop: 85,
		paddingLeft: 30,
		paddingRight: 30
	},
	passwordSection: {
		//flexDirection: 'column',
		borderWidth: 1,
		borderRadius: 5,
		borderColor: '#E2E9F4',
		paddingTop: 10,
		paddingBottom: 10,
		paddingRight: 20,
		paddingLeft: 0,
	},
	passwordField: {
		marginTop:10,
		marginBottom: 10,
		width: '49%',
		justifyContent: 'center',
	},
	passwordSubmit: {
		alignSelf: 'flex-end',
	},
	'@media (min-width: 700)': {
		headerTeamMobile: {
			display: 'none'
		}
	},
	'@media (max-width: 768)': {
		inputContainer: {
			flexDirection: 'column',
		},
		passwordField: {
			width: '100%'
		},
		passwordSubmit: {
			alignSelf: 'flex-start'
		}
	},
	'@media (max-width: 700)': {
		header: {
			flexDirection: 'column'
		},
		headerTeam: {
			display: 'none'
		},
		headerTeamMobile: {
			display: 'flex',
			marginBottom: 50
		},
		content: {
			paddingTop: 25
		}
	},
	'@media (max-width: 450)': {
		passwordSubmit: {
			width: '100%'
		}
	},
});
