import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SearchSvg({fill = '#9ba8ca', ...props}) {
	return (
		<Svg width={12.251} height={12.251} viewBox="0 0 12.251 12.251" {...props}>
			<Path
				data-name="Path 549"
				d="M8.756 7.705H8.2L8 7.516a4.56 4.56 0 10-.49.49l.189.2v.553l3.5 3.5 1.044-1.044zm-4.2 0a3.152 3.152 0 113.152-3.152 3.148 3.148 0 01-3.155 3.152z"
				fill={fill}
			/>
		</Svg>
	)
}

export default SearchSvg
