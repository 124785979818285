import React from 'react';
import {View} from 'react-native';
import {createStackNavigator} from '@react-navigation/stack';

import JobCreateOptions from './JobCreateOptions';
import JobSingle from './JobSingle';
import {basicStyles} from '../../styles/basic';

const Stack = createStackNavigator();

function JobAddNavigation(props) {
	return (
		<View style={basicStyles.flexScale}>
			<Stack.Navigator
				backBehavior="history"
				screenOptions={{
					header: () => null,
					cardStyle: {
						backgroundColor: '#F8FAFD'
					}
				}}
				initialRouteName="JobCreateOptions"
			>
				<Stack.Screen
					name={"JobCreateOptions"}
					component={JobCreateOptions}
					options={{
						title: "Jobs"
					}}
				/>
				<Stack.Screen
					name={"JobAdd"}
					component={JobSingle}
					options={{
						title: "Jobs"
					}}
				/>
			</Stack.Navigator>
		</View>
	);
}

export default JobAddNavigation;
