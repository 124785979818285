import * as React from "react"
import Svg, { G, Circle, Path } from "react-native-svg"

function BriefcaseBlueAlt(props) {
	return (
		<Svg width={72} height={72} viewBox="0 0 72 72" {...props}>
			<G data-name="Group 981">
				<G data-name="Ellipse 36" fill="none" stroke="#0065db" strokeWidth={4}>
					<Circle cx={36} cy={36} r={36} stroke="none" />
					<Circle cx={36} cy={36} r={34} />
				</G>
				<G data-name="Group 945">
					<G data-name="Group 940" fill="#0065db">
						<Path
							data-name="Path 495"
							d="M47.781 43.836h-3.972V41.85H27.918v1.986h-3.973V41.85H16v9.932h39.727V41.85h-7.946z"
						/>
						<Path
							data-name="Path 496"
							d="M51.754 27.945h-5.959v-3.972A3.984 3.984 0 0041.822 20H29.9a3.984 3.984 0 00-3.973 3.973v3.973h-5.954A3.984 3.984 0 0016 31.918v7.945h7.945v-3.972h3.973v3.973h15.891v-3.973h3.973v3.973h7.945v-7.946a3.984 3.984 0 00-3.973-3.973zm-9.932 0H29.9v-3.972h11.922z"
						/>
					</G>
				</G>
			</G>
		</Svg>
	)
}

export default BriefcaseBlueAlt;
