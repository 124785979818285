import React, {useState, useEffect, useRef} from 'react';
import {View, ScrollView, Dimensions, TouchableOpacity} from 'react-native';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import {basicStyles} from "../styles/basic";
import UnauthenticatedHeader from "../components/UnauthenticatedHeader";
import EStyleSheet from "react-native-extended-stylesheet";
import AppText from "../components/AppText";
import ContentSection from "../components/ContentSection";
import AppTextInput from "../components/AppTextInput";
import {useDebouncedCallback} from "use-debounce";
import AddressInput from "../components/AddressInput";
import AppButton from "../components/AppButton";
import FieldSet from '../components/FieldSet';
import TimeZonesService from "../services/TimeZonesService";
import SubscriptionTypesService from "../services/SubscriptionTypesService";
import StripePaymentSubscription from "../components/StripePaymentSubscription";
import SubscriptionSelector from "../components/SubscriptionSelector";
import TouchView from "../components/TouchView";
import RemoveSvg from "../components/svgs/RemoveSvg";
import {setInitialized, setOrganization, setOrganizationRoleId, setPermissions, setToken, setUser} from "../store/auth/authActions";
import {setWarning} from "../store/warning/warningActions";
import {setProcessingIndicatorActive} from "../store/miscellaneous/miscellaneousActions";
import OnBoardingService from "../services/OnBoardingService";
import AsyncStorage from "@react-native-community/async-storage";
import AuthService from "../services/AuthService";
import SubscriptionSelectorsContainer from "../components/SubscriptionSelectorsContainer";
import {cycleNotification} from "../store/notifications/notificationActions";
import {FREE_PLAN_FEATURES, GOOGLE_CLIENT_ID} from '../config';
import {GoogleLogin, GoogleOAuthProvider} from "@react-oauth/google";

function OnBoarding(props) {
    const [currentStep, setCurrentStep] = useState(0);
    const [header1Height, setHeader1Height] = useState(0);
    const [businessNameInput, setBusinessNameInput] = useState('');
    const [addressInput, setAddressInput] = useState('');
    const testEl = useRef(null);
    const [googlePlaces, setGooglePlaces] = useState([]);
    const [selectedGooglePlace, setSelectedGooglePlace] = useState({});
    const [organization, setOrganization] = useState({});
    const [timeZones, setTimeZones] = useState([]);
    const [user, setUser] = useState({});
    const [subscriptionTypes, setSubscriptionTypes] = useState([]);
    const [teamMembers, setTeamMembers] = useState([]);
    const [cardToken, setCardToken] = useState(null);
    const [completed, setCompleted] = useState(false);

    const {navigate} = props.navigation;
    const {setProcessingIndicatorActive, setToken, setOrganizationRoleId, setPermissions, setInitialized, setWarning, cycleNotification} = props;
    const setAuthUser = props.setUser;
    const setAuthOrganization = props.setOrganization;

    const [getGooglePlaceDetailsDebounce] = useDebouncedCallback(() => {
        googlePlaceFind();
    }, 500);

    function googlePlaceFind() {
        const asyncGooglePlaceFind = async () => {
            let service = new window.google.maps.places.PlacesService(testEl.current);

            let request = {
                query: businessNameInput + ' ' + addressInput,
                fields: ['name', 'geometry', 'formatted_address', 'place_id'],
            }

            service.textSearch(request, (results, status) => {
                console.log('status: ', status);
                console.log('results: ', results);
                if (results && status === window.google.maps.places.PlacesServiceStatus.OK) {
                    setGooglePlaces(results);
                }
            });
        };

        asyncGooglePlaceFind();
    }


    function googlePlaceFindDetails(placeId) {
        const asyncGooglePlaceFindDetails = async () => {
            let service = new window.google.maps.places.PlacesService(testEl.current);

            let request = {
                placeId: placeId,
                fields: ['name', 'geometry', 'formatted_address', 'place_id', 'address_component', 'photo', 'formatted_phone_number', 'website', 'utc_offset_minutes'],
            };

            service.getDetails(request, function (place, status) {
                if (place && status === window.google.maps.places.PlacesServiceStatus.OK) {
                    console.log('PLACE: ', place);
                    setOrganization({
                        ...organization,
                        organization_title: place.name,
                        organization_address: place.formatted_address,
                        organization_phone: place.formatted_phone_number,
                        organization_website: place.website,
                    });
                    place.google_place_id = placeId;
                    setSelectedGooglePlace(place);
                    incrementCurrentStep()
                }
            })
        };

        asyncGooglePlaceFindDetails();
    }

    function getScreenMaxHeight() {
        const windowHeight = Dimensions.get('window').height;

        return windowHeight - (header1Height);
    }

    function incrementCurrentStep() {
        setCurrentStep(currentStep + 1);
    }

    function decrementCurrentStep() {
        setCurrentStep(currentStep - 1);
    }

    function updateOrganization(prop, value) {
        setOrganization({...organization, [prop]: value});
    }

    function updateUser(prop, value) {
        setUser({...user, [prop]: value});
    }

    function updateTeamMember(index, prop, value) {
        setTeamMembers((prevTeamMembers) => {
            let teamMembers = [...prevTeamMembers];
            teamMembers[index][prop] = value;
            return teamMembers;
        });
    }


    function addTeamMember() {
        setTeamMembers((prevTeamMembers) => {
            let teamMembers = [...prevTeamMembers];
            teamMembers.push({});
            return teamMembers;
        });
    }


    function removeTeamMember(index) {
        setTeamMembers((prevTeamMembers) => {
            let teamMembers = [...prevTeamMembers];
            teamMembers.splice(index, 1);
            return teamMembers;
        });
    }

    async function getGoogleAccount (googleCred) {
        if (googleCred) {
            try {
                const userData = await OnBoardingService.getGoogleAccount(googleCred);
                setUser({
                    user_email: userData['email'],
                    profile_first_name: userData['first_name'],
                    profile_last_name: userData['last_name'],
                    user_google_auth_sub: userData['google_code']
                });

            } catch (error) {
                console.log(error);
            }
        }
    }

    async function submitOnBoard() {
        setProcessingIndicatorActive(true);
        try {
            let loginData = await OnBoardingService.onBoard(
                user,
                organization,
                selectedGooglePlace,
                teamMembers,
                cardToken
            );
            const {token, organizations} = loginData;
            const authUser = loginData.user;
            const authOrganization = organizations[0];
            const {role_id} = authOrganization;

            await AsyncStorage.setItem('authToken', token);
            await AsyncStorage.setItem('organizationId', authOrganization.organization_id);
            await AsyncStorage.setItem('roleId', role_id);

            setToken(token);
            setAuthUser(authUser);
            setAuthOrganization(authOrganization);
            setOrganizationRoleId(role_id);
            setPermissions(await AuthService.getPermissions(authOrganization.organization_id));
            setInitialized(true);

            setWarning({
                confirmAction() {
                    setCompleted(true);
                },
                confirmLabel: 'Continue',
                active: true,
                warningMessage: 'Registration Successful!'
            });
        } catch (e) {
            console.log('e: ', e);
            setProcessingIndicatorActive(false);
        }
        setProcessingIndicatorActive(false);
    }

    useEffect(() => {
        if (completed) {
            navigate('Authenticated', {
                screen: 'Jobs'
            });
        }
    }, [completed])

    useEffect(() => {
        if (businessNameInput || addressInput) {
            getGooglePlaceDetailsDebounce();
        }
    }, [businessNameInput, addressInput]);

    useEffect(() => {
        const getTimeZones = async () => {
            let timeZonesData = await TimeZonesService.getTimeZones();
            setTimeZones(timeZonesData);
        };

        const getSubscriptionTypes = async () => {
            let subscriptionTypesData = await SubscriptionTypesService.getSubscriptionTypes(1);
            setSubscriptionTypes(subscriptionTypesData);
        };

        getTimeZones();
        getSubscriptionTypes();
    }, []);

    return (
        <View style={basicStyles.flexScale}>
            <UnauthenticatedHeader
                onLayout={({nativeEvent: {layout: {x, y, width, height}}}) => {
                    if (!header1Height) {
                        setHeader1Height(prevHeaderHeight => prevHeaderHeight + height);
                    }
                }}
            />
            <ScrollView>
                <View style={[
                    basicStyles.flexScale,
                    basicStyles.flexCenterContent,
                    {
                        minHeight: getScreenMaxHeight(),
                    },
                ]}>
                    <View style={{
                        maxWidth: currentStep === 3 || currentStep === 4 ? '90%' : 663,
                        width: '100%'
                    }}>
                        {
                            currentStep === 0 ?
                                <View style={{paddingTop: 60, paddingBottom: 60}}>
                                    <AppText style={styles.heading}>
                                        Welcome to Fluid Local! Let's get started by identifying your business online. Let's get Google to fill in some of this on boarding stuff for you!
                                    </AppText>
                                    <ContentSection style={[
                                        basicStyles.inputContainerPaddingVertical,
                                        {
                                            width: '100%',
                                            marginTop: 10
                                        }
                                    ]}>
                                        <View style={[
                                            basicStyles.flexRow,
                                            basicStyles.alignContentCenter,
                                            basicStyles.justifyContentFlexStart,
                                            basicStyles.flexWrap,
                                            basicStyles.inputContainerPaddingHorizontal,
                                        ]}>
                                            <View style={[basicStyles.inputWrapper, {width: '100%'}]}>
                                                <AppTextInput
                                                    label="Business Name"
                                                    onChangeText={(value) => {
                                                        setBusinessNameInput(value);
                                                        updateOrganization('organization_title');
                                                    }}
                                                    value={businessNameInput}
                                                />
                                            </View>
                                            <View style={[basicStyles.inputWrapper, {width: '100%', zIndex: 10, elevation: 10}]}>
                                                <AddressInput
                                                    label="Business Address"
                                                    onChangeAddress={(address) => {
                                                        setAddressInput(address);
                                                        updateOrganization('organization_address');
                                                    }}
                                                    value={addressInput}
                                                    showDirections={false}
                                                />
                                            </View>
                                            <View style={[basicStyles.inputWrapper, {width: '100%', marginTop: 30}]}>
                                                {
                                                    googlePlaces.map((googlePlace) => {
                                                        return (
                                                            <View key={googlePlace.place_id} style={{marginBottom: 20}}>
                                                                <AppText style={styles.heading}>Google Place Details</AppText>
                                                                <AppText>Business Name: {googlePlace.name}</AppText>
                                                                <AppText style={{marginBottom: 10}}>Address: {googlePlace.formatted_address}</AppText>
                                                                <View style={[basicStyles.flexRow, basicStyles.alignContentCenter]}>
                                                                    <AppButton
                                                                        label="Click here to use this location."
                                                                        action={() => {
                                                                            googlePlaceFindDetails(googlePlace.place_id)
                                                                        }}
                                                                    />
                                                                </View>
                                                            </View>
                                                        );
                                                    })
                                                }
                                            </View>
                                            <View style={[basicStyles.flexRow, basicStyles.flexCenterContent, {width: '100%'}]}>
                                                <AppButton
                                                    label="Skip"
                                                    theme="transRed"
                                                    action={() => {
                                                        incrementCurrentStep();
                                                    }}
                                                />
                                            </View>
                                        </View>
                                        <div ref={testEl}/>
                                    </ContentSection>
                                    <View style={{marginTop: 30}}>
                                        <AppText style={styles.heading}>
                                            Want us to give you a white glove onboarding process where our team will walk you through this process step by step? <TouchableOpacity onPress={() => {
                                            window.open('https://fluid.services/schedule-call/', '_blank')
                                        }}>
                                            <AppText style={[styles.heading, {textDecoration: 'underline'}]}>
                                                Schedule a time with Fluid
                                            </AppText>
                                        </TouchableOpacity>
                                        </AppText>
                                    </View>
                                </View>
                                : null
                        }
                        {
                            currentStep === 1 ?
                                <View style={{paddingTop: 60, paddingBottom: 60}}>
                                    {
                                        selectedGooglePlace.google_place_id ?
                                            <AppText style={styles.heading}>
                                                According to Google, this is the business you want to add to Fluid Local. It's important to verify how Google has your business listed online. If
                                                something
                                                doesn't look right, feel free to correct below.
                                            </AppText>
                                            :
                                            <AppText style={styles.heading}>
                                                Enter Business Information
                                            </AppText>
                                    }
                                    <FieldSet
                                        style={{marginTop: 10}}
                                        chunkSize={1}
                                        fields={[
                                            {
                                                type: 'text',
                                                label: 'Organization Title',
                                                onChangeText: value => updateOrganization('organization_title', value),
                                                value: organization.organization_title || '',
                                            },
                                            {
                                                type: 'address',
                                                label: 'Address',
                                                onChangeAddress: value => updateOrganization('organization_address', value),
                                                value: organization.organization_address || '',
                                                showDirections: false,
                                            },
                                            {
                                                type: 'phone',
                                                label: 'Phone',
                                                onChangeText: value => updateOrganization('organization_phone', value),
                                                value: organization.organization_phone || '',
                                            },
                                            {
                                                type: 'text',
                                                label: 'Business Email',
                                                onChangeText: value => updateOrganization('organization_email', value),
                                                value: organization.organization_email || '',
                                            },
                                            {
                                                type: 'text',
                                                label: 'Website',
                                                onChangeText: value => updateOrganization('organization_website', value),
                                                value: organization.organization_website || '',
                                            },
                                            {
                                                type: 'picker',
                                                label: 'Time Zone',
                                                onValueChange: value => updateOrganization('time_zone_id', value),
                                                selectedValue: organization.time_zone_id || '',
                                                items: timeZones,
                                                labelExtractor: 'time_zone_title',
                                                valueExtractor: 'time_zone_id',
                                            },
                                        ]}
                                        footer={(
                                            <View style={[
                                                basicStyles.flexRow,
                                                basicStyles.justifyContentSpaceBetween,
                                                basicStyles.alignContentCenter,
                                                {
                                                    width: '100%',
                                                    paddingTop: 30
                                                }
                                            ]}>
                                                <AppButton
                                                    label="Back"
                                                    action={() => {
                                                        setOrganization({});
                                                        setSelectedGooglePlace(null);
                                                        setGooglePlaces([]);
                                                        setAddressInput('');
                                                        setBusinessNameInput('');
                                                        decrementCurrentStep();
                                                    }}
                                                />
                                                <AppButton
                                                    label="Next"
                                                    action={() => {
                                                        if (!organization.organization_title) {
                                                            cycleNotification('Organization Title Required.')
                                                        } else if (!organization.organization_address) {
                                                            cycleNotification('Organization Address Required.')
                                                        } else {
                                                            incrementCurrentStep();
                                                        }
                                                    }}
                                                />
                                            </View>
                                        )}
                                    />
                                </View>
                                : null
                        }
                        {
                            currentStep === 2 ?
                                <View style={{paddingTop: 60, paddingBottom: 60}}>
                                    <View
                                        style={basicStyles.flexRow}
                                    >
                                        <AppText style={[styles.heading, {marginRight: 'auto'}]}>Time to setup your own profile with Fluid Local</AppText>
                                        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                                            <GoogleLogin
                                                onSuccess={response => (getGoogleAccount(response.credential))}
                                                onError={e => console.log(e)}
                                            />
                                        </GoogleOAuthProvider>
                                    </View>

                                    <ContentSection style={[
                                        basicStyles.inputContainerPaddingVertical,
                                        {marginTop: 10}
                                    ]}>
                                        <View style={[
                                            basicStyles.flexRow,
                                            basicStyles.alignContentCenter,
                                            basicStyles.justifyContentFlexStart,
                                            basicStyles.flexWrap,
                                            basicStyles.inputContainerPaddingHorizontal
                                        ]}>
                                            <View style={[
                                                basicStyles.inputWrapper,
                                                {
                                                    width: '100%'
                                                }
                                            ]}>
                                                <AppTextInput
                                                    label="Your First Name"
                                                    onChangeText={(value) => {
                                                        updateUser('profile_first_name', value)
                                                    }}
                                                    value={user.profile_first_name || ''}
                                                />
                                            </View>
                                            <View style={[
                                                basicStyles.inputWrapper,
                                                {
                                                    width: '100%'
                                                }
                                            ]}>
                                                <AppTextInput
                                                    label="Your Last Name"
                                                    onChangeText={(value) => {
                                                        updateUser('profile_last_name', value)
                                                    }}
                                                    value={user.profile_last_name || ''}
                                                />
                                            </View>
                                            <View style={[
                                                basicStyles.inputWrapper,
                                                {
                                                    width: '100%'
                                                }
                                            ]}>
                                                <AppText style={styles.heading}>
                                                    When Fluid Local shares a review on your website, we give credit to the team member who earned it! But each user of Fluid
                                                    Local has a right to control how they are displayed online. Don't want to share your last name or even first name? No problem, use initials! It's up
                                                    to
                                                    you.
                                                </AppText>
                                                <AppTextInput
                                                    label="Public Display Name"
                                                    onChangeText={(value) => {
                                                        updateUser('profile_display_name', value)
                                                    }}
                                                    value={user.profile_display_name || ''}
                                                />
                                            </View>
                                            <View style={[
                                                basicStyles.inputWrapper,
                                                {
                                                    width: '100%'
                                                }
                                            ]}>
                                                <AppTextInput
                                                    label="Your Email"
                                                    onChangeText={(value) => {
                                                        updateUser('user_email', value)
                                                    }}
                                                    value={user.user_email || ''}
                                                />
                                            </View>
                                            {
                                                !user.user_google_auth_sub && (
                                                    <View style={[
                                                        basicStyles.inputWrapper,
                                                        {
                                                            width: '100%'
                                                        }
                                                    ]}>
                                                        <AppTextInput
                                                            secureTextEntry={true}
                                                            label="Password"
                                                            onChangeText={(value) => {
                                                                updateUser('user_password', value)
                                                            }}
                                                            value={user.user_password || ''}
                                                        />
                                                    </View>
                                                )
                                            }
                                        </View>
                                        <View style={[
                                            basicStyles.flexRow,
                                            basicStyles.inputContainerPaddingHorizontal,
                                            {
                                                width: '100%',
                                                borderTopWidth: 1,
                                                borderTopColor: '#E2E9F4',
                                                marginTop: 35,
                                            },
                                        ]}>
                                            <View style={[
                                                basicStyles.flexRow,
                                                basicStyles.justifyContentSpaceBetween,
                                                basicStyles.alignContentCenter,
                                                {
                                                    width: '100%',
                                                    paddingTop: 30
                                                }
                                            ]}>
                                                <AppButton
                                                    label="Back"
                                                    action={() => {
                                                        setUser({});
                                                        decrementCurrentStep();
                                                    }}
                                                />
                                                <AppButton
                                                    label="Next"
                                                    action={() => {
                                                        if (!user.profile_first_name) {
                                                            cycleNotification('Your First Name is Required.');
                                                        } else if (!user.user_email) {
                                                            cycleNotification('Your Email is Required.');
                                                        } else if (!user.user_password && !user.user_google_auth_sub) {
                                                            cycleNotification('Your Password is Required.');
                                                        } else {
                                                            incrementCurrentStep();
                                                        }
                                                    }}
                                                />
                                            </View>
                                        </View>
                                    </ContentSection>
                                </View>
                                : null
                        }
                        {
                            currentStep === 3 ?
                                <View style={{paddingTop: 60, paddingBottom: 60}}>
                                    <AppText style={styles.heading}>
                                        Select a Plan Below
                                    </AppText>
                                    <AppText style={styles.subHeading}>
                                        Plans can be upgraded any time in your settings
                                    </AppText>
                                    <AppText style={{marginTop: 22, height: 18, color: '#990000'}}>
                                        {/*{state.errorMessage ? state.errorMessage : ''}*/}
                                    </AppText>
                                    <SubscriptionSelectorsContainer>
                                        <SubscriptionSelector
                                            subscriptionType={{
                                                subscription_type_title: 'Fluid Local Free',
                                                subscription_type_cents: '0',
                                                subscription_type_max_users: 1,
                                                subscription_type_features: FREE_PLAN_FEATURES
                                            }}
                                            active={!organization.subscription_type_id}
                                            onSelect={() => updateOrganization('subscription_type_id', null)}
                                            onDeselect={() => updateOrganization('subscription_type_id', null)}
                                            onConfirm={() => {
                                                submitOnBoard();
                                            }}
                                        />
                                        {
                                            subscriptionTypes.map((subscriptionType) => {
                                                return (
                                                    <SubscriptionSelector
                                                        key={subscriptionType.subscription_type_id}
                                                        active={organization.subscription_type_id === subscriptionType.subscription_type_id}
                                                        subscriptionType={subscriptionType}
                                                        onSelect={() => updateOrganization('subscription_type_id', subscriptionType.subscription_type_id)}
                                                        onDeselect={() => updateOrganization('subscription_type_id', null)}
                                                        onConfirm={() => {
                                                            incrementCurrentStep();
                                                        }}
                                                    />
                                                );
                                            })
                                        }
                                    </SubscriptionSelectorsContainer>
                                    <ContentSection style={{marginRight: 10, marginLeft: 10}}>
                                        <View style={[
                                            basicStyles.flexRow,
                                            basicStyles.inputContainerPaddingHorizontal,
                                            basicStyles.justifyContentCenter,
                                            basicStyles.alignContentCenter,
                                            {
                                                paddingTop: 15,
                                                paddingBottom: 15
                                            }
                                        ]}>
                                            <AppButton
                                                label="Back"
                                                action={() => {
                                                    decrementCurrentStep();
                                                }}
                                            />
                                        </View>
                                    </ContentSection>
                                </View>
                                : null
                        }
                        <View style={[
                            {paddingTop: 60, paddingBottom: 60},
                            ...(currentStep === 4 ? [{}] : [styles.hidden])
                        ]}>
                            <AppText style={styles.heading}>
                                Subscription Payment
                            </AppText>
                            <AppText style={styles.subHeading}>
                                Plans can be upgraded any time in your settings
                            </AppText>
                            <AppText style={{marginTop: 22, height: 18, color: '#990000'}}>
                                {/*{state.errorMessage ? state.errorMessage : ''}*/}
                            </AppText>
                            <StripePaymentSubscription
                                subscriptionTypes={subscriptionTypes}
                                selectedSubscriptionTypeId={organization.subscription_type_id}
                                onUpdateSubscriptionType={value => updateOrganization('subscription_type_id', value)}
                                containerStyle={{width: '100%', maxWidth: 1200}}
                                onSubmit={(cardToken) => {
                                    setCardToken(cardToken)
                                    incrementCurrentStep();
                                }}
                                coupon={{}}
                                freeTrialDays={0}
                                submitLabel="Confirm"
                                onBack={() => {
                                    decrementCurrentStep();
                                }}
                            />
                        </View>
                        {
                            currentStep === 5 ?
                                <View style={{paddingTop: 60, paddingBottom: 60}}>
                                    <AppText style={styles.heading}>Fluid Local is designed to measure how your team is performing for your customers. Whether it's a technician in the field or a sale
                                        on your website. Fluid Local ensures you can measure each team members performance while also creating authentic localilized content to share with your audience
                                        and community.
                                        To do this though, your team needs to be onboarded with Fluid Local. You can always add/remove team members at anytime but we can send out a few invites right
                                        now to get you started.
                                        Add each email address below.
                                    </AppText>
                                    <ContentSection style={[
                                        basicStyles.inputContainerPaddingVertical,
                                        {marginTop: 10}
                                    ]}>
                                        <View style={[
                                            basicStyles.flexRow,
                                            basicStyles.alignContentCenter,
                                            basicStyles.justifyContentFlexStart,
                                            basicStyles.flexWrap,
                                            basicStyles.inputContainerPaddingHorizontal
                                        ]}>
                                            {
                                                teamMembers.map((teamMember, index) => {
                                                    return (

                                                        <View style={[
                                                            basicStyles.inputWrapper,
                                                            {
                                                                width: '100%',
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                alignItems: 'center'
                                                            }
                                                        ]}>
                                                            <AppTextInput
                                                                wrapperStyle={{flex: 1}}
                                                                label="Email"
                                                                onChangeText={(value) => {
                                                                    updateTeamMember(index, 'user_email', value)
                                                                }}
                                                                value={teamMember.user_email || ''}
                                                            />
                                                            <TouchView action={() => removeTeamMember(index)}>
                                                                <RemoveSvg/>
                                                            </TouchView>
                                                        </View>
                                                    );
                                                })
                                            }
                                            <AppButton
                                                label="Add Team Member"
                                                action={() => {
                                                    addTeamMember();
                                                }}
                                            />
                                        </View>
                                        <View style={[
                                            basicStyles.flexRow,
                                            basicStyles.inputContainerPaddingHorizontal,
                                            {
                                                width: '100%',
                                                borderTopWidth: 1,
                                                borderTopColor: '#E2E9F4',
                                                marginTop: 35,
                                            },
                                        ]}>
                                            <View style={[
                                                basicStyles.flexRow,
                                                basicStyles.justifyContentSpaceBetween,
                                                basicStyles.alignContentCenter,
                                                {
                                                    width: '100%',
                                                    paddingTop: 30
                                                }
                                            ]}>
                                                <AppButton
                                                    label="Back"
                                                    action={() => {
                                                        setUser({});
                                                        decrementCurrentStep();
                                                    }}
                                                />
                                                <AppButton
                                                    label="Next"
                                                    action={() => {
                                                        submitOnBoard();
                                                    }}
                                                />
                                            </View>
                                        </View>
                                    </ContentSection>
                                </View>
                                : null
                        }
                    </View>
                </View>
            </ScrollView>

        </View>
    );
}


const mapStateToProps = (state) => {
    const {auth} = state;
    return {auth};
};


const mapDispatchToProps = dispatch => (
    bindActionCreators({
        setToken,
        setUser,
        setOrganization,
        setOrganizationRoleId,
        setWarning,
        setProcessingIndicatorActive,
        setPermissions,
        setInitialized,
        cycleNotification,
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(OnBoarding);

const styles = EStyleSheet.create({
    hidden: {
        opacity: 0,
        position: 'absolute',
        pointerEvents: 'none'
    },
    heading: {
        color: '#0b2774',
        fontSize: 18,
        fontFamily: 'SourceSansPro-SemiBold',
    },
    subHeading: {
        color: '#0b2774',
        fontSize: 18,
        fontFamily: 'SourceSansPro-Light',
    },
    subscriptionTypesContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        maxWidth: 1200,
        width: '100%',
        flexWrap: 'wrap'
    },
    subscriptionTypeWrapper: {
        flex: '0 0 33.333%',
        width: '33.333%',
        paddingRight: 10,
        paddingLeft: 10
    },
    subscriptionTypeContainer: {
        width: '100%',
        marginBottom: 20
    },
    subscriptionTypeText: {
        color: '#000000',
    },
    selectedSubscriptionTypeText: {
        color: '#FFFFFF',
    },
    subscriptionTypeTitleContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10,
    },
    subscriptionTypeTitle: {
        fontFamily: 'SourceSansPro-Light',
        fontSize: 28,
    },
    subscriptionTypePricePerMonth: {
        fontFamily: 'SourceSansPro-Bold',
        fontSize: 89,
        marginBottom: 10,
    },
    subscriptionTypeContentSectionWrapper: {
        paddingLeft: 24,
        paddingRight: 24,
        paddingTop: 40,
        alignItems: 'flex-start',
    },
    priceHeader: {
        flexDirection: 'column'
    },
    subscriptionTypePerMonth: {
        fontFamily: 'SourceSansPro-SemiBold',
        opacity: .4,
    },
    subscriptionTypeFeaturesHeaderContainer: {
        borderTopWidth: 1,
        borderBottomWidth: 1,
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 20,
        paddingRight: 20,
        width: '100%',
        marginTop: 50,
        marginBottom: 25,
    },
    subscriptionTypeFeaturesHeaderText: {
        fontFamily: 'SourceSansPro-Bold',
        fontSize: 20,
    },
    subscriptionTypeFeatureDot: {
        fontSize: 28,
        position: 'relative',
        bottom: 2,
        marginRight: 10,
    },
    subscriptionTypeButtonsSectionWrapper: {
        borderTopWidth: 1,
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 25,
        paddingRight: 25,
        flexDirection: 'row',
    },
    contentContainer: {
        backgroundColor: '#f8fafd',
        flex: 1,
        padding: 60,
    },
    accountInvitationHeader: {
        flexWrap: 'wrap',
        paddingRight: 15,
        paddingLeft: 15
    },
    '@media (max-width: 1050)': {
        subscriptionTypesContainer: {
            flexDirection: 'column'
        },
        priceHeader: {
            flexDirection: 'row',
            width: '100%'
        },
        subscriptionTypeTitleContainer: {
            width: '50%',
            justifyContent: 'center'
        },
        subscriptionTypeValueContainer: {
            width: '50%',
            textAlign: 'center',
            borderLeftWidth: 1,
        },
        subscriptionTypeContainer: {
            marginRight: 0,
            marginBottom: 30,
        },
        subscriptionTypeButtonsSectionWrapper: {
            marginTop: 20,
        }
    },
    '@media (max-width: 768)': {
        contentContainer: {
            paddingRight: 30,
            paddingLeft: 30
        },
        subHeading: {
            textAlign: 'center'
        }
    },
    '@media (max-width: 650)': {
        subscriptionTypePricePerMonth: {
            fontSize: 70
        },
    },
    '@media (max-width: 500)': {
        priceHeader: {
            flexDirection: 'column'
        },
        subscriptionTypeValueContainer: {
            borderLeftWidth: 0,
            textAlign: 'left',
            width: '100%',
        },
        subscriptionTypeTitleContainer: {
            justifyContent: 'flex-start',
            width: '100%',
        },
    },
    '@media (max-width: 400)': {
        footerButton: {
            width: '100%'
        }
    }
});
