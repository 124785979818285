import * as React from "react"
import Svg, { Path } from "react-native-svg"

function ChevronHorizontalBlackSvg({direction = 'left', fill = '#000000', ...props}) {

	const d = direction === 'left' ? 'M0 5.223L5.279 0l1.369 1.354-3.911 3.869 3.911 3.87-1.369 1.354z' : 'M6.648 5.224l-5.279 5.223L0 9.093l3.911-3.869L0 1.354 1.369 0z';

	return (
		<Svg width={6.648} height={10.447} viewBox="0 0 6.648 10.447" {...props}>
			<Path
				data-name="Path 26"
				d={d}
				fill={fill}
			/>
		</Svg>
	)
}

export default ChevronHorizontalBlackSvg;
