import * as React from "react"
import Svg, { Rect, Path } from "react-native-svg"

function MessageFeedUnresolvedSvg({color = "#F04438", ...props}) {
    return (
        <Svg
            width={12}
            height={13}
            viewBox="0 0 12 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Rect x={0.5} y={1} width={11} height={11} rx={5.5} stroke={color} />
            <Path
                d="M7.564 6.114L5.553 4.045l.53-.545L9 6.5l-2.917 3-.53-.545 2.011-2.07H3v-.77h4.564z"
                fill={color}
            />
        </Svg>
    )
}

export default MessageFeedUnresolvedSvg
