import * as React from "react"
import Svg, {
	Defs,
	LinearGradient,
	Stop,
	Circle,
	G,
	Path,
} from "react-native-svg"

function BriefcaseBlueSvg(props) {
	return (
		<Svg
			data-name="Group 210"
			width={41.819}
			height={41.819}
			viewBox="0 0 41.819 41.819"
			{...props}
		>
			<Defs>
				<LinearGradient
					id="prefix__a"
					x1={-0.195}
					y1={0.5}
					x2={1.173}
					y2={0.5}
					gradientUnits="objectBoundingBox"
				>
					<Stop offset={0} stopColor="#1b8ce9" />
					<Stop offset={1} stopColor="#467aff" />
				</LinearGradient>
			</Defs>
			<Circle
				data-name="Ellipse 32"
				cx={15.407}
				cy={15.407}
				transform="translate(5.503 5.502)"
				fill="url(#prefix__a)"
				r={15.407}
			/>
			<Circle
				data-name="Ellipse 33"
				cx={20.909}
				cy={20.909}
				opacity={0.068}
				fill="url(#prefix__a)"
				r={20.909}
			/>
			<G data-name="Group 954">
				<G data-name="Group 940" fill="#fff">
					<Path
						data-name="Path 495"
						d="M25.921 23.504h-1.639v-.82h-6.556v.82h-1.64v-.82h-3.278v4.1H29.2v-4.1h-3.279z"
					/>
					<Path
						data-name="Path 496"
						d="M27.561 16.946h-2.459v-1.639a1.644 1.644 0 00-1.639-1.639h-4.918a1.644 1.644 0 00-1.637 1.639v1.639h-2.461a1.644 1.644 0 00-1.639 1.64v3.282h3.278v-1.643h1.64v1.643h6.557v-1.643h1.639v1.643H29.2v-3.282a1.644 1.644 0 00-1.639-1.64zm-4.1 0h-4.916v-1.639h4.918z"
					/>
				</G>
			</G>
		</Svg>
	)
}

export default BriefcaseBlueSvg
