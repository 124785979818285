import React, {useState, useEffect, useRef} from 'react';
import {View, ScrollView} from 'react-native';
import {connect} from 'react-redux';
import {useDebouncedCallback} from 'use-debounce';

import {basicStyles} from '../../styles/basic';
import PageTop from '../../components/PageTop';
import Avatar from '../../components/Avatar';
import {AVATAR_DEFAULT, AVATAR_PATH_ORG, AVATAR_PATH_SURVEY_WEBSITE, FLUID_ROLE_IDS} from '../../config';
import AppText from '../../components/AppText';
import {textStyles} from '../../styles/text';
import OrganizationsService from '../../services/OrganizationsService';
import SurveyWebsitesService from '../../services/SurveyWebsitesService';
import PageContentContainer from '../../components/PageContentContainer';
import FieldSet from '../../components/FieldSet';
import AppButton from '../../components/AppButton';
import EStyleSheet from 'react-native-extended-stylesheet';
import ContentSection from "../../components/ContentSection";
import SurveysService from "../../services/SurveysService";
import {formatDateAmPm, formatUtcTimeToLocalTime} from '../../utilities/methods';

function SurveySettings(props) {
    const [organization, setOrganization] = useState({surveyWebsites: []});
    const [surveyWebsites, setSurveyWebsites] = useState([]);
    const [isMobile, setIsMobile] = useState(false);
    const [isDesktop, setIsDesktop] = useState(false);
    const [survey, setSurvey] = useState({surveyQuestions: []});
    const organizationId = props.route?.params?.organizationId;
    const firstUpdate = useRef(true);
    const [scheduleHour, setScheduleHour] = useState('--');
    const [scheduleMinute, setScheduleMinute] = useState('--');
    const [amPm, setAmPm] = useState('--');
    const [scheduleTime, setScheduleTime] = useState();

    const updateOrganization = (prop, value) => {
        setOrganization({...organization, [prop]: value});
    };

    const [updateOrganizationDebounce] = useDebouncedCallback(() => {
        if (organizationId) {
            OrganizationsService.updateOrganization(
                organizationId,
                {
                    ...organization,
                    surveyWebsites: organization.surveyWebsites.filter(
                        surveyWebsite => surveyWebsite.survey_website_id && surveyWebsite.organization_survey_website_url
                    )
                },
            );
        }
    }, 500);

    const [updateSurveyDebounce] = useDebouncedCallback(() => {
        if (survey.survey_id) {
            SurveysService.updateSurvey(survey.survey_id, survey)
        }
    }, 500);

    const hours = getHours();
    const minutes = getMinutes();

    function getHours() {
        let hours = [{key:'--', value:'--'}];

        for(let i = 1; i <= 12; i++) {
            let hour = i + '';
            hours.push({key: i, value: hour});
        }

        return hours;
    }

    function getMinutes() {
        let minutes = [{key:'--', value:'--'}];

        for(let i = 0; i <= 59; i++) {
            let min = i + '';
            if(i < 10) {
                min = '0' + i;
            }

            minutes.push({key: min, value: min});
        }

        return minutes;
    }

    const parseScheduleTime = (orgData) => {
      if(orgData.scheduled_survey_time) {
          let localTime = formatUtcTimeToLocalTime(orgData.scheduled_survey_time);
          setScheduleHour(localTime.hour == '0' ? '12' : localTime.hour);
          setScheduleMinute(localTime.minute + '');
          setAmPm(localTime.amPm);
      }
    };

    const addSurveyWebsite = () => {
        setOrganization({
            ...organization,
            surveyWebsites: [
                ...organization.surveyWebsites,
                {
                    organization_id: props.auth.organization.organization_id
                }
            ]
        });
    };

    const updateSurveyWebsite = (prop, value, index) => {
        let surveyWebsites = [...organization.surveyWebsites];
        surveyWebsites[index][prop] = value;

        setOrganization({
            ...organization,
            surveyWebsites,
        });
    };

    const updateSurveyQuestion = (prop, value, surveyQuestionId) => {
        let surveyQuestions = [...survey.surveyQuestions];
        let surveyQuestionIndex = surveyQuestions.findIndex((surveyQuestion) => {
            return surveyQuestion.survey_question_id === surveyQuestionId;
        });

        surveyQuestions[surveyQuestionIndex][prop] = value;

        setSurvey({
            ...survey,
            surveyQuestions
        });
    }

    const addSurveyQuestionRedirect = () => {
        const redirectableSurveyQuestionIndex = getRedirectableSurveyQuestionIndex();

        setSurvey(prevSurvey => {
            let newSurvey = Object.assign({}, prevSurvey);

            newSurvey.surveyQuestions[redirectableSurveyQuestionIndex].surveyQuestionRedirects.push({});

            return newSurvey;
        });
    };

    const updateSurveyQuestionRedirect = (prop, value, surveyQuestionRedirectIndex) => {
        const redirectableSurveyQuestionIndex = getRedirectableSurveyQuestionIndex();
        setSurvey(prevSurvey => {
            let newSurvey = Object.assign({}, prevSurvey);
            newSurvey.surveyQuestions[redirectableSurveyQuestionIndex].surveyQuestionRedirects.filter(surveyQuestionRedirect => !surveyQuestionRedirect.store_front_id)[surveyQuestionRedirectIndex][prop] = value;

            return newSurvey;
        });
    }

    const handleLayout = ({nativeEvent}) => {
        const {width} = nativeEvent.layout;


        if (width > 1024) {
            setIsDesktop(true);
        } else {
            setIsDesktop(false);
        }
        if (width > 688) {
            setIsMobile(false);
        } else {
            setIsMobile(true);
        }
    }

    const getRedirectableSurveyQuestion = () => {
        return survey.surveyQuestions[getRedirectableSurveyQuestionIndex()];
    };


    const getRedirectableSurveyQuestionIndex = () => {
        return survey.surveyQuestions.findIndex((surveyQuestion) => {
            return surveyQuestion.survey_question_redirect_enabled == 1;
        });
    };

    const handleManualReviewRequest = async () => {
        let orgData = await OrganizationsService.manualReviewRequest(organizationId);
        if (orgData) {
            setOrganization(orgData);
        }
    }

    const getManualRequestStatus = (organization) => {
        if (organization.manualReviewRequest) {
            return organization.manualReviewRequest.third_party_review_queue_status;
        }

        return organization.lastBrightLocalRequest
            ? formatDateAmPm(organization.lastBrightLocalRequest)
            : 'Unavailable';
    }

    useEffect(() => {

        const getData = async () => {
            if (organizationId) {
                let orgData = await OrganizationsService.getOrganization(organizationId);
                setOrganization(orgData);
                parseScheduleTime(orgData);
                let surveyData = await OrganizationsService.getOrganizationSurvey(organizationId);
                setSurvey(surveyData);
            }
            let surveyWebsitesData = await SurveyWebsitesService.getSurveyWebsites();
            setSurveyWebsites(surveyWebsitesData);
            firstUpdate.current = false;
        };

        getData();
    }, [organizationId]);

    useEffect(() => {
        if (firstUpdate.current) {
            return;
        }

        updateOrganizationDebounce();
    }, [organization, updateOrganizationDebounce]);

    useEffect(() => {
        if (firstUpdate.current) {
            return;
        }

        updateSurveyDebounce();
    }, [survey, updateSurveyDebounce]);

    useEffect(() => {
        if(scheduleHour !== '--' && scheduleMinute !== '--' && amPm !== '--') {
            let time = new Date();
            let hour = parseInt(scheduleHour);

            if(amPm == 'pm' && hour < 12) {
                hour += 12;
            } else if (amPm == 'am' && hour == 12) {
                hour = 0;
            }

            time.setHours(hour, scheduleMinute);

            let utcHour = time.getUTCHours();
            if(utcHour < 10) {
                utcHour = ('0' + utcHour)
            }

            let utcMinute = time.getUTCMinutes();
            if(utcMinute < 10) {
                utcMinute = ('0' + utcMinute);
            }

            let utcTime = utcHour + ':' + utcMinute + ':00';

            setScheduleTime(utcTime);
        } else if(scheduleHour === '--' && scheduleMinute === '--' && amPm === '--') {
            setScheduleTime(null);
        }
    }, [scheduleHour, scheduleMinute, amPm]);

    useEffect(() => {
        updateOrganization('scheduled_survey_time', scheduleTime);
    }, [scheduleTime]);

    return (
        <ScrollView contentContainerStyle={basicStyles.flexScale} onLayout={handleLayout}>
            <PageTop style={[
                basicStyles.flexRow,
                basicStyles.alignContentCenter,
                basicStyles.justifyContentSpaceBetween
            ]}>
                <View style={[
                    basicStyles.flexRow,
                    basicStyles.alignContentCenter,
                    basicStyles.justifyContentSpaceBetween
                ]}>
                    <Avatar
                        source={{uri: organization.organization_thumbnail ? AVATAR_PATH_ORG + organization.organization_thumbnail : AVATAR_DEFAULT}}
                        width={66}
                        style={{marginRight: 20}}
                    />
                    <AppText style={[textStyles.pageTitle, textStyles.semiBold]}>
                        Survey Settings
                    </AppText>
                </View>
            </PageTop>
            <PageContentContainer>
                <AppText style={[textStyles.pageSubTitle, textStyles.semiBold]}>
                    Customer Survey Request Type
                </AppText>
                <FieldSet
                    style={{marginBottom: 50}}
                    chunkSize={1}
                    fields={[
                        // {
                        //     type: 'text',
                        //     label: 'Enter Message',
                        //     onChangeText: value => updateOrganization('organization_customer_survey_prompt', value),
                        //     value: organization.organization_customer_survey_prompt || '',
                        //     width: '100%'
                        // },
                        {
                            type: 'picker',
                            label: 'Send Via',
                            onValueChange: value => updateOrganization('organization_customer_survey_communication_type', value),
                            selectedValue: organization.organization_customer_survey_communication_type || '',
                            items: [{value: 'Text Message'}, {value: 'Email'}],
                            labelExtractor: 'value',
                            valueExtractor: 'value',
                            width: (isMobile ? '100%' : '50%')
                        }
                    ]}
                />
                <AppText style={[textStyles.pageSubTitle, textStyles.semiBold]}>
                    Set a Time of Day for Sending Surveys
                </AppText>
                <AppText style={{marginBottom: 10}}>
                    What time of day do you want surveys sent out? If left empty, surveys will be sent out when a job is completed in Fluid Local
                </AppText>
                <FieldSet
                    style={{marginBottom: 50}}
                    chunkSize={(isMobile ? 1 : 3)}
                    fields={[
                        {
                            type: 'picker',
                            label: 'Hour',
                            onValueChange: value => {setScheduleHour(value)},
                            selectedValue: scheduleHour || '--',
                            items: hours,
                            labelExtractor: 'key',
                            valueExtractor: 'value',
                            width: (isMobile ? '100%' : '33.333%')
                        },
                        {
                            type: 'picker',
                            label: 'Minute',
                            onValueChange: value => setScheduleMinute(value),
                            selectedValue: scheduleMinute || '--',
                            items: minutes,
                            labelExtractor: 'key',
                            valueExtractor: 'value',
                            width: (isMobile ? '100%' : '33.333%')
                        },
                        {
                            type: 'picker',
                            label: 'AM/PM',
                            onValueChange: value => setAmPm(value),
                            selectedValue: amPm || '--',
                            items: [
                                {
                                    key:'--',
                                    value:'--'
                                },
                                {
                                    key: 'AM',
                                    value: 'am'
                                },
                                {
                                    key: 'PM',
                                    value: 'pm'
                                }
                            ],
                            labelExtractor: 'key',
                            valueExtractor: 'value',
                            width: (isMobile ? '100%' : '33.333%')
                        }
                    ]}
                />
                {FLUID_ROLE_IDS.includes(props.auth.organizationRoleId) && (
                    <>
                        <AppText style={[textStyles.pageSubTitle, textStyles.semiBold]}>
                            Manual Request for Third Party Reviews from BrightLocal
                        </AppText>
                        <FieldSet
                            style={{marginBottom: 50}}
                            chunkSize={(isDesktop ? 2 : 1)}
                            fields={[
                                {
                                    type: 'button',
                                    label: 'Last Request: ' + getManualRequestStatus(organization),
                                    actionLabel: 'Request Third Party Reviews',
                                    action: handleManualReviewRequest,
                                    disabled: ['In Queue', 'Processing'].includes(getManualRequestStatus(organization))
                                },
                            ]}
                        />
                    </>
                )}
                <View style={[basicStyles.flexRow, basicStyles.alignContentCenter, basicStyles.justifyContentSpaceBetween, styles.surveyWebsitesHeader]}>
                    <AppText style={[textStyles.pageSubTitle, textStyles.semiBold, {marginBottom: 0}]}>
                        Survey Websites
                    </AppText>
                    <AppButton
                        label="Add Review Website"
                        action={() => addSurveyWebsite()}
                        style={styles.surveyWebsitesButton}
                    />
                </View>
                {
                    organization.surveyWebsites.map((surveyWebsite, index) => {
                        let surveyWebsiteObj = surveyWebsites.find((theSurveyWebsite) => {
                            return theSurveyWebsite.survey_website_id === surveyWebsite.survey_website_id
                        });
                        return (
                            <FieldSet
                                key={index}
                                chunkSize={(isMobile ? 1 : 2)}
                                style={{marginBottom: 50}}
                                fields={[
                                    {
                                        type: 'picker',
                                        label: 'Survey Website',
                                        onValueChange: value => updateSurveyWebsite('survey_website_id', value, index),
                                        selectedValue: surveyWebsite.survey_website_id || '',
                                        items: surveyWebsites,
                                        labelExtractor: 'survey_website_title',
                                        valueExtractor: 'survey_website_id',
                                        iconExtractor: 'survey_website_icon',
                                        iconRoot: AVATAR_PATH_SURVEY_WEBSITE,
                                        showLabel: false
                                    },
                                    {
                                        type: 'text',
                                        label: 'URL',
                                        onChangeText: value => updateSurveyWebsite('organization_survey_website_url', value, index),
                                        value: surveyWebsite.organization_survey_website_url || ''
                                    },
                                    ...(
                                        surveyWebsiteObj?.survey_website_redirect_capable == 1 ?
                                            [
                                                {
                                                    type: 'picker',
                                                    label: 'Auto-Redirect?',
                                                    onValueChange: value => updateSurveyWebsite('organization_survey_website_redirect', value, index),
                                                    selectedValue: surveyWebsite.organization_survey_website_redirect || '0',
                                                    items: [
                                                        {
                                                            label: 'Yes',
                                                            value: '1'
                                                        },
                                                        {
                                                            label: 'No',
                                                            value: '0'
                                                        },
                                                    ]
                                                }
                                            ]
                                            :
                                            []
                                    )
                                ]}
                            />
                        );
                    })
                }
                <AppText style={[textStyles.pageSubTitle, textStyles.semiBold]}>
                    Post Survey Copy
                </AppText>
                <FieldSet
                    style={{marginBottom: 50}}
                    chunkSize={1}
                    fields={[
                        {
                            type: 'text',
                            label: 'Post Review Copy Text',
                            onChangeText: (value) => updateOrganization('organization_post_review_copy_text', value),
                            value: organization.organization_post_review_copy_text || ''
                        }
                    ]}
                />
                <AppText style={[textStyles.pageSubTitle, textStyles.semiBold]}>
                    Configure Survey Questions
                </AppText>
                {
                    survey.surveyQuestions.filter(surveyQuestion => surveyQuestion.survey_question_editable == 1)
                        .map((surveyQuestion, index) => {
                            return (
                                <FieldSet
                                    style={{marginBottom: 10}}
                                    key={index}
                                    chunkSize={2}
                                    fields={[
                                        {
                                            type: 'text',
                                            label: 'Survey Question',
                                            value: surveyQuestion.survey_question_text,
                                            disabled: true
                                        },
                                        {
                                            type: 'picker',
                                            label: 'Status',
                                            onValueChange: value => updateSurveyQuestion('survey_question_status', value, surveyQuestion.survey_question_id),
                                            selectedValue: surveyQuestion.survey_question_status,
                                            items: [
                                                {
                                                    label: 'Active',
                                                    value: 'active'
                                                },
                                                {
                                                    label: 'Inactive',
                                                    value: 'inactive'
                                                }
                                            ],
                                            disabled: surveyQuestion.survey_question_editable != 1
                                        }
                                    ]}
                                />
                            );
                        })
                }
                {
                    getRedirectableSurveyQuestion() ?
                        <>
                            <View style={[basicStyles.flexRow, basicStyles.alignContentCenter, basicStyles.justifyContentSpaceBetween, styles.surveyWebsitesHeader, {marginTop: 50}]}>
                                <AppText style={[textStyles.pageSubTitle, textStyles.semiBold, {marginBottom: 0}]}>
                                    Overall Rating Redirect
                                </AppText>
                                <AppButton
                                    label="Add Rating Redirect"
                                    action={() => addSurveyQuestionRedirect()}
                                    style={styles.surveyWebsitesButton}
                                />
                            </View>
                            {
                                getRedirectableSurveyQuestion().surveyQuestionRedirects.filter(surveyQuestionRedirect => !surveyQuestionRedirect.store_front_id).map((surveyQuestionRedirect, index) => {
                                    return (
                                        <FieldSet
                                            style={{marginBottom: 10}}
                                            key={index}
                                            chunkSize={2}
                                            fields={[
                                                {
                                                    type: 'picker',
                                                    label: 'Overall Rating Value',
                                                    onValueChange: value => updateSurveyQuestionRedirect('survey_question_redirect_value', value, index),
                                                    selectedValue: surveyQuestionRedirect.survey_question_redirect_value || '',
                                                    items: [
                                                        {
                                                            label: 'One',
                                                            value: '1',
                                                        },
                                                        {
                                                            label: 'Two',
                                                            value: '2',
                                                        },
                                                        {
                                                            label: 'Three',
                                                            value: '3',
                                                        },
                                                        {
                                                            label: 'Four',
                                                            value: '4',
                                                        },
                                                        {
                                                            label: 'Five',
                                                            value: '5',
                                                        },
                                                    ].filter((item) => {
                                                        //filter values already selected
                                                        for (let surveyQuestionRedirectObj of getRedirectableSurveyQuestion().surveyQuestionRedirects.filter(surveyQuestionRedirect => !surveyQuestionRedirect.store_front_id)) {
                                                            if (surveyQuestionRedirectObj.survey_question_redirect_value == surveyQuestionRedirect.survey_question_redirect_value) {
                                                                return true;
                                                            } else if (surveyQuestionRedirectObj.survey_question_redirect_value == item.value) {
                                                                return false;
                                                            }
                                                        }
                                                        return true;
                                                    })
                                                },
                                                {
                                                    type: 'text',
                                                    label: 'Redirect URL',
                                                    onChangeText: (value) => updateSurveyQuestionRedirect('survey_question_redirect_url', value, index),
                                                    value: surveyQuestionRedirect.survey_question_redirect_url || ''
                                                }
                                            ]}

                                        />
                                    );
                                })
                            }
                        </>
                        : null
                }
            </PageContentContainer>
        </ScrollView>
    );
}

const mapStateToProps = (state) => {
    const {auth} = state;
    return {auth};
};

export default connect(mapStateToProps)(SurveySettings);

const styles = EStyleSheet.create({
    surveyWebsitesHeader: {
        flexDirection: 'row',
        marginBottom: 25
    },
    '@media (max-width: 375)': {
        surveyWebsitesHeader: {
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start'
        },
        surveyWebsitesButton: {
            width: '100%',
            marginTop: 15
        }
    }
});
