import React from 'react';
import {View, StyleSheet} from 'react-native';
import ContentSection from './ContentSection';
import {basicStyles} from '../styles/basic';
import AppText from './AppText';
import AppButton from './AppButton';

function CreateOptionsSection({
	icon,
	title,
	subTitle,
    subTitleChildren = null,
	label1,
	label2,
	button1Children,
	button2Children,
	action1 = () => {},
	action2 = () => {},
	style = {}
}) {
	return (
		<ContentSection style={[
			style,
			basicStyles.alignContentCenter,
			{paddingTop: 60, paddingBottom: 60}
		]}>
			<View style={styles.iconContainer}>
				{icon}
			</View>
			<AppText style={styles.title}>
				{title}
			</AppText>
			<AppText style={styles.subTitle}>
				{subTitle}
			</AppText>
			{
				subTitleChildren ?
					subTitleChildren
					:
					null
			}
			<View style={[
				basicStyles.flexRow,
				basicStyles.flexCenterContent,
			]}>
				{
					label1 ?
						<AppButton
							label={label1}
							action={action1}>
							{button1Children}
						</AppButton>
						: null
				}
				{
					label2 ?
						<AppButton
							label={label2}
							action={action2}
							theme="transBlue"
							style={{marginLeft: 10}}
						>
							{button2Children}
						</AppButton>
						: null
				}
			</View>

		</ContentSection>
	);
}

export default CreateOptionsSection;

const styles = StyleSheet.create({
	iconContainer: {
		width: 205,
		height: 133,
		backgroundColor: '#F8FAFD',
		justifyContent: 'center',
		alignItems: 'center',
		marginBottom: 30,
	},
	title: {
		fontFamily: 'SourceSansPro-SemiBold',
		fontSize: 20,
		marginBottom: 10,
	},
	subTitle: {
		fontSize: 14,
		color: '#9BA8CA',
		marginBottom: 35,
	},
});
