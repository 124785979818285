import React from 'react';
import {useLinkProps} from '@react-navigation/native';
import {View, TouchableOpacity, Platform} from 'react-native';

const LinkButton = ({to, action, children, style, ...rest}) => {
	const {onPress, ...props} = useLinkProps({to, action});

	const [isHovered, setIsHovered] = React.useState(false);

	if (Platform.OS === 'web') {
		// It's important to use a `View` or `Text` on web instead of `TouchableX`
		// Otherwise React Native for Web omits the `onClick` prop that's passed
		// You'll also need to pass `onPress` as `onClick` to the `View`
		// You can add hover effects using `onMouseEnter` and `onMouseLeave`
		return (
			<View
				style={[style]}
				onClick={onPress}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
				{...props}
				{...rest}
			>
				{children}
			</View>
		);
	}

	return (
		<TouchableOpacity
			style={style}
			onPress={onPress} {...props} {...rest}
		>
			{children}
		</TouchableOpacity>
	);
};

export default LinkButton;
