import React from 'react';
import {View} from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

function SubscriptionSelectorsContainer({children, style = {}}) {
    return (
        <View style={[styles.subscriptionTypesContainer, style]}>
            {children}
        </View>
    );
}

export default SubscriptionSelectorsContainer;

const styles = EStyleSheet.create({
    subscriptionTypesContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        flexWrap: 'wrap'
    },
    '@media (max-width: 1050)': {
        subscriptionTypesContainer: {
            flexDirection: 'column'
        },
    }
});
