import * as React from "react"
import Svg, { G, Circle, Path } from "react-native-svg"

function BlueCircleChevronRightSvg({size = 35, ...props}) {
	return (
		<Svg width={size} height={size} viewBox="0 0 35 35" {...props}>
			<G data-name="Group 960" transform="translate(-1263.103 -427)">
				<Circle
					data-name="Ellipse 83"
					cx={17.5}
					cy={17.5}
					r={17.5}
					transform="rotate(-90 862.552 -400.552)"
					fill="#3ca6f5"
				/>
				<Path
					data-name="Path 26"
					d="M1284.146 444.62l-4.226 4.185-1.1-1.085 3.133-3.1-3.133-3.1 1.1-1.085z"
					fill="#fff"
				/>
			</G>
		</Svg>
	)
}

export default BlueCircleChevronRightSvg
