import React from 'react';
import {View} from 'react-native';
import {createStackNavigator} from '@react-navigation/stack';

import UserProfile from './UserProfile';
import UserProfileChangePassword from './UserProfileChangePassword';
import {basicStyles} from '../../styles/basic';

const Stack = createStackNavigator();

function UserProfileIndexEditNavigation(props) {
	return (
		<View style={basicStyles.flexScale}>
			<Stack.Navigator
				backBehavior="history"
				screenOptions={{
					header: () => null,
					cardStyle: {
						backgroundColor: '#F8FAFD'
					}
				}}
				initialRouteName="UserProfile"
			>
				<Stack.Screen
					name={"UserProfile"}
					component={UserProfile}
					options={{
						title: "User Profile"
					}}
				/>
				<Stack.Screen
					name={"UserProfileChangePassword"}
					component={UserProfileChangePassword}
					options={{
						title: "User Profile"
					}}
				/>
			</Stack.Navigator>
		</View>
	);
}

export default UserProfileIndexEditNavigation;
