import React, {createRef, useEffect, useRef, useCallback, useState, forwardRef, useImperativeHandle} from 'react';
import {View, ActivityIndicator} from 'react-native';
import {TextInputMask} from 'react-native-masked-text';
import {useDebouncedCallback} from 'use-debounce';
import {CancelToken} from "axios";
import EStyleSheet from "react-native-extended-stylesheet";

import AppText from './AppText';
import {inputStyles} from '../styles/input';
import MessagesService from "../services/MessagesService";

const  PhoneInput = forwardRef( (
	{
		label,
		onChangeText,
		onSubmitEditing,
		value = '',
		blurOnSubmit = false,
		autoCapitalize = 'none',
		returnKeyType = 'next',
		outline = false,
		secureTextEntry = false,
		wrapperStyle = {},
		labelStyle = {},
		inputStyle = {},
		validateSms = false,
		validationCallBack = (valid) => valid,
		...rest
	},
	ref
) =>  {

	let inputRef = createRef();
	const validatePhoneNumberRequestSource = useRef(null);
	const [validated, setValidated] = useState(false);
	const [isValidating, setIsValidating] = useState(false);
	const [invalid, setInvalid] = useState(false);

	useImperativeHandle(ref, // forwarded ref
		function () {
			return {
				focus() {
					inputRef.focus()
				},
				blur() {
					inputRef.blur()
				},
			} // the forwarded ref value
		}, []);

	const handleValidatePhoneNumber = useCallback(async () => {
		setIsValidating(true);
		// cancel any pending request
		//note: assigning this to a variable because I can't figure out why it's throwing an eslint error about unused expression
		let derp = validatePhoneNumberRequestSource.current?.cancel();
		// create new cancel token
		validatePhoneNumberRequestSource.current = CancelToken.source();

		return await MessagesService.validatePhoneNumber(value, validatePhoneNumberRequestSource.current?.token);
	}, [value, validatePhoneNumberRequestSource])

	const [handleValidatePhoneNumberDebounce] = useDebouncedCallback(async () => {
		try {
			const valid = await handleValidatePhoneNumber();
			if(valid) {
				setIsValidating(false);
				setValidated(true);
			} else {
				setIsValidating(false);
				setInvalid(true);
			}
		} catch (e) {
			setIsValidating(false);
			setInvalid(true);
		}
	}, 500)

	useEffect(() => {
		if(validateSms && value.replace(/\D/g,'').length === 10) {
			handleValidatePhoneNumberDebounce();
		} else if(validateSms) {
			// cancel any pending request
			//note: assigning this to a variable because I can't figure out why it's throwing an eslint error about unused expression
			let derp = validatePhoneNumberRequestSource.current?.cancel();
			setIsValidating(false);
			setValidated(false);
			setInvalid(false);
		}

		return () => {
			let derp = validatePhoneNumberRequestSource.current?.cancel();
		};
	}, [value, validateSms, handleValidatePhoneNumberDebounce]);

	useEffect(() => {
		if(validateSms) {
			validationCallBack(validated);
		}
	}, [validated, validateSms, validationCallBack])

	return (
		<View style={[inputStyles.inputWrapper, wrapperStyle]}
		      onClick={() => inputRef.focus()}
		      onPress={() => inputRef.focus()}
		>
			<AppText style={[inputStyles.inputLabel, labelStyle]}>
				{label}
			</AppText>
			<TextInputMask
				type={'custom'}
				options={{
					mask: '(999) 999-9999',
				}}
				refInput={(ref) => {
					inputRef = ref
				}}
				placeholder="(___) ___-____"
				value={value}
				style={[inputStyles.input, inputStyle]}
				blurOnSubmit={blurOnSubmit}
				autoCapitalize={autoCapitalize}
				returnKeyType={returnKeyType}
				onChangeText={onChangeText}
				onSubmitEditing={onSubmitEditing} // where something like () => secondInput.focus() goes
				outline={outline}
				secureTextEntry={secureTextEntry}
				keyboardType={undefined}
				{...rest}
			/>
			{
				isValidating ?
					<View style={[styles.validationWrapper]}>
						<AppText style={[styles.validationText]}>
							Validating Phone Number
						</AppText>
						<ActivityIndicator size="small" style={{ transform: [{ scaleX: .5 }, { scaleY: .5 }] }}/>
					</View>
					: null
			}
			{
				validated ?
					<View style={[styles.validationWrapper]}>
						<AppText style={[styles.validationText, styles.validationTextValid]}>
							Phone number valid
						</AppText>
					</View>
					: null
			}
			{
				invalid ?
					<View style={[styles.validationWrapper]}>
						<AppText style={[styles.validationText, styles.validationTextInvalid]}>
							Phone number is invalid
						</AppText>
					</View>
					: null
			}
		</View>
	);
})

export default PhoneInput;

const styles = EStyleSheet.create({
	validationWrapper: {
		position: 'absolute',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		bottom: -2,
	},
	validationText: {
		fontFamily: 'SourceSansPro-light',
		fontSize: 12,
	},
	validationTextValid: {
		color: '#00d895'
	},
	validationTextInvalid: {
		color: 'red'
	},
});
