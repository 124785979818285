import React, {useState, useEffect, useRef} from 'react';
import {View, ScrollView} from 'react-native';
import {connect} from 'react-redux';

import {basicStyles} from '../../../styles/basic';
import PageTop from '../../../components/PageTop';
import PageContentContainer from '../../../components/PageContentContainer';
import Avatar from '../../../components/Avatar';
import {AVATAR_DEFAULT, AVATAR_PATH_ORG} from '../../../config';
import FieldSet from '../../../components/FieldSet';
import AppText from "../../../components/AppText";
import {textStyles} from "../../../styles/text";
import IntegrationsService from "../../../services/IntegrationsService";
import AutoReplies from "./components/AutoReplies";
import OrganizationsService from "../../../services/OrganizationsService";
import {useDebouncedCallback} from "use-debounce";
import Scripts from "./components/Scripts";

let minutes = [];
for (let i = 0; i <= 60; i++) {
    let min = i + '';

    minutes.push({key: min, value: min});
}

function MessageFlowSettings(props) {
    const organizationId = props.route?.params?.organizationId;
    const [organization, setOrganization] = useState({});
    const [organizationTextLineGroup, setOrganizationTextLineGroup] = useState({});
    const [scrollEnabled, setScrollEnabled] = useState(true);

    const [updateOrganizationDebounce] = useDebouncedCallback((organization) => {
        if (organizationId) {
            OrganizationsService.updateOrganization(
                organizationId,
                organization,
            );
        }
    }, 500);

    useEffect(() => {
        if (organizationId) {
            OrganizationsService.getOrganization(organizationId)
                .then((organization) => {
                    setOrganization(organization);
                });

            IntegrationsService.getOrganizationTextlineGroup(organizationId)
                .then(groupData => {
                    setOrganizationTextLineGroup(groupData.group);
                });
        }
    }, [organizationId]);

    return (
        <ScrollView contentContainerStyle={basicStyles.flexScale} scrollEnabled={scrollEnabled}>
            <PageTop style={[
                basicStyles.flexRow,
                basicStyles.alignContentCenter,
                basicStyles.justifyContentSpaceBetween
            ]}>
                <View style={[
                    basicStyles.flexRow,
                    basicStyles.alignContentCenter,
                    basicStyles.justifyContentSpaceBetween
                ]}>
                    <Avatar
                        source={{uri: organization.organization_thumbnail ? AVATAR_PATH_ORG + organization.organization_thumbnail : AVATAR_DEFAULT}}
                        width={66}
                        style={{marginRight: 20}}
                    />
                    <AppText style={[textStyles.pageTitle, textStyles.semiBold]}>
                        MessageFlow Settings
                    </AppText>
                </View>
            </PageTop>
            <PageContentContainer>
                <AppText style={[textStyles.pageSubTitle, textStyles.semiBold]}>
                    Phone Number
                </AppText>
                <FieldSet
                    style={{marginBottom: 50}}
                    chunkSize={2}
                    fields={[
                        {
                            type: 'text',
                            label: 'Phone Number',
                            value: organizationTextLineGroup?.phone_number || '',
                            disabled: true
                        },
                        {
                            type: 'text',
                            label: 'Department',
                            value: organizationTextLineGroup?.name || '',
                            disabled: true
                        },
                    ]}
                />
                <AutoReplies organizationId={organizationId}/>
                <AppText style={[textStyles.pageSubTitle, textStyles.semiBold]}>
                    Notifications
                </AppText>
                <FieldSet
                    style={{marginBottom: 50}}
                    chunkSize={2}
                    fields={[
                        {
                            type: 'toggle',
                            label: 'Notify Business Email when new inbound message has arrived',
                            value: !!parseInt(organization.organization_message_notifications_enabled),
                            onChange: (value) => {
                                const update = value ? '1' : '0';
                                setOrganization(current => {
                                    return {
                                        ...current,
                                        organization_message_notifications_enabled: update
                                    };
                                });

                                //todo: should probably do with use effect, if this and time between notifications are set too close together then one might not update
                                updateOrganizationDebounce({organizationId, organization_message_notifications_enabled: update});
                            }
                        },
                        {
                            type: 'picker',
                            label: 'Time between inbound messages by the minute',
                            title: 'minutes',
                            onValueChange: value => {
                                setOrganization(current => {
                                    return {
                                        ...current,
                                        organization_message_notifications_rate_minutes: value
                                    };
                                });

                                //todo: should probably do with use effect, if this and notification toggle are set too close together then one might not update
                                updateOrganizationDebounce({organizationId, organization_message_notifications_rate_minutes: value});
                            },
                            selectedValue: organization.organization_message_notifications_rate_minutes,
                            labelExtractor: 'value',
                            valueExtractor: 'value',
                            items: minutes
                        }
                    ]}
                />
                <Scripts organizationId={organizationId} setScrollEnabled={setScrollEnabled}/>
            </PageContentContainer>
        </ScrollView>
    );
}

const mapStateToProps = (state) => {
    const {auth} = state;
    return {auth};
};

export default connect(mapStateToProps)(MessageFlowSettings)
