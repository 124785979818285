import React, {forwardRef, createRef, useState} from 'react';
import {View, StyleSheet} from 'react-native';
import {Picker} from '@react-native-community/picker';

import AppText from './AppText';
import {inputStyles} from '../styles/input';
import {basicStyles} from '../styles/basic';
import ChevronDownSvg from './svgs/ChevronDownSvg';
import {AVATAR_DEFAULT} from '../config';
import Avatar from './Avatar';
import StatusIndicator from './StatusIndicator';

const AppPickerInput = forwardRef(({
	                                   label,
	                                   selectedValue,
	                                   onValueChange,
	                                   title = null,
	                                   labelExtractor = 'label',
	                                   valueExtractor = 'value',
	                                   iconExtractor = null,
	                                   iconRoot = null,
	                                   items = [],
	                                   wrapperStyle = {},
	                                   labelStyle = {},
	                                   showLabel = true,
	                                   naked = false,
	                                   nextInputRef = null,
	                                   statusColor = null,
	                                   arrowBordered = true,
										nullable = false,
	                                   ...rest
                                   },
                                   ref,
) => {
	const [isFocused, setIsFocused] = useState(false);
	if (!ref) {
		ref = createRef();
	}

	const getSelectedLabel = () => {
		let selected = items.find(item => {
			return getItemValue(item) === selectedValue;
		});

		if (selected) {
			return getItemLabel(selected);
		}

		return 'Select ' + (title || label); // return space to keep height
	};

	const getSelectedIcon = () => {

		if (iconRoot) {
			let selected = items.find(item => {
				return item[valueExtractor] === selectedValue;
			});

			if (selected) {
				return iconRoot + selected[iconExtractor];
			}
		}

		return AVATAR_DEFAULT;
	};

	const getItemLabel = (item) => {
		if (typeof labelExtractor === 'function') {
			return labelExtractor(item);
		}

		return item[labelExtractor];
	};

	const getItemValue = (item) => {
		if (typeof valueExtractor === 'function') {
			return valueExtractor(item);
		}

		return item[valueExtractor];
	};

	return (

		<View style={naked ? [wrapperStyle] : [
			inputStyles.inputWrapper,
			{
				paddingRight: 35,
				...(iconExtractor ? {paddingLeft: 123} : {}),
				...(statusColor ? {paddingLeft: 35} : {}),
				...(showLabel ? {} : {justifyContent: 'center'}),
			},
			wrapperStyle,
			...(isFocused ? [styles.focused] : []),
		]}
		      onClick={() => ref.current.focus()}
		      onPress={() => ref.current.focus()}
		>
			{
				iconExtractor ?
					<View style={[
						basicStyles.flexCenterContent,
						{
							width: 108,
							height: '100%',
							position: 'absolute',
							top: 0,
							left: 0,
							bottom: 0,
							borderRightWidth: 1,
							borderRightColor: '#E2E9F4',
						},
					]}>
						<Avatar
							source={{uri: getSelectedIcon()}}
							width={66}
						/>
					</View>
					: null
			}
			{
				statusColor ?
					<View style={[
						basicStyles.flexCenterContent,
						{
							width: 45,
							height: '100%',
							position: 'absolute',
							top: 0,
							left: 0,
							bottom: 0,
						},
					]}>
						<StatusIndicator color={statusColor}/>
					</View>
					: null
			}
			<View>
				{
					showLabel ?
						<AppText style={[inputStyles.inputLabel, labelStyle]}>
							{label}
						</AppText>
						: null
				}
				<AppText style={[inputStyles.input]}>
					{getSelectedLabel()}
				</AppText>
			</View>
			{
				naked ? null :
					<View style={[
						basicStyles.flexCenterContent,
						{
							width: 25,
							height: '100%',
							position: 'absolute',
							top: 0,
							right: 0,
							bottom: 0,
							borderLeftWidth: arrowBordered ? 1 : 0,
							borderLeftColor: '#E2E9F4',
						},
					]}>
						<ChevronDownSvg color="#0065DB" onFocus={() => ref.current.focus()}/>
					</View>
			}
			<Picker
				selectedValue={selectedValue}
				onValueChange={value => {
					if (value !== '') {
						onValueChange(value);
					} else if(nullable) {
						onValueChange(null);
					}
				}}
				style={basicStyles.overlayHidden}
				ref={ref}
				{...rest}
				onFocus={() => setIsFocused(true)}
				onBlur={() => setIsFocused(false)}
			>
				<Picker.Item
					label={'Select ' + (title || label)}
					value=""
				/>
				{
					items.map(item => {
						return (
							<Picker.Item
								key={getItemValue(item)}
								label={getItemLabel(item)}
								value={getItemValue(item)}
							/>
						);
					})
				}
			</Picker>
		</View>
	);
});

export default AppPickerInput;


const styles = StyleSheet.create({
	focused: {
		borderWidth: 1,
		borderColor: '#4D90FE',
	},
});
