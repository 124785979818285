import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function LinesSvg({fill = '#0065db', ...props}) {
	return (
		<Svg width={11} height={10} viewBox="0 0 11 10" {...props}>
			<G data-name="Group 690" fill={fill}>
				<Path data-name="Rectangle 232" d="M0 0h11v2H0z" />
				<Path data-name="Rectangle 233" d="M0 4h11v2H0z" />
				<Path data-name="Rectangle 234" d="M0 8h11v2H0z" />
			</G>
		</Svg>
	)
}

export default LinesSvg
