import React from 'react';
import {View} from 'react-native';
import EStyleSheet from "react-native-extended-stylesheet";

import {basicStyles} from "../styles/basic";
import ContentSection from "./ContentSection";
import {hexToRgb} from "../utilities/methods";
import GreenCircleWhiteCheck from "./svgs/GreenCircleWhiteCheckSvg";
import AppText from "./AppText";
import AppButton from "./AppButton";

function SubscriptionSelector({
    subscriptionType,
    active = false,
    selectLabel = 'Select Plan',
    deselectLabel = 'Deselect',
    confirmLabel = 'Next',
    deselectDisabled = false,
    confirmDisabled = false,
    onSelect = () => {
    },
    onDeselect = () => {
    },
    onConfirm = () => {
    },
}) {
    const textColor = active ? '#FFFFFF' : '#000000';
    const altTextColor = active ? '#FFFFFF' : '#0065DB';
    const containerBackgroundColor = active ? '#467AFF' : '#FFFFFF';
    const textColorRgb = hexToRgb(textColor);

    return (
        <View style={styles.subscriptionTypeWrapper}>
            <ContentSection
                style={[
                    styles.subscriptionTypeContainer,
                    {
                        backgroundColor: containerBackgroundColor,
                    },
                ]}
            >
                <View style={styles.subscriptionTypeContentSectionWrapper}>
                    <View style={styles.priceHeader}>
                        <View style={styles.subscriptionTypeTitleContainer}>
                            {
                                active ?
                                    <GreenCircleWhiteCheck/>
                                    :
                                    null
                            }
                            <AppText style={[
                                styles.subscriptionTypeTitle,
                                {color: textColor},
                            ]}>
                                {subscriptionType.subscription_type_title}
                            </AppText>
                        </View>
                        <View style={[{borderColor: `rgba(${textColorRgb?.r}, ${textColorRgb?.g}, ${textColorRgb?.b}, .25)`}, styles.subscriptionTypeValueContainer]}>
                            {
                                subscriptionType.subscription_type_cents && subscriptionType.subscription_type_cents > 0 ?
                                    <>
                                        <AppText style={[
                                            styles.subscriptionTypePricePerMonth,
                                            {color: textColor},
                                        ]}>
                                            ${(parseInt(subscriptionType.subscription_type_cents) / 100)}
                                        </AppText>
                                        <AppText style={[
                                            styles.subscriptionTypePerMonth,
                                            {color: textColor},
                                        ]}>
                                            Per {subscriptionType.subscription_type_interval_count > 1 ? subscriptionType.subscription_type_interval_count : ''} {subscriptionType.subscription_type_interval + (subscriptionType.subscription_type_interval_count > 1 ? 's' : '')}
                                        </AppText>
                                    </>
                                    :
                                    <AppText style={[
                                        styles.subscriptionTypePricePerMonth,
                                        {
                                            color: textColor,
                                            marginBottom: 28
                                        },
                                    ]}>
                                        Free
                                    </AppText>
                            }
                        </View>
                    </View>
                    <View style={[
                        styles.subscriptionTypeFeaturesHeaderContainer,
                        {borderColor: `rgba(${textColorRgb?.r}, ${textColorRgb?.g}, ${textColorRgb?.b}, .25)`},
                    ]}>
                        <AppText style={[
                            styles.subscriptionTypeFeaturesHeaderText,
                            {color: textColor},
                        ]}>
                            Plan Features
                        </AppText>
                    </View>
                    <View style={styles.subscriptionTypeFeaturesContainer}>
                        {subscriptionType.subscription_type_features.map((feature, f) => (
                            <View key={f} style={styles.subscriptionTypeFeatureRow}>
                                <AppText style={[styles.subscriptionTypeFeatureDot, { color: altTextColor }]}>{`\u2022`}</AppText>
                                <AppText style={{ color: textColor }}>{feature}</AppText>
                            </View>
                        ))}
                    </View>
                </View><View style={[
                styles.subscriptionTypeButtonsSectionWrapper,
                {borderColor: `rgba(${textColorRgb?.r}, ${textColorRgb?.g}, ${textColorRgb?.b}, .25)`},
            ]}>
                {
                    active ?
                        <>
                            {
                                !deselectDisabled ?
                                    <AppButton
                                        action={() => onDeselect()}
                                        style={{
                                            flex: 1,
                                            backgroundColor: 'transparent',
                                            borderColor: '#FFFFFF',
                                            borderWidth: 1,
                                            marginRight: 20,
                                        }}
                                    >
                                        <AppText style={{color: '#FFFFFF'}}>
                                            {deselectLabel}
                                        </AppText>
                                    </AppButton>
                                    : null
                            }
                            {
                                !confirmDisabled ?
                                    <AppButton
                                        action={() => onConfirm()}
                                        theme="transBlue"
                                        style={{
                                            flex: 1,
                                            backgroundColor: '#FFFFFF',
                                            borderColor: '#FFFFFF',
                                            borderWidth: 1,
                                        }}
                                    >
                                        <AppText style={{color: '#467AFF'}}>
                                            {confirmLabel}
                                        </AppText>
                                    </AppButton>
                                    : null
                            }
                        </>
                        :
                        <AppButton
                            action={() => onSelect()}
                            theme="green"
                            style={{flex: 1}}
                        >
                            <AppText style={{color: '#FFFFFF'}}>
                                {selectLabel}
                            </AppText>
                        </AppButton>
                }
            </View>
            </ContentSection>
        </View>
    );
}

export default SubscriptionSelector;

const styles = EStyleSheet.create({
    subscriptionTypeWrapper: {
        flex: '0 0 33.333%',
        width: '33.333%',
        paddingRight: 10,
        paddingLeft: 10,
        marginBottom: 10
    },
    subscriptionTypesContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        maxWidth: 1200,
        width: '100%',
        flexWrap: 'wrap',
    },
    subscriptionTypeContainer: {
        width: '100%',
        marginBottom: 20,
        height: '100%'
    },
    subscriptionTypeText: {
        color: '#000000',
    },
    selectedSubscriptionTypeText: {
        color: '#FFFFFF',
    },
    subscriptionTypeTitleContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10,
    },
    subscriptionTypeTitle: {
        fontFamily: 'SourceSansPro-Light',
        fontSize: 28,
    },
    subscriptionTypePricePerMonth: {
        fontFamily: 'SourceSansPro-Bold',
        fontSize: 89,
        marginBottom: 10,
    },
    subscriptionTypeContentSectionWrapper: {
        paddingLeft: 24,
        paddingRight: 24,
        paddingTop: 40,
        alignItems: 'flex-start',
    },
    priceHeader: {
        flexDirection: 'column'
    },
    subscriptionTypePerMonth: {
        fontFamily: 'SourceSansPro-SemiBold',
        opacity: .4,
    },
    subscriptionTypeFeaturesHeaderContainer: {
        borderTopWidth: 1,
        borderBottomWidth: 1,
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 20,
        paddingRight: 20,
        width: '100%',
        marginTop: 50,
        marginBottom: 25,
    },
    subscriptionTypeFeaturesHeaderText: {
        fontFamily: 'SourceSansPro-Bold',
        fontSize: 20,
    },
    subscriptionTypeFeatureDot: {
        fontSize: 28,
        position: 'relative',
        bottom: 2,
        marginRight: 10,
    },
    subscriptionTypeButtonsSectionWrapper: {
        borderTopWidth: 1,
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 25,
        paddingRight: 25,
        flexDirection: 'row',
        marginTop: 'auto',
    },
    accountInvitationHeader: {
        flexWrap: 'wrap',
        paddingRight: 15,
        paddingLeft: 15
    },
    '@media (max-width: 1050)': {
        subscriptionTypeWrapper: {
            width: '100%',
            flex: '0 0 100%',
            marginBottom: 10
        },
        subscriptionTypesContainer: {
            flexDirection: 'column'
        },
        priceHeader: {
            flexDirection: 'row',
            width: '100%'
        },
        subscriptionTypeTitleContainer: {
            width: '50%',
            justifyContent: 'center'
        },
        subscriptionTypeValueContainer: {
            width: '50%',
            textAlign: 'center',
            borderLeftWidth: 1,
        },
        subscriptionTypeContainer: {
            marginRight: 0,
            marginBottom: 30,
        },
        subscriptionTypeButtonsSectionWrapper: {
            marginTop: 20,
        }
    },
    '@media (max-width: 650)': {
        subscriptionTypePricePerMonth: {
            fontSize: 70
        },
    },
    '@media (max-width: 500)': {
        priceHeader: {
            flexDirection: 'column'
        },
        subscriptionTypeValueContainer: {
            borderLeftWidth: 0,
            textAlign: 'left',
            width: '100%',
        },
        subscriptionTypeTitleContainer: {
            justifyContent: 'flex-start',
            width: '100%',
        },
    },
    subscriptionTypeFeaturesContainer: {
        paddingBottom: 24,
    },
    subscriptionTypeFeatureRow: {
        flexDirection: 'row',
        alignItems: 'center',
    }
});
