import React from 'react';
import {useLinkProps} from '@react-navigation/native';
import AppButton from "./AppButton";

function InteriorTabNavLink({to, action, active, icon, label, style= {}, ...rest}) {
    const {onPress, ...linkProps} = useLinkProps({to, action});

    return (
        <AppButton
            icon={icon}
            label={label}
            theme={active ? 'blue' : 'transBlue'}
            action={() => onPress()}
            animated={false}
            style={style}
            {...linkProps}
            {...rest}
        />
    );
}

export default InteriorTabNavLink;
