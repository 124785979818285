import React from "react"
import Svg, { Path } from "react-native-svg"

function ChevronDownSvg({color, opacity, ...rest}) {
	return (
		<Svg width={8.37} height={5.326} viewBox="0 0 8.37 5.326" {...rest}>
			<Path
				data-name="Path 426"
				d="M4.185 5.326L0 1.1 1.085 0l3.1 3.133L7.285 0 8.37 1.1z"
				fill={color || '#fff'}
				opacity={opacity || 0.55}
			/>
		</Svg>
	)
}

export default ChevronDownSvg;
