import React, {useState, useCallback, useEffect} from 'react';
import {ScrollView, View, TouchableOpacity} from "react-native";
import EStyleSheet from "react-native-extended-stylesheet";

import Modal from "../../../../../../components/Modal";
import MessagesService from "../../../../../../services/MessagesService";
import ContentSection from "../../../../../../components/ContentSection";
import AppText from "../../../../../../components/AppText";
import {textStyles} from "../../../../../../styles/text";
import AppTextInput from "../../../../../../components/AppTextInput";
import ToggleField from "../../../../../../components/ToggleField";
import {basicStyles} from "../../../../../../styles/basic";
import AppButton from "../../../../../../components/AppButton";
import ScriptVariableModal from "./ScriptVariableModal";

function ScriptModal({onClose, onSubmit, organizationId, active = false, scriptId = null}) {
    const [script, setScript] = useState({
        message_script_id: null,
        message_script_category_id: null,
        message_script_title: '',
        message_script_body: '',
        message_script_active: '1',
        message_script_category_title: '',
        message_script_order: '0',
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [scriptVariables, setScriptVariables] = useState([]);
    const [variableModalState, setVariableModalState] = useState({
        active: false,
        variableId: null
    });

    const updateScript = useCallback((prop, value) => {
        setScript(current => {
            let update = {...current};
            update[prop] = value;

            return update;
        });
    }, []);

    const handleClose = useCallback(() => {
        onClose();
        setScript({
            message_script_id: null,
            message_script_category_id: null,
            message_script_title: '',
            message_script_body: '',
            message_script_active: '1',
            message_script_category_title: '',
            message_script_order: '0',
        });
        setIsSubmitting(false);
    }, [onClose]);

    const handleSubmit = useCallback(() => {
        if (!isSubmitting) {
            setIsSubmitting(true);
            let promise;
            if (scriptId) {
                promise = MessagesService.updateMessageScript(organizationId, scriptId, script);
            } else {
                promise = MessagesService.insertMessageScript(organizationId, script);
            }

            promise.then((script) => {
                onSubmit(script);
                handleClose();
            }).catch(() => {
                setIsSubmitting(false);
            });
        }
    }, [script, scriptId, organizationId, onSubmit, handleClose, isSubmitting])

    useEffect(() => {
        if (scriptId) {
            MessagesService.getMessageScript(organizationId, scriptId)
                .then((messageScript) => {
                    setScript(messageScript);
                })
        }
    }, [organizationId, scriptId]);

    useEffect(() => {
        MessagesService.getScriptVariables(organizationId)
            .then(scriptVariables => {
                setScriptVariables(scriptVariables);
            })
    }, [organizationId])

    return (
        <>
            <Modal
                active={active && !variableModalState.active}
                onClose={handleClose}>
                <ContentSection
                    style={[styles.contentWrapper]}
                    onClick={e => {
                        e.stopPropagation();
                    }}
                >
                    <ScrollView>
                        <AppText style={[textStyles.pageTitle, {marginBottom: 10}]}>
                            {scriptId ? 'Update' : 'Create New'} Script
                        </AppText>
                        <AppTextInput
                            label="Title"
                            value={script.message_script_title}
                            onChangeText={value => {
                                updateScript('message_script_title', value);
                            }}
                            wrapperStyle={styles.inputWrapper}
                        />
                        <AppTextInput
                            label="Category"
                            value={script.message_script_category_title}
                            onChangeText={value => {
                                updateScript('message_script_category_title', value);
                            }}
                            wrapperStyle={styles.inputWrapper}
                        />
                        <ToggleField
                            label="Enabled"
                            value={parseInt(script.message_script_active) === 1}
                            onChange={value => {
                                updateScript('message_script_active', value ? '1' : '0');
                            }}
                            wrapperStyle={styles.inputWrapper}
                        />
                        <AppTextInput
                            inputStyle={styles.scriptBodyInput}
                            multiline={true}
                            label="Script Body"
                            value={script.message_script_body}
                            onChangeText={value => {
                                updateScript('message_script_body', value);
                            }}
                            wrapperStyle={[styles.scriptBodyWrapper, styles.inputWrapper]}
                        />
                        <AppTextInput
                            label="Order"
                            value={script.message_script_order}
                            onChangeText={value => {
                                updateScript('message_script_order', value);
                            }}
                            wrapperStyle={styles.inputWrapper}
                        />
                        <View style={styles.variablesWrapper}>
                            <AppText style={styles.variablesHeading}>
                                Variables:
                            </AppText>
                            {
                                scriptVariables.map(variable => {
                                    return (
                                        <TouchableOpacity
                                            key={variable.message_script_variable_id}
                                            style={styles.variableWrapper}
                                            onPress={() => {
                                                setVariableModalState({
                                                    active: true,
                                                    variableId: variable.message_script_variable_id
                                                })
                                            }}
                                        >
                                            <AppText style={styles.variableTitle}>
                                                [{variable.message_script_variable_title}]
                                            </AppText>
                                        </TouchableOpacity>
                                    );
                                })
                            }
                        </View>
                        <View
                            style={[basicStyles.flexRow, basicStyles.justifyContentSpaceBetween, basicStyles.alignContentCenter, {marginTop: 20}]}>
                            <AppButton
                                label="Add Variable"
                                action={() => {
                                    setVariableModalState({
                                        active: true,
                                        variableId: null
                                    })
                                }}
                                disabled={isSubmitting}
                            />
                            <View style={[basicStyles.flexRow]}>
                                <AppButton
                                    label="Cancel"
                                    action={handleClose}
                                    style={{marginRight: 10}}
                                    theme="transBlue"
                                    disabled={isSubmitting}
                                />
                                <AppButton
                                    label="Confirm"
                                    action={handleSubmit}
                                    disabled={isSubmitting}
                                />
                            </View>
                        </View>
                    </ScrollView>
                </ContentSection>
            </Modal>
            <ScriptVariableModal
                active={active && variableModalState.active}
                organizationId={organizationId}
                variableId={variableModalState.variableId}
                onClose={() => {
                    setVariableModalState({
                        active: false,
                        variableId: null
                    })
                }}
                onSubmit={() => {
                    MessagesService.getScriptVariables(organizationId)
                        .then(scriptVariables => {
                            setScriptVariables(scriptVariables);
                            setVariableModalState({
                                active: false,
                                variableId: null
                            })
                        });
                }}
            />
        </>
    );
}

export default ScriptModal;

const styles = EStyleSheet.create({
    contentWrapper: {
        padding: 32,
        width: 600,
        maxWidth: '100%',
        maxHeight: '100vh'
    },
    scriptBodyWrapper: {
        height: 'auto'
    },
    scriptBodyInput: {
        height: 200,
        overflowY: 'auto'
    },
    inputWrapper: {
        marginBottom: 10
    },
    variablesWrapper: {
        marginTop: 20,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    variablesHeading: {
        fontFamily: 'SourceSansPro-Bold',
    },
    variableWrapper: {
        marginRight: 5,
        marginLeft: 5
    },
    variableTitle: {
        color: '#3CA6F5'
    },
});
