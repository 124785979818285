import React from 'react';
import {View} from 'react-native';

import AppText from './AppText';
import AppButton from './AppButton';
import {inputStyles} from '../styles/input';
import {basicStyles} from '../styles/basic';

function ButtonField({label, actionLabel, action, disabled = false, wrapperStyle = {}, labelStyle = {}}) {

	return (
		<View style={[
			inputStyles.inputWrapper,
			basicStyles.flexRow,
			basicStyles.alignContentCenter,
			wrapperStyle,
		]}
		>
			<View style={basicStyles.flexScale}>
				<AppText style={[inputStyles.inputLabel, labelStyle]}>
					{label}
				</AppText>
			</View>
			<AppButton
				label={actionLabel}
				theme="green"
				action={action}
				disabled={disabled}
			/>
		</View>

	);
}

export default ButtonField;
