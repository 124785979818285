import React, {useCallback, useEffect, useState} from 'react';
import {View, ScrollView, Image, StyleSheet} from 'react-native';
import {connect, useDispatch} from 'react-redux';
import OrganizationsService from '../../services/OrganizationsService';
import { setOrganization } from '../../store/auth/authActions';
import IntegrationsService from '../../services/IntegrationsService';
import {basicStyles} from '../../styles/basic';
import AppText from '../../components/AppText';
import AppButton from '../../components/AppButton';
import {textStyles} from '../../styles/text';
import {IMAGE_PATH_INTEGRATION} from '../../config';
import FieldSet from '../../components/FieldSet';
import EStyleSheet from 'react-native-extended-stylesheet';

function TextlineIntegration(props) {
    const dispatch = useDispatch();
    const [integration, setIntegration] = useState({integrationImages: []});
    const [token, setToken] = useState(props.auth.organization.organization_textline_token || '');
    const [enabled, setEnabled] = useState(!!parseInt(props.auth.organization.organization_textline_enabled));
    const [groupUUID, setGroupUUID] = useState(props.auth.organization.organization_textline_group_uuid || '');
    const [groups, setGroups] = useState([]);
    const [buttonChunkSize, setButtonChunkSize] = useState(2);
    const handleLayout = ({nativeEvent}) => {
        const {width} = nativeEvent.layout;

        if (width > 840) {
            setButtonChunkSize(2);
        } else {
            setButtonChunkSize(1);
        }
    };

    useEffect(() => {
        const getIntegration = async () => {
            const integrationData = await IntegrationsService.getIntegration(props.route.params.id);

            setIntegration(integrationData);
        };

        getIntegration();
    }, [props.route.params.id, props.auth.organization.organization_id]);

    /**
     * Handle textline token save
     */

    const handleTokenSave = useCallback(async () => {
        IntegrationsService.setOrganizationTextlineToken(props.auth.organization.organization_id, token);

        if (token) {
            IntegrationsService.getOrganizationTextlineGroups(props.auth.organization.organization_id, token)
                .then(async (groups) => {
                    setGroups(groups || []);
                    setEnabled(true);
                    dispatch(setOrganization(await OrganizationsService.getOrganization(props.auth.organization.organization_id)));
                })
                .catch(() => {
                    setEnabled(false);
                    setToken('');
                    setGroups([]);
                    IntegrationsService.setOrganizationTextlineEnabled(props.auth.organization.organization_id, false);
                });
        } else {
            setEnabled(false);
            setToken('');
            setGroups([]);
            await IntegrationsService.setOrganizationTextlineEnabled(props.auth.organization.organization_id, false);
            dispatch(setOrganization(await OrganizationsService.getOrganization(props.auth.organization.organization_id)));
        }
    }, [props.auth, token]);

    /**
     * Handle department select
     */

    const handleGroupChange = useCallback(async (value) => {
        setGroupUUID(value);
        await IntegrationsService.setOrganizationTextlineGroup(props.auth.organization.organization_id, value || null);
        dispatch(setOrganization(await OrganizationsService.getOrganization(props.auth.organization.organization_id)));
    }, [props.auth, groupUUID]);

    /**
     * Handle enabled toggle
     */

    const handleEnabledChange = useCallback(async (value) => {
        setEnabled(value);
        await IntegrationsService.setOrganizationTextlineEnabled(props.auth.organization.organization_id, value || false);
        dispatch(setOrganization(await OrganizationsService.getOrganization(props.auth.organization.organization_id)));
    });

    const SaveTokenButton = () => {
        return <AppButton
            label="Save"
            theme="green"
            large={true}
            action={handleTokenSave}
        />;
    };

    /**
     * Fetch initial groups list on load
     */

    useEffect(() => {
        if (token && !groups.length) {
            IntegrationsService.getOrganizationTextlineGroups(props.auth.organization.organization_id, token)
                .then((groups) => {
                    setGroups(groups || []);
                })
                .catch(() => {
                    setToken('');
                    setEnabled(false);
                    setGroups([]);

                    IntegrationsService.setOrganizationTextlineEnabled(props.auth.organization.organization_id, false);
                });
            }
            
        if (!token && enabled) {
            IntegrationsService.setOrganizationTextlineEnabled(props.auth.organization.organization_id, false);
        }
    }, []);

    return (
        <ScrollView contentContainerStyle={basicStyles.flexScale} onLayout={handleLayout}>
            <View style={styles.contentWrapper}>
                <View style={[
                    basicStyles.alignContentCenter,
                    basicStyles.justifyContentSpaceBetween,
                    styles.contentContainer
                ]}>
                    <View style={[styles.contentLeft, basicStyles.alignContentCenter]}>
                        <View>
                            <AppText style={[
                                {fontSize: 28, marginBottom: 30},
                                textStyles.bold,
                            ]}>
                                {integration.integration_title}
                            </AppText>
                            <AppText style={{fontSize: 16, maxWidth: 465}}>
                                {integration.integration_description}
                            </AppText>
                        </View>
                    </View>
                    <View style={[styles.contentRight, basicStyles.alignContentCenter]}>
                        {
                            integration.integrationImages.length ?
                                <Image
                                    source={{uri: IMAGE_PATH_INTEGRATION + integration.integrationImages[0].integration_image_file}}
                                    style={styles.image} // todo: width or height HAS to be known in order for an image to scale...
                                    resizeMode="contain"
                                />
                                : null
                        }
                    </View>
                </View>
                <FieldSet
                    chunkSize={buttonChunkSize}
                    fields={[
                        {
                            type: 'text',
                            label: 'Access Token',
                            value: token,
                            onChangeText: setToken,
                            wrapperStyle: styles.buttonStyles,
                            inputStyle: styles.inputStyles,
                            actionButton: <SaveTokenButton />
                        },
                        {
                            type: 'picker',
                            label: 'Department',
                            onValueChange: handleGroupChange,
                            selectedValue: groupUUID,
                            items: groups,
                            labelExtractor: (group) => group.name + ' - ' + group.phone_number,
                            valueExtractor: 'uuid',
                            disabled: !token,
                            wrapperStyle: !token || !groups.length ? { opacity: 0.4 } : null,
                        },
                        {
                            type: 'toggle',
                            label: 'Enable Textline Integration',
                            onChange: handleEnabledChange,
                            value: enabled,
                            disabled: !token
                        },
                    ]}
                />
            </View>
        </ScrollView>
    );
}

const mapStateToProps = (state) => {
    const {auth} = state;
    return {auth};
};

export default connect(mapStateToProps)(TextlineIntegration);


const styles = EStyleSheet.create({
    contentWrapper: {
        paddingTop: 70,
        paddingBottom: 70,
        paddingLeft: 40,
        paddingRight: 40,
    },
    contentContainer: {
        flexDirection: 'row',
        paddingBottom: 60
    },
    contentLeft: {
        width: '50%',
        paddingRight: 40
    },
    contentRight: {
        width: '50%',
    },
    image: {
        width: 408,
        height: 315,
        marginBottom: 10,
    },
    '@media (max-width: 920)': {
        contentContainer: {
            flexDirection: 'column'
        },
        contentLeft: {
            width: '100%',
            paddingBottom: 50,
        },
        contentRight: {
            width: '100%'
        }
    },
    '@media (max-width: 408)': {
        contentWrapper: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        contentLeft: {
            paddingLeft: 20,
            paddingRight: 20,
        },
        image: {
            width: 350
        },
        inputStyles: {
            paddingBottom: 20,
        },
        buttonStyles: {
            height: 'auto', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'inherit'
        }
    },
    '@media (max-width: 350)': {
        contentLeft: {
            paddingBottom: 10,
        },
        image: {
            width: 280
        },
    }
});
