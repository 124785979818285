import Http from "../utilities/Http";
import {API_ROOT} from "../config";

export default {
	async getCustomUploads(organizationId) {
		const response = await Http().get(`${API_ROOT}/organizations/${organizationId}/custom-uploads`);

		return response.data.customUploads;
	},
	async insertCustomUpload(organizationId, customUpload) {
		const response = await Http().post(`${API_ROOT}/organizations/${organizationId}/custom-uploads`, {
			customUpload
		});

		return response.data.customUpload;
	},
	async updateCustomUpload(organizationId, customUploadId, customUpload, cancelToken = null) {
		const response = await Http().post(`${API_ROOT}/organizations/${organizationId}/custom-uploads/${customUploadId}`, {
				customUpload
			},
			{cancelToken}
		);

		return response.data.customUpload;
	},
	async deleteCustomUpload(organizationId, customUploadId) {
		await Http().delete(`${API_ROOT}/organizations/${organizationId}/custom-uploads/${customUploadId}`);
	},
	async uploadCSV(organizationId, customUploadId, formData) {
		let response = await Http().post(API_ROOT + `/organizations/${organizationId}/custom-uploads/upload-csv/${customUploadId}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		});

		return response.data.customUpload;
	},
	async getCustomUploadCSVContent(organizationId, customUploadId) {
		const response = await Http().get(`${API_ROOT}/organizations/${organizationId}/custom-uploads/csv-content/${customUploadId}`);

		return response.data.csvContent;
	},
	async sendCustomUpload(organizationId, customUploadId) {
		const response = await Http().get(`${API_ROOT}/organizations/${organizationId}/custom-uploads/send/${customUploadId}`);

		return response.data.customUpload;
	},
};
