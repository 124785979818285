import * as React from "react"
import Svg, { Path } from "react-native-svg"

function InfoSvg(props) {
    return (
        <Svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M8 14.667A6.667 6.667 0 118 1.333a6.667 6.667 0 010 13.334zm0-1.334A5.333 5.333 0 108 2.667a5.333 5.333 0 000 10.666zm-.667-8.666h1.334V6H7.333V4.667zm0 2.666h1.334v4H7.333v-4z"
                fill="#9EABCC"
            />
        </Svg>
    )
}

export default InfoSvg;
