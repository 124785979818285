import * as React from "react"
import Svg, { Path } from "react-native-svg"

function TriangleDownSvg(props) {
	return (
		<Svg width={7} height={6} viewBox="0 0 7 6" {...props}>
			<Path data-name="Polygon 1" d="M3.5 6L0 0h7z" />
		</Svg>
	)
}

export default TriangleDownSvg
