import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {View, ScrollView, Platform} from 'react-native';

import {basicStyles} from '../../styles/basic';
import AppText from '../../components/AppText';
import {textStyles} from '../../styles/text';
import PageContentContainer from '../../components/PageContentContainer';
import StatusIconRow from '../../components/StatusIconRow';
import BriefcaseBlueSvg from '../../components/svgs/BriefcaseBlueSvg';
import BriefcaseBlueAlt from '../../components/svgs/BriefcaseBlueAlt';
import MockUpSvg from '../../components/svgs/MockUpSvg';
import GreenCircleWhiteCheckSvg from '../../components/svgs/GreenCircleWhiteCheckSvg';
import YellowCircleOpenMailSvg from '../../components/svgs/YellowCircleOpenMailSvg';
import CreateOptionsSection from '../../components/CreateOptionsSection';
import UploadOverlayHidden from '../../components/UploadOverlayHidden';
import {IMPORT_TEMPLATE_PATH_USERS} from '../../config';
import UsersService from '../../services/UsersService';
import {setProcessingIndicatorActive} from '../../store/miscellaneous/miscellaneousActions';
import {bindActionCreators} from "redux";
import ImportConfiguration from "../../components/ImportConfiguration";
import EStyleSheet from 'react-native-extended-stylesheet';

function TeamMemberCreateOptions(props) {
    const {navigate} = props.navigation;
    const [users, setUsers] = useState([]);
    const [usersImportState, setUsersImportState] = useState({
        columns: [],
        firstRow: [],
        availableColumns: [],
        defaultConfiguration: null,
        initialized: false,
        formData: null,
    });
    const {setProcessingIndicatorActive} = props;

    useEffect(() => {
        const getUsers = async () => {
            let usersData = await UsersService.getUsers(props.auth.organization.organization_id);
            setUsers(usersData);
        };

        getUsers();
    }, [props.auth.organization.organization_id]);

    const activeUsers = users.filter(user => {
        return user.user_organization_accepted_timestamp;
    });

    const pendingUsers = users.filter(user => {
        return !user.user_organization_accepted_timestamp;
    });

    const importUsers = async (columnsConfiguration) => {
        let data = null;
        let newFormData = Object.assign(usersImportState.formData);
        newFormData.append('columnsConfiguration', JSON.stringify(columnsConfiguration));
        try {
            setProcessingIndicatorActive(true);
            data = await UsersService.importUsers(props.auth.organization.organization_id, newFormData);
            setProcessingIndicatorActive(false);
            navigate('TeamMemberImportResults', {
                importData: data,
            });
        } catch (error) {
            console.log('uploadError: ', error);
        }
    };

    const getUsersImportConfiguration = async (results) => {
        let file = results[0];
        let formData = new FormData();
        formData.append('file', file);
        let data = null;
        try {
            data = await UsersService.getUsersImportConfiguration(props.auth.organization.organization_id, formData);
            setUsersImportState({
                columns: data.columns,
                firstRow: data.firstRow,
                availableColumns: data.availableColumns,
                defaultConfiguration: data.defaultConfiguration,
                initialized: true,
                formData
            });
        } catch (error) {
            console.log('uploadError: ', error);
        }

    }

    return (
        <>
            <ScrollView contentContainerStyle={basicStyles.flexScale}>
                <PageContentContainer>
                    <View style={basicStyles.centerContent}>
                        <AppText style={[
                            textStyles.pageTitle,
                            textStyles.semiBold,
                            {
                                paddingBottom: 40,
                                textAlign: 'center',
                            },
                        ]}>
                            Invite Team Members
                        </AppText>
                    </View>
                    <StatusIconRow
                        statusRows={[
                            {
                                icon: <BriefcaseBlueSvg/>,
                                count: users.length,
                                status: 'invited',
                            },
                            {
                                icon: <GreenCircleWhiteCheckSvg/>,
                                count: activeUsers.length,
                                status: 'Confirmed',
                            },
                            {
                                icon: <YellowCircleOpenMailSvg/>,
                                count: pendingUsers.length,
                                status: 'Pending',
                            },
                        ]}
                        style={styles.statusIconContainer}
                        styleWrapper={styles.statusIconItem}
                        styleCopy={styles.statusCopy}
                        styleNumber={styles.statusNumber}
                    />
                    <View style={[
                        basicStyles.flexRow,
                        styles.wrapper,
                    ]}>
                        <View style={[styles.sectionItem, styles.leftPane]}>
                            <CreateOptionsSection
                                icon={<MockUpSvg/>}
                                title="Invite Multiple Users"
                                subTitle="Import via CSV file"
                                label1="Upload CSV"
                                button1Children={
                                    <UploadOverlayHidden
                                        callback={getUsersImportConfiguration}
                                    />
                                }
                            />
                        </View>
                        <View style={[styles.sectionItem, styles.rightPane]}>
                            <CreateOptionsSection
                                icon={<BriefcaseBlueAlt/>}
                                title="Invite Single User"
                                subTitle="Send Email Invite"
                                label1="Invite User"
                                action1={() => navigate('TeamMemberAdd')}
                            />
                        </View>
                    </View>
                </PageContentContainer>
            </ScrollView>
            {
                usersImportState.initialized ?
                    <ImportConfiguration
                        columns={usersImportState.columns}
                        firstRow={usersImportState.firstRow}
                        availableColumns={usersImportState.availableColumns}
                        defaultConfiguration={usersImportState.defaultConfiguration}
                        onSubmit={importUsers}
                        onOffClick={() => setUsersImportState(prevState => {
                            return {...prevState, initialized: false}
                        })}
                    />
                    : null
            }
        </>
    );
}

const mapStateToProps = (state) => {
    const {auth} = state;
    return {auth};
};


const mapDispatchToProps = dispatch => (
    bindActionCreators({
        setProcessingIndicatorActive,
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(TeamMemberCreateOptions);


const styles = EStyleSheet.create({
    wrapper: {
        marginTop: 60
    },
    sectionItem: {
        width: '50%',
    },
    leftPane: {
        paddingRight: 20
    },
    rightPane: {
        paddingLeft: 20
    },
    '@media (max-width: 768)': {
        wrapper: {
            flexDirection: 'column'
        },
        sectionItem: {
            width: '100%'
        },
        leftPane: {
            paddingRight: 0,
            paddingBottom: 20
        },
        rightPane: {
            paddingLeft: 0
        }
    },
    '@media (max-width: 650)': {
        statusIconContainer: {
            paddingTop: 15,
            paddingBottom: 15,
            paddingRight: 10,
            borderRadius: 5,
            borderColor: '#E2E9F4',
            borderWidth: 1,
        },
        statusIconItem: {
            flexDirection: 'column',
            borderTopWidth: 0,
            borderRightWidth: 0,
            borderBottomWidth: 0,
            borderLeftWidth: 0,
            textAlign: 'center',

        }
    },
    '@media (max-width: 400)': {
        statusCopy: {
            flexDirection: 'column',
            paddingBottom: 5
        },
        statusNumber: {
            marginRight: 0,
        }
    }
});