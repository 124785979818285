import React, {forwardRef, useCallback, useLayoutEffect, useRef, useState} from 'react';
import DatePicker from 'react-datepicker';
import {View} from 'react-native';

import {basicStyles} from '../styles/basic';
import AppText from './AppText';
import EStyleSheet from "react-native-extended-stylesheet";
import moment from "moment/moment";
import AppButton from "./AppButton";
import CalendarSvg from "./svgs/CalendarSvg";

function AppDateRangePicker({startDateValue, endDateValue, onChangeDates = () => {}, popperMods, wrapperStyle = {}, labelStyle = {}}) {
	const [dateFilterOpen, setDateFilterOpen] = useState(false);
	const dropdownNode = useRef(null);

	const popperModifiers = popperMods ? popperMods : {
		offset: {
			enabled: true,
			offset: "5px, 10px"
		},
		preventOverflow: {
			enabled: true,
			escapeWithReference: false,
			boundariesElement: "viewport"
		}
	};

	const dateOptions = [
		'Today',
		'Yesterday',
		'Last 7 Days',
		'Last 30 Days',
		'This Month',
		'Last Month'
	];
	const dateValues = {
		'Today': [moment().startOf('day'), moment().endOf('day')],
		'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
		'Last 7 Days': [moment().subtract(7, 'days').startOf('day'), moment().endOf('day')],
		'Last 30 Days': [moment().subtract(30, 'days').startOf('day'), moment().endOf('day')],
		'This Month': [moment().startOf('month'), moment().endOf('month')],
		'Last Month': [moment().startOf('month').subtract(1, 'month'), moment().subtract(1, 'month').endOf('month')]
	};

	const dateLabel = useCallback(() => {
		return (
			<>
				{moment(startDateValue).format('MMM D, YYYY')} - { endDateValue ? moment(endDateValue).format('MMM D, YYYY') : ''}
			</>
		);
	}, [startDateValue, endDateValue])

	const CustomInput = forwardRef(
		({ value, onClick, className, style }, ref) => (
			<View  className={className} onClick={onClick} ref={ref} style={style}>
				<AppText>
					Custom
				</AppText>
			</View>
		),
	);

	// <View> Elements are not valid to use this method with, had to create a dummy div for this
	const useClickOutside = (
		node = null,
		onClickOutside = () => {},
		useCapture = false,
	) => {
		const onClick = useCallback(
			(e) => {
				if (node && !node.contains(e.target)) {
					onClickOutside();
				}
			},
			[node, onClickOutside],
		);

		useLayoutEffect(() => {
			document.addEventListener('click', onClick, useCapture);

			return () => {
				document.removeEventListener('click', onClick, useCapture);
			};
		}, [onClick, useCapture]);
	};

	useClickOutside(dropdownNode.current, () => {
		if(dateFilterOpen) {
			setDateFilterOpen(false);
		}
	});



	return (
		<View style={[
			basicStyles.flexRow,
			basicStyles.alignContentCenter,
			basicStyles.justifyContentSpaceBetween,
			styles.dateRangeButtonWrapper,

		]}>
			<AppButton
				theme="transBlue"
				style={[styles.resultsFilterButton]}
				action={(e) => {
					e.stopPropagation();
					setDateFilterOpen(!dateFilterOpen);
				}}
				animated={false}
			>
				<CalendarSvg style={{marginRight: 10}}/>
				<AppText>{dateLabel()}</AppText>
			</AppButton>

			<View
				style={[
					basicStyles.contentSection,
					styles.dateDropdown,
					{
						position: 'absolute',
						top: '100%',
						left: 0,
						width: 175,
						zIndex: 10
					},
					(!dateFilterOpen ? {display: 'none'} : {})
				]}
			>
				<div ref={dropdownNode}>
					{
						dateOptions.map((dateOption) => {
							return (
								<AppText key={dateOption} style={[styles.dateOption]} onClick={() => {
									onChangeDates(dateValues[dateOption]);
									setDateFilterOpen(false);
								}}>{dateOption}</AppText>
							);
						})
					}
					<View style={[basicStyles.flexScale, basicStyles.flexRow, basicStyles.justifyContentSpaceBetween, basicStyles.alignContentCenter]}>
						<DatePicker
							showPopperArrow={false}
							popperPlacement={"right-end"}
							selectsRange={true}
							onChange={onChangeDates}
							startDate={startDateValue}
							endDate={endDateValue}
							customInput={<CustomInput className="testing" style={[styles.dateOption]} />}
							shouldCloseOnSelect={false}
							popperModifiers={popperModifiers}
						/>
					</View>
				</div>
			</View>
		</View>
	);
}

export default AppDateRangePicker;

const styles = EStyleSheet.create({
	resultsFilterButton: {
		flexDirection: 'row',
		alignItems: 'center',
		paddingLeft: 15,
		paddingRight: 15
	},
	dateDropdown: {
		background: 'white',

	},
	dateOption: {
		paddingTop: 15,
		paddingBottom: 15,
		paddingLeft: 10,
		display: 'block',
		cursor: 'pointer',
	},
	dateRangeButtonWrapper: {
		marginLeft: 15,
		marginRight: 15
	},
	'@media (max-width: 750)': {
		dateRangeButtonWrapper: {
			marginLeft: 0,
			marginRight: 0
		}
	},
});