import React, {useRef} from 'react';
import {View, TextInput, StyleSheet} from 'react-native';

import SearchSvg from './svgs/SearchSvg';
import BlueCircleArrowSvg from './svgs/BlueCircleArrowRightSvg';

function SearchBar({style = {}, ...props}) {
	const inputRef = useRef(null);

	return (
		<View
			style={[styles.wrapper, style]}
			onClick={() => {
				if(inputRef.current) {
					inputRef.current.focus();
				}
			}}
		>
			<SearchSvg fill="#0065DB"/>
			<TextInput ref={inputRef} style={[styles.input]} {...props} />
			<BlueCircleArrowSvg/>
		</View>
	);
}

export default SearchBar;

const styles = StyleSheet.create({
	wrapper: {
		backgroundColor: '#FFFFFF',
		flexDirection: 'row',
		alignItems: 'center',
		height: 60,
		paddingLeft: 20,
		paddingRight: 10,
		borderWidth: 1,
		borderColor: '#E2E9F4',
		borderRadius: 30
	},
	input: {
		flex: 1,
		marginLeft: 10,
		marginRight: 10,
		outlineWidth: 0,
		fontFamily: 'SourceSansPro-Regular',
	},
});
