import React, {createRef, useState} from 'react';
import {View, StyleSheet} from 'react-native';
import {Picker} from '@react-native-community/picker';


import ChevronDownSvg from './svgs/ChevronDownSvg';
import ContentSection from './ContentSection';
import AppText from './AppText';
import {basicStyles} from '../styles/basic';
import AppButton from './AppButton';
import StripeCardInput from './StripeCardInput';
import {capitalizeString} from '../utilities/methods';
import EStyleSheet from 'react-native-extended-stylesheet';

function StripePaymentSubscription({
	subscriptionTypes,
	selectedSubscriptionTypeId,
	onUpdateSubscriptionType,
	onSubmit,
	submitLabel = 'Complete Purchase',
	containerStyle = {},
	coupon = {},
	freeTrialDays = 0,
	onBack = null
}) {

	const [submitEngaged, setSubmitEngaged] = useState(false);

	const pickerRef = createRef;

	let selectedSubscriptionType = subscriptionTypes.find(subscriptionType => {
		return subscriptionType.subscription_type_id === selectedSubscriptionTypeId;
	});

	function getSelectedLabel() {

		if (selectedSubscriptionType) {
			return selectedSubscriptionType.subscription_type_title;
		}

		return 'Select Subscription Type';
	}

	function submit() {
		setSubmitEngaged(true);
	}

	return (
		<ContentSection style={containerStyle}>
			<View style={styles.container}>
				<View style={styles.subscriptionTypePickerWrapper}>
					<View>
						<View style={styles.phonySelectWrapper}>
							<AppText style={styles.selectedSubscriptionTypeTitle}>
								{getSelectedLabel()}
							</AppText>
							{
								selectedSubscriptionType ?
									<View style={styles.priceContainer}>
										<AppText style={styles.priceText}>
											${(parseInt(selectedSubscriptionType.subscription_type_cents) / 100).toFixed(2)}
										</AppText>
										<AppText style={styles.perMonthText}>
											Per {selectedSubscriptionType.subscription_type_interval_count > 1 ? selectedSubscriptionType.subscription_type_interval_count : ''} {selectedSubscriptionType.subscription_type_interval + (selectedSubscriptionType.subscription_type_interval_count > 1 ? 's' : '')}
										</AppText>
									</View>
									: null
							}
							<View style={[
								basicStyles.flexCenterContent,
								{
									width: 40,
									height: '100%',
									position: 'absolute',
									top: 0,
									right: 0,
									bottom: 0,
									borderLeftWidth: 1,
									borderLeftColor: '#E2E9F4',
								},
							]}>
								<ChevronDownSvg color="#FFFFFF" onFocus={() => pickerRef.current.focus()}/>
							</View>
						</View>
						<Picker
							selectedValue={selectedSubscriptionTypeId}
							onValueChange={onUpdateSubscriptionType}
							ref={pickerRef}
							style={basicStyles.overlayHidden}
						>
							{
								subscriptionTypes.map(subscriptionType => {
									return (
										<Picker.Item
											key={subscriptionType.subscription_type_id}
											label={subscriptionType.subscription_type_title}
											value={subscriptionType.subscription_type_id}
										/>
									);
								})
							}
						</Picker>
					</View>
					{
						freeTrialDays ?
							<View style={{marginTop: 20}}>
								<AppText style={{fontFamily: 'SourceSansPro-SemiBold', fontSize: 18}}>
									Free Trial! - {freeTrialDays} Days
								</AppText>
							</View>
							: null
					}
					{
						coupon.coupon_id ?
							<View style={{marginTop: 20}}>
								<AppText style={{fontFamily: 'SourceSansPro-SemiBold', fontSize: 18}}>
									Coupon Applied! - {coupon.stripe_data.percent_off}% Off {capitalizeString(coupon.stripe_data.duration)}
								</AppText>
							</View>
							: null
					}
				</View>
				<StripeCardInput
					onSubmit={(cardToken) => onSubmit(cardToken)}
					submitEngaged={submitEngaged}
				/>
			</View>
			<View style={styles.footer}>
				<AppButton
					label={submitLabel}
					theme="blue"
					action={() => {
						if (!submitEngaged) {
							submit();
						}
					}}
				/>
				{
					onBack ?
						<AppButton
							label="Back"
							theme="transRed"
							action={() => {
                                onBack()
                            }}
                            style={{marginTop: 10}}
						/>
						: null
				}
			</View>
		</ContentSection>
	);
}

export default StripePaymentSubscription;

const styles = EStyleSheet.create({
	container: {
		padding: 30,
	},
	subscriptionTypePickerWrapper: {
		marginBottom: 60,
	},
	phonySelectWrapper: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: '#0065DB',
		borderRadius: 10,
		height: 97,
		paddingRight: 40,
		paddingLeft: 40,
	},
	selectedSubscriptionTypeTitle: {
		fontFamily: 'SourceSansPro-Bold',
		fontSize: 22,
		color: '#FFFFFF',
	},
	priceContainer: {
		alignItems: 'flex-start',
		paddingRight: 30,
	},
	priceText: {
		fontSize: 22,
		color: '#FFFFFF',
	},
	perMonthText: {
		fontFamily: 'SourceSansPro-SemiBold',
		fontSize: 14,
		color: 'rgba(255,255,255,.4)',
	},
	footer: {
		justifyContent: 'center',
		alignItems: 'center',
		padding: 15,
		borderTopWidth: 1,
		borderTopColor: '#E2E9F4',
		marginTop: 30,
	},
	stripeContainer: {
		paddingTop: 17,
		paddingBottom: 17,
		paddingLeft: 24,
		paddingRight: 24,
		borderWidth: 1,
		borderColor: '#e2ecf2',
		borderRadius: 4,
		backgroundColor: '#f5f8fa',
		width: '100%',
	},
	'@media (max-width: 500)': {
		phonySelectWrapper: {
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'flex-start'
		},
		priceContainer: {
			flexDirection: 'row',
			alignItems: 'center',
		},
		perMonthText: {
			marginLeft: 10,
		}
	}
});
