export const authActionTypes = {
    SET_USER: 'SET_USER',
    SET_TOKEN: 'SET_TOKEN',
    SET_ORGANIZATION: 'SET_ORGANIZATION',
    SET_ORGANIZATION_ROLE_ID: 'SET_ORGANIZATION_ROLE_ID',
    SET_PERMISSIONS: 'SET_PERMISSIONS',
    RESET_STATE: 'RESET_STATE',
    SET_INITIALIZED: 'SET_INITIALIZED',
    EMULATE_USER: 'EMULATE_USER',
    STOP_USER_EMULATION: 'STOP_USER_EMULATION',
    SET_REAL_TOKEN: 'SET_REAL_TOKEN',
    SET_REAL_ORGANIZATION_ID: 'SET_REAL_ORGANIZATION_ID',
    SET_REAL_ORGANIZATION_ROLE_ID: 'SET_REAL_ORGANIZATION_ROLE_ID',
};

export const setUser = user => (
    {
        type: authActionTypes.SET_USER,
        payload: user,
    }
);

export const setToken = token => (
    {
        type: authActionTypes.SET_TOKEN,
        payload: token,
    }
);

export const setOrganization = organization => (
    {
        type: authActionTypes.SET_ORGANIZATION,
        payload: organization,
    }
);

export const setOrganizationRoleId = organizationRoleId => (
    {
        type: authActionTypes.SET_ORGANIZATION_ROLE_ID,
        payload: organizationRoleId,
    }
);

export const resetState = () => (
    {
        type: authActionTypes.RESET_STATE,
    }
);

export const setInitialized = initialized => (
    {
        type: authActionTypes.SET_INITIALIZED,
        payload: initialized
    }
);

export const setPermissions = permissions => (
    {
        type: authActionTypes.SET_PERMISSIONS,
        payload: permissions
    }
);

export const emulateUser = data => (
    {
        type: authActionTypes.EMULATE_USER,
        payload: data
    }
);

export const setRealToken = token => (
    {
        type: authActionTypes.SET_REAL_TOKEN,
        payload: token
    }
);

export const setRealOrganizationRoleId = organizationRoleId => (
    {
        type: authActionTypes.SET_REAL_ORGANIZATION_ROLE_ID,
        payload: organizationRoleId
    }
);

export const setRealOrganizationId = organizationId => (
    {
        type: authActionTypes.SET_REAL_ORGANIZATION_ID,
        payload: organizationId
    }
);

export const stopEmulatingUser = organizationId => (
    {
        type: authActionTypes.STOP_USER_EMULATION,
        payload: organizationId
    }
);
