import Http from "../utilities/Http";
import {API_ROOT} from "../config";

export default {
	async getOrganizationRoles(organizationId) {
		let response = await Http().get(API_ROOT + '/organizations/' + organizationId + '/roles');

		return response.data.roles;
	}
};
