import React, {useEffect, useRef, useState} from 'react';
import {ScrollView, View} from 'react-native';

import {basicStyles} from '../../styles/basic';
import PageTop from "../../components/PageTop";
import Avatar from "../../components/Avatar";
import {
    AVATAR_DEFAULT,
    AVATAR_PATH_ORG,
    AVATAR_PATH_USER,
} from "../../config";
import AppText from "../../components/AppText";
import {getUserDisplayName} from "../../utilities/methods";
import PageContentContainer from "../../components/PageContentContainer";
import {connect} from "react-redux";
import {textStyles} from "../../styles/text";
import ContentSection from "../../components/ContentSection";
import AppButton from "../../components/AppButton";
import UploadOverlayHidden from "../../components/UploadOverlayHidden.web";
import ContentSectionDivider from "../../components/ContentSectionDivider";
import {useDebouncedCallback} from "use-debounce";
import UsersService from "../../services/UsersService";
import AppTextInput from "../../components/AppTextInput";
import {bindActionCreators} from "redux";
import {setWarning} from '../../store/warning/warningActions';
import {inputStyles} from "../../styles/input";
import {CommonActions} from "@react-navigation/native";
import EStyleSheet from 'react-native-extended-stylesheet';

function UserProfile(props) {

    const [state, setState] = useState({
        user: {...props.auth.user},
        organization: {...props.auth.organization},
        updatingUser: false,
    });

    const organizationId = props.route?.params?.organizationId || props.auth.organization.organization_id;
    const {setWarning} = props;
    const firstUpdate = useRef(true);

    const updateAvatar = async (results) => {
        let file = results[0];
        let formData = new FormData();
        formData.append('image', file);

        let fileName;
        try {
            fileName = await UsersService.uploadImage(organizationId, formData);
        } catch (error) {
            console.log('uploadError: ', error);
        }

        updateUser('user_avatar_file', fileName);
    };

    const updateUser = (prop, value) => {
        setState(prevState => {
            return Object.assign({}, prevState, {user: {...prevState.user, [prop]: value}});
        });
    };

    const [updateUserDebounce] = useDebouncedCallback(async () => {
        if (state.user.user_id) {
           let userData = await UsersService.updateUser(
                organizationId,
                state.user.user_id,
                state.user,
            );
            setState(prevState => {
                return {
                    ...prevState,
                    user: userData,
                    updatingUser: true
                };
            });
        }
    }, 500);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if(state.updatingUser) {
            setState(prevState => {
                return {...prevState, updatingUser: false};
            })
            return ;
        }

        updateUserDebounce();

    }, [state.user, updateUserDebounce]);

    return (
        <ScrollView contentContainerStyle={basicStyles.flexScale}>
            <PageTop style={[basicStyles.flexRow, basicStyles.justifyContentSpaceBetween, basicStyles.alignContentCenter]}>
                <View style={styles.header}>
                    <View style={styles.headerUser}>
                        <Avatar
                            source={{uri: state.user.user_avatar_file ? AVATAR_PATH_USER + state.user.user_avatar_file : AVATAR_DEFAULT}}
                            width={50}
                        />
                        <AppText style={styles.userName}>
                            {
                                getUserDisplayName(state.user)
                            }
                        </AppText>
                    </View>
                    <View style={styles.headerTeam}>
                        <Avatar
                            source={{uri: state.organization.organization_thumbnail ? AVATAR_PATH_ORG + state.organization.organization_thumbnail : AVATAR_DEFAULT}}
                            width={30}
                        />
                        <AppText style={styles.teamMemberName}>
                            {state.organization.organization_title}
                        </AppText>
                    </View>
                </View>
            </PageTop>
            <PageContentContainer style={styles.content}>
                <>
                    <View style={[styles.headerTeam, styles.headerTeamMobile]}>
                        <Avatar
                            source={{uri: state.organization.organization_thumbnail ? AVATAR_PATH_ORG + state.organization.organization_thumbnail : AVATAR_DEFAULT}}
                            width={30}
                        />
                        <AppText style={styles.teamMemberName}>
                            {state.organization.organization_title}
                        </AppText>
                    </View>
                    <AppText style={[textStyles.pageSubTitle, textStyles.semiBold]}>
                        Profile
                    </AppText>
                    <ContentSection
                        style={[
                            styles.profileContentSection
                        ]}>
                        <View style={[
                            basicStyles.widthThird,
                            basicStyles.alignContentCenter,
                            basicStyles.inputContainerPaddingVertical,
                            styles.profileImageContainer,
                        ]}>
                            <AppText style={[
                                styles.profileImageContainerTitle,
                            ]}>
                                Profile Image
                            </AppText>
                            <Avatar
                                source={{uri: state.user.user_avatar_file ? AVATAR_PATH_USER + state.user.user_avatar_file : AVATAR_DEFAULT}}
                                width={151}
                                style={styles.avatar}
                            />
                            <View style={[
                                basicStyles.alignContentCenter,
                                styles.imageButtons,
                            ]}>
                                <AppText style={[
                                    styles.profileImageContainerTitle,
                                    styles.profileImageContainerTitleTablet
                                ]}>
                                    Profile Image
                                </AppText>
                                <AppButton
                                    style={[styles.imageButton, styles.imageUploadButton]}
                                    textStyle={styles.imageButtonText}
                                    label={state.user.user_avatar_file ? 'Change Photo' : 'Upload Photo'}
                                >
                                    <UploadOverlayHidden
                                        callback={updateAvatar}
                                    />
                                </AppButton>
                                <AppButton
                                    style={[styles.imageButton, styles.imageRemoveButton]}
                                    textStyle={[styles.imageButtonText, styles.imageButtonTextRemove]}
                                    action={() => {
                                        setWarning({
                                            confirmAction: () => updateUser('user_avatar_file', null),
                                            confirmLabel: 'Remove Photo',
                                            rejectLabel: 'Cancel',
                                            warningMessage: 'Are you sure you want to remove your profile image?',
                                            active: true,
                                        });
                                    }}
                                    label="Remove Photo"
                                />
                            </View>
                        </View>
                        <ContentSectionDivider/>
                        <View style={[
                            basicStyles.padding20,
                            basicStyles.widthTwoThird,
                            basicStyles.flexScale,
                            basicStyles.inputContainerPaddingVertical,
                            styles.profileInformationContainer
                        ]}>
                            <AppText style={[
                                textStyles.semiBold,
                                {marginBottom: 20},
                            ]}>
                                Profile information
                            </AppText>
                            <View style={[
                                styles.profileInformationInputContainer
                            ]}>
                                <AppTextInput
                                    wrapperStyle={styles.inputField}
                                    label="First Name"
                                    onChangeText={value => updateUser('profile_first_name', value)}
                                    value={state.user.profile_first_name || ''}
                                />
                                <AppTextInput
                                    wrapperStyle={styles.inputField}
                                    label="Last Name"
                                    onChangeText={value => updateUser('profile_last_name', value)}
                                    value={state.user.profile_last_name || ''}
                                />
                            </View>
                        </View>
                    </ContentSection>
                    <AppText style={[textStyles.pageSubTitle, textStyles.semiBold]}>
                        Account
                    </AppText>
                    <ContentSection
                        style={[
                            basicStyles.flexRow,
                            basicStyles.inputContainerPaddingVertical,
                            basicStyles.inputContainerPaddingHorizontal,
                            styles.accountSection,
                        ]}>
                        <View style={[
                            basicStyles.widthHalf,
                            styles.accountField
                        ]}>
                            <AppTextInput
                                label="Account Email"
                                onChangeText={value => updateUser('user_email', value)}
                                value={state.user.user_email || ''}
                            />
                        </View>
                        <View style={[
                            basicStyles.widthHalf,
                            styles.accountField
                        ]}>
                            <View style={[basicStyles.flexScale, styles.passwordSection]}>
                                <View style={[basicStyles.flexScale, basicStyles.widthThird]}>
                                    <AppText style={[inputStyles.inputLabel]}>
                                        Log In Password
                                    </AppText>
                                    <AppText style={{color: '#000'}}>
                                        *****************
                                    </AppText>
                                </View>
                                <AppButton
                                    style={[basicStyles.widthThird, styles.changePassword]}
                                    label="Change Password"
                                    theme="green"
                                    action={() => {
                                        props.navigation.navigate('UserProfileChangePassword', {
                                            id: state.user.user_id,
                                        })
                                    }}
                                />
                            </View>
                            <AppButton
                                style={[basicStyles.widthThird, styles.changePasswordMobile]}
                                label="Change Password"
                                theme="green"
                                action={() => {
                                    props.navigation.navigate('UserProfileChangePassword', {
                                        id: state.user.user_id,
                                    })
                                }}
                            />
                        </View>
                    </ContentSection>
                </>
            </PageContentContainer>
        </ScrollView>
    );
}

const mapStateToProps = (state) => {
    const {auth} = state;
    return {auth};
};

const mapDispatchToProps = (dispatch) => (
    bindActionCreators({
        setWarning,
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);

const styles = EStyleSheet.create({
    header: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'space-between',
        //marginTop: 75,
        //marginBottom: 25,
    },
    headerUser: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    userName: {
        color: '#000',
        fontSize: 28,
        fontFamily: 'SourceSansPro-SemiBold',
        paddingLeft: 10
    },
    headerTeam: {
        flexDirection: 'row',
        alignItems: 'center',
        borderColor: '#E2E9F4',
        borderWidth: 1,
        borderRadius: 8,
        padding: 20
    },
    teamMemberName: {
        fontSize: 16,
        fontFamily: 'SourceSansPro-Regular',
        color: '#8F95A5',
        paddingLeft: 10
    },
    content: {
        paddingTop: 85,
        paddingLeft: 30,
        paddingRight: 30
    },
    avatar: {
        marginBottom: 20
    },
    profileContentSection: {
        flexDirection: 'row',
        marginBottom: 50
    },
    profileImageContainer: {
        paddingTop: 20,
        paddingRight: 20,
        paddingLeft: 20,
        paddingBottom: 0,
        justifyContent: 'space-between'
    },
    profileImageContainerTitle: {
        fontFamily: 'SourceSansPro-SemiBold',
        marginBottom: 20,
    },
    profileImageContainerTitleTablet: {
        display: 'none'
    },
    imageButtons: {
        flexDirection: 'row',
        borderWidth: 1,
        borderColor: '#E2E9F4',
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
    },
    imageButton: {
        backgroundColor: '#FFFFFF',
    },
    imageButtonText: {
        color: '#000000',
    },
    imageUploadButton: {
        borderTopWidth: 0,
        borderRightWidth: 1,
        borderBottomWidth: 0,
        borderLeftWidth: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderColor: '#E2E9F4'
    },
    imageRemoveButton: {
        borderWidth: 0,
    },
    inputField: {
        marginTop: 10,
        marginBottom: 10
    },
    accountSection: {
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 10,
        paddingRight: 10
    },
    accountField: {
        paddingLeft: 10,
        paddingRight: 10
    },
    passwordSection: {
        flexDirection: 'row',
        borderWidth: 1,
        borderRadius: 5,
        borderColor: '#E2E9F4',
        paddingTop: 10,
        paddingBottom: 10,
        paddingRight: 10,
        paddingLeft: 20,
    },
    changePassword: {
        minWidth: 150
    },
    changePasswordMobile: {
        display: 'none'
    },
    '@media (min-width: 700)': {
        headerTeamMobile: {
            display: 'none'
        }
    },
    '@media (max-width: 1000)': {
        avatar: {
            marginBottom: 0,
            marginRight: 30
        },
        profileContentSection: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        profileImageContainer: {
            width: '100%',
            borderBottomWidth: 1,
            borderBottomColor: '#E2E9F4',
            flexDirection: 'row',
            justifyContent: 'center',
            paddingBottom: 20,
        },
        profileImageContainerTitle: {
            display: 'none'
        },
        imageButtons: {
            flexDirection: 'column',
            borderWidth: 0,
        },
        imageUploadButton: {
            borderTopWidth: 1,
            borderBottomWidth: 1,
            borderRightWidth: 1,
            borderLeftWidth: 1,
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
            borderColor: '#0065DB',
            marginBottom: 15
        },
        imageRemoveButton: {
            borderTopWidth: 1,
            borderBottomWidth: 1,
            borderRightWidth: 1,
            borderLeftWidth: 1,
            borderColor: '#EA0024'
        },
        imageButtonTextRemove: {
            color: '#EA0024'
        },
        profileInformationContainer: {
            width: '100%',
        },
        profileInformationInputContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        inputField: {
            width: '49%'
        }
    },
    '@media (max-width: 1000) and (min-width: 475)': {
        profileImageContainerTitleTablet: {
            display: 'flex'
        }
    },
    '@media (max-width: 800)': {
        accountSection: {
            flexDirection: 'column'
        },
        accountField: {
            width: '100%',
            marginTop: 10,
            marginBottom: 10
        }
    },
    '@media (max-width: 700)': {
        header: {
            flexDirection: 'column'
        },
        headerTeam: {
            display: 'none'
        },
        headerTeamMobile: {
            display: 'flex',
            marginBottom: 50
        },
        content: {
            paddingTop: 25
        }
    },
    '@media (max-width: 475)': {
        profileImageContainer: {
            flexDirection: 'column'
        },
        headerTeamMobile: {
            //display: 'flex'
        },
        avatar: {
            marginRight: 0,
            marginBottom: 30
        },
        profileImageContainerTitle: {
            display: 'flex'
        },
        profileInformationInputContainer: {
            flexDirection: 'column'
        },
        inputField: {
            width: '100%'
        },
        changePassword: {
            display: 'none',
        },
        changePasswordMobile: {
            display: 'flex',
            width: '100%',
            marginTop: 20
        }
    },
});
