import React, {useState, useEffect, useCallback, useRef, useMemo} from 'react';
import {TextInput, View, FlatList, TouchableOpacity, Dimensions, ActivityIndicator} from 'react-native';
import EStyleSheet from "react-native-extended-stylesheet";
import {useDebouncedCallback} from 'use-debounce';
import {CancelToken} from "axios";
import {connect} from 'react-redux';

import AppText from "../../components/AppText";
import MessagesService from "../../services/MessagesService";
import AppButton from "../../components/AppButton";
import {basicStyles} from "../../styles/basic";
import {getMinuetsSinceDate, formatDate, formatPhoneNumber} from "../../utilities/methods";
import MessageFeedResolvedSvg from "../../components/svgs/MessageFeedResolvedSvg";
import MessageFeedUnresolvedSvg from "../../components/svgs/MessageFeedUnresolvedSvg";
import {navigate} from "../../utilities/navigation";
import CreateNewMessageModal from "./components/CreateNewMessageModal";
import MessageFeed from "./components/MessageFeed";

function MessageFlow(props) {
    const {realToken, user, organization} = props.auth;
    const organizationId = props.route?.params?.organizationId || props.auth.organization.organization_id;
    const selectedMessageFeedId = props.route?.params?.messageFeedId;
    const [messageFeeds, setMessageFeeds] = useState([]);
    const [newFeeds, setNewFeeds] = useState([]);
    const [filters, setFilters] = useState({
        search: null,
        message_feed_resolved: null
    });
    const [page, setPage] = useState(0);
    const [isLastPage, setIsLastPage] = useState(false);
    const [newMessageModalActive, setNewMessageModalActive] = useState(false);
    // const [selectedMessageFeedId, setSelectedMessageFeedId] = useState(props.route?.params?.messageFeedId || null); //Will be used in message component
    const perPage = 20;
    const fetchDataRequestSource = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    const {screenHeight, screenWidth} = useMemo(() => {
        return {
            screenHeight: Dimensions.get('window').height,
            screenWidth: Dimensions.get('window').width,
        };
    });

    const headerHeight = useMemo(() => {
        let headerHeight = 0;
        if(screenWidth <= 630) {
            headerHeight += 80;
        }

        if (realToken && user && organization) {
            headerHeight += 60;
        }

        return headerHeight;
    });

    const fetchData = useCallback(async () => {
        // cancel any pending request
        //note: assigning this to a variable because I can't figure out why it's throwing an eslint error about unused expression
        let derp = fetchDataRequestSource.current?.cancel('Request Canceled');
        // create new cancel token
        fetchDataRequestSource.current = CancelToken.source();
        setIsLoading(true);
        try {
            const messageFeeds = await MessagesService.getMessageFeeds(organizationId, {...filters, message_feed_ids: newFeeds}, page, perPage, fetchDataRequestSource.current?.token);
            setIsLastPage(messageFeeds.length < perPage);
            setMessageFeeds(current => {
                return [
                    ...(page === 0 ? [] : current),
                    ...messageFeeds
                ];
            });
            // setIsLoading(false);
        } catch (e) {
            if(!e.__CANCEL__) {
                console.log('Error fetching message feeds: ', e);
            }
        }
    }, [organizationId, filters, page, newFeeds]);

    const [fetchDataDebounce] = useDebouncedCallback(async () => {
        await fetchData();
    }, 500);

    const updateSelectedMessageInList = (messageFeed) => {
        if(messageFeed.message_feed_id == selectedMessageFeedId) {
            setMessageFeeds(current => {
                const selectedMessageFeedIndex = current.findIndex(messageFeed => {
                    return messageFeed.message_feed_id == selectedMessageFeedId;
                })

                if (selectedMessageFeedIndex >= 0) {
                    current.splice(selectedMessageFeedIndex, 1, {
                        ...current[selectedMessageFeedIndex],
                        ...messageFeed
                    });

                    return [...current];
                }

                return current;
            });
        }
    };


    useEffect(() => {
        setPage(0);
    }, [filters]);

    useEffect(() => {
        fetchDataDebounce();
        return () => {
            if(fetchDataRequestSource.current) {
                fetchDataRequestSource.current.cancel();
            }
        };
    }, [organizationId, filters, page, newFeeds]);

    useEffect(() => {
        if (messageFeeds.length && page === 0 && (!selectedMessageFeedId)) {
            if(screenWidth > 550) {
                navigate('MessageFlow', {messageFeedId: messageFeeds[0].message_feed_id})
            }
        }
    }, [selectedMessageFeedId, messageFeeds, page, screenWidth, newFeeds]);

    return (
        <View style={[styles.messageFlowPageContainer, {maxHeight: screenHeight - headerHeight}]}>
            <View style={[styles.messageFeedsContainer]}>
                <View style={[styles.filtersWrapper]}>
                    <View style={[basicStyles.flexRow, basicStyles.justifyContentFlexStart]}>
                        <AppButton
                            label="All"
                            action={() => {
                                setFilters(current => {
                                    return {
                                        ...current,
                                        message_feed_resolved: null
                                    }
                                });
                            }}
                            theme={filters.message_feed_resolved === 0 ? 'transBlue' : 'blue'}
                            style={[{marginRight: 5}]}
                        />
                        <AppButton
                            label="Unresolved"
                            action={() => {
                                setFilters(current => {
                                    return {
                                        ...current,
                                        message_feed_resolved: 0
                                    }
                                });
                            }}
                            theme={filters.message_feed_resolved === 0 ? 'blue' : 'transBlue'}
                        />
                    </View>
                    <View style={[styles.searchFieldWrapper]}>
                        <TextInput
                            style={[styles.searchField]}
                            placeholder="Search"
                            onChangeText={value => {
                                setFilters(current => {
                                    return {
                                        ...current,
                                        search: value
                                    }
                                });
                            }}
                            value={filters.search || ''}
                        />
                    </View>
                </View>
                {
                    isLoading && messageFeeds.length === 0 ?
                        <ActivityIndicator style={styles.loadingIndicator} size="large" color="#467AFF"/>
                        : null
                }
                <FlatList
                    style={[basicStyles.flexScale]}
                    data={messageFeeds.sort((messageFeedA, messageFeedB) => {
                        // Put newly created feeds to the top
                        let includesA = newFeeds.includes(messageFeedA.message_feed_id);
                        let includesB = newFeeds.includes(messageFeedB.message_feed_id);
                        if (includesA && includesB) {
                            return 0;
                        } else if (includesA) {
                            return -1;
                        } else if (includesB) {
                            return 1;
                        }

                        // Put feeds without any messages to the bottom
	                    if(!messageFeedB.message_feed_last_message_created_timestamp) {
		                    return 1;
	                    }
                        return new Date(messageFeedA.message_feed_last_message_created_timestamp) < new Date(messageFeedB.message_feed_last_message_created_timestamp) ? 1 : -1;
                    })}
                    keyExtractor={({message_feed_id}) => message_feed_id}
                    onEndReachedThreshold={0.1}
                    onEndReached={() => {
                        if (!isLastPage) {
                            setPage(current => current + 1);
                        }
                    }}
                    renderItem={({item: messageFeed}) => {
                        let timeSinceString = ' ';
                        if(messageFeed.message_feed_last_message_created_timestamp) {
                            const minuetsSinceCreated = getMinuetsSinceDate(messageFeed.message_feed_last_message_created_timestamp);
                            if (minuetsSinceCreated > 1440) {
                                timeSinceString = formatDate(messageFeed.message_feed_last_message_created_timestamp);
                            } else if (minuetsSinceCreated > 60) {
                                timeSinceString = `${Math.round((minuetsSinceCreated / 60))}h`;
                            } else {
                                timeSinceString = `${minuetsSinceCreated}m`;
                            }
                        }

                        return (
                            <TouchableOpacity
                                onPress={() => {
                                    navigate('MessageFlow', {messageFeedId: messageFeed.message_feed_id})
                                }}
                                style={[
                                    styles.messageFeedWrapper,
                                    selectedMessageFeedId === messageFeed.message_feed_id ? styles.messageFeedActive : {}
                                ]}
                            >
                                <View style={[styles.messageDetailsLeft]}>
                                    <AppText style={[styles.messengerIdentifierString]}>
                                        {messageFeed.message_feed_contact_name ? messageFeed.message_feed_contact_name : formatPhoneNumber(messageFeed.message_feed_phone_number_formatted)}
                                    </AppText>
                                    <AppText style={[styles.messageSnippet]}>
                                        {messageFeed.message_feed_last_message ? messageFeed.message_feed_last_message : '(no message history)'}
                                    </AppText>
                                </View>
                                <View style={[styles.messageDetailsRight]}>
                                    <AppText style={[styles.timeSinceString]}>
                                        {timeSinceString}
                                    </AppText>
                                    {
                                        messageFeed.message_feed_resolved == 1 ?
                                            <MessageFeedResolvedSvg/>
                                            :
                                            <MessageFeedUnresolvedSvg/>
                                    }
                                </View>
                            </TouchableOpacity>
                        );
                    }}
                />
                <View style={[styles.createNewMessageFeedContainer]}>
                    <AppButton
                        label="Create New +"
                        action={() => {
                            setNewMessageModalActive(true);
                        }}
                    />
                </View>
            </View>
            <View style={[styles.messageFeedContainer, selectedMessageFeedId ? styles.messageFeedContainerActive : {}]}>
                {
                    selectedMessageFeedId ?
                        <MessageFeed
                            organizationId={organizationId}
                            messageFeedId={selectedMessageFeedId}
                            updateSelectedMessageInList={updateSelectedMessageInList}
                        />
                        : null
                }
            </View>
            {
                newMessageModalActive ?
                    <CreateNewMessageModal
                        handleSubmit={async (phoneNumber) => {
                            const newMessageFeed = await MessagesService.insertMessageFeed(organizationId, phoneNumber);
                            setNewFeeds([...newFeeds, newMessageFeed.message_feed_id]);
                            navigate('MessageFlow', {messageFeedId: newMessageFeed.message_feed_id});
                            setNewMessageModalActive(false);
                            setPage(0);
                        }}
                        handleClose={() => {
                            setNewMessageModalActive(false);
                        }}
                    />
                    : null
            }
        </View>
    );
}

const mapStateToProps = (state) => {
    const {auth} = state;
    return {auth};
};

export default connect(mapStateToProps)(MessageFlow);

const styles = EStyleSheet.create({
    messageFlowPageContainer: {
        flexDirection: 'row',
        flex: 1,
    },
    messageFeedsContainer: {
        width: '25%',
        borderRightWidth: 1,
        borderRightColor: 'rgb(226, 233, 244)',
        backgroundColor: '#f8fafd',
        zIndex: 1,
    },
    messageFeedContainer: {
        width: '75%',
        flex: 1,
        zIndex: 1,
    },
    filtersWrapper: {
        padding: 15,
        borderBottomWidth: 1,
        borderBottomColor: '#E8EDF6',
    },
    widthHalf: {
        width: '50%'
    },
    searchFieldWrapper: {
        backgroundColor: '#FFFFFF',
        flexDirection: 'row',
        alignItems: 'center',
        height: 40,
        paddingLeft: 8,
        paddingRight: 8,
        borderWidth: 1,
        borderColor: '#E2E9F4',
        borderRadius: 30,
        marginTop: 15
    },
    searchField: {
        flex: 1,
        marginLeft: 10,
        marginRight: 10
    },
    messageFeedWrapper: {
        borderBottomWidth: 1,
        borderBottomColor: '#E8EDF6',
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 16,
        paddingRight: 16,
        flexDirection: 'row',
    },
    messageFeedActive: {
        backgroundColor: 'rgba(60, 166, 245, 0.1)'
    },
    createNewMessageFeedContainer: {
        padding: 15
    },
    messageDetailsLeft: {
        flex: 1
    },
    messageDetailsRight: {
        alignItems: 'flex-end'
    },
    messageSnippet: {
        maxWidth: '100%',
        textWrap: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        color: 'rgba(158, 171, 204, 1)',
        fontFamily: 'SourceSansPro-Light',
    },
    timeSinceString: {
        fontSize: 14,
        color: 'rgba(158, 171, 204, 1)',

        fontFamily: 'SourceSansPro-Light',
    },
    messengerIdentifierString: {
        fontFamily: 'SourceSansPro-Bold',
    },
    '@media (max-width: 1000)': {
        messageFeedsContainer: {
            width: '40%'
        },
        messageFeedContainer: {
            width: '60%'
        },
    },
    '@media (max-width: 630)': {
        messageFlowPageContainer: {
            maxHeight: 'auto'
        },
    },
    '@media (max-width: 550)': {
        messageFeedsContainer: {
            width: '100%'
        },
        messageFeedContainer: {
            width: '100%',
            position: 'absolute',
            left: '100%',
            top: 0,
            bottom: 0,
            zIndex: 1,
        },
        messageFeedContainerActive: {
            transform: [{translateX: '-100%'}]
        }
    },
    loadingIndicator: {
        position: 'absolute',
        top: '50%',
        left: 0,
        right: 0,
        marginRight: 'auto',
        marginLeft: 'auto'
    }
});
