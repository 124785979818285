import React from "react"
import Svg, {Path, Defs, LinearGradient, Stop, G, Circle} from "react-native-svg"

function HourGlassSvg(props) {
	return (
		<Svg width={43.724} height={43.724} viewBox="0 0 43.724 43.724" {...props}>
			<Defs>
				<LinearGradient
					id="prefix__a"
					x1={-0.195}
					y1={0.5}
					x2={1.173}
					y2={0.5}
					gradientUnits="objectBoundingBox"
				>
					<Stop offset={0} stopColor="#1b8ce9" />
					<Stop offset={1} stopColor="#467aff" />
				</LinearGradient>
			</Defs>
			<G data-name="Group 957" transform="translate(-704.808 -469.808)">
				<Circle
					data-name="Ellipse 32"
					cx={21.862}
					cy={21.862}
					transform="translate(704.808 469.808)"
					fill="url(#prefix__a)"
					r={21.862}
				/>
				<G data-name="Group 683">
					<Path
						data-name="Path 450"
						d="M733.166 501.905l-.112-6-4.058-3.932 3.922-4.078-.092-5.99-12 .204.102 6 4.068 3.932-3.932 4.058.102 6.01zm-10.246-14.33l-.06-3.5 8-.136.06 3.5-3.932 4.068z"
						fill="#fff"
					/>
				</G>
			</G>
		</Svg>
	)
}

export default HourGlassSvg;
