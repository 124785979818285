import * as React from "react"
import Svg, { G, Circle, Path } from "react-native-svg"

function BlueCircleArrowSvg(props) {
	return (
		<Svg width={46} height={46} viewBox="0 0 46 46" {...props}>
			<G data-name="Group 1161" transform="translate(161 1752)">
				<Circle
					data-name="Ellipse 67"
					cx={23}
					cy={23}
					r={23}
					transform="translate(-161 -1752)"
					fill="#0065db"
				/>
				<G data-name="Group 1158" fill="none" stroke="#fff" strokeWidth={2}>
					<Path data-name="Line 362" d="M-145.156-1729.5h12.105" />
					<Path
						data-name="Path 531"
						d="M-137.484-1723.539l5.93-5.929-6.033-6.032"
					/>
				</G>
			</G>
		</Svg>
	)
}

export default BlueCircleArrowSvg
