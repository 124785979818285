import * as React from "react"
import Svg, { G, Circle, Path } from "react-native-svg"

function RemoveSvg(props) {
	return (
		<Svg
			data-name="Group 716"
			width={23}
			height={23}
			viewBox="0 0 23 23"
			{...props}
		>
			<G data-name="Group 28">
				<G
					data-name="Ellipse 2"
					fill="#f54444"
					stroke="#f54444"
					opacity={0.104}
				>
					<Circle cx={11.5} cy={11.5} r={11.5} stroke="none" />
					<Circle cx={11.5} cy={11.5} r={11} fill="none" />
				</G>
			</G>
			<G data-name="Group 27">
				<G
					data-name="Ellipse 2"
					fill="#f54444"
					stroke="#f54444"
					transform="translate(2 2)"
				>
					<Circle cx={9.5} cy={9.5} r={9.5} stroke="none" />
					<Circle cx={9.5} cy={9.5} r={9} fill="none" />
				</G>
			</G>
			<Path
				data-name="Line 45"
				fill="none"
				stroke="#fff"
				strokeWidth={2}
				d="M6.37 11.5h10.261"
			/>
		</Svg>
	)
}

export default RemoveSvg;
