import React, {useState} from 'react';
import {View, StyleSheet} from 'react-native';

function SvgBorder({children, style, ...rest}) {
	const [width, setWidth] = useState(null);
	return (
		<View
			onLayout={(event) => {
				const {width} = event.nativeEvent.layout;
				setWidth(width);
			}}
			style={[
				styles.svgWrapper,
				(width ? {borderRadius: width / 2} : {}),
				style
			]}
			{...rest}
		>
			{children}
		</View>
	);
}

export default SvgBorder;

const styles = StyleSheet.create({
	svgWrapper: {
		borderWidth: 5,
		borderColor: 'rgba(27,140,233,.07)',
	}
});
