import React, {useState, useEffect} from 'react';
import {useLinkProps} from '@react-navigation/native';
import {View, TouchableOpacity, Platform, StyleSheet} from 'react-native';

import FontelloIcon from './FontelloIcon';
import AppText from './AppText';
import EStyleSheet from 'react-native-extended-stylesheet';

const FontelloLink = ({to, action, children, active, icon, label, ...rest}) => {
	const {onPress, ...props} = useLinkProps({to, action});
	const [isHovered, setIsHovered] = useState(false);
	const [showPopupTimeOut, setShowPopupTimeout] = useState(null);
	const [popupShown, setPopupShown] = useState(false);

	useEffect(() => {
		if (isHovered) {
			setShowPopupTimeout(() => setTimeout(() => {
				setPopupShown(true);
			}, 0));
		} else {
			setShowPopupTimeout((showPopupTimeOut) => {
				clearTimeout(showPopupTimeOut);
			});
			setPopupShown(false);
		}
	}, [isHovered]);

	if (Platform.OS === 'web') {
		return (
			<View
				onClick={onPress}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
				style={[
					styles.iconWrapper,
					(active ? styles.iconWrapperActive : styles.iconWrapperInactive),
				]}
				{...props}
				{...rest}
			>
				{
					popupShown ?
						<View style={styles.popupLabelContainer}>
							<AppText style={styles.popupLabel}>
								{label}
							</AppText>
						</View>
						: null
				}
				<FontelloIcon
					name={icon}
					style={[
						{
							fontSize: 20,
							transitionDuration: '150ms',
						},
						((active || isHovered) ? styles.iconActive : styles.iconInactive),
					]}
				/>
				<AppText style={[styles.mobileLabel, (active ?  styles.mobileLabelActive : null)]}>{label}</AppText>
			</View>
		);
	}

	return (
		<TouchableOpacity
			onPress={onPress} {...props} {...rest}
			style={[
				styles.iconWrapper,
				(active ? styles.iconWrapperActive : styles.iconWrapperInactive),
			]}
		>
			<FontelloIcon
				name={icon}
				style={[
					{fontSize: 20},
					((active) ? styles.iconActive : styles.iconInactive),
				]}
			/>
			<AppText style={[styles.mobileLabel, (active ?  styles.mobileLabelActive : null)]}>{label}</AppText>
		</TouchableOpacity>
	);

};

export default FontelloLink;

const styles = EStyleSheet.create({
	iconWrapper: {
		width: 55,
		height: 55,
		borderRadius: 55 / 2,
		alignItems: 'center',
		justifyContent: 'center',
	},
	iconWrapperActive: {
		backgroundColor: '#F8FAFD'
	},
	iconWrapperInactive: {
		backgroundColor: 'transparent'
	},
	iconActive: {
		color: '#0065DB',
	},
	iconInactive: {
		color: '#D9D9D9',
	},
	popupLabelContainer: {
		position: 'absolute',
		left: '90%',
		padding: 8,
		borderWidth: 1,
		borderColor: '#E2E9F4',
		borderRadius: 8,
		backgroundColor: '#FFFFFF',
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 3,
		},
		shadowOpacity: 0.1,
		shadowRadius: 9.11,
	},
	popupLabel: {
		color: '#467AFF',
		fontSize: 12,
		whiteSpace: 'nowrap',
	},
	mobileLabel: {
		display: 'none'
	},
	'@media (max-width: 630)': {
		iconWrapper: {
			flexDirection: 'row',
			width: '100%',
			paddingLeft: 30,
			justifyContent:'flex-start',
			borderRadius: 0,
			borderBottomWidth: 1,
			borderColor: '#E2E9F4'
		},
		popupLabelContainer: {
			display: 'none'
		},
		iconWrapperActive: {
			backgroundColor: '#0065DB'
		},
		iconActive: {
			color: '#FFF'
		},
		iconInactive: {
			color: '#0065DB'
		},
		mobileLabel: {
			display: 'flex',
			paddingLeft: 30,
		},
		mobileLabelActive: {
			color: '#FFF'
		}
	}
});
