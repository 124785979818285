import AppText from "./AppText";
import NotificationTile from "./NotificationTile";
import {View} from "react-native";
import React from "react";
import EStyleSheet from "react-native-extended-stylesheet";

function UserNotifications({user, updateNotification, hasViewAccountReportsPermission = false, hasAdminTeamNotificationsPermission = false, hasAdminNotificationFailuresPermission = false, ...props}) {
    const scheduledNotificationItems = [
        {
            label: 'Daily',
            value: 'daily',
        },
        {
            label: 'Weekly',
            value: 'weekly',
        },
        {
            label: 'Monthly',
            value: 'monthly',
        },
        {
            label: 'Quarterly',
            value: 'quarterly',
        },
    ];

    const realtimeNotificationItems = [
        {
            label: 'Real-time',
            value: 'realtime',
        }
    ];

    return (<>
            {
                hasViewAccountReportsPermission ?
                    <>
                        <NotificationTile
                            notificationTitle="Customer Report"
                            notificationId="user_notification_frequency_customer"
                            items={scheduledNotificationItems}
                            defaultItem="monthly"
                            selectedFrequency={user.user_notification_frequency_customer}
                            callback={updateNotification}
                        />
                        <NotificationTile
                            notificationTitle="Review Report"
                            notificationId="user_notification_frequency_review"
                            items={scheduledNotificationItems}
                            defaultItem="monthly"
                            selectedFrequency={user.user_notification_frequency_review}
                            callback={updateNotification}
                        />
                        <NotificationTile
                            notificationTitle="Market Report"
                            notificationId="user_notification_frequency_market"
                            items={scheduledNotificationItems}
                            defaultItem="monthly"
                            selectedFrequency={user.user_notification_frequency_market}
                            callback={updateNotification}
                        />
                        <NotificationTile
                            notificationTitle="Team Report"
                            notificationId="user_notification_frequency_team"
                            items={scheduledNotificationItems}
                            defaultItem="monthly"
                            selectedFrequency={user.user_notification_frequency_team}
                            callback={updateNotification}
                        />
                    </>
                    :
                    null
            }

            <NotificationTile
                notificationTitle="Completed Survey"
                notificationId="user_notification_completed_survey"
                items={realtimeNotificationItems}
                defaultItem="realtime"
                selectedFrequency={user.user_notification_completed_survey}
                callback={updateNotification}
            />
            {
                (
                    hasAdminTeamNotificationsPermission || hasAdminNotificationFailuresPermission
                ) ?
                    <View>
                        <AppText style={[styles.contentHeader, styles.managerContentHeader]}>Manager Alerts & Notifications</AppText>
                        {
                            hasAdminTeamNotificationsPermission ?
                                <NotificationTile
                                    notificationTitle="Team Completed Surveys"
                                    notificationId="user_admin_notification_team_completed_survey"
                                    items={realtimeNotificationItems}
                                    defaultItem="realtime"
                                    selectedFrequency={user.user_admin_notification_team_completed_survey}
                                    callback={updateNotification}
                                />
                                :
                                null
                        }
                        {
                            hasAdminNotificationFailuresPermission ?
                                <NotificationTile
                                    notificationTitle="Notification Failures"
                                    notificationId="user_admin_notification_failure"
                                    items={realtimeNotificationItems}
                                    defaultItem="realtime"
                                    selectedFrequency={user.user_admin_notification_failure}
                                    callback={updateNotification}
                                />
                                :
                                null
                        }
                    </View>
                    :
                    null
            }</>
    );
}

export default UserNotifications;


const styles = EStyleSheet.create({
    header: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'space-between',
        //arginTop: 75,
        //marginBottom: 25,
    },
    headerUser: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    userName: {
        color: '#000',
        fontSize: 28,
        fontFamily: 'SourceSansPro-SemiBold',
        paddingLeft: 10
    },
    headerTeam: {
        flexDirection: 'row',
        alignItems: 'center',
        borderColor: '#E2E9F4',
        borderWidth: 1,
        borderRadius: 8,
        padding: 20
    },
    teamMemberName: {
        fontSize: 16,
        fontFamily: 'SourceSansPro-Regular',
        color: '#8F95A5',
        paddingLeft: 10
    },
    content: {
        paddingTop: 85,
        paddingLeft: 30,
        paddingRight: 30
    },
    contentHeader: {
        paddingLeft: 30,
        paddingBottom: 40,
        fontFamily: 'SourceSansPro-SemiBold',
        fontSize: 20,
        color: '#000'
    },
    managerContentHeader: {
        paddingTop: 60
    },
    '@media (min-width: 700)': {
        headerTeamMobile: {
            display: 'none'
        }
    },
    '@media (max-width: 700)': {
        header: {
            flexDirection: 'column'
        },
        headerTeam: {
            display: 'none'
        },
        headerTeamMobile: {
            display: 'flex',
            marginBottom: 50
        },
        content: {
            paddingTop: 25
        }
    }
});

