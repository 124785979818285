import * as React from "react"
import Svg, { G, Circle, Path } from "react-native-svg"

function ExpandSvg(props) {
    return (
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            width={35}
            height={35}
            viewBox="0 0 35 35"
            {...props}
        >
            <G data-name="Group 1244" transform="translate(-1239.051 -931)">
                <Circle
                    data-name="Ellipse 65"
                    cx={17.5}
                    cy={17.5}
                    r={17.5}
                    transform="rotate(-90 1102.525 -136.526)"
                    fill="#f3f5fa"
                />
                <Path
                    data-name="Path 499"
                    d="M1256.868 943.217l2.671 2.671 1.188-1.188-3.859-3.868L1253 944.7l1.2 1.188zm0 10.4l-2.671-2.673-1.189 1.188 3.859 3.868 3.869-3.868-1.2-1.188z"
                    fill="#023d83"
                />
            </G>
        </Svg>
    )
}

export default ExpandSvg
