import Http from "../utilities/Http";
import {API_ROOT} from "../config";

export default {
	async getSurveyResponse(surveyResponseCode) {
		let response = await Http().get(API_ROOT + '/surveys/get-survey-response/' + surveyResponseCode);

		return response.data.surveyResponse;
	},
	async updateSurvey(surveyId, survey) {
		let response = await Http().post(API_ROOT + '/surveys/update-survey/' + surveyId, {
			survey
		});

		return response.data.survey;
	},
	async updateSurveyResponse(surveyResponseCode, surveyResponse) {
		let response = await Http().post(API_ROOT + '/surveys/update-survey-response/' + surveyResponseCode, {
				surveyResponse
			});

		return response.data.surveyResponse;
	},
	async getReviewResponse(surveyResponseAnswerId) {
		let response = await Http().get(API_ROOT + '/surveys/get-review-response/' + surveyResponseAnswerId);

		return response.data.surveyReviewResponse;
	},
	async updateReviewResponse(surveyResponseAnswerId, jobId, notificationType, surveyResponseText, userId, isAiGeneratedResponse) {
		let response = await Http().post(API_ROOT + '/surveys/update-review-response/' + surveyResponseAnswerId, {
			jobId,
			notificationType,
			surveyResponseText,
			userId,
			isAiGeneratedResponse,
		});

		return response.data;
	},
	async generateReviewResponse(surveyResponseId) {
		let response = await Http().post(API_ROOT + '/surveys/generate-review-response/' + surveyResponseId);

		return response.data;
	},
};
