import React, {useState, useRef, useEffect} from 'react';
import {View} from 'react-native';
import EStyleSheet from "react-native-extended-stylesheet";

import AppText from "../../../components/AppText";
import MessagesService from "../../../services/MessagesService";
import AppButton from "../../../components/AppButton";
import {basicStyles} from "../../../styles/basic";
import {textStyles} from "../../../styles/text";
import ScreenOverlay from "../../../components/ScreenOverlay";
import ContentSection from "../../../components/ContentSection";
import PhoneInput from "../../../components/PhoneInput";

function CreateNewMessageModal({handleClose, handleSubmit = phoneNumber => phoneNumber}) {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberSmsReady, setPhoneNumberSmsReady] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    return (
        <ScreenOverlay onClick={() => {
            handleClose();
        }}>
            <ContentSection
                style={[styles.contentWrapper]}
                onClick={e => {
                    e.stopPropagation();
                }}
            >
                <AppText style={[textStyles.pageTitle, {marginBottom: 10}]}>
                    Send a Text
                </AppText>
                <PhoneInput
                    ref={inputRef}
                    label="What is the phone number?"
                    onChangeText={value => setPhoneNumber(value)}
                    value={phoneNumber}
                    validateSms={true}
                    validationCallBack={(validated) => {
                        setPhoneNumberSmsReady(validated);
                    }}
                />
                <View style={[basicStyles.flexRow, basicStyles.justifyContentFlexEnd, {marginTop: 20}]}>
                    <AppButton
                        label="Cancel"
                        action={handleClose}
                        style={{marginRight: 10}}
                        theme="transBlue"
                    />
                    <AppButton
                        label="Confirm"
                        action={() => {
                            handleSubmit(phoneNumber);
                        }}
                        style={{marginRight: 10}}
                        disabled={!phoneNumberSmsReady}
                    />
                </View>
            </ContentSection>
        </ScreenOverlay>
    );
}

export default CreateNewMessageModal;

const styles = EStyleSheet.create({
    contentWrapper: {
        padding: 32,
        width: 400,
        maxWidth: '100%'
    }
});
