import * as React from "react"
import Svg, { Path } from "react-native-svg"

function FilterIconSvg(props) {
	return (
		<Svg width={12.75} height={8.5} viewBox="0 0 12.75 8.5" {...props}>
			<Path
				data-name="Path 547"
				d="M0 8.5h4.25V7.083H0zM0 0v1.417h12.75V0zm0 4.958h8.5V3.542H0z"
				fill="#0065db"
			/>
		</Svg>
	)
}

export default FilterIconSvg
