import React, {useState, useEffect} from 'react';
import {
	NavigationHelpersContext,
	useNavigationBuilder,
	TabRouter,
	createNavigatorFactory,
	CommonActions,
} from '@react-navigation/native';
import {View, StyleSheet} from 'react-native';
import AsyncStorage from '@react-native-community/async-storage';

import AppText from './AppText';
import FontelloLink from './FontelloLink';
import SiteIconSvg from './svgs/SiteIconSvg';
import TouchView from './TouchView';
import FontelloIcon from './FontelloIcon';
import {basicStyles} from '../styles/basic';
import AuthService from '../services/AuthService';
import store from '../store';
import {resetState} from '../store/auth/authActions';
import {setWarning} from '../store/warning/warningActions';
import UserOrganizationsService from '../services/UserOrganizationsService';
import SwitchOrganizations from './SwitchOrganizations';
import EStyleSheet from 'react-native-extended-stylesheet';
import MenuIconMobileOpenSvg from './svgs/MenuIconMobileOpenSvg';
import MenuIconMobileCloseSvg from './svgs/MenuIconMobileCloseSvg';
import Avatar from "./Avatar";
import {AVATAR_DEFAULT, AVATAR_PATH_ORG} from "../config";
import {textStyles} from "../styles/text";

function MainNavigator({initialRouteName, children, screenOptions, backBehavior, tabBarStyle, contentStyle}) {
	const {state, navigation, descriptors} = useNavigationBuilder(TabRouter, {
		children,
		screenOptions,
		initialRouteName,
		backBehavior,
	});
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

	let userProfileSettings;

	const logout = async () => {
		await AuthService.logout();
		await AsyncStorage.removeItem('authToken');
		await AsyncStorage.removeItem('organizationId');
		await AsyncStorage.removeItem('roleId');
		await AsyncStorage.removeItem('emulateAuthToken');
		await AsyncStorage.removeItem('emulateOrganizationId');
		await AsyncStorage.removeItem('emulateOrganizationRoleId');
		store.dispatch(resetState());

		navigation.navigate('Unauthenticated', {
			screen: 'Login'
		});
	};

	const warnLogout = async () => {
		setIsMobileMenuOpen(false);
		const userOrganizations = await UserOrganizationsService.getUserOrganizations();
		if(userOrganizations.length > 1) {
			store.dispatch(setWarning({
				confirmAction: () => logout(),
				confirmLabel: 'Sign Out',
				confirmLabel2: 'Switch Accounts',
				rejectLabel: 'Cancel',
				warningMessage: 'Are you sure you want to sign out?',
				active: true,
				confirm2Destroy: false,
				replaceContent: null,
				wrapperStyle: {width: '100%'},
				confirmAction2: () => {
					store.dispatch(setWarning({
						replaceContent: <SwitchOrganizations organizations={userOrganizations} backAction={() => warnLogout()}/>
					}));
				},
			}));
		} else {
			store.dispatch(setWarning({
				confirmAction: () => logout(),
				confirmLabel: 'Sign Out',
				rejectLabel: 'Cancel',
				warningMessage: 'Are you sure you want to sign out?',
				active: true
			}));
		}
	};

	const showHideMobileMenu = async () => {
		setIsMobileMenuOpen(!isMobileMenuOpen);
	};

	useEffect(() => {
		const unsubscribe = navigation.addListener('state', () => {
			setIsMobileMenuOpen(false);
		});

		return unsubscribe;
	}, [navigation]);

	return (
		<NavigationHelpersContext.Provider value={navigation}>
			<View style={styles.navigationWrapper}>
				<View style={[styles.tabsContainer, (isMobileMenuOpen ? null : styles.hide)]}>
					<View style={styles.fluidMenuHeader}>
						<View style={styles.fluidLogo}>
							<SiteIconSvg/>
							<AppText style={styles.fluidLogoText}>FLUID</AppText>
						</View>
						<TouchView
							style={styles.menuIconMobile}
							action={() => showHideMobileMenu()}
						>
							<MenuIconMobileOpenSvg />
						</TouchView>
					</View>
					{
						state.routes.map(route => {
							const routeActive = state.routes[state.index].key === route.key;

							let menuIcon = (
									<FontelloLink
										key={route.key}
										action={CommonActions.navigate(route.name, {
											screen: descriptors[route.key].options.defaultRoute ? descriptors[route.key].options.defaultRoute :route?.state?.routes[0]?.name
										})}
										active={routeActive}
										icon={descriptors[route.key].options.navIcon}
										label={descriptors[route.key].options.headerTitle || route.name}
									/>);

							if(route.name == "UserProfileSettings") {
								userProfileSettings = menuIcon;
							} else {
								return menuIcon;
							}
						})
					}
					<View style={[basicStyles.flexScale, styles.menuBottom]}>
						<TouchView style={styles.userProfileSettings}>
							{
								userProfileSettings
							}
						</TouchView>
						<TouchView action={() => warnLogout()}>
							<FontelloIcon
								name="logout"
								style={styles.logoutIcon}
							/>
							<AppText style={styles.logoutText}>Log Out</AppText>
						</TouchView>
					</View>
				</View>
				<View style={styles.pageWrapper}>
					{ descriptors[state.routes[state.index].key].options.hideHeader ? null :
					<View style={styles.header}>
						<AppText style={styles.headerTitle}>
							{descriptors[state.routes[state.index].key].options.organization && (
								<>
									<Avatar
										source={{uri: descriptors[state.routes[state.index].key].options.organization.organization_thumbnail ? AVATAR_PATH_ORG + descriptors[state.routes[state.index].key].options.organization.organization_thumbnail : AVATAR_DEFAULT}}
										width={45}
										style={{marginRight: 10}}
									/>
										{descriptors[state.routes[state.index].key].options.organization.organization_title + ' '}
								</>
							)}
							{descriptors[state.routes[state.index].key].options.headerTitle || state.routes[state.index].name}
						</AppText>
						<TouchView
							style={styles.menuIconMobile}
							action={() => showHideMobileMenu()}
						>
							<MenuIconMobileCloseSvg />
						</TouchView>
					</View>
					}
					<View style={styles.pageWrapper}>
						{descriptors[state.routes[state.index].key].render()}
					</View>
				</View>
			</View>
		</NavigationHelpersContext.Provider>
	);
}


export const createMainNavigator = createNavigatorFactory(MainNavigator);

const styles = EStyleSheet.create({
	navigationWrapper: {
		flex: 1,
		paddingLeft: 80,
	},
	tabsContainer: {
		paddingTop: 23,
		width: 80,
		position: 'absolute',
		left: 0,
		top: 0,
		bottom: 0,
		alignItems: 'center',
		borderRightWidth: 1,
		borderRightColor: '#E2E9F4',
		zIndex: 1,
		elevation: 1,
	},
	fluidMenuHeader: {
		marginBottom: 25
	},
	fluidLogoText: {
		display: 'none'
	},
	pageWrapper: {
		flex: 1,
		backgroundColor: '#F8FAFD',
	},
	pageTitle: {
		fontSize: 20,
	},
	header: {
		backgroundColor: '#467AFF',
		width: '100%',
		height: 75,
		justifyContent: 'center',
		paddingLeft: 45,
	},
	headerTitle: {
		fontSize: 20,
		color: '#FFFFFF',
		fontFamily: 'SourceSansPro-Light',
		display: 'flex',
		alignItems: 'center',
	},
	menuBottom: {
		justifyContent: 'flex-end',
		alignItems: 'center',
		paddingBottom: 30,
	},
	userProfileSettings: {
		paddingBottom: 20
	},
	logoutIcon: {
		fontSize: 20,
		color: '#D9D9D9',
	},
	logoutText: {
		display: 'none'
	},
	menuIconMobile: {
		display: 'none'
	},
	'@media (max-width: 630)': {
		header: {
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
			paddingRight: 45
		},
		menuIconMobile: {
			display: 'flex'
		},
		navigationWrapper: {
			paddingLeft: 0,
		},
		tabsContainer: {
			//display: 'none'
			right: 0,
			left: 'auto',
			width: 300,
			alignItems: 'flex-start',
			backgroundColor: '#FFF',
			shadowColor: '#000',
			shadowOffset: {
				width: 0,
				height: 3,
			},
			shadowOpacity: 0.1,
			shadowRadius: 9.11,
		},
		hide: {
			display: 'none'
		},
		fluidLogo: {
			flexDirection: 'row',
			alignItem: 'center'
		},
		fluidLogoText: {
			display: 'flex',
			alignItems: 'center',
			paddingLeft: 10
		},
		fluidMenuHeader: {
			flexDirection: 'row',
			justifyContent: 'space-between',
			width: '100%',
			paddingLeft: 25,
			paddingRight: 30,
			paddingBottom: 20,
			marginBottom: 0,
			borderColor: '#E2E9F4',
			borderBottomWidth: 1
		},
		menuBottom: {
			width: '100%',
			paddingLeft: 0,
			paddingRight: 0
		},
		userProfileSettings: {
			width: '100%',
			borderTopWidth: 1,
			borderColor: '#E2E9F4'
		},
		logoutIcon: {
			display: 'none'
		},
		logoutText: {
			display: 'flex'
		},
	},
});
