import React, {useCallback, useState, useEffect, useRef, useMemo, useLayoutEffect} from 'react';
import {View, StyleSheet, ScrollView, TextInput} from 'react-native';
import {CommonActions} from '@react-navigation/native';
import {useDebouncedCallback} from 'use-debounce';
import {connect, useDispatch} from 'react-redux';
import {bindActionCreators} from 'redux';
import {CancelToken} from "axios";

import AppText from '../../components/AppText';
import {basicStyles} from '../../styles/basic';
import BackBar from '../../components/BackBar';
import PageTop from '../../components/PageTop';
import {textStyles} from '../../styles/text';
import JobsService from '../../services/JobsService';
import PageContentContainer from '../../components/PageContentContainer';
import FieldSet from '../../components/FieldSet';
import TouchView from '../../components/TouchView';
import ContentSection from '../../components/ContentSection';
import {formatDate, getDate, getJobSurveyStatus, getUserDisplayName, hexToRgb} from '../../utilities/methods';
import RemoveSvg from '../../components/svgs/RemoveSvg';
import AppButton from '../../components/AppButton';
import AppPickerInput from '../../components/AppPickerInput';
import {FLUID_ADMIN_ROLE_ID, JOB_STATUS_COLORS, JOB_STATUS_COMPLETED_ID} from '../../config';
import {setWarning} from '../../store/warning/warningActions';
import {setIsJobSinglePage} from '../../store/miscellaneous/miscellaneousActions';
import UsersService from '../../services/UsersService';
import MailSvg from '../../components/svgs/MailSvg';
import EStyleSheet from 'react-native-extended-stylesheet';
import ChevronHorizontalBlackSvg from "../../components/svgs/ChevronHorizontalBlackSvg";
import SurveysService from "../../services/SurveysService";
import store from "../../store";
import {setReviewResponse} from "../../store/reviewResponse/reviewResponseActions";

function JobSingle(props) {
    const [jobState, setJobState] = useState({
        job: {
            jobParts: [],
            job_status_id: 1,
            user_id: props.auth.permissions.includes('manage_account_jobs') ? null : props.auth.user.user_id,
            jobNumberJobNotes: []
        },
        updatingJob: false,
    });
    const isFluidAdmin = props.auth.organizationRoleId === FLUID_ADMIN_ROLE_ID;
    const [jobStatuses, setJobStatuses] = useState([]);
    const [users, setUsers] = useState([]);
    const {setWarning} = props;
    const [fieldSetChunkSize, setFieldSetChunkSize] = useState(4);
    const [surveyButtonChunkSize, setSurveyButtonChunkSize] = useState(3);
    const [isMobile, setIsMobile] = useState(false);
    const [isTablet, setIsTablet] = useState(false);
    const firstUpdate = useRef(true);
    const fieldSaveRequestSource = useRef(null);

    const organizationId = useMemo(() => {
        return props.route.params?.organizationId || props.auth.organization.organization_id;
    }, [props.route.params, props.auth.organization.organization_id]);

    const statusRgb = hexToRgb(JOB_STATUS_COLORS[getJobStatusToken(jobState.job.job_status_id)]);

    const SendSurveyButton = ({resend = false}) => {
        return <AppButton
            label={resend ? 'Resend' : 'Send Survey'}
            theme="green"
            icon={<MailSvg fill="#FFFFFF"/>}
            large={true}
            action={() => warnSendSurvey(resend)}
        />;
    };

    useLayoutEffect(() => {
        props.setIsJobSinglePage(true);

        return () => {
            props.setIsJobSinglePage(false);
        }
    }, []);

    let surveyStatus = getJobSurveyStatus(jobState.job);

    const handleSaveJob = useCallback(
        async (job) => {

        // cancel any pending request
        //note: assigning this to a variable because I can't figure out why it's throwing an eslint error about unused expression
        let derp = fieldSaveRequestSource.current?.cancel();
        // create new cancel token
        fieldSaveRequestSource.current = CancelToken.source();

        return await JobsService.updateJob(
            organizationId,
            props.route.params.id,
            job,
            fieldSaveRequestSource.current?.token
        );
    }, []);

    const [updateJobDebounce] = useDebouncedCallback(async () => {
        if (props.route?.params?.id) {

            let jobData = await handleSaveJob(jobState.job);
            setJobState(prevJobState => {
                return {
                    ...prevJobState,
                    job: jobData,
                    updatingJob: true,
                };
            });
        }
    }, 0);

    function updateJob(prop, value) {
        setJobState(prevJobState => {
            return {...prevJobState, job: {...prevJobState.job, [prop]: value}};
        });
    }

    function updateSurveyAnswer(prop, value) {
        const answers = (jobState.job.surveyResponse?.surveyAnswers || [])
            .map(answer => {
                if (answer.survey_question_token === prop) {
                    return {
                        ...answer,
                        survey_response_answer_text: value
                    };
                }

                return answer;
            });

        setJobState(prevJobState => ({
            ...prevJobState,
            job: {
                ...prevJobState.job,
                surveyResponse: {
                    ...prevJobState.job.surveyResponse,
                    [prop]: value,
                    surveyAnswers: answers
                }
            }
        }));
    }

    function updateJobPart(prop, value, index) {
        setJobState(prevJobState => {
            let jobData = {...prevJobState.job};
            jobData.jobParts[index][prop] = value;
            return {
                ...prevJobState,
                job: jobData,
            };
        });
    }

    function addJobPart() {
        setJobState(prevJobState => {
            let jobData = {...prevJobState.job};
            return {
                ...prevJobState,
                job: {
                    ...jobData,
                    jobParts: [...jobData.jobParts, {}],
                },
            };
        });
    }

    function removeJobPart(index) {
        setJobState(prevJobState => {
            let jobData = {...prevJobState.job};
            jobData.jobParts.splice(index, 1);
            return {
                ...prevJobState,
                job: jobData,
            };
        });
    }

    async function createNewJob() {
        const {navigate} = props.navigation;
        const jobData = await JobsService.insertJob(organizationId, jobState.job);
        navigate('JobIndexEditNavigation', {
            screen: 'JobEdit',
            params: {
                id: jobData.job_id,
            },
        });
    }

    function warnUpdateJobStatus(jobStatusId) {
        if (jobStatusId == JOB_STATUS_COMPLETED_ID && surveyStatus === 'Not Sent') {
            setWarning({
                confirmAction: () => {
                    updateJob('job_status_id', jobStatusId);
                    sendSurvey();
                },
                rejectionAction: () => updateJob('job_status_id', jobStatusId),
                confirmLabel: 'Send Survey Now',
                rejectLabel: 'Send Survey Later',
                warningMessage: 'Would you like to send the survey now that the job is complete?',
                active: true,
            });
        } else {
            updateJob('job_status_id', jobStatusId);
        }
    }

    function getJobParts(jobPart, index) {
        return (
            <View style={[styles.tableRow]} key={index}>
                <View style={[styles.columnSmall, styles.partsColumn1]}>
                    <TextInput
                        style={[
                            styles.jobPartInput,
                            ((jobPart.job_part_number || '').length ? {} : styles.inputEmpty),
                        ]}
                        value={jobPart.job_part_number || ''}
                        onChangeText={value => updateJobPart('job_part_number', value, index)}
                    />
                </View>
                <View style={[styles.columnLarge, styles.partsColumn2]}>
                    <TextInput
                        style={[
                            styles.jobPartInput,
                            ((jobPart.job_part_description || '').length ? {} : styles.inputEmpty),
                        ]}
                        value={jobPart.job_part_description || ''}
                        onChangeText={value => updateJobPart('job_part_description', value, index)}
                    />
                </View>
                <View style={[
                    styles.columnSmall,
                    styles.partsColumn3
                ]}>
                    <TextInput
                        style={[
                            styles.jobPartInput,
                            ((jobPart.job_part_quantity || '').length ? {} : styles.inputEmpty),
                            {marginRight: 10},
                        ]}
                        value={jobPart.job_part_quantity || ''}
                        onChangeText={value => updateJobPart('job_part_quantity', value, index)}
                    />
                    <TouchView action={() => removeJobPart(index)}>
                        <RemoveSvg/>
                    </TouchView>
                </View>
            </View>
        );
    }

    function getMobileJobParts(jobPart, index) {
        return (
            <View style={[styles.tableRow, styles.mobileJobPartsWrapper]} key={index}>
                <View style={{width: '100%', flexDirection: 'row'}}>
                    <View style={[styles.column1]}>
                        <AppText style={[styles.columnHead]}>Part Number</AppText>
                        <TextInput
                            style={[
                                styles.jobPartInput,
                                ((jobPart.job_part_number || '').length ? {} : styles.inputEmpty),
                            ]}
                            value={jobPart.job_part_number || ''}
                            onChangeText={value => updateJobPart('job_part_number', value, index)}
                        />
                    </View>
                    <View style={[styles.column2]}>
                        <View>
                            <AppText style={[styles.columnHead]}>Quantity</AppText>
                            <TextInput
                                style={[
                                    styles.jobPartInput,
                                    ((jobPart.job_part_quantity || '').length ? {} : styles.inputEmpty),
                                    {marginRight: 10},
                                ]}
                                value={jobPart.job_part_quantity || ''}
                                onChangeText={value => updateJobPart('job_part_quantity', value, index)}
                            />
                        </View>
                    </View>
                    <TouchView action={() => removeJobPart(index)} style={[styles.column3]}>
                        <View>
                            <AppText style={[styles.columnHead]}>Remove</AppText>
                            <RemoveSvg/>
                        </View>
                    </TouchView>
                </View>
                <View style={[
                    styles.columnLarge,
                    styles.mobilePartDescription
                ]}>
                    <View>
                        <AppText style={[styles.columnHead]}>Part Description</AppText>
                        <TextInput
                            style={[
                                styles.jobPartInput,
                                ((jobPart.job_part_description || '').length ? {} : styles.inputEmpty),
                            ]}
                            value={jobPart.job_part_description || ''}
                            onChangeText={value => updateJobPart('job_part_description', value, index)}
                        />
                    </View>
                </View>
            </View>
        );
    }

    function getJobStatusToken(jobStatusId) {
        const jobStatus = jobStatuses.find(jobStatus => {
            return jobStatus.job_status_id === jobStatusId;
        });

        if (jobStatus) {
            return jobStatus.job_status_token;
        }

        return null;
    }

    async function sendSurvey(resend = false) {
        const jobData = await JobsService.sendJobSurvey(
            organizationId,
            props.route.params.id,
            resend
        );

        setJobState(prevJobState => {
            return {...prevJobState, job: jobData};
        });

        setWarning({
            confirmAction() {
            },
            confirmLabel: 'Continue',
            active: true,
            warningMessage: 'Survey Sent Successfully!'
        });
    }

    async function removeSurveyResponse() {
        await JobsService.removeSurveyResponse(jobState.job.surveyResponse.survey_response_id);
        setJobState(prevJobState => ({
            ...prevJobState,
            job: {
                ...prevJobState.job,
                surveyResponse: null,
            }
        }));
    }

    async function warnSendSurvey(resend = false) {
        setWarning({
            confirmAction: async () => {
                sendSurvey(resend);
            },
            confirmLabel: 'Send Now',
            rejectLabel: 'Send Later',
            warningMessage: 'Are you sure you want to send the survey now?',
            active: true
        });
    }

    const handleLayout = ({nativeEvent}) => {
        const {width} = nativeEvent.layout;

        if (width > 920) {
            setFieldSetChunkSize(4);
            setSurveyButtonChunkSize(3);
        } else if (width > 688) {
            setFieldSetChunkSize(2);
            setSurveyButtonChunkSize(2);
            setIsTablet(false);
        } else {
            setFieldSetChunkSize(1);
            setSurveyButtonChunkSize(1);
            setIsTablet(true);
        }

        if (width > 450) {
            setIsMobile(false);
        } else {
            setIsMobile(true);
        }
    };

    async function reviewResponseCallback (data) {
        var surveyResponseAnswer = data.surveyAnswers.find(el => el.survey_question_token = 'review' && el.survey_question_type_token == 'text-long');

        if(!surveyResponseAnswer?.survey_response_answer_id) {
            return;
        }

        var surveyReviewResponse = await SurveysService.getReviewResponse(surveyResponseAnswer.survey_response_answer_id);

        store.dispatch(setReviewResponse({
            active: true,
            review: data.review,
            response: (surveyReviewResponse?.survey_review_response_text ? surveyReviewResponse.survey_review_response_text : ''),
            isAiGeneratedResponse: (surveyReviewResponse?.survey_review_response_text ? surveyReviewResponse.survey_review_response_ai_generated === '1' : false),
            userId: data.user_id,
            reviewerName: data['screen-name'],
            surveyResponseId: data.survey_response_id,
            surveyResponseAnswerId: surveyResponseAnswer.survey_response_answer_id,
            organizationTitle: props.auth?.organization?.organization_title,
            jobId: data.job_id,
            jobCustomerEmail: data.job_customer_email,
            jobCustomerPhone: data.job_customer_phone,
            allowAiGeneratedResponse: data.organization_allow_ai_automatic_review_response === '1'
        }));
    }

    useEffect(() => {
        if (props.route?.params?.id) {
            const getJob = async () => {
                let jobData = await JobsService.getJob(organizationId, props.route.params.id);
                setJobState(prevJobState => {
                    return {...prevJobState, job: {...prevJobState.job, ...jobData}};
                });
            };

            getJob();
        }
        const getJobStatuses = async () => {
            let jobStatusesData = await JobsService.getJobStatuses(organizationId);
            setJobStatuses(jobStatusesData);
        };
        const getUsers = async () => {
            let usersData = await UsersService.getUsers(organizationId);
            setUsers(usersData);
        };
        getJobStatuses();
        getUsers();

    }, [props.route?.params?.id, organizationId]);

    useEffect(() => {
        if(props.route.params?.review == 'true' && jobState.job?.surveyResponse) {
            //React native is persisting the route params, this is causing other jobs to display their reviews
            //Set the review to false so it isn't persisted
            props.route.params.review = 'false';
            reviewResponseCallback(jobState.job?.surveyResponse)
        }

        if (firstUpdate.current) {
            if (Object.keys(jobState.job).length > 4) { //Wait till job initialized, check if it has more than default props
                firstUpdate.current = false;
            }
            return;
        }

        if (jobState.updatingJob) {
            setJobState(prevJobState => {
                return {...prevJobState, updatingJob: false};
            });
            return;
        }

        updateJobDebounce();
    }, [jobState.job, updateJobDebounce]);

    const getSurveyActionButton = useCallback((job) => {
        if (!job.surveyResponse && !job.scheduled_survey_response_id) {
            return <SendSurveyButton />
        }

        if (job.surveyResponse?.survey_response_text_error_timestamp) {
            return <SendSurveyButton resend={true} />
        }

        return null;
    }, []);

    const backAction = useMemo(() => {
        if (props.route.params?.organizationId) {
            // back to admin client jobs list
            return CommonActions.navigate('Clients', {
                screen: 'ClientPartnerSingleNavigation',
                params: {
                    organizationId,
                    screen: 'JobIndexEditNavigation',
                    params: {
                        screen: 'JobsList',
                    }
                },
            })
        } else {
            // back to jobs list
            return CommonActions.navigate('Jobs', {
                screen: 'JobIndexEditNavigation',
                params: {
                    screen: 'JobsList',
                },
            })
        }

    }, []);

    return (
        <View style={[basicStyles.flexScale]} onLayout={handleLayout}>
            <BackBar
                action={backAction}
                label="Back To All Jobs"
            />
            <ScrollView contentContainerStyle={basicStyles.flexScale}>
                <PageTop style={[
                    basicStyles.flexRow,
                    basicStyles.alignContentCenter,
                    basicStyles.justifyContentSpaceBetween,
                    (jobState.job.job_status_id ? {
                        borderBottomColor: JOB_STATUS_COLORS[getJobStatusToken(jobState.job.job_status_id)],
                    } : {}),
                ]}>
                    <AppText style={[textStyles.pageTitle, textStyles.semiBold]}>
                        {
                            jobState.job.job_id ?
                                <>
                                    Job ID - <AppText style={textStyles.light}>{jobState.job.job_number}</AppText>
                                </>
                                : 'Create New Job'
                        }
                    </AppText>
                    <AppPickerInput
                        wrapperStyle={{
                            width: 160,
                            ...(jobState.job.job_status_id ? {
                                backgroundColor: `rgba(${statusRgb?.r}, ${statusRgb?.g}, ${statusRgb?.b}, .15)`,
                                borderColor: JOB_STATUS_COLORS[getJobStatusToken(jobState.job.job_status_id)],
                            } : {}),
                            ...(isMobile ? {display: 'none'} : {})
                        }}
                        label="Job Status"
                        onValueChange={warnUpdateJobStatus}
                        selectedValue={jobState.job.job_status_id}
                        items={jobStatuses}
                        labelExtractor="job_status_title"
                        valueExtractor="job_status_id"
                        statusColor={JOB_STATUS_COLORS[getJobStatusToken(jobState.job.job_status_id)]}
                        showLabel={false}
                        arrowBordered={false}
                    />
                </PageTop>
                <PageContentContainer>
                    <AppText style={[textStyles.pageSubTitle, textStyles.semiBold]}>
                        Overview
                    </AppText>
                    <FieldSet
                        style={{marginBottom: 50}}
                        chunkSize={fieldSetChunkSize}
                        fields={[
                            {
                                type: 'date',
                                label: 'Date Created',
                                value: jobState.job.job_timestamp || getDate(),
                                editable: false,
                            },
                            {
                                type: 'text',
                                label: 'Job ID',
                                onChangeText: value => updateJob('job_number', value),
                                value: jobState.job.job_number || '',
                            },
                            {
                                type: 'picker',
                                label: 'Team Member',
                                onValueChange: value => updateJob('user_id', value),
                                selectedValue: jobState.job.user_id || '',
                                items: users,
                                labelExtractor: (job) => getUserDisplayName(job),
                                valueExtractor: 'user_id',
                                disabled: !props.auth.permissions.includes('manage_account_jobs')
                            },
                            {
                                type: 'text',
                                label: 'Product Type',
                                onChangeText: value => updateJob('job_product_type', value),
                                value: jobState.job.job_product_type || '',
                            },
                            {
                                type: 'text',
                                label: 'Brand',
                                onChangeText: value => updateJob('job_product_brand', value),
                                value: jobState.job.job_product_brand || '',
                            },
                            {
                                type: 'text',
                                label: 'Product Model Number',
                                onChangeText: value => updateJob('job_product_model_number', value),
                                value: jobState.job.job_product_model_number || '',
                            },
                            {
                                type: 'text',
                                label: 'Customer Name',
                                onChangeText: value => updateJob('job_customer_name', value),
                                value: jobState.job.job_customer_name || '',
                            },
                            {
                                type: 'text',
                                label: 'Customer Email',
                                onChangeText: value => updateJob('job_customer_email', value),
                                value: jobState.job.job_customer_email || '',
                            },
                            {
                                type: 'phone',
                                label: 'Customer Phone',
                                onChangeText: value => updateJob('job_customer_phone', value),
                                value: jobState.job.job_customer_phone || '',
                                style: styles.customerPhone
                            },
                            {
                                type: 'address',
                                label: 'Customer Address',
                                onChangeAddress: value => updateJob('job_customer_address', value),
                                value: jobState.job.job_customer_address || '',
                                width: '75%',
                                style: styles.customerAddress
                            },
                        ]}
                    />
                    <AppText style={[textStyles.pageSubTitle, textStyles.semiBold]}>
                        Parts
                    </AppText>
                    <View style={[styles.tableWrapper]}>
                        <View style={[styles.tableRow, styles.columnHeadWrapper]}>
                            <AppText style={[styles.columnSmall, styles.columnHead]}>
                                Part Number
                            </AppText>
                            <AppText style={[styles.columnLarge, styles.columnHead]}>
                                Part Description
                            </AppText>
                            <AppText style={[styles.columnSmall, styles.columnHead]}>
                                Quantity
                            </AppText>
                        </View>
                        {
                            jobState.job.jobParts.map((jobPart, index) => {

                                return isTablet ? getMobileJobParts(jobPart, index) : getJobParts(jobPart, index);
                            })
                        }
                        <View style={{padding: 40}}>
                            <TouchView
                                style={[basicStyles.button, basicStyles.uploadButton]}
                                action={() => addJobPart()}
                            >
                                <AppText style={[textStyles.buttonText]}>
                                    Add Part +
                                </AppText>
                            </TouchView>
                        </View>
                    </View>
                    <AppText style={[textStyles.pageSubTitle, textStyles.semiBold]}>
                        Customer Notes
                    </AppText>
                    <FieldSet
                        style={{marginBottom: 50}}
                        chunkSize={1}
                        fields={[
                            {
                                type: 'text',
                                label: '',
                                value: jobState.job.job_customer_notes || '',
                                onChangeText: value => updateJob('job_customer_notes', value),
                                placeholder: "Enter Note"
                            }
                        ]}
                    />
                    <AppText style={[textStyles.pageSubTitle, textStyles.semiBold]}>
                        Job Notes
                    </AppText>
                    <View style={{marginBottom: 50}}>
                        <FieldSet
                            style={{marginBottom: 10}}
                            chunkSize={1}
                            fields={[
                                {
                                    type: 'text',
                                    label: '',
                                    value: jobState.job.job_notes || '',
                                    onChangeText: value => updateJob('job_notes', value),
                                    placeholder: "Enter Note"
                                }
                            ]}
                        />
                        {
                            jobState.job.jobNumberJobNotes.map(jobNote => {
                                if (jobNote.job_notes && jobNote.job_id != jobState.job.job_id) {
                                    return (
                                        <ContentSection
                                            style={{padding: 40, justifyContent: 'center', marginBottom: 10}}
                                            key={jobNote.job_id}
                                        >
                                            <TextInput
                                                value={formatDate(jobNote.job_timestamp) + ' - ' + jobNote.job_notes}
                                                disabled={true}
                                            />
                                        </ContentSection>
                                    );
                                }
                            })
                        }
                    </View>
                    {
                        jobState.job.job_id ?
                            <>
                                <AppText style={[textStyles.pageSubTitle, textStyles.semiBold]}>
                                    Survey
                                </AppText>
                                <FieldSet
                                    chunkSize={1}
                                    fields={[{
                                        type: 'text',
                                        label: 'Status',
                                        disabled: true,
                                        value: surveyStatus,
                                        actionButton: getSurveyActionButton(jobState.job),
                                    }]}
                                />
                                {
                                    jobState.job?.surveyResponse?.surveyAnswers.length ?
                                        <>
                                            <View style={styles.surveyResponseHeaderContainer}>
                                                <AppText style={[textStyles.pageSubTitle, textStyles.semiBold, {marginTop: 30}]}>
                                                    Survey Response
                                                </AppText>
                                                {isFluidAdmin && (
                                                    <AppButton
                                                        style={styles.deleteSurveyResponseButton}
                                                        theme="transRed"
                                                        action={() => {
                                                            setWarning({
                                                                confirmAction: removeSurveyResponse,
                                                                confirmLabel: 'Remove Survey Response',
                                                                rejectLabel: 'Cancel',
                                                                warningMessage: 'Are you sure you want to remove this survey response?',
                                                                active: true,
                                                            });
                                                        }}
                                                        label="Remove Survey Response"
                                                    />
                                                )}
                                            </View>
                                            <FieldSet
                                                chunkSize={2}
                                                fields={[
                                                    {
                                                        type: 'text',
                                                        label: 'Screen Name',
                                                        disabled: !isFluidAdmin,
                                                        value: (jobState.job?.surveyResponse ? jobState.job?.surveyResponse['screen-name'] : ''),
                                                        actionButton: null,
                                                        onChangeText: value => updateSurveyAnswer('screen-name', value)
                                                    },
                                                    {
                                                        type: 'range',
                                                        label: 'Overall Rating',
                                                        disabled: !isFluidAdmin,
                                                        value: (jobState.job?.surveyResponse ? jobState.job?.surveyResponse['overall-rating'] : ''),
                                                        actionButton: null,
                                                        onChange: value => updateSurveyAnswer('overall-rating', value),
                                                    },
                                                    {
                                                        type: 'text',
                                                        label: 'Review',
                                                        disabled: !isFluidAdmin,
                                                        value: jobState.job?.surveyResponse?.review,
                                                        actionButton: null,
                                                        onChangeText: value => updateSurveyAnswer('review', value)
                                                    },
                                                    {
                                                        type: 'text',
                                                        label: 'Respond to review',
                                                        disabled: true,
                                                        value: '',
                                                        actionButton: (
                                                            jobState.job?.surveyResponse?.surveyAnswers.length ?
                                                                <AppButton
                                                                    action={() => {
                                                                        reviewResponseCallback(jobState.job?.surveyResponse)
                                                                    }}
                                                                    style={[
                                                                        {
                                                                            backgroundColor: '#0065DB',
                                                                            padding: 10,
                                                                            borderRadius: 5,
                                                                        },
                                                                        basicStyles.flexRow,
                                                                        basicStyles.flexCenterContent,
                                                                    ]}
                                                                >
                                                                    <AppText style={{
                                                                        color: '#FFFFFF',
                                                                        marginRight: 10,
                                                                    }}>
                                                                        Respond
                                                                    </AppText>
                                                                    <ChevronHorizontalBlackSvg fill="#FFFFFF" direction="right"/>
                                                                </AppButton>
                                                                :
                                                                null
                                                        )
                                                    }
                                                ]}
                                            />
                                        </>
                                        :
                                        null
                                }
                            </>
                            : null
                    }
                    {
                        !jobState.job.job_id ?
                            <AppButton
                                label="Submit Job"
                                action={() => createNewJob()}
                                style={styles.surveySubmitButton}
                            />
                            : null
                    }
                </PageContentContainer>
            </ScrollView>
        </View>
    );
}

const mapStateToProps = (state) => {
    const {auth} = state;
    return {auth};
};

const mapDispatchToProps = (dispatch) => (
    bindActionCreators({
        setWarning,
        setIsJobSinglePage,
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(JobSingle);

const styles = EStyleSheet.create({
    tableWrapper: {
        borderWidth: 1,
        borderRadius: 5,
        borderColor: '#E2E9F4',
        backgroundColor: '#FFFFFF',
        marginBottom: 50,
    },
    tableRow: {
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 30,
        paddingRight: 30,
        flexDirection: 'row',
        alignItems: 'center',
        borderBottomWidth: 1,
        borderColor: '#E2E9F4',
    },
    columnSmall: {
        width: '20%',
    },
    columnLarge: {
        width: '60%',
    },
    columnHead: {
        fontSize: 12,
        color: '#9BA8CA',
    },
    jobPartInput: {
        width: '100%',
    },
    inputEmpty: {
        backgroundColor: 'rgba(0,0,0, .05)',
    },
    surveySubmitButton: {
        width: 145, alignSelf: 'center', marginTop: 50, height: 50
    },
    partsColumn1: {
        paddingRight: 20
    },
    partsColumn2: {
        paddingRight: 20
    },
    partsColumn3: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    '@media (max-width: 1000)': {
        customerPhone: {
            width: '100%',
            marginBottom: 18
        },
        customerAddress: {
            width: '100%'
        },
    },
    '@media (max-width: 768)': {
        columnSmall: {
            width: '33.333%'
        },
        columnLarge: {
            width: '100%'
        },
        surveySubmitButton: {
            width: '100%'
        },
        columnHeadWrapper: {
            display: 'none'
        },
        mobileJobPartsWrapper: {
            flexDirection: 'column'
        },
        mobilePartDescription: {
            paddingTop: 20
        },
        column1: {
            width: '50%'
        },
        column2: {
            width: '25%',

        },
        column3: {
            width: '25%',
            alignItems: 'flex-end'
        },
        columnHead: {
            paddingBottom: 10
        }
    },
    surveyResponseHeaderContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    deleteSurveyResponseButton: {
        marginLeft: 'auto',
        marginTop: 8,
        height: 32,
    }
});
