import * as React from "react"
import Svg, { G, Circle, Path } from "react-native-svg"

function CollapseSvg(props) {
    return (
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            width={35}
            height={35}
            viewBox="0 0 35 35"
            {...props}
        >
            <G data-name="Group 1125" transform="translate(-1239 -427)">
                <Circle
                    data-name="Ellipse 2"
                    cx={17.5}
                    cy={17.5}
                    r={17.5}
                    transform="rotate(-90 850.5 -388.5)"
                    fill="#f3f5fa"
                />
                <Path
                    data-name="Path 500"
                    d="M1253 449.466l1.165 1.156 2.6-2.6 2.6 2.6 1.156-1.156-3.757-3.766zm7.529-10.81l-1.165-1.156-2.6 2.6-2.599-2.6-1.165 1.156 3.764 3.764z"
                    fill="#023d83"
                />
            </G>
        </Svg>
    )
}

export default CollapseSvg
