import * as React from "react"
import Svg, { Path } from "react-native-svg"

function EyeSvg({fill = '#0065db', ...props}) {
	return (
		<Svg width={12.232} height={8.34} viewBox="0 0 12.232 8.34" {...props}>
			<Path
				data-name="Path 550"
				d="M6.116 0A6.576 6.576 0 000 4.17a6.57 6.57 0 0012.232 0A6.576 6.576 0 006.116 0zm0 6.95A2.78 2.78 0 118.9 4.17a2.781 2.781 0 01-2.784 2.78zm0-4.45a1.668 1.668 0 101.668 1.67A1.666 1.666 0 006.116 2.5z"
				fill={fill}
			/>
		</Svg>
	)
}

export default EyeSvg
