import React from 'react';
import {View} from 'react-native';

import {createSubNavigator} from '../../components/SubNavigator';
import TeamMemberIndexEditNavigation from './TeamMemberIndexEditNavigation';
import TeamMemberAddNavigation from './TeamMemberAddNavigation';

const SubNavigator = createSubNavigator();

function TeamMembers(props) {
    return (
        <View style={{flex: 1}}>
            <SubNavigator.Navigator backBehavior="history" initialRouteName="TeamMemberIndexEditNavigation">
                <SubNavigator.Screen
                    name="TeamMemberIndexEditNavigation"
                    component={TeamMemberIndexEditNavigation}
                    options={{
                        title: 'Team Members'
                    }}
                />
                <SubNavigator.Screen
                    name="TeamMemberAddNavigation"
                    component={TeamMemberAddNavigation}
                    options={{
                        title: 'Invite Member'
                    }}
                />
            </SubNavigator.Navigator>
        </View>
    );
}


export default TeamMembers;
