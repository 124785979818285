import {warningActionTypes} from './warningActions';

const initialState = {
	confirmAction: () => {},
	confirmAction2: null,
	rejectionAction: () => {},
	confirmLabel: null,
	confirmLabel2: null,
	rejectLabel: null,
	confirmParams: [],
	rejectParams: [],
	actionParent: null,
	warningMessage: null,
	warningSubMessage: null,
	active: false,
	indestructible: false,
	confirmDestroy: true,
	confirm2Destroy: true,
	rejectDestroy: true,
	wrapperStyle: {},
};

const warningReducer = (state = initialState, action) => {
	switch (action.type) {
		case warningActionTypes.SET_WARNING:
			return Object.assign({}, state, action.payload);
		case warningActionTypes.DESTROY_WARNING:
			return Object.assign({}, initialState);
		case warningActionTypes.SET_INDESTRUCTIBLE:
			return Object.assign({}, state, {indestructible: action.payload});
		default:
			return state;
	}
};

export default warningReducer;
