import React, {createRef} from 'react';
import {View} from 'react-native';
import {TextInputMask} from 'react-native-masked-text';

import AppText from './AppText';
import {inputStyles} from '../styles/input';

function NumericInput(
	{
		label,
		onChangeText,
		onSubmitEditing,
		value = '',
		blurOnSubmit = false,
		autoCapitalize = 'none',
		returnKeyType = 'next',
		outline = false,
		secureTextEntry = false,
		wrapperStyle = {},
		labelStyle = {},
		inputStyle = {},
		...rest
	},
) {
	let inputRef = createRef();
	return (
		<View style={[inputStyles.inputWrapper, wrapperStyle]}
		      onClick={() => inputRef.focus()}
		      onPress={() => inputRef.focus()}
		>
			<AppText style={[inputStyles.inputLabel, labelStyle]}>
				{label}
			</AppText>
			<TextInputMask
				refInput={(ref) => {
					inputRef = ref
				}}
				value={value}
				style={[inputStyles.input, inputStyle]}
				blurOnSubmit={blurOnSubmit}
				autoCapitalize={autoCapitalize}
				returnKeyType={returnKeyType}
				onChangeText={onChangeText}
				onSubmitEditing={onSubmitEditing} // where something like () => secondInput.focus() goes
				outline={outline}
				secureTextEntry={secureTextEntry}
				keyboardType={undefined}
				{...rest}
				type={'only-numbers'}
			/>
		</View>
	);
};

export default NumericInput;
