import React from 'react';
import {View} from 'react-native';

import ContentSection from './ContentSection';
import {basicStyles} from '../styles/basic';
import AppTextInput from './AppTextInput';
import AppPickerInput from './AppPickerInput';
import AddressInput from './AddressInput';
import CopyField from './CopyField';
import DownloadField from './DownloadField';
import PhoneInput from './PhoneInput';
import NumericInput from './NumericInput';
import MoneyInput from "./MoneyInput";
import StarRangeInputField from "./StarRangeInputField";
import ToggleField from "./ToggleField";
import ButtonField from './ButtonField';

function FieldSet({fields, chunkSize = 4, style = {}, header = null, footer = null}) {
	const chunkFields = (fieldsArray) => {
		return Array.from({length: Math.ceil(fieldsArray.length / chunkSize)}, (v, i) => {
			return fieldsArray.slice(i * chunkSize, i * chunkSize + chunkSize);
		});
	};

	const fieldChunks = chunkFields(fields);

	let fieldIndex = -1;

	return (
		<ContentSection style={[
			basicStyles.inputContainerPaddingVertical,
			{
				...(header ? {paddingTop: 0} : {})
			},
			style,
		]}>
			{
				header ?
					<View style={basicStyles.sectionHeader}>
						{header}
					</View>
					: null
			}
			<View style={[
				basicStyles.flexRow,
				basicStyles.alignContentCenter,
				basicStyles.justifyContentFlexStart,
				basicStyles.flexWrap,
				basicStyles.inputContainerPaddingHorizontal
			]}>
				{
					fieldChunks.map((fieldChunk, fieldChunkIndex) => {
						return fieldChunk.map((field, index) => {
							fieldIndex++;

							return (
								<View
									key={index}
									style={[
										basicStyles.inputWrapper,
										(fieldChunkIndex === fieldChunks.length - 1 ? {marginBottom: 0} : {}),
										{
											width: field.width || ((1 / chunkSize) * 100) + '%',
											zIndex: fields.length - fieldIndex,
											elevate: fields.length - fieldIndex,
										},
										field.style
									]}>
									{
										{
											'text': <AppTextInput
												label={field.label}
												onChangeText={field.onChangeText}
												value={field.value}
												nextInputRef={field.nextInputRef}
												ref={field.ref}
												{...field}
											/>,
											'numeric': <NumericInput
												label={field.label}
												onChangeText={field.onChangeText}
												value={field.value}
												nextInputRef={field.nextInputRef}
												ref={field.ref}
												{...field}
											/>,
											'money': <MoneyInput
												label={field.label}
												onChangeText={field.onChangeText}
												value={field.value}
												nextInputRef={field.nextInputRef}
												ref={field.ref}
												{...field}
											/>,
											'phone': <PhoneInput
												label={field.label}
												onChangeText={field.onChangeText}
												value={field.value}
												nextInputRef={field.nextInputRef}
												ref={field.ref}
												rest={() => {
													const {type, ...rest} = field;
													return rest;
												}}
											/>,
											'address': <AddressInput
												label={field.label}
												onChangeAddress={field.onChangeAddress}
												value={field.value}
												nextInputRef={field.nextInputRef}
												ref={field.ref}
												{...field}
											/>,
											'date': <AppTextInput
												label={field.label}
												onChangeText={field.onChangeText}
												value={field.value}
												nextInputRef={field.nextInputRef}
												ref={field.ref}
												{...field}
											/>,
											'picker': <AppPickerInput
												title={field.title}
												label={field.label}
												onValueChange={field.onValueChange}
												selectedValue={field.selectedValue}
												items={field.items}
												labelExtractor={field.labelExtractor}
												valueExtractor={field.valueExtractor}
												nextInputRef={field.nextInputRef}
												ref={field.ref}
												{...field}
											/>,
											'copy':<CopyField
												label={field.label}
												value={field.value}
												nextInputRef={field.nextInputRef}
												ref={field.ref}
												{...field}
											/>,
											'download':<DownloadField
												label={field.label}
												value={field.value}
												name={field.name}
												nextInputRef={field.nextInputRef}
												ref={field.ref}
												{...field}
											/>,
											'button':<ButtonField
												label={field.label}
												actionLabel={field.actionLabel}
												action={field.action}
												nextInputRef={field.nextInputRef}
												ref={field.ref}
												{...field}
											/>,
											'range':<StarRangeInputField
												label={field.label}
												value={field.value}
												nextInputRef={field.nextInputRef}
												ref={field.ref}
												{...field}
											/>,
											'toggle':<ToggleField
												label={field.label}
												value={field.value}
												nextInputRef={field.nextInputRef}
												ref={field.ref}
												{...field} />
										}[field.type]
									}
								</View>
							);
						});
					})
				}
			</View>

			{
				footer ?
					<View style={[
						basicStyles.flexRow,
						basicStyles.inputContainerPaddingHorizontal,
						{
							width: '100%',
							borderTopWidth: 1,
							borderTopColor: '#E2E9F4',
							marginTop: 35,
						},
					]}>
						{footer}
					</View>
					: null
			}
		</ContentSection>
	);
}

export default FieldSet;
