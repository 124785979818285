import Http from "../utilities/Http";
import {API_ROOT} from "../config";

export default {
	async getAdditionalServices() {
		let response = await Http().get(API_ROOT + '/additional-services/');

		return response.data.additionalServices;
	},
};
