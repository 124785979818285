import React, {useState, useCallback, useEffect} from 'react';
import {ScrollView, View} from "react-native";
import EStyleSheet from "react-native-extended-stylesheet";

import Modal from "../../../../../../components/Modal";
import MessagesService from "../../../../../../services/MessagesService";
import ContentSection from "../../../../../../components/ContentSection";
import AppText from "../../../../../../components/AppText";
import {textStyles} from "../../../../../../styles/text";
import AppTextInput from "../../../../../../components/AppTextInput";
import {basicStyles} from "../../../../../../styles/basic";
import AppButton from "../../../../../../components/AppButton";
import AppPickerInput from "../../../../../../components/AppPickerInput";
import ToggleField from "../../../../../../components/ToggleField";

function ScriptVariableModal({onClose, onSubmit, organizationId, active = false, variableId = null}) {
    const [variable, setVariable] = useState({
        message_script_variable_id: null,
        message_script_variable_type_id: '',
        message_script_variable_title: '',
        variableOptions: [],
        variableOptionsString: ''
    });
    const [variableTypes, setVariableTypes] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const updateVariable = useCallback((prop, value) => {
        setVariable(current => {
            let update = {...current};
            update[prop] = value;

            return update;
        })
    }, []);

    const handleClose = useCallback(() => {
        onClose();
        setVariable({
            message_script_variable_id: null,
            message_script_variable_type_id: '',
            message_script_variable_title: '',
            variableOptions: [],
            variableOptionsString: ''
        });
        setIsSubmitting(false);
    }, [onClose]);

    const handleSubmit = useCallback(() => {
        if (!isSubmitting) {
            setIsSubmitting(true);
            let promise;
            if (variableId) {
                promise = MessagesService.updateScriptVariable(organizationId, variableId, {
                    ...variable,
                    variableOptions: variable.variableOptionsString.split(',')
                        .map(variableOptionValue => {
                            return {
                                message_script_variable_option_value: variableOptionValue.trim()
                            }
                        })
                });
            } else {
                promise = MessagesService.insertScriptVariable(organizationId, {
                    ...variable,
                    variableOptions: variable.variableOptionsString.split(',')
                        .map(variableOptionValue => {
                            return {
                                message_script_variable_option_value: variableOptionValue.trim()
                            }
                        })
                });
            }

            promise.then(variable => {
                onSubmit(variable);
                handleClose()
            }).catch(() => {
                setIsSubmitting(false);
            })
        }
    }, [handleClose, onSubmit, organizationId, variableId, variable, isSubmitting]);

    useEffect(() => {
        if (variableId) {
            MessagesService.getScriptVariable(organizationId, variableId)
                .then(variable => {
                    setVariable({
                        ...variable,
                        variableOptionsString: variable.variableOptions.map(variableOption => {
                            return variableOption.message_script_variable_option_value;
                        }).join(',')
                    });
                });
        }
    }, [organizationId, variableId]);

    useEffect(() => {
        MessagesService.getMessageScriptVariableTypes()
            .then(variableTypes => {
                setVariableTypes(variableTypes);
            })
    }, []);

    return (
        <Modal
            active={active}
            onClose={handleClose}>
            <ContentSection
                style={[styles.contentWrapper]}
                onClick={e => {
                    e.stopPropagation();
                }}
            >
                <ScrollView>
                    <AppText style={[textStyles.pageTitle, {marginBottom: 10}]}>
                        {variableId ? 'Update' : 'Create New'} Variable
                    </AppText>
                    <AppTextInput
                        label="Title"
                        value={variable.message_script_variable_title}
                        onChangeText={value => {
                            updateVariable('message_script_variable_title', value.replace(/\s/g, ''));
                        }}
                        wrapperStyle={styles.inputWrapper}
                    />
                    <AppPickerInput
                        label="Type"
                        selectedValue={variable.message_script_variable_type_id}
                        onValueChange={value => {
                            updateVariable('message_script_variable_type_id', value);
                        }}
                        items={variableTypes}
                        labelExtractor="message_script_variable_type_title"
                        valueExtractor="message_script_variable_type_id"
                        wrapperStyle={styles.inputWrapper}
                    />
                    <ToggleField
                        label="Allow Mulitple?"
                        value={parseInt(variable.message_script_variable_allow_multiple) === 1}
                        onChange={value => {
                            updateVariable('message_script_variable_allow_multiple', value ? '1' : '0');
                        }}
                    />
                    {
                        //if dropdown type
                        variable.message_script_variable_type_id === '2' ?
                            <AppTextInput
                                label="Options (Comma Separated)"
                                value={variable.variableOptionsString}
                                onChangeText={value => {
                                    updateVariable('variableOptionsString', value);
                                }}
                                wrapperStyle={styles.optionsInputWrapper}
                                inputStyle={styles.optionsInput}
                                multiline={true}
                                lines={5}
                            />
                            : null
                    }
                    <View style={[basicStyles.flexRow, basicStyles.justifyContentFlexEnd, {marginTop: 20}]}>
                        <AppButton
                            label="Cancel"
                            action={handleClose}
                            style={{marginRight: 10}}
                            theme="transBlue"
                            disabled={isSubmitting}
                        />
                        <AppButton
                            label="Confirm"
                            action={handleSubmit}
                            disabled={isSubmitting}
                        />
                    </View>
                </ScrollView>
            </ContentSection>
        </Modal>
    );
}

export default ScriptVariableModal;

const styles = EStyleSheet.create({
    contentWrapper: {
        padding: 32,
        width: 600,
        maxWidth: '100%',
        maxHeight: '100vh'
    },
    inputWrapper: {
        marginBottom: 10,
    },
    optionsInputWrapper: {
        marginBottom: 10,
        height: 'auto'
    },
    optionsInput: {
        minHeight: 130,
        overflowY: 'auto'
    }
});
