import * as React from "react"
import Svg, { G, Circle, Path } from "react-native-svg"

function ErrorSvg(props) {
	return (
		<Svg
			data-name="Group 902"
			width={37.34}
			height={37.34}
			viewBox="0 0 37.34 37.34"
			{...props}
		>
			<G data-name="Group 140" fill="#ea0024">
				<Circle
					data-name="Ellipse 33"
					cx={18.67}
					cy={18.67}
					opacity={0.068}
					r={18.67}
				/>
				<Circle
					data-name="Ellipse 32"
					cx={13.757}
					cy={13.757}
					transform="translate(4.913 4.913)"
					r={13.757}
				/>
			</G>
			<Path
				data-name="Path 343"
				d="M17.765 27v-6.26h-3.48l5-9.74v6.26h3.35z"
				fill="#fff"
			/>
		</Svg>
	)
}

export default ErrorSvg;
