import * as React from "react"
import Svg, {
	Defs,
	LinearGradient,
	Stop,
	G,
	Circle,
	Path,
} from "react-native-svg"

function ThumbnailViewSvg(props) {
	return (
		<Svg width={26} height={26} viewBox="0 0 26 26" {...props}>
			<Defs>
				<LinearGradient
					id="prefix__a"
					x1={-0.195}
					y1={0.5}
					x2={1.173}
					y2={0.5}
					gradientUnits="objectBoundingBox"
				>
					<Stop offset={0} stopColor="#1b8ce9" />
					<Stop offset={1} stopColor="#467aff" />
				</LinearGradient>
			</Defs>
			<G data-name="Group 958" transform="translate(-1258 -213)">
				<Circle
					data-name="Ellipse 82"
					cx={13}
					cy={13}
					r={13}
					transform="translate(1258 213)"
					fill="url(#prefix__a)"
				/>
				<G data-name="Group 691" fill="#fff">
					<Path data-name="Rectangle 230" d="M1265 222h12v7h-12z" />
					<Path data-name="Rectangle 231" d="M1265 227h12v4h-12z" />
				</G>
			</G>
		</Svg>
	)
}

export default ThumbnailViewSvg;
