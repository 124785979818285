import React, {useState} from 'react';
import {StyleSheet, Animated} from 'react-native';

import AppText from './AppText';
import TouchView from './TouchView';

function AppButton({
	label,
	children,
	theme = 'blue',
	action = () => {
	},
	disabled = false,
	style = {},
	textStyle = {},
	animated = true,
	icon = null,
	large=false,
}) {
	const transparentKeys = ['transBlue', 'transRed'];
	const transparent = transparentKeys.includes(theme);

	const borderColors = {
		transBlue: '#0065DB',
		transRed: '#EA0024',
	};
	let borderColor;
	if (transparent) {
		borderColor = borderColors[theme];
	}

	let regularTextColor, hoveredTextColor;

	if (theme === 'transBlue') {
		regularTextColor = '#000000';
		hoveredTextColor = '#FFFFFF';
	} else if (theme === 'transRed') {
		regularTextColor = borderColors['transRed'];
		hoveredTextColor = '#FFFFFF';
	} else {
		regularTextColor = '#FFFFFF';
		hoveredTextColor = '#FFFFFF';
	}

	const [isHovered] = useState(new Animated.Value(0));

	const outputColorRanges = {
		blue: ['#0065DB', '#0051AF'],
		green: ['#47CC8A', '#38AD73'],
		transBlue: ['#FFFFFF', borderColor],
		transRed: ['#FFFFFF', borderColor],
	};

	const handleAnimationIn = () => {
		Animated.timing(isHovered, {
			toValue: 1,
			duration: 250,
		}).start();
	};

	const handleAnimationOut = () => {
		Animated.timing(isHovered, {
			toValue: 0,
			duration: 250,
		}).start();
	};

	const backgroundColor = isHovered.interpolate({
		inputRange: [0, 1],
		outputRange: outputColorRanges[theme],
	});

	const color = isHovered.interpolate({
		inputRange: [0, 1],
		outputRange: [regularTextColor, hoveredTextColor],
	});


	const Icon = () => {
		return icon;
	};

	return (
		<TouchView
			onMouseEnter={() => animated ? handleAnimationIn() : null}
			onMouseLeave={() => animated ? handleAnimationOut() : null}
			action={disabled ? () => {} : action}
			style={[
				styles.button,
				{backgroundColor},
				(borderColor ? {borderColor} : {borderColor: backgroundColor}),
				...(icon ? [styles.buttonWithIcon] : []),
				...(large ? [{minHeight: 50}] : []),
				disabled ? styles.buttonDisabled : null,
				style,
			]}
		>
			<AppText style={[
				styles.buttonText,
				{color},
				...(icon ? [{marginRight: 10}] : []),
				textStyle
			]}>
				{label}
			</AppText>
			{
				icon ?
					<Icon/>
					:
					null
			}
			{
				children ?
					children :
					null
			}
		</TouchView>
	);
}

export default AppButton;

const styles = StyleSheet.create({
	button: {
		borderWidth: 1,
		borderRadius: 5,
		paddingTop: 10,
		paddingBottom: 10,
		paddingRight: 20,
		paddingLeft: 20,
		justifyContent: 'center',
		alignItems: 'center',
	},
	buttonWithIcon: {
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	buttonText: {
		fontSize: 13,
	},
	buttonDisabled: {
		opacity: 0.3
	}
});
