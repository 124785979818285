import React from 'react';
import {View, StyleSheet} from 'react-native';
import {Picker} from '@react-native-community/picker';

import ChevronDownSvg from './svgs/ChevronDownSvg';
import AppText from './AppText';
import {basicStyles} from '../styles/basic';

function SubNavMobileSelect({style, selectedValue, onValueChange, items}) {

	const getSelectedLabel = () => {
		return items.find(item => {
			return item.value === selectedValue;
		}).label;
	};

	return (
		<View style={[styles.wrapper, style]}>
			<View style={styles.contentWrapper}>
				<AppText style={styles.text}>
					{getSelectedLabel()}
				</AppText>
				<ChevronDownSvg/>
			</View>
			<Picker
				selectedValue={selectedValue}
				onValueChange={onValueChange}
				style={basicStyles.overlayHidden}
			>
				{
					items.map(item => {
						return (
							<Picker.Item
								key={item.key}
								label={item.label}
								value={item.value}
							/>
						);
					})
				}
			</Picker>
		</View>
	);
}

export default SubNavMobileSelect;

const styles = StyleSheet.create({
	wrapper: {
		backgroundColor: 'rgba(0,0,0,.23)',
		borderRadius: 5,
		justifyContent: 'center',
		alignItems: 'center',
		paddingTop: 15,
		paddingBottom: 15,
	},
	text: {
		color: '#FFFFFF',
		fontFamily: 'SourceSansPro-SemiBold',
		marginRight: 10
	},
	select: {
		opacity: 0,
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		width: '100%',
	},
	contentWrapper: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center'
	}
});
