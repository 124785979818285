import * as React from "react"
import Svg, { Path } from "react-native-svg"

function MapSvg({fill = '#0065db', ...props}) {
	return (
		<Svg width={14.605} height={14.605} viewBox="0 0 14.605 14.605" {...props}>
			<Path
				data-name="Path 520"
				d="M14.2 0l-.13.024L9.737 1.7 4.868 0 .292 1.542A.409.409 0 000 1.931V14.2a.4.4 0 00.406.406l.13-.024L4.868 12.9l4.868 1.7 4.576-1.542a.409.409 0 00.292-.389V.406A.4.4 0 0014.2 0zM9.737 12.982L4.868 11.27V1.623l4.868 1.712z"
				fill={fill}
			/>
		</Svg>
	)
}

export default MapSvg
