export const warningActionTypes = {
	SET_WARNING: 'SET_WARNING',
	DESTROY_WARNING: 'DESTROY_WARNING',
	SET_INDESTRUCTIBLE: 'SET_INDESTRUCTIBLE',
};

export const setWarning = warning => (
	{
		type: warningActionTypes.SET_WARNING,
		payload: warning,
	}
);

export const setIndestructible = indestructible => (
	{
		type: warningActionTypes.SET_INDESTRUCTIBLE,
		payload: indestructible,
	}
);

export const destroyWarning = () => (
	{
		type: warningActionTypes.DESTROY_WARNING,
	}
);
