import Axios from 'axios';
import store from '../store';
import {cycleNotification} from "../store/notifications/notificationActions";

export default () => {
    const state = store.getState();

    const instance = Axios.create({
        baseURL: '/api',
        withCredentials: false,
        headers: {
            common: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Auth-Token': state.auth.token,
            },
        },
    });

    instance.interceptors.response.use(
        (response) => {
            if (response.data.message) {
                console.log('responseMessage: ', response.data.message);
            }

            if (response.data.notification) {
                store.dispatch(cycleNotification(response.data.notification));
            }

            return response;
        },
        (error) => {
            if (error?.response?.status !== 401) { // don't alert 401
                if (error?.response?.data?.message) {
                    alert(error.response.data.message);
                } else if (error?.response?.data?.meta?.message) {
                    alert(error?.response.data.meta.message);
                }
            }

            if (!Axios.isCancel(error)) {
                if (error.response) {
                    console.log('responseError: ', error.response);
                } else {
                    console.log('errorMessage: ', error.message);
                }
            }

            return Promise.reject(error);
        },
    );

    return instance;
}
