import React from 'react';
import {View, StyleSheet} from 'react-native';
import {bindActionCreators} from 'redux';

import AppText from './AppText';
import Avatar from './Avatar';
import TouchView from './TouchView';
import AsyncStorage from '@react-native-community/async-storage';
import {setOrganization, setOrganizationRoleId} from '../store/auth/authActions';
import {destroyWarning} from '../store/warning/warningActions';
import {connect} from 'react-redux';
import ActionRowWithAvatar from './ActionRowWithAvatar';
import {AVATAR_DEFAULT, AVATAR_PATH_ORG} from '../config';
import {basicStyles} from '../styles/basic';
import ChevronLeftBlueSvg from './svgs/ChevronLeftBlueSvg';
import {navigate} from '../utilities/navigation';
import {ScrollView} from 'react-native-web';

function SwitchOrganizations({organizations, backAction, ...rest}) {
	const switchOrganization = async (organization) => {
		const {setOrganization, setOrganizationRoleId, destroyWarning} = rest;
		const {role_id} = organization;
		await AsyncStorage.setItem('organizationId', organization.organization_id);
		await AsyncStorage.setItem('roleId', role_id);
		setOrganization(organization);
		setOrganizationRoleId(role_id);
		destroyWarning();
		navigate('Authenticated');
	};

	return (
		<ScrollView>
			<View style={[styles.topSection]}>
				<TouchView action={() => backAction()} style={[basicStyles.flexRow, basicStyles.alignContentCenter, {marginBottom: 25}]}>
					<ChevronLeftBlueSvg style={{marginRight: 12}}/>
					<AppText>
						Back
					</AppText>
				</TouchView>
				<AppText style={styles.warningMessage}>
					Switch Accounts
				</AppText>
			</View>
			<View style={styles.bottomSection}>
				{
					organizations.map(organization => {
						return (
							<ActionRowWithAvatar
								key={organization.organization_id}
								icon={
									<Avatar
										source={{uri: organization.organization_thumbnail ? AVATAR_PATH_ORG + organization.organization_thumbnail : AVATAR_DEFAULT}}
										width={66}
									/>
								}
								title={organization.organization_title}
								touchAction={() => switchOrganization(organization)}
							/>
						);
					})
				}
			</View>
		</ScrollView>
	);
}

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		setOrganization,
		setOrganizationRoleId,
		destroyWarning,
	}, dispatch)
);

export default connect(null, mapDispatchToProps)(SwitchOrganizations);

const styles = StyleSheet.create({
	container: {
		maxWidth: 680,
	},
	topSection: {
		borderBottomWidth: 1,
		borderBottomColor: '#E2E9F4',
		justifyContent: 'center',
		alignItems: 'center',
		paddingTop: 30,
		paddingRight: 60,
		paddingLeft: 60,
		paddingBottom: 30,
	},
	bottomSection: {
		justifyContent: 'center',
		alignItems: 'center',
		paddingTop: 30,
		paddingRight: 30,
		paddingLeft: 30,
		paddingBottom: 30,
	},
	warningMessage: {
		fontSize: 28,
		textAlign: 'center',
		fontFamily: 'SourceSansPro-Light',
	},
});
