import React, {useEffect, useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import AppText from "./AppText";
import AppButton from "./AppButton";
import {Editor, EditorState, RichUtils} from 'draft-js';
import 'draft-js/dist/Draft.css';
import {stateToHTML} from 'draft-js-export-html';
import TouchView from './TouchView';
import AppPickerInput from "./AppPickerInput";

function RichTextEditor(
		{
			value = '',
			defaultValue = '',
			onUpdate = () => {},
			wrapperStyle = {},
			aiUpdate = false,
	    }
)
{
	const [editorState, setEditorState] = useState(
		() => EditorState.createWithText((value ? value : defaultValue)),
	);

	const [fontSizeState, setFontSizeState] = useState('FONT-15')

	const styleMap = {
		'FONT-8': {
			fontSize: '8px',
		},
		'FONT-9': {
			fontSize: '9px',
		},
		'FONT-10': {
			fontSize: '10px',
		},
		'FONT-11': {
			fontSize: '11px',
		},
		'FONT-12': {
			fontSize: '12px',
		},
		'FONT-13': {
			fontSize: '13px',
		},
		'FONT-14': {
			fontSize: '14px',
		},
		'FONT-15': {
			fontSize: '15px',
		},
		'FONT-20': {
			fontSize: '20px',
		},
		'FONT-25': {
			fontSize: '25px',
		},
		'FONT-30': {
			fontSize: '30px',
		},
	};

	const onChange = editorState => {
		setEditorState(editorState);
	};

	const getHtmlContent = () => {
		var contentState = editorState.getCurrentContent();
		var html = stateToHTML(contentState);

		onUpdate(html)
	};

	const toggleFontSize = (fontSize) => {
		setFontSizeState(fontSize);
		onChange(RichUtils.toggleInlineStyle( editorState, fontSize ));
	}

	const getInlineStyle = (style) => {
		onChange(RichUtils.toggleInlineStyle(editorState, style));
	};

	const getBlockType = (block) => {
		onChange(RichUtils.toggleBlockType(editorState, block))
	};

	useEffect(() => {
		getHtmlContent();
	}, [editorState]);

	useEffect(() => {
		if (aiUpdate) {
			onChange(EditorState.createWithText((value ? value : defaultValue)));
		}
	}, [aiUpdate, value]);




	return (
		<View style={
		[
			styles.container,
			{
				...wrapperStyle,
			}
		]}>
			<View style={styles.toolBar}>
				<TouchView
					style={[styles.toolBarButton, {marginLeft: 0}]}
					action={() => {
						getInlineStyle('BOLD')
					}}
				>
					<AppText>Bold</AppText>
				</TouchView>
				<TouchView
					style={styles.toolBarButton}
					action={() => {
						getInlineStyle('ITALIC')
					}}
				>
					<AppText>Italic</AppText>
				</TouchView>
				<TouchView
					style={styles.toolBarButton}
					action={() => {
						getInlineStyle('UNDERLINE')
					}}
				>
					<AppText>Underline</AppText>
				</TouchView>
				<TouchView
					style={styles.toolBarButton}
					action={() => {
						getInlineStyle('CODE')
					}}
				>
					<AppText>Code</AppText>
				</TouchView>
				<TouchView
					style={styles.toolBarButton}
					action={() => {
						getBlockType('unordered-list-item')
					}}
				>
					<AppText>UL</AppText>
				</TouchView>
				<TouchView
					style={styles.toolBarButton}
					action={() => {
						getBlockType('ordered-list-item')
					}}
				>
					<AppText>OL</AppText>
				</TouchView>
				<TouchView
					style={styles.toolBarButton}
					action={() => {
						getBlockType('header-one')
					}}
				>
					<AppText>H1</AppText>
				</TouchView>
				<TouchView
					style={styles.toolBarButton}
					action={() => {
						getBlockType('header-two')
					}}
				>
					<AppText>H2</AppText>
				</TouchView>
				<TouchView
					style={styles.toolBarButton}
					action={() => {
						getBlockType('header-three')
					}}
				>
					<AppText>H3</AppText>
				</TouchView>
				<AppPickerInput
					title={"Font Size"}
					label={"Font Size"}
					onValueChange={(value) => {toggleFontSize(value)}}
					selectedValue={(fontSizeState ? fontSizeState : 'FONT-15')}
					items={[
						{
							"label" : "8px",
							"value" : 'FONT-8'
						},
						{
							"label" : "9px",
							"value" : 'FONT-9'
						},
						{
							"label" : "10px",
							"value" : 'FONT-10'
						},
						{
							"label" : "11px",
							"value" : 'FONT-11'
						},
						{
							"label" : "12px",
							"value" : 'FONT-12'
						},
						{
							"label" : "13px",
							"value" : 'FONT-13'
						},
						{
							"label" : "14px",
							"value" : 'FONT-14'
						},
						{
							"label" : "15px",
							"value" : 'FONT-15'
						},
						{
							"label" : "20px",
							"value" : 'FONT-20'
						},
						{
							"label" : "25px",
							"value" : 'FONT-25'
						},
						{
							"label" : "30px",
							"value" : 'FONT-30'
						}
					]}
					labelExtractor="label"
					valueExtractor="value"
					wrapperStyle={{height: 50, marginLeft: 10}}
					labelStyle={{display:'none'}}
				/>
			</View>
			<View style={styles.editorStyles}>
				<Editor
					customStyleMap={styleMap}
					editorState={editorState}
					onChange={onChange}
				/>
			</View>
		</View>
	);
}

export default RichTextEditor;

const styles = StyleSheet.create({
	container: {
		borderWidth: 1,
		borderColor: '#eae8e8',
	},
	editorStyles: {
		height: 150,
		padding: 10,
		fontSize: 15,
		fontFamily: 'SourceSansPro-Light',
	},
	toolBar: {
		flex: 1,
		flexDirection: 'row',
		flexWrap: 'wrap',
		borderBottomWidth:1,
		borderColor: '#eae8e8',
		padding: 10,
		alignItems: 'center'
	},
	toolBarButton: {
		marginLeft: 10
	}
});
