import React from 'react';
import {View, StyleSheet} from 'react-native';

function PageTop({children, style = {}, ...rest}) {
	return (
		<View style={[styles.pageTopContainer, style]} {...rest}>
			{children}
		</View>
	);
}

export default PageTop;

const styles = StyleSheet.create({
	pageTopContainer: {
		padding: 45,
		borderBottomWidth: 1,
		borderBottomColor: '#E2E9F4',
	},
});
