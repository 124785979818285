import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function MockUpSvg(props) {
	return (
		<Svg width={137.343} height={89} viewBox="0 0 137.343 89" {...props}>
			<G data-name="Group 127">
				<G data-name="Group 124">
					<Path data-name="Rectangle 309" fill="#0065db" d="M0 0h22v22H0z" />
					<G data-name="Group 123" fill="none" stroke="#0065db" strokeWidth={4}>
						<Path data-name="Line 75" d="M35.703 5.314h26.941" />
						<Path data-name="Line 76" d="M35.703 12.049h101.64" />
						<Path data-name="Line 77" d="M35.703 18.784h101.64" />
					</G>
				</G>
				<G data-name="Group 125">
					<Path
						data-name="Rectangle 309"
						fill="#0065db"
						d="M0 34.001h22v21H0z"
					/>
					<G data-name="Group 123" fill="none" stroke="#0065db" strokeWidth={4}>
						<Path data-name="Line 75" d="M35.703 38.378h26.941" />
						<Path data-name="Line 76" d="M35.703 45.113h101.64" />
						<Path data-name="Line 77" d="M35.703 51.848h101.64" />
					</G>
				</G>
				<G data-name="Group 126">
					<Path data-name="Rectangle 309" fill="#0065db" d="M0 66h22v23H0z" />
					<G data-name="Group 123" fill="none" stroke="#0065db" strokeWidth={4}>
						<Path data-name="Line 75" d="M35.703 71.441h26.941" />
						<Path data-name="Line 76" d="M35.703 78.176h101.64" />
						<Path data-name="Line 77" d="M35.703 84.911h101.64" />
					</G>
				</G>
			</G>
		</Svg>
	)
}

export default MockUpSvg;
