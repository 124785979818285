import React from 'react';
import {View} from 'react-native';
import {createStackNavigator} from '@react-navigation/stack';

import TeamMemberCreateOptions from './TeamMemberCreateOptions';
import TeamMemberSingle from './TeamMemberSingle';
import ImportResults from '../ImportResults';
import {basicStyles} from '../../styles/basic';

const Stack = createStackNavigator();

function TeamMemberAddNavigation() {
	return (
		<View style={basicStyles.flexScale}>
			<Stack.Navigator
				backBehavior="history"
				screenOptions={{
					header: () => null,
					cardStyle: {
						backgroundColor: '#F8FAFD'
					}
				}}
				initialRouteName="TeamMemberCreateOptions"
			>
				<Stack.Screen
					name={'TeamMemberCreateOptions'}
					component={TeamMemberCreateOptions}
					options={{
						title: "Team Members"
					}}
				/>
				<Stack.Screen
					name={'TeamMemberAdd'}
					component={TeamMemberSingle}
					options={{
						title: "Team Members"
					}}
				/>
				<Stack.Screen
					name={'TeamMemberImportResults'}
					component={ImportResults}
					initialParams={{type: 'users'}}
					options={{
						title: "Team Members"
					}}
				/>
			</Stack.Navigator>
		</View>
	);
}

export default TeamMemberAddNavigation;
