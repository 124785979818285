import * as React from "react"
import Svg, { G, Circle, Path } from "react-native-svg"

function YellowCircleOpenMailSvg(props) {
	return (
		<Svg
			xmlns="http://www.w3.org/2000/svg"
			width={41.819}
			height={41.819}
			viewBox="0 0 41.819 41.819"
			{...props}
		>
			<G data-name="Group 981" transform="translate(-271 -284)">
				<Circle
					data-name="Ellipse 33"
					cx={20.909}
					cy={20.909}
					transform="translate(271 284)"
					fill="#fac73d"
					opacity={0.068}
					r={20.909}
				/>
				<Circle
					data-name="Ellipse 32"
					cx={15.407}
					cy={15.407}
					transform="translate(277 290)"
					fill="#fac73d"
					r={15.407}
				/>
				<Path
					data-name="Path 366"
					d="M299.243 303.338a1.511 1.511 0 00-.717-1.3L291.625 298l-6.9 4.041a1.518 1.518 0 00-.725 1.297v7.625a1.53 1.53 0 001.525 1.525h12.2a1.53 1.53 0 001.525-1.525zm-7.618 3.812l-6.3-3.935 6.3-3.691 6.3 3.691z"
					fill="#fff"
				/>
			</G>
		</Svg>
	)
}

export default YellowCircleOpenMailSvg
