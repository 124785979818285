import React, {useState, useRef, useEffect} from 'react';
import {
    StyleSheet,
    TextInput,
    View,
    ScrollView,
    Switch,
    TouchableWithoutFeedback,
    Animated,
    PanResponder,
    ActivityIndicator
} from 'react-native';
import {Col, Row, Grid} from 'react-native-easy-grid';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {useDebouncedCallback} from 'use-debounce';
import {navigate} from '../utilities/navigation';

import AppText from './AppText';
import {basicStyles} from '../styles/basic';
import AppButton from './AppButton';
import FilterIconSvg from './svgs/FilterIconSvg';
import TouchView from './TouchView';
import EyeSvg from './svgs/EyeSvg';
import ChevronDownSvg from './svgs/ChevronDownSvg';
import SearchSvg from './svgs/SearchSvg';
import AppPickerInput from './AppPickerInput';
import StarRangeInput from './StarsRangeInput';
import {setSpreadSheetFilterOpen, setProcessingIndicatorActive} from '../store/miscellaneous/miscellaneousActions';
import TriangleDownSvg from './svgs/TriangleDownSvg';
import Http from '../utilities/Http';
import EStyleSheet from 'react-native-extended-stylesheet';
import {Text} from "react-native-svg";

function SpreadSheet({rowCount, rows, columns, rowKeyExtractor, deleteAction = null, exportUrl = null, exportTitle = null, navTab = null, searchElement = null, initialDisabledColumns = [], isLoading = false, ...props}) {
    const {spreadsheetFilterOpen} = props.miscellaneous;
    const {setSpreadSheetFilterOpen, setProcessingIndicatorActive} = props;
    const [state, setState] = useState({
        moveToColumnKey: null,
        orderedColumns: [...columns],
        columnPanAnimatedValues: columns.reduce((accumulated, column) => {
            return {
                ...accumulated,
                [column.key]: new Animated.ValueXY(),
            };
        }, {}),
        columnSizes: {},
        columnHeadContentSizes: {},
        cellFocused: {colIndex: null, rowIndex: null},
        excludedColumns: initialDisabledColumns,
        columnFilterSearch: '',
        activePanKey: null,
        columnOrderByKey: null,
        reverseOrderBy: false,
        dragging: false,
    });


    const [exportState, setExportState] = useState({
        downloadHref: null,
    })
    const downloadAnchorRef = useRef();


    const [handleDragDebounce] = useDebouncedCallback((column) => {
        setState(prevState => {
            const dragDistance = prevState.columnPanAnimatedValues[column.key].x._value;
            const {orderedColumns, columnSizes} = prevState;
            const filteredColumns = orderedColumns.filter(column => !state.excludedColumns.includes(column.key));
            const columnCurrentIndex = orderedColumns.findIndex(orderedColumn => {
                return orderedColumn.key === column.key;
            });
            const columnCurrentFilteredIndex = filteredColumns.findIndex(orderedColumn => {
                return orderedColumn.key === column.key;
            });
            let moveToKey = null;


            if (dragDistance > 0) { //moving right
                let indexDistance = 0;
                const columnLoop = filteredColumns.slice(columnCurrentFilteredIndex + 1);
                for (let [index, orderedColumn] of columnLoop.entries()) {
                    indexDistance += (columnSizes[orderedColumn.key] / 2) + (index > 0 ? columnSizes[columnLoop[index - 1].key] / 2 : 0);
                    if (dragDistance > indexDistance) {
                        moveToKey = orderedColumns.find(aColumn => {
                            return orderedColumn.key === aColumn.key;
                        }).key;
                    }
                }
            } else if (dragDistance < 0) { // moving left
                let indexDistance = 0;
                const columnLoop = filteredColumns.slice(0, columnCurrentFilteredIndex).reverse();
                for (let [index, orderedColumn] of columnLoop.entries()) {
                    indexDistance += (columnSizes[orderedColumn.key] / 2) + (index > 0 ? columnSizes[columnLoop[index - 1].key] / 2 : 0);

                    if (Math.abs(dragDistance) > indexDistance) {
                        moveToKey = orderedColumns.find(aColumn => {
                            return orderedColumn.key === aColumn.key;
                        }).key;
                    }
                }

            }

            return {...prevState, moveToColumnKey: moveToKey};
        });
    }, 70, {maxWait: 140});

    const [columnHeadPanResponders] = useState(columns.reduce((accumulated, column) => {
        return {
            ...accumulated,
            [column.key]: (
                PanResponder.create({
                    onStartShouldSetPanResponder: () => {
                        return true;
                    },
                    onPanResponderMove: Animated.event(
                        [
                            null,
                            {
                                dx: state.columnPanAnimatedValues[column.key].x,
                            },
                        ],
                        {
                            listener: ({nativeEvent}, gestureState) => {
                                handleDragDebounce(column);
                            },
                        },
                    ),
                    onPanResponderGrant: () => {
                        setState(prevState => {
                            let newState = {...prevState};
                            newState.activePanKey = column.key;
                            newState.columnPanAnimatedValues[column.key].setOffset({
                                x: state.columnPanAnimatedValues[column.key].x._value,
                            });
                            newState.dragging = true;

                            return newState;

                        });
                    },
                    onPanResponderRelease: () => {
                        setState(prevState => {
                            let newOrderedColumns = [...prevState.orderedColumns];
                            if (prevState.moveToColumnKey !== null) {
                                const columnCurrentIndex = prevState.orderedColumns.findIndex(orderedColumn => {
                                    return orderedColumn.key === column.key;
                                });
                                const moveToColumnIndex = prevState.orderedColumns.findIndex(orderedColumn => {
                                    return orderedColumn.key === prevState.moveToColumnKey;
                                });
                                newOrderedColumns = arrayMove(newOrderedColumns, columnCurrentIndex, moveToColumnIndex);
                            }

                            prevState.columnPanAnimatedValues[column.key].setValue({x: 0, y: 0});

                            return Object.assign({}, prevState, {
                                activePanKey: null,
                                moveToColumnKey: null,
                                orderedColumns: newOrderedColumns,
                                dragging: false,
                            });
                        });

                    },
                })
            ),
        };
    }, {}));

    const filteredOrderedColumns = state.orderedColumns.filter(column => !state.excludedColumns.includes(column.key));

    const filterByColumns = state.orderedColumns.filter(column => state.excludedColumns.includes(column.key) && column.filterByColumn);

    function arrayMove(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            let k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    }

    function extractRowColumnValue(row, column) {
        const value = column.valueExtractor(row);

        if (value !== null) {
            return value;
        }

        return '';
    }

    function toggleColumnExclusion(columnKey) {
        if (state.excludedColumns.includes(columnKey)) {
            setState(previousState => {
                let newExcludedColumns = previousState.excludedColumns.filter(key => key !== columnKey);
                navigate('Team', {
                    ...props.route?.params,
                    filterByColumns: state.orderedColumns.filter(column => newExcludedColumns.includes(column.key) && column.filterByColumn).map(column => column.key).join(','),
                    filterByColumnValues: state.orderedColumns.filter(column => newExcludedColumns.includes(column.key) && column.filterByColumn).map(column => column.filterByColumn).join(',')
                });
                return Object.assign({}, previousState, {excludedColumns: newExcludedColumns});
            });
        } else {
            setState(previousState => {
                let newExcludedColumns = [...previousState.excludedColumns, columnKey];
                navigate('Team', {
                    ...props.route?.params,
                    filterByColumns: state.orderedColumns.filter(column => newExcludedColumns.includes(column.key) && column.filterByColumn).map(column => column.key).join(','),
                    filterByColumnValues: state.orderedColumns.filter(column => newExcludedColumns.includes(column.key) && column.filterByColumn).map(column => column.filterByColumn).join(',')
                });
                return Object.assign({}, previousState, {excludedColumns: newExcludedColumns});
            });
        }
    }

    function getColumnHeadPanStyles(columnKey) {
        let left = 0;
        let width = 0;

        for (let column of filteredOrderedColumns) {
            if (column.key === columnKey) {
                width += state.columnSizes[column.key];
                return {
                    left,
                    width,
                };
            }
            left += state.columnSizes[column.key];
        }

        return {
            left,
            width,
        };
    }

    function setCellFocused(colIndex, rowIndex) {
        setState(prevState => {
            return Object.assign({}, prevState, {cellFocused: {colIndex, rowIndex}});
        });
    }

    function clearCellFocused() {
        setState(prevState => {
            return Object.assign({}, prevState, {cellFocused: {colIndex: null, rowIndex: null}});
        });
    }

    function getRowKey(row) {
        if (typeof rowKeyExtractor === 'function') {
            return rowKeyExtractor(row);
        }

        return row[rowKeyExtractor];
    }

    function sortByColumn(columnKey) {
        setState(prevState => {
            let newState = {...prevState};
            if (prevState.columnOrderByKey === columnKey && prevState.reverseOrderBy) {
                newState.columnOrderByKey = null;
                newState.reverseOrderBy = false;
                return newState;
            }
            newState.columnOrderByKey = columnKey;
            newState.reverseOrderBy = prevState.columnOrderByKey === columnKey;

            return newState;
        });
    }

    function columnActive(columnKey) {

        return state.columnOrderByKey === columnKey;
    }

    let sortedRows = [...rows];
    if (state.columnOrderByKey) {
        let sortColumn = columns.find(column => {
            return column.key === state.columnOrderByKey;
        });
        sortedRows.sort((row1, row2) => {
            let row1SortColValue = extractRowColumnValue(row1, sortColumn);
            let row2SortColValue = extractRowColumnValue(row2, sortColumn);

            switch (sortColumn.sortCast) {
                case 'int':
                    row1SortColValue = parseInt(row1SortColValue);
                    row2SortColValue = parseInt(row2SortColValue);
                    break;
                default:
                    break;
            }

            return (row1SortColValue > row2SortColValue) ? 1 : ((row2SortColValue > row1SortColValue) ? -1 : 0);
        });

        if (state.reverseOrderBy) {
            sortedRows = sortedRows.reverse();
        }
    }


    async function downloadCsvExport() {
        setProcessingIndicatorActive(true);
        const exportColumns = filteredOrderedColumns.filter(column => column.exportable !== false).map(column => column.exportName);
        const filterColumns = filterByColumns.map(column => column.key);
        const filterColumnValues = filterByColumns.map(column => column.filterByColumn);
        const dataUrl = exportUrl + '&csv&columns=' + encodeURIComponent(exportColumns) + '&filterColumns=' + encodeURIComponent(filterColumns) + '&filterColumnValues=' + encodeURIComponent(filterColumnValues);
        let windowUrl = window.URL || window.webkitURL;

        const data = await Http().get(dataUrl);
        let blob = new Blob([data.data], {type: 'text/csv'});
        setExportState(prevState => {
            return {
                downloadHref: windowUrl.createObjectURL(blob),
            };
        });

    }

    useEffect(() => {
        if(exportState.downloadHref) {
            downloadAnchorRef.current.click();
        }
        setProcessingIndicatorActive(false);
    }, [exportState.downloadHref])

    return (
        <>
            <View>
                <View style={[
                    basicStyles.flexRow,
                    basicStyles.alignContentCenter,
                    styles.spreadSheetContainer
                ]}>
                    {rows.length > 0 && isLoading && (
                        <View style={[{position: 'absolute', bottom: -40, left: '50%'}]}>
                            <ActivityIndicator size="small" color="#467AFF"/>
                        </View>
                    )}
                    <View style={[
                        styles.resultsHeaderContainer
                    ]}>
                        { searchElement }
                        <View style={styles.resultsHeaderDetailsContainer}>
                            <AppText style={{
                                fontSize: 20,
                                fontFamily: 'SourceSansPro-SemiBold',
                            }}>
                                Results
                            </AppText>
                            <View style={styles.resultsCount}/>
                            <AppText style={{fontSize: 20, fontFamily: 'SourceSansPro-Light'}}>
                                {(rowCount ? rowCount : rows.length)}
                            </AppText>
                        </View>
                        <AppButton
                            theme="transBlue"
                            style={[styles.resultsFilterButton]}
                            action={(e) => {
                                e.stopPropagation();
                                setSpreadSheetFilterOpen(!spreadsheetFilterOpen);
                            }}
                            animated={false}
                        >
                            <FilterIconSvg style={{marginRight: 10}}/>
                            <AppText>Filter</AppText>
                        </AppButton>
                        <TouchableWithoutFeedback onPress={(e) => {
                            e.stopPropagation();
                        }}>
                            <View style={{marginLeft: 30}}>
                                {
                                    spreadsheetFilterOpen ?
                                        <View
                                            onPress={(e) => {
                                                e.stopPropagation();
                                            }}
                                            style={[
                                                {
                                                    position: 'absolute',
                                                    top: '100%',
                                                    left: 0,
                                                    paddingTop: 10,
                                                },
                                            ]}>
                                            <View style={[
                                                basicStyles.contentSection,
                                            ]}>
                                                <View style={[
                                                    styles.columnFilterSectionPadding,
                                                    {
                                                        paddingTop: 16,
                                                        paddingBottom: 16,
                                                    },
                                                ]}>
                                                    <TouchView
                                                        style={[
                                                            basicStyles.flexRow,
                                                            basicStyles.alignContentCenter,
                                                            {
                                                                borderWidth: 1,
                                                                borderColor: '#E2E9F4',
                                                                borderRadius: 5,
                                                                paddingRight: 12,
                                                            },
                                                        ]}
                                                        action={() => setState(previousState => {
                                                            return Object.assign({}, previousState, {excludedColumns: []});
                                                        })}
                                                    >
                                                        <View style={[
                                                            basicStyles.flexCenterContent,
                                                            {
                                                                width: 30,
                                                                height: 30,
                                                                borderRightWidth: 1,
                                                                borderRightColor: '#E2E9F4',
                                                                marginRight: 8,
                                                            },
                                                        ]}>
                                                            <EyeSvg/>
                                                        </View>
                                                        <AppText style={{fontSize: 12, color: '#c6cdd8', flex: 1}}>
                                                            Display All
                                                        </AppText>
                                                        <ChevronDownSvg color="#0065DB"/>
                                                    </TouchView>
                                                </View>
                                                <View style={[
                                                    styles.columnFilterSectionPadding,
                                                    basicStyles.flexRow,
                                                    basicStyles.alignContentCenter,
                                                    {
                                                        paddingTop: 10,
                                                        paddingBottom: 10,
                                                        borderTopWidth: 1,
                                                        borderBottomWidth: 1,
                                                        borderColor: '#E2E9F4',
                                                    },
                                                ]}>
                                                    <SearchSvg style={{marginRight: 5}}/>
                                                    <TextInput
                                                        placeholder="Enter Field"
                                                        value={state.columnFilterSearch}
                                                        onChangeText={(value) => setState(previousState => {
                                                            return Object.assign({}, previousState, {columnFilterSearch: value});
                                                        })}
                                                    />
                                                </View>
                                                <ScrollView
                                                    style={[
                                                        styles.columnFilterSectionPadding,
                                                        {
                                                            paddingTop: 20,
                                                            paddingBottom: 20,
                                                            maxHeight: 160,
                                                        },
                                                    ]}>
                                                    {
                                                        columns.filter(column => {
                                                            if (state.columnFilterSearch !== '') {
                                                                return column.label.indexOf(state.columnFilterSearch) >= 0;
                                                            }
                                                            return true;
                                                        }).map(column => {
                                                            return (
                                                                <View
                                                                    key={column.key}
                                                                    style={[
                                                                        basicStyles.flexRow,
                                                                        basicStyles.alignContentCenter,
                                                                        {width: '100%', marginBottom: 10},
                                                                    ]}
                                                                >
                                                                    <Switch
                                                                        style={{marginRight: 10}}
                                                                        value={!state.excludedColumns.includes(column.key)}
                                                                        onValueChange={() => toggleColumnExclusion(column.key)}
                                                                        trackColor={{
                                                                            true: '#0065DB',
                                                                            false: '#F2F2F2',
                                                                        }}
                                                                        thumbColor="#0065DB"
                                                                        activeThumbColor="#FFFFFF"
                                                                    />
                                                                    <AppText numberOfLines={1}>
                                                                        {column.label}
                                                                    </AppText>
                                                                </View>
                                                            );
                                                        })
                                                    }
                                                </ScrollView>
                                            </View>
                                        </View>
                                        : null
                                }
                            </View>
                        </TouchableWithoutFeedback>
                    </View>
                    <View style={[styles.navExportWrapper, (!navTab ? styles.navExportWrapperNoNav : {})]}>
                        {
                            navTab ?? null
                        }
                        {
                            exportUrl ?
                                <AppButton
                                    theme="transBlue"
                                    label="Export All"
                                    action={() => downloadCsvExport()}
                                    style={[styles.exportButton, (!navTab ? styles.exportButtonNoNav : {})]}
                                />
                                : null
                        }
                    </View>
                </View>
                {
                    rows.length ?
                        <ScrollView style={[styles.grid, styles.gridInversion]} horizontal={true} showsHorizontalScrollIndicator={true}>
                            <View style={styles.gridInversion}>
                                {
                                    state.orderedColumns.filter(columnPan => state.columnPanAnimatedValues[columnPan.key] && !state.excludedColumns.includes(columnPan.key)).map((columnPan) => {
                                        const {width, left} = getColumnHeadPanStyles(columnPan.key);
                                        const sortIconStyle = [];

                                        if (!columnActive(columnPan.key)) {
                                            sortIconStyle.push(styles.sortIconInactive);
                                        } else if (state.reverseOrderBy) {
                                            sortIconStyle.push(styles.sortIconRotated);
                                        }

                                        return (
                                            <Animated.View
                                                key={columnPan.key}
                                                style={[
                                                    styles.columnPan,
                                                    ...(state.activePanKey === columnPan.key && state.dragging ? [styles.columnPanActive] : []),
                                                    {
                                                        left,
                                                        width,
                                                        transform: [{
                                                            translateX: state.columnPanAnimatedValues[columnPan.key].x,
                                                        }],
                                                    },
                                                ]}
                                                {...columnHeadPanResponders[columnPan.key].panHandlers}
                                            >
                                                <AppText style={styles.columnHeadLabel} numberOfLines={1}>
                                                    {columnPan.label}
                                                </AppText>
                                                <TouchView
                                                    style={[styles.sortIconWrapper, basicStyles.flexCenterContent]}
                                                    action={() => sortByColumn(columnPan.key)}
                                                >
                                                    <TriangleDownSvg style={sortIconStyle}/>
                                                </TouchView>
                                            </Animated.View>
                                        );
                                    })
                                }
                                <Grid>
                                    <Row style={[
                                        styles.row,
                                        styles.rowHead,
                                        {
                                            zIndex: 1,
                                            elevation: 1,
                                        },
                                    ]}>
                                        {
                                            filteredOrderedColumns.map((column, colIndex) => {
                                                const sortIconStyle = [];

                                                if (!columnActive(column.key)) {
                                                    sortIconStyle.push(styles.sortIconInactive);
                                                } else if (state.reverseOrderBy) {
                                                    sortIconStyle.push(styles.sortIconRotated);
                                                }

                                                return (
                                                    <Col
                                                        key={column.key}
                                                        numberOfLines={1}
                                                        style={[
                                                            styles.cell,
                                                            styles.cellHead,
                                                            (colIndex === state.orderedColumns.length - 1 ? styles.cellLast : {}),
                                                            {
                                                                opacity: state.activePanKey === column.key && state.dragging ? 0 : 1,
                                                                ...(column.key === state.moveToColumnKey ? {backgroundColor: '#b4b6b9'} : {}),
                                                                whitespace: 'no-wrap',
                                                                minWidth: state.columnHeadContentSizes[column.key] + 70,
                                                            },
                                                        ]}
                                                        onLayout={({nativeEvent: {layout: {x, y, width, height}}}) => {
                                                            setState(prevState => {
                                                                return Object.assign({}, prevState, {columnSizes: {...prevState.columnSizes, [column.key]: width}});
                                                            });
                                                        }}
                                                    >
                                                        <View
                                                            style={{flexDirection: 'row', alignItems: 'center', whitespace: 'no-wrap'}} numberOfLines={1}
                                                            onLayout={({nativeEvent: {layout: {x, y, width, height}}}) => {
                                                                setState(prevState => {
                                                                    return Object.assign({}, prevState, {columnHeadContentSizes: {...prevState.columnHeadContentSizes, [column.key]: width}});
                                                                });
                                                            }}
                                                        >
                                                            <AppText style={styles.columnHeadLabel} numberOfLines={1}>
                                                                {column.label}
                                                            </AppText>
                                                        </View>

                                                        <TouchView
                                                            style={[styles.sortIconWrapper, basicStyles.flexCenterContent]}
                                                        >
                                                            <TriangleDownSvg style={sortIconStyle}/>
                                                        </TouchView>
                                                    </Col>
                                                );
                                            })
                                        }
                                    </Row>
                                    {
                                            sortedRows.map((row, rowIndex) => {
                                                return (
                                                    <Row
                                                        key={getRowKey(row)}
                                                        style={[
                                                            styles.row,
                                                            styles.rowBody,
                                                            (rowIndex % 2 !== 0 ? styles.rowOdd : {}),
                                                            (rowIndex === rows.length - 1 ? styles.rowLast : {}),
                                                            (rowIndex === state.cellFocused.rowIndex ? styles.rowFocused : {}),
                                                        ]}
                                                    >
                                                        {
                                                            filteredOrderedColumns.map((column, colIndex) => {
                                                                return (
                                                                    <Col
                                                                        key={column.key}
                                                                        style={
                                                                            [
                                                                                styles.cell,
                                                                                styles.cellBody,
                                                                                (colIndex === state.orderedColumns.length - 1 ? styles.cellLast : {}),
                                                                                (
                                                                                    rowIndex === state.cellFocused.rowIndex && colIndex === state.cellFocused.colIndex ?
                                                                                        styles.cellFocused
                                                                                        : {}
                                                                                ),
                                                                                {minWidth: state.columnHeadContentSizes[column.key] + 70},
                                                                            ]
                                                                        }
                                                                    >
                                                                        {
                                                                            {
                                                                                text: <TextInput
                                                                                    onFocus={() => setCellFocused(colIndex, rowIndex)}
                                                                                    onBlur={() => clearCellFocused()}
                                                                                    value={extractRowColumnValue(row, column)}
                                                                                    onChangeText={(value) => column.onChangeText(rowIndex, value)}
                                                                                    disabled={column.disabled}
                                                                                />,
                                                                                picker: <AppPickerInput
                                                                                    onFocus={() => setCellFocused(colIndex, rowIndex)}
                                                                                    onBlur={() => clearCellFocused()}
                                                                                    onValueChange={(value) => column.onValueChange(rowIndex, value)}
                                                                                    selectedValue={extractRowColumnValue(row, column)}
                                                                                    items={column.items}
                                                                                    labelExtractor={column.labelExtractor}
                                                                                    valueExtractor={column.pickerValueExtractor}
                                                                                    naked={true}
                                                                                    showLabel={false}
                                                                                    disabled={column.disabled}
                                                                                    title={column.title}
                                                                                    wrapperStyle={{
                                                                                        position: 'absolute',
                                                                                        top: 0,
                                                                                        left: 0,
                                                                                        right: 0,
                                                                                        bottom: 0,
                                                                                        justifyContent: 'center',
                                                                                        alignItems: 'flex-start',
                                                                                        paddingLeft: 20,
                                                                                        paddingRight: 20,
                                                                                    }}
                                                                                />,
                                                                                range: <StarRangeInput
                                                                                    value={extractRowColumnValue(row, column)}
                                                                                    disabled={column.disabled}
                                                                                    onChange={(value) => column.onChange(rowIndex, value)}
                                                                                />,
                                                                                custom: typeof column.render === 'function' ? column.render(row) : null,
                                                                            }[column.type]
                                                                        }
                                                                    </Col>
                                                                );
                                                            })
                                                        }

                                                    </Row>
                                                );
                                            })
                                    }
                                </Grid>
                            </View>
                        </ScrollView>
                    :
                    isLoading ?
                        <View style={[basicStyles.flexRow, basicStyles.flexCenterContent]}>
                            <Text style={{marginLeft: 10, marginRight: 6}}>
                                <ActivityIndicator size="large" color="#467AFF"/>
                            </Text>
                            <AppText>Gathering Data</AppText>
                        </View>
                        :
                        <View style={basicStyles.flexCenterContent}>
                            <AppText>No Records Found</AppText>
                        </View>
                }
            </View>
            <a ref={downloadAnchorRef}  href={exportState.downloadHref} download={exportTitle}/>
        </>
    );
}

const mapStateToProps = (state) => {
    const {miscellaneous} = state;
    return {miscellaneous};
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
        setSpreadSheetFilterOpen,
        setProcessingIndicatorActive
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(SpreadSheet);

const styles = EStyleSheet.create({
    spreadSheetContainer: {
        marginBottom: 55,
        zIndex: 1,
        elevation: 1,
    },
    dragIcon: {
        marginRight: 10,
        cursor: 'move',
        width: 6,
        flexShrink: 0,
    },
    cell: {
        paddingRight: 20,
        paddingLeft: 20,
        justifyContent: 'center',
        borderRightWidth: 1,
        borderRightColor: '#E2E9F4',
    },
    cellHead: {
        backgroundColor: '#F8FAFD',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingRight: 30,
    },
    columnPan: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        paddingRight: 30,
        paddingLeft: 20,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: '#F8FAFD',
        height: 39,
        opacity: 0,
        zIndex: 1,
        elevation: 1,
        cursor: 'move',
    },
    columnPanActive: {
        opacity: 1,
        zIndex: 99,
        elevation: 99,
    },
    cellBody: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    cellLast: {
        borderRightWidth: 0,
    },
    cellFocused: {
        backgroundColor: '#FFFFFF',
    },
    row: {
        flexGrow: 1,
        flexShrink: 1,
        borderBottomWidth: 1,
        borderBottomColor: '#E2E9F4',
    },
    rowHead: {
        height: 40,
        flexBasis: 40,
    },
    rowBody: {
        height: 65,
        flexBasis: 65,
    },
    rowLast: {
        borderBottomWidth: 0,
    },
    rowOdd: {
        backgroundColor: '#F8FAFD',
    },
    rowFocused: {
        backgroundColor: 'rgba(214,230,248,50)',
    },
    columnHeadText: {
        fontFamily: 'SourceSansPro-SemiBold',
        fontSize: 14,
        textAlign: 'center',
        whiteSpace: 'no-wrap',
    },
    grid: {
        borderWidth: 1,
        borderColor: '#E2E9F4',
        borderRadius: 5,
        minWidth: '100%',
    },
    gridInversion: {
        transform:[{rotateX:'180deg'}]
    },
    columnFilterSectionPadding: {
        paddingLeft: 16,
        paddingRight: 16,
    },
    columnHeadLabel: {
        whiteSpace: 'no-wrap',
    },
    sortIconWrapper: {
        padding: 5,
        position: 'absolute',
        right: 10,
        top: '50%',
        transform: [{translateY: '-50%'}],
        borderWidth: 1,
        borderColor: '#ddd',
        backgroundColor: '#FFF',
        cursor: 'pointer'
    },
    sortIconInactive: {
        opacity: .3,
    },
    sortIconRotated: {
        transform: [{rotate: '180deg'}]
    },
    hiddenAnchor: {
        position: 'absolute',
        opacity: 0,
        pointerEvents: 'none'
    },
    resultsHeaderContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
    },
    resultsHeaderDetailsContainer: {
        flexDirection: 'row',
    },
    resultsCount: {
        width: 1,
        height: 20,
        backgroundColor: '#D9E8F8',
        marginRight: 10,
        marginLeft: 10,
    },
    resultsFilterButton: {
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 15
    },
    navExportWrapper: {
        flexDirection: 'row',
    },
    '@media (max-width: 1100)': {
        resultsHeaderContainer: {
            flexWrap: 'wrap',
            maxWidth: '50%',
            marginRight: 'auto',
        },
        navExportWrapper: {
            alignItems: 'flex-end',
            flexDirection: 'column',
        },
        exportButton: {
            marginTop: 15
        },
        navExportWrapperNoNav: {
            alignSelf: 'flex-start'
        },
        exportButtonNoNav: {
            marginTop: 0,
        }
    },
    '@media (max-width: 750)': {
        resultsHeaderContainer: {
            flexDirection: 'column',
            alignItems: 'flex-start',

        },
        resultsFilterButton: {
            marginLeft: 0,
            marginTop: 15,
        },
        navExportWrapper: {
            alignSelf: 'flex-start',
        },
    },
    '@media (max-width: 600)': {
        resultsHeaderContainer: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        spreadSheetContainer: {
            alignItems: 'flex-end'
        },
    },
    '@media (max-width: 500)': {
        exportButton: {
            marginTop: 0
        }
    },

});
