import React, {useState, useEffect} from 'react';
import {View, ScrollView} from 'react-native';
import { useFocusEffect } from '@react-navigation/native';

import AppText from '../../components/AppText';
import {basicStyles} from '../../styles/basic';
import PageTop from '../../components/PageTop';
import {textStyles} from '../../styles/text';
import PageContentContainer from '../../components/PageContentContainer';
import CouponsService from '../../services/CouponsService';
import ActionRow from '../../components/ActionRow';
import {capitalizeString} from '../../utilities/methods';
import AppButton from '../../components/AppButton';
import {CommonActions} from '@react-navigation/native';
import Pagination from "../../components/Pagination";
import EStyleSheet from 'react-native-extended-stylesheet';

function CouponsList(props) {
	const [coupons, setCoupons] = useState([]);
	const {navigate} = props.navigation;
	const perPage = 10;
	const [pageCount, setPageCount] = useState(1);
	const [paginationRange, setPaginationRange] = useState([1]);

	let currentPage = props.route?.params?.currentPage ? parseInt(props.route.params.currentPage) : 1;

	useFocusEffect(
		React.useCallback(() => {
			const getCoupons = async () => {
				const couponsData = await CouponsService.getCouponsPaginated(null, perPage, currentPage);

				currentPage = couponsData.currentPage;
				setPageCount(couponsData.pageCount);

				setCoupons(couponsData.coupons);
				setPaginationRange(couponsData.paginationRange);
			};

			getCoupons();
		}, [currentPage])
	);

	return (
		<ScrollView contentContainerStyle={basicStyles.flexScale}>
			<PageTop style={[basicStyles.flexRow, basicStyles.justifyContentSpaceBetween, basicStyles.alignContentCenter]}>
				<AppText style={textStyles.pageTitle}>
					Coupons
				</AppText>
				<AppButton
					label={'Add Coupon'}
					action={() => navigate('CouponAdd')}
				/>
			</PageTop>
			<PageContentContainer>
				{
					coupons.map(coupon => {
						return (
							<ActionRow
								key={coupon.coupon_id}
								action={CommonActions.navigate('CouponEdit', {
									 couponId: coupon.coupon_id
								})}
								style={styles.actionRow}
							>
								<View style={[basicStyles.actionRowColumn, styles.actionRowItem]}>
									<AppText style={basicStyles.columnLabel}>
										Coupon Title
									</AppText>
									<AppText style={basicStyles.columnValue}>
										{coupon.coupon_title}
									</AppText>
								</View>
								<View style={[basicStyles.actionRowColumn, styles.actionRowItem]}>
									<AppText style={basicStyles.columnLabel}>
										Percent Off
									</AppText>
									<AppText style={basicStyles.columnValue}>
										{coupon.stripe_data.percent_off}%
									</AppText>
								</View>
								<View style={[basicStyles.actionRowColumn, styles.actionRowItem]}>
									<AppText style={basicStyles.columnLabel}>
										Duration
									</AppText>
									<AppText style={basicStyles.columnValue}>
										{capitalizeString(coupon.stripe_data.duration)}
									</AppText>
								</View>
							</ActionRow>
						);
					})
				}
			</PageContentContainer>
			<Pagination screenName="CouponsList" pageCount={pageCount} pageIndex={currentPage} paginationRange={paginationRange} />
		</ScrollView>
	)
}

export default CouponsList;

const styles = EStyleSheet.create({
	'@media (max-width: 800)': {
		actionRow: {
			paddingLeft: 40,
			paddingRight: 35,
		}
	},
	'@media (max-width: 450)': {
		actionRow: {
			flexDirection: 'column',
			alignItems: 'flex-start',
			justifyContent: 'flex-start',
		},
		actionRowItem: {
			marginTop: 15,
		}
	}
});
