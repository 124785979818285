import Http from "../utilities/Http";
import {API_ROOT} from "../config";

export default {
	async getSubscriptionTypes(onBoardingOnly = 0) {
		let response = await Http().get(API_ROOT + '/subscription-types/', {
			params: {
				onBoardingOnly
			}
		});

		return response.data.subscriptionTypes;
	},
};
