import Http from "../utilities/Http";
import {API_ROOT} from "../config";

export default {
	async getUsers(organizationId, search = null, orderBy = null, order = null, joinedAfterDate = null, joinedBeforeDate = null) {
		let response = await Http().get(API_ROOT + '/organizations/' + organizationId + '/users', {
			params: {
				search,
				order,
				orderBy,
				joinedAfterDate,
				joinedBeforeDate,
			}
		});

		return response.data.users;
	},
	async getUsersPaginated(organizationId, search = null, orderBy = null, order = null, joinedAfterDate = null, joinedBeforeDate = null, perPage = null, currentPage = null, filterByColumns = null, filterByColumnValues = null) {
		let response = await Http().get(API_ROOT + '/organizations/' + organizationId + '/users/paginated', {
			params: {
				search,
				order,
				orderBy,
				joinedAfterDate,
				joinedBeforeDate,
				perPage,
				currentPage,
				filterByColumns,
				filterByColumnValues
			}
		});

		return response.data;
	},
	async getUser(organizationId, userId) {
		let response = await Http().get(API_ROOT + '/organizations/' + organizationId + '/users/' + userId);

		return response.data.user;
	},
	async insertUser(organizationId, user) {
		let response = await Http().post(API_ROOT + '/organizations/' + organizationId + '/users', {
			user
		});

		return response.data.user;
	},
	async updateUser(organizationId, userId, user) {
		let response = await Http().post(API_ROOT + '/organizations/' + organizationId + '/users/' + userId, {
			user
		});

		return response.data.user;
	},
	async uploadImage(organizationId, formData) {
		let response = await Http().post(API_ROOT + '/organizations/' + organizationId + '/users/upload-image', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		});

		return response.data.fileName;
	},
	async importUsers(organizationId, formData) {
		let response = await Http().post(API_ROOT + '/organizations/' + organizationId + '/users/import', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		});

		return response.data;
	},
	async getUsersImportConfiguration(organizationId, formData) {
		let response = await Http().post(API_ROOT + '/organizations/' + organizationId + '/users/get-import-configuration', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		});

		return response.data;
	},
	async sendUserInvite(organizationId, userId) {
		let response = await Http().get(API_ROOT + '/organizations/' + organizationId + '/users/send-user-invitation/' + userId);

		return response.data.user;
	},
	async getUsersWithJobs(organizationId, search = null, orderBy = null, order = null, joinedAfterDate = null, joinedBeforeDate = null) {
		let response = await Http().get(API_ROOT + '/organizations/' + organizationId + '/users/dashboard', {
			params: {
				search,
				order,
				orderBy,
				joinedAfterDate,
				joinedBeforeDate,
			}
		});

		return response.data.users;
	},
};
