import React, {useState, useEffect, useCallback, useMemo, useRef} from 'react';
import {TouchableOpacity, View} from 'react-native';
import EStyleSheet from "react-native-extended-stylesheet";
import {CancelToken} from "axios";
import {connect} from 'react-redux';

import MessagesService from "../../../../services/MessagesService";
import ContentSection from "../../../../components/ContentSection";
import {basicStyles} from "../../../../styles/basic";
import AppText from "../../../../components/AppText";
import {textStyles} from "../../../../styles/text";
import AppPickerInput from "../../../../components/AppPickerInput";
import AppButton from "../../../../components/AppButton";
import {generateUuid} from "../../../../utilities/methods";
import TimeZonesService from "../../../../services/TimeZonesService";
import ToggleField from "../../../../components/ToggleField";
import AppTextInput from "../../../../components/AppTextInput";
import CloseSvg from "../../../../components/svgs/CloseSvg";
import {setWarning} from "../../../../store/warning/warningActions";
import {bindActionCreators} from "redux";

function AutoReplies({organizationId, setWarning}) {
    const [autoReplies, setAutoReplies] = useState([]);
    const [timeZones, setTimeZones] = useState([]);
    const [insertingIndexes, setInsertingIndexes] = useState([]);

    const updateDataRequestSources = useRef([]);

    const {hours, minutes, amPm} = useMemo(() => {
        let hours = [];
        for (let i = 1; i <= 12; i++) {
            let hour = '';
            if (i < 10) {
                hour += '0';
            }
            hour += i + '';
            hours.push({key: i, value: hour});
        }

        let minutes = [];
        for (let i = 0; i <= 59; i++) {
            let min = i + '';
            if (i < 10) {
                min = '0' + i;
            }

            minutes.push({key: min, value: min});
        }

        const amPm = [
            {
                value: 'AM',
            },
            {
                value: 'PM',
            },
        ];

        return {hours, minutes, amPm};
    }, []);

    const updateAutoReply = useCallback((index, prop, value) => {
        setAutoReplies(current => {
            let autoReplies = [...current];
            autoReplies[index][prop] = value;

            return autoReplies;
        });
    }, [autoReplies, setAutoReplies])

    const extractTimeParts = useCallback((time) => {
        let [hourPart, minutePart] = time.split(':');
        let amPmPart;
        if (hourPart == 0) {
            amPmPart = 'AM';
            hourPart = 12 + '';
        } else if (hourPart >= 12) {
            amPmPart = 'PM';
            hourPart = (hourPart > 12 ? hourPart - 12 : hourPart) + '';
            if (hourPart < 10) {
                hourPart = '0' + hourPart;
            }
        } else {
            amPmPart = 'AM';
        }

        return [hourPart, minutePart, amPmPart];

    }, []);


    const formatAutoReplyTimeParts = useCallback((autoReply) => {
        if (autoReply.message_auto_reply_min_time) {
            const [minTimeHour, minTimeMinute, minTimeAmPm] = extractTimeParts(autoReply.message_auto_reply_min_time);
            autoReply.minTimeHour = minTimeHour;
            autoReply.minTimeMinute = minTimeMinute;
            autoReply.minTimeAmPm = minTimeAmPm;
        }

        if (autoReply.message_auto_reply_max_time) {
            const [maxTimeHour, maxTimeMinute, maxTimeAmPm] = extractTimeParts(autoReply.message_auto_reply_max_time);
            autoReply.maxTimeHour = maxTimeHour;
            autoReply.maxTimeMinute = maxTimeMinute;
            autoReply.maxTimeAmPm = maxTimeAmPm;
        }

        return autoReply;
    }, [extractTimeParts]);

    const formatTimeFromParts = useCallback((hour, minute, amPm) => {
        hour = parseInt(hour);
        minute = parseInt(minute);
        if (amPm == 'PM' && hour < 12) {
            hour += 12;
        } else if (amPm == 'AM' && hour == 12) {
            hour = 0;
        }

        if (hour < 10) {
            hour = '0' + hour;
        }

        if (minute < 10) {
            minute = '0' + minute;
        }

        return `${hour}:${minute}:00`
    }, []);

    const formatAutoReplyTimes = useCallback((autoReply) => {
        autoReply.message_auto_reply_min_time = formatTimeFromParts(autoReply.minTimeHour, autoReply.minTimeMinute, autoReply.minTimeAmPm);
        autoReply.message_auto_reply_max_time = formatTimeFromParts(autoReply.maxTimeHour, autoReply.maxTimeMinute, autoReply.maxTimeAmPm);

        return autoReply;
    }, [formatTimeFromParts])

    const saveAutoReply = useCallback(async (autoReplyIndex) => {
        let autoReply;
        if (!autoReplies[autoReplyIndex].message_auto_reply_id) {
            setInsertingIndexes(current => {
                return [
                    ...current,
                    autoReplyIndex
                ]
            });
            autoReply = await MessagesService.insertAutoReply(organizationId, formatAutoReplyTimes(autoReplies[autoReplyIndex]));
        } else {
            if (updateDataRequestSources.current[autoReplyIndex]) {
                updateDataRequestSources.current[autoReplyIndex].cancel('Request Canceled');
            }
            updateDataRequestSources.current[autoReplyIndex] = CancelToken.source();
            autoReply = await MessagesService.updateAutoReply(organizationId, autoReplies[autoReplyIndex].message_auto_reply_id, formatAutoReplyTimes(autoReplies[autoReplyIndex]), updateDataRequestSources.current[autoReplyIndex].token);
        }

        setAutoReplies(current => {
            const autoReplies = [...current];

            autoReplies.splice(autoReplyIndex, 1, formatAutoReplyTimeParts(autoReply))

            return autoReplies;
        })
    }, [organizationId, autoReplies, formatAutoReplyTimeParts, formatAutoReplyTimes]);

    const deleteAutoReply = useCallback(async (autoReplyIndex) => {
        if (autoReplies[autoReplyIndex].message_auto_reply_id) {
            await MessagesService.deleteAutoReply(organizationId, autoReplies[autoReplyIndex].message_auto_reply_id)
        }

        setAutoReplies((current) => {
            const autoReplies = [...current];

            autoReplies.splice(autoReplyIndex, 1);

            return autoReplies;
        });

    }, [organizationId, autoReplies]);


    useEffect(() => {
        MessagesService.getAutoReplies(organizationId)
            .then(autoReplies => {
                autoReplies = autoReplies.map(formatAutoReplyTimeParts);
                setAutoReplies(current => [...current, ...autoReplies]);
            });
        TimeZonesService.getTimeZones()
            .then(timeZonesData => {
                setTimeZones(timeZonesData);
            });
    }, [organizationId, formatAutoReplyTimeParts]);

    return (
        <>
            <View
                style={[basicStyles.flexRow, basicStyles.justifyContentSpaceBetween, basicStyles.alignContentCenter, {marginBottom: 25}]}>
                <AppText style={[textStyles.pageSubTitle, textStyles.semiBold, {marginBottom: 0}]}>
                    Auto-Replies
                </AppText>
                <AppButton
                    label="Add Auto-Reply"
                    action={() => {
                        setAutoReplies(current => {
                            return [
                                {temp_key: generateUuid()},
                                ...current,
                            ];
                        })
                    }}
                />
            </View>
            {
                autoReplies.length > 0 ?
                    autoReplies.map((autoReply, index) => {
                        return (
                            <ContentSection key={autoReply.message_auto_reply_id || autoReply.temp_key} style={[
                                basicStyles.inputContainerPaddingVertical,
                                basicStyles.inputContainerPaddingHorizontal,
                                {marginBottom: 40}
                            ]}>
                                <TouchableOpacity
                                    style={[styles.deleteIcon]}
                                    onPress={() => {
                                        setWarning({
                                            confirmAction: () => {
                                                deleteAutoReply(index)
                                            },
                                            warningMessage: 'Are you sure you want to delete this Auto-Reply?',
                                            confirmLabel: 'Confirm',
                                            rejectLabel: 'Cancel',
                                            active: true
                                        });
                                    }}>
                                    <CloseSvg/>
                                </TouchableOpacity>
                                <AppText style={styles.heading}>
                                    When meeting the following condition
                                </AppText>

                                <View style={[
                                    basicStyles.flexRow,
                                    basicStyles.alignContentStart,
                                    basicStyles.justifyContentFlexStart,
                                    basicStyles.flexWrap,
                                ]}>
                                    <View style={[basicStyles.flexRow, basicStyles.alignContentStart, {
                                        maxWidth: '100%',
                                        flexWrap: 'wrap'
                                    }]}>
                                        <View>
                                            <View
                                                style={[basicStyles.inputWrapper, styles.inputWrapper, {marginTop: 17,}]}>
                                                <AppPickerInput
                                                    label="Current time is"
                                                    title="condition"
                                                    selectedValue={autoReply.message_auto_reply_condition_type || ''}
                                                    onValueChange={(value) => {
                                                        updateAutoReply(index, 'message_auto_reply_condition_type', value);
                                                    }}
                                                    items={[
                                                        {
                                                            item: 'within'
                                                        },
                                                        {
                                                            item: 'not within'
                                                        }
                                                    ]}
                                                    labelExtractor="item"
                                                    valueExtractor="item"
                                                />
                                            </View>
                                            <View
                                                style={[basicStyles.inputWrapper, styles.inputWrapper, {marginTop: 17}]}>
                                                <AppPickerInput
                                                    label="Timezone"
                                                    title="timezone"
                                                    selectedValue={autoReply.time_zone_id || ''}
                                                    onValueChange={(value) => {
                                                        updateAutoReply(index, 'time_zone_id', value);
                                                    }}
                                                    items={timeZones}
                                                    labelExtractor="time_zone_title"
                                                    valueExtractor="time_zone_id"
                                                />
                                            </View>
                                        </View>
                                    </View>
                                    <View style={{flex: 1}}>
                                        <AppText style={styles.subHeading}>
                                            Start Time
                                        </AppText>

                                        <View style={[
                                            basicStyles.flexRow,
                                            basicStyles.alignContentCenter,
                                            basicStyles.justifyContentFlexStart,
                                            basicStyles.flexWrap
                                        ]}>
                                            <View style={[basicStyles.inputWrapper, styles.inputWrapper]}>
                                                <AppPickerInput
                                                    label="Hour"
                                                    onValueChange={(value) => {
                                                        updateAutoReply(index, 'minTimeHour', value);
                                                    }}
                                                    selectedValue={autoReply.minTimeHour}
                                                    items={hours}
                                                    labelExtractor="value"
                                                    valueExtractor="value"
                                                />
                                            </View>
                                            <View style={[basicStyles.inputWrapper, styles.inputWrapper]}>
                                                <AppPickerInput
                                                    label="Minute"
                                                    onValueChange={(value) => {
                                                        updateAutoReply(index, 'minTimeMinute', value);
                                                    }}
                                                    selectedValue={autoReply.minTimeMinute}
                                                    items={minutes}
                                                    labelExtractor="value"
                                                    valueExtractor="value"
                                                />
                                            </View>
                                            <View style={[basicStyles.inputWrapper, styles.inputWrapper]}>
                                                <AppPickerInput
                                                    label="AM/PM"
                                                    onValueChange={(value) => {
                                                        updateAutoReply(index, 'minTimeAmPm', value);
                                                    }}
                                                    selectedValue={autoReply.minTimeAmPm}
                                                    items={amPm}
                                                    labelExtractor="value"
                                                    valueExtractor="value"
                                                />
                                            </View>
                                        </View>

                                        <AppText style={styles.subHeading}>
                                            End Time
                                        </AppText>

                                        <View style={[
                                            basicStyles.flexRow,
                                            basicStyles.alignContentCenter,
                                            basicStyles.justifyContentFlexStart,
                                            basicStyles.flexWrap
                                        ]}>
                                            <View style={[basicStyles.inputWrapper, styles.inputWrapper]}>
                                                <AppPickerInput
                                                    label="Hour"
                                                    onValueChange={(value) => {
                                                        updateAutoReply(index, 'maxTimeHour', value);
                                                    }}
                                                    selectedValue={autoReply.maxTimeHour}
                                                    items={hours}
                                                    labelExtractor="value"
                                                    valueExtractor="value"
                                                />
                                            </View>
                                            <View style={[basicStyles.inputWrapper, styles.inputWrapper]}>
                                                <AppPickerInput
                                                    label="Minute"
                                                    onValueChange={(value) => {
                                                        updateAutoReply(index, 'maxTimeMinute', value);
                                                    }}
                                                    selectedValue={autoReply.maxTimeMinute}
                                                    items={minutes}
                                                    labelExtractor="value"
                                                    valueExtractor="value"
                                                />
                                            </View>
                                            <View style={[basicStyles.inputWrapper, styles.inputWrapper]}>
                                                <AppPickerInput
                                                    label="AM/PM"
                                                    onValueChange={(value) => {
                                                        updateAutoReply(index, 'maxTimeAmPm', value);
                                                    }}
                                                    selectedValue={autoReply.maxTimeAmPm}
                                                    items={amPm}
                                                    labelExtractor="value"
                                                    valueExtractor="value"
                                                />
                                            </View>
                                        </View>
                                    </View>

                                </View>


                                <View style={[
                                    basicStyles.flexRow,
                                    basicStyles.alignContentCenter,
                                    basicStyles.justifyContentCenter,
                                    basicStyles.flexWrap
                                ]}>
                                    <View style={[basicStyles.inputWrapper, styles.toggleWrapper]}>
                                        <ToggleField
                                            label="Sunday"
                                            value={parseInt(autoReply.message_auto_reply_on_sunday) === 1}
                                            onChange={value => {
                                                updateAutoReply(index, 'message_auto_reply_on_sunday', value ? '1' : '0');
                                            }}
                                        />
                                    </View>
                                    <View style={[basicStyles.inputWrapper, styles.toggleWrapper]}>
                                        <ToggleField
                                            label="Monday"
                                            value={parseInt(autoReply.message_auto_reply_on_monday) === 1}
                                            onChange={value => {
                                                updateAutoReply(index, 'message_auto_reply_on_monday', value ? '1' : '0');
                                            }}
                                        />
                                    </View>
                                    <View style={[basicStyles.inputWrapper, styles.toggleWrapper]}>
                                        <ToggleField
                                            label="Tuesday"
                                            value={parseInt(autoReply.message_auto_reply_on_tuesday) === 1}
                                            onChange={value => {
                                                updateAutoReply(index, 'message_auto_reply_on_tuesday', value ? '1' : '0');
                                            }}
                                        />
                                    </View>
                                    <View style={[basicStyles.inputWrapper, styles.toggleWrapper]}>
                                        <ToggleField
                                            label="Wednesday"
                                            value={parseInt(autoReply.message_auto_reply_on_wednesday) === 1}
                                            onChange={value => {
                                                updateAutoReply(index, 'message_auto_reply_on_wednesday', value ? '1' : '0');
                                            }}
                                        />
                                    </View>
                                    <View style={[basicStyles.inputWrapper, styles.toggleWrapper]}>
                                        <ToggleField
                                            label="Thursday"
                                            value={parseInt(autoReply.message_auto_reply_on_thursday) === 1}
                                            onChange={value => {
                                                updateAutoReply(index, 'message_auto_reply_on_thursday', value ? '1' : '0');
                                            }}
                                        />
                                    </View>
                                    <View style={[basicStyles.inputWrapper, styles.toggleWrapper]}>
                                        <ToggleField
                                            label="Friday"
                                            value={parseInt(autoReply.message_auto_reply_on_friday) === 1}
                                            onChange={value => {
                                                updateAutoReply(index, 'message_auto_reply_on_friday', value ? '1' : '0');
                                            }}
                                        />
                                    </View>
                                    <View style={[basicStyles.inputWrapper, styles.toggleWrapper]}>
                                        <ToggleField
                                            label="Saturday"
                                            value={parseInt(autoReply.message_auto_reply_on_saturday) === 1}
                                            onChange={value => {
                                                updateAutoReply(index, 'message_auto_reply_on_saturday', value ? '1' : '0');
                                            }}
                                        />
                                    </View>
                                </View>

                                <AppText style={styles.heading}>
                                    Automatically reply with this message
                                </AppText>

                                <View style={[
                                    basicStyles.flexRow,
                                    basicStyles.alignContentCenter,
                                    basicStyles.justifyContentFlexStart,
                                    basicStyles.flexWrap
                                ]}>
                                    <View style={[basicStyles.inputWrapper, {width: '100%'}]}>
                                        <AppTextInput
                                            wrapperStyle={{height: 'auto'}}
                                            inputStyle={{minHeight: 57}}
                                            multiline={true}
                                            lines={4}
                                            label="Message Body"
                                            value={autoReply.message_auto_reply_body}
                                            onChangeText={value => {
                                                updateAutoReply(index, 'message_auto_reply_body', value);
                                            }}
                                        />
                                    </View>
                                </View>
                                <View style={[basicStyles.alignContentCenter]}>
                                    <AppButton
                                        label="Save"
                                        action={() => {
                                            if (!insertingIndexes.includes(index)) {
                                                saveAutoReply(index);
                                            }
                                        }}
                                    />
                                </View>

                            </ContentSection>
                        );
                    })
                    :
                    <ContentSection style={[
                        basicStyles.inputContainerPaddingVertical,
                        basicStyles.inputContainerPaddingHorizontal,
                        basicStyles.alignContentCenter,
                        basicStyles.justifyContentCenter
                    ]}>
                        <AppText>
                            No Auto-Replies Currently Configured
                        </AppText>
                    </ContentSection>
            }
        </>
    );
}

const mapDispatchToProps = (dispatch) => (
    bindActionCreators({
        setWarning,
    }, dispatch)
);

export default connect(null, mapDispatchToProps)(AutoReplies)

const styles = EStyleSheet.create({
    heading: {
        fontFamily: 'SourceSansPro-Bold',
        paddingLeft: 22,
        fontSize: 16,
        marginBottom: 10
    },
    subHeading: {
        fontFamily: 'SourceSansPro-Bold',
        paddingLeft: 22
    },
    toggleWrapper: {
        minWidth: 130
    },
    inputWrapper: {
        minWidth: 170,
        flex: 1
    },
    deleteIcon: {
        position: 'absolute',
        top: 15,
        right: 15
    }
});
