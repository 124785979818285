import * as React from "react"
import Svg, { G, Circle, Path } from "react-native-svg"

function YellowCircleInProgressSvg(props) {
	return (
		<Svg
			xmlns="http://www.w3.org/2000/svg"
			width={41.819}
			height={41.819}
			viewBox="0 0 41.819 41.819"
			{...props}
		>
			<G data-name="Group 981" transform="translate(44 3)">
				<Circle
					data-name="Ellipse 33"
					cx={20.909}
					cy={20.909}
					transform="translate(-44 -3)"
					fill="#fac73d"
					opacity={0.068}
					r={20.909}
				/>
				<Circle
					data-name="Ellipse 32"
					cx={15.407}
					cy={15.407}
					transform="translate(-38.497 3.502)"
					fill="#fac73d"
					r={15.407}
				/>
				<Path
					data-name="Path 497"
					d="M-24 12.08V9a10.056 10.056 0 000 20v-3.08A7.361 7.361 0 01-30 19a7.361 7.361 0 016-6.92zm7.97 5.92H-13a9.63 9.63 0 00-9-9v3.08A7.164 7.164 0 01-16.03 18zM-22 25.92V29a9.63 9.63 0 009-9h-3.03A7.164 7.164 0 01-22 25.92z"
					fill="#fff"
				/>
			</G>
		</Svg>
	)
}

export default YellowCircleInProgressSvg
