import * as React from "react"
import Svg, { G, Rect, Path } from "react-native-svg"

function MenuIconMobileOpenSvg(props) {
	return (
		<Svg width={40} height={40} viewBox="0 0 40 40" {...props}>
			<G data-name="Group 984">
				<G data-name="Group 983">
					<Rect
						data-name="Rectangle 456"
						width={40}
						height={40}
						rx={20}
						fill="#9ba8ca"
						opacity={0.131}
					/>
				</G>
				<G
					data-name="Group 982"
					fill="none"
					stroke="#0065db"
					strokeWidth={1.25}
				>
					<Path data-name="Line 159" d="M10.5 15.5h20" />
					<Path data-name="Line 160" d="M10.5 20.5h20" />
					<Path data-name="Line 161" d="M10.5 25.5h20" />
				</G>
			</G>
		</Svg>
	)
}

export default MenuIconMobileOpenSvg
