import React, {useState, useEffect} from 'react';
import {View, ScrollView, StyleSheet} from 'react-native';
import {connect} from 'react-redux';
import {CommonActions} from '@react-navigation/native';

import {basicStyles} from '../../styles/basic';
import {AVATAR_DEFAULT, AVATAR_PATH_ORG, AVATAR_PATH_USER, CLIENT_ADMIN_ROLE_ID, CLIENT_ACCOUNT_OWNER_ROLE_ID} from '../../config';
import OrganizationsService from '../../services/OrganizationsService';
import PageTop from '../../components/PageTop';
import Avatar from '../../components/Avatar';
import AppText from '../../components/AppText';
import {textStyles} from '../../styles/text';
import PageContentContainer from '../../components/PageContentContainer';
import ContentSection from '../../components/ContentSection';
import UsersService from '../../services/UsersService';
import {checkTimestampAfter, formatDate, getUserDisplayName} from '../../utilities/methods';
import AppBarChart from '../../components/AppBarChart';
import BackBar from '../../components/BackBar';
import EStyleSheet from 'react-native-extended-stylesheet';

function ClientCommissions(props) {
	const [organization, setOrganization] = useState({});
	const [users, setUsers] = useState([]);
	const [invoices, setInvoices] = useState([]);

	const organizationId = props.route?.params?.organizationId;

	useEffect(() => {
		const getData = async () => {
			let orgData = await OrganizationsService.getOrganization(organizationId);
			setOrganization(orgData);
			let usersData = await UsersService.getUsers(organizationId);
			setUsers(usersData);
			let invoicesData = await OrganizationsService.getCustomerInvoices(organizationId);
			setInvoices(invoicesData);
		};

		getData();
	}, [organizationId]);

	const adminUsers = users.filter(user => {
		return user.role_id == CLIENT_ACCOUNT_OWNER_ROLE_ID || user.role_id == CLIENT_ADMIN_ROLE_ID;
	});

	function invoicePaymentReducer(invoiceA, invoiceB) {
		let value1, value2;
		if (typeof invoiceA === 'object') {
			value1 = invoiceA.amount_paid;
		} else {
			value1 = invoiceA;
		}
		if (typeof invoiceB === 'object') {
			value2 = invoiceB.amount_paid;
		} else {
			value2 = invoiceB;
		}
		return value1 + value2;
	}

	const commissionRate = props.auth.organization.organization_partner_commission_rate / 100;

	const commissions1Months = commissionRate * invoices.filter(invoice => {
		return checkTimestampAfter(invoice.period_start, 1);
	}).reduce(invoicePaymentReducer, 0);

	const commissions12Months = commissionRate * invoices.filter(invoice => {
		return checkTimestampAfter(invoice.period_start, 12);
	}).reduce(invoicePaymentReducer, 0);

	const commissionsAll = commissionRate * invoices.reduce(invoicePaymentReducer, 0);

	return (
		<View style={basicStyles.flexScale}>
			<BackBar
				action={CommonActions.navigate('Clients', {
					screen: 'OrganizationList'
				})}
				label="Back to all Clients"
			/>
			<ScrollView contentContainerStyle={basicStyles.flexScale}>
				<PageTop style={[
					basicStyles.flexRow,
					basicStyles.alignContentCenter,
					basicStyles.justifyContentSpaceBetween,
				]}>
					<View style={[
						basicStyles.flexRow,
						basicStyles.alignContentCenter,
						basicStyles.justifyContentSpaceBetween,
					]}>
						<Avatar
							source={{uri: organization.organization_thumbnail ? AVATAR_PATH_ORG + organization.organization_thumbnail : AVATAR_DEFAULT}}
							width={66}
							style={{marginRight: 20}}
						/>
						<AppText style={[textStyles.pageTitle, textStyles.semiBold]}>
							{organization.organization_title}
						</AppText>
					</View>
				</PageTop>
				<PageContentContainer>
					<AppText style={[textStyles.pageSubTitle, textStyles.semiBold]}>
						{/*todo: Why "Fluid Account"?*/}
						Fluid Account
					</AppText>
					<ContentSection style={[
						basicStyles.flexRow,
						basicStyles.justifyContentSpaceBetween,
						basicStyles.alignContentCenter,
						styles.sectionPadding,
						styles.fluidAccountContainer,
					]}>
						<View style={styles.dataColumn}>
							<View style={styles.dataRow}>
								<AppText style={styles.dataRowLabel}>
									Account Type
								</AppText>
								<AppText style={styles.dataRowValue}>
									{organization.subscription_type_title} Plan
								</AppText>
							</View>
							<View style={styles.dataRow}>
								<AppText style={styles.dataRowLabel}>
									Active Team Members
								</AppText>
								<AppText style={styles.dataRowValue}>
									{organization.activeMembersCount}
								</AppText>
							</View>
							<View style={styles.dataRow}>
								<AppText style={styles.dataRowLabel}>
									Storefronts
								</AppText>
								<AppText style={styles.dataRowValue}>
									{organization.storeFrontsCount}
								</AppText>
							</View>
						</View>
						<View style={styles.dataColumn}>
							<View style={styles.dataRow}>
								<AppText style={styles.dataRowLabel}>
									Reviews Completed in the last 30 days
								</AppText>
								<AppText style={styles.dataRowValue}>
									{organization.surveyResponsesCompletedLast30DaysCount}
								</AppText>
							</View>
							<View style={styles.dataRow}>
								<AppText style={styles.dataRowLabel}>
									Posts in last 30 days
								</AppText>
								<AppText style={styles.dataRowValue}>
									{organization.jobsPostedLast30DaysCount}
								</AppText>
							</View>
							<View style={[styles.dataRow, styles.lastDataRow]}>
								<AppText style={styles.dataRowLabel}>
									Change in total Posts
								</AppText>
								<AppText style={styles.dataRowValue}>
									{organization.surveyResponsesCompletedLast30DaysCount}
								</AppText>
							</View>
						</View>
					</ContentSection>
					<AppText style={[textStyles.pageSubTitle, textStyles.semiBold]}>
						Team Admins
					</AppText>
					<ContentSection style={[styles.sectionPadding, styles.teamContainerWrapper]}>
						{
							adminUsers.map((user, userIndex) => {
								return (
									<View
										key={user.user_organization_id}
										style={[
											styles.teamContainer,
											...(userIndex === adminUsers.length - 1 ? [{borderBottomWidth: 0}] : []),
										]}
									>
										<View style={[
											basicStyles.flexRow,
											basicStyles.alignContentCenter,
											styles.mainColumn
										]}>
											<Avatar
												source={{uri: user.user_avatar_file ? AVATAR_PATH_USER + user.user_avatar_file : AVATAR_DEFAULT}}
												width={44}
												style={{marginRight: 12}}
											/>
											<AppText style={styles.userName}>
												{getUserDisplayName(user)}
											</AppText>
										</View>
										<View style={[
											styles.secondaryColumn
										]}>
											<View style={[
												basicStyles.flexRow,
												basicStyles.alignContentCenter,
												basicStyles.justifyContentCenter,
												styles.rowSection
											]}>
												<AppText style={styles.altLabel}>
													Role
												</AppText>
												<AppText>
													{user.role_title}
												</AppText>
											</View>
											<View style={[
												basicStyles.flexRow,
												basicStyles.alignContentCenter,
												basicStyles.justifyContentFlexEnd,
												styles.rowSection,
											]}>
												<AppText style={styles.altLabel}>
													Signup Date
												</AppText>
												<AppText>
													{user.user_organization_accepted_timestamp ? formatDate(user.user_organization_accepted_timestamp, 'MM.DD.YYYY') : 'Pending'}
												</AppText>
											</View>
										</View>
									</View>
								);
							})
						}
					</ContentSection>
					<AppText style={[textStyles.pageSubTitle, textStyles.semiBold]}>
						Commission Generated
					</AppText>
					<ContentSection style={[styles.sectionPadding, {marginBottom: 70}]}>
						{
							invoices.length ?
								<AppBarChart
									data={[
										{
											label: 'Past Month',
											value: commissions1Months / 100,
										},
										{
											label: 'Past Year',
											value: commissions12Months / 100,
										},
										{
											label: 'All Time',
											value: commissionsAll / 100,
										},
									]}
								/>
								: null
						}
					</ContentSection>
				</PageContentContainer>
			</ScrollView>
		</View>
	);
}

const mapStateToProps = (state) => {
	const {auth} = state;
	return {auth};
};

export default connect(mapStateToProps)(ClientCommissions);

const styles = EStyleSheet.create({
	dataColumn: {
		width: '45%',
	},
	dataRow: {
		padding: 24,
		borderBottomWidth: 1,
		borderBottomColor: '#D9E8F8',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	dataRowLabel: {
		fontSize: 16,
		color: '#9BA8CA',
	},
	dataRowValue: {
		fontSize: 16,
	},
	sectionPadding: {
		padding: 24,
	},
	fluidAccountContainer: {
		marginBottom: 70,
	},
	userName: {
		fontFamily: 'SourceSansPro-SemiBold',
	},
	altLabel: {
		color: '#9BA8CA',
		marginRight: 12,
	},
	commissions: {
		fontFamily: 'SourceSansPro-Bold',
		fontSize: 16,
	},
	rowSection: {
		width: '50%'
	},
	mainColumn: {
		width: '33.333%'
	},
	secondaryColumn: {
		width: '66.666%',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	teamContainerWrapper: {
		marginBottom: 70
	},
	teamContainer: {
		padding: 24,
		borderBottomWidth: 1,
		borderBottomColor: '#D9E8F8',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	'@media (max-width: 950)': {
		fluidAccountContainer: {
			flexDirection: 'column',
			width: '100%'
		},
		dataColumn: {
			width: '100%',
		},
		lastDataRow: {
			borderBottomWidth: 0,
		}
	},
	'@media (max-width: 768)': {
		teamContainerWrapper: {
			paddingLeft: 0,
			paddingRight: 0,
			paddingTop: 0,
		},
		teamContainer: {
			flexDirection: 'column',
			alignItems: 'flex-start',
			justifyContent: 'center',
			paddingLeft: 0,
			paddingRight: 0,
		},
		mainColumn: {
			width: '100%',
			paddingLeft: 24,
			paddingRight: 24,
			//borderBottomWidth: 1,
			//borderBottomColor: '#D9E8F8',
		},
		secondaryColumn: {
			width: '100%',
			paddingLeft: 24,
			paddingRight: 24,
			paddingTop: 15,
		},
		rowSection: {
			justifyContent: 'flex-start'
		}
	},
	'@media (max-width: 550)': {
		dataRow: {
			paddingLeft: 0,
			paddingRight: 0,
		},
		dataRowLabel: {
			paddingRight: 10
		}
	},
	'@media (max-width: 450)': {
		rowSection: {
			flexDirection: 'column',
			alignItems: 'flex-start'
		}
	}
});
