import * as React from "react"
import Svg, { Path } from "react-native-svg"

function CheckMarkSvg({fill = '#FFFFFF', ...props}) {
	return (
		<Svg width={11.971} height={8.91} viewBox="0 0 11.971 8.91" {...props}>
			<Path
				data-name="Path 452"
				d="M4.275 8.91L0 4.635l1.206-1.206 3.07 3.061L10.766 0l1.206 1.214z"
				fill={fill}
			/>
		</Svg>
	)
}

export default CheckMarkSvg;
