import React from 'react';
import {View} from 'react-native';
import {basicStyles} from '../styles/basic';

function ContentSection({children, style = {}, ...rest}) {
	return (
		<View style={[basicStyles.contentSection, style]} {...rest}>
			{children}
		</View>
	);
}

export default ContentSection;
