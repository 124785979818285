import React, {forwardRef, createRef} from 'react';
import {View} from 'react-native';

import AppText from './AppText';
import {inputStyles} from '../styles/input';
import {basicStyles} from '../styles/basic';
import StarRangeInput from "./StarsRangeInput";

const StarRangeInputField = forwardRef((
	{
		label,
		value = '',
		wrapperStyle = {},
		labelStyle = {},
		inputStyle = {},
		actionButton = null,
		...rest
	},
	ref,
) => {
	if (!ref) {
		ref = createRef();
	}

	const ActionButton = () => {
		return actionButton;
	};

	return (
		<View
			style={[
				inputStyles.inputWrapper,
				basicStyles.flexRow,
				basicStyles.alignContentCenter,
				wrapperStyle,
			]}>

			<View style={basicStyles.flexScale}>
				<AppText style={[inputStyles.inputLabel, labelStyle]}>
					{label}
				</AppText>
				<StarRangeInput
					value={value}
					{...rest}
				/>
			</View>
			{
				actionButton ?
					<ActionButton/>
					: null
			}
		</View>
	);
});

export default StarRangeInputField;
