import React from 'react';
import {Platform, Animated} from 'react-native';

function TouchView({action = () => {}, style= {}, ...rest}) {
	if(Platform.OS === 'web') {
		return (<Animated.View onClick={(e) => action(e)} style={[{cursor: 'pointer'}, style]} {...rest}/>)
	}

	return (<Animated.TouchableWithoutFeedback onPress={(e) => action(e)} style={style} {...rest}/>)
}

export default TouchView;
