import React from 'react';
import {connect} from 'react-redux';
import {View} from 'react-native';
import {createStackNavigator} from '@react-navigation/stack';

import ImportResults from '../ImportResults';
import BatchQueueImportResults from "../BatchQueueImportResults";
import BatchQueue from '../BatchQueue';
import {basicStyles} from '../../styles/basic';

const Stack = createStackNavigator();

function ImportHistoryNavigation(props) {
	return (
		<View style={basicStyles.flexScale}>
			<Stack.Navigator
				backBehavior="history"
				screenOptions={{
					header: () => null,
					cardStyle: {
						backgroundColor: '#F8FAFD'
					}
				}}
				initialRouteName="BatchQueue"
			>
				<Stack.Screen
					name={"JobImportResults"}
					component={BatchQueueImportResults}
					initialParams={{type: 'jobs'}}
					options={{
						title: "Jobs"
					}}
				/>
				<Stack.Screen
					name={"BatchQueue"}
					component={BatchQueue}
          initialParams={{organization_id: props.auth.organization.organization_id}}
					options={{
						title: "Batch Queue"
					}}
				/>
			</Stack.Navigator>
		</View>
	);
}

const mapStateToProps = (state) => {
    const {auth} = state;
    return {auth};
};

export default connect(mapStateToProps)(ImportHistoryNavigation);
