import React, {useEffect, useState, useCallback} from 'react';
import {Animated, View, TouchableOpacity, StyleSheet, Text} from "react-native";

const emojis = [
    "🙂",
    "😃",
    "😊",
    "😅",
    "😎",
    "😭",
    "😒",
    "❤️",
    "😆",
    "🙁",
    "😠",
    "😖",
    "👍",
    "😲",
    "😋",
    "😉",
];

function EmojiSelector({callback, active, onClose, wrapperStyle = {}}) {
    const [heightInitialized, setHeightInitialized] = useState(false);
    const [defaultHeight, setDefaultHeight] = useState(null);
    const height = new Animated.Value(0);

    const animateIn = () => {
        Animated.timing(height, {
            toValue: defaultHeight,
            duration: 200,
        }).start();
    };

    const animateOut = () => {
        Animated.timing(height, {
            toValue: 0,
            duration: 200,
        }).start();
    };

    useEffect(() => {
        if(heightInitialized) {
            if (active) {
                animateIn();
            } else {
                animateOut();
            }
        }
    }, [active]);

    return (
        <Animated.View
            style={[
                styles.emojisOuterWrapper,
                wrapperStyle,
                heightInitialized ? {height} : styles.preInitialized
            ]}
            onLayout={(event) => {
                if(!heightInitialized) {
                    const {height} = event.nativeEvent.layout;
                    setDefaultHeight(height);
                    setHeightInitialized(true);
                }
            }}
        >
            <View style={[styles.emojisBorderPadding]}>
                <View style={[styles.emojisWrapper]}>
                    {
                        emojis.map(emoji => {
                            return (
                                <TouchableOpacity
                                    key={emoji}
                                    onPress={() => {
                                        callback(emoji)
                                    }}
                                >
                                    <Text style={styles.emoji}>
                                        {emoji}
                                    </Text>
                                </TouchableOpacity>
                            );
                        })
                    }
                </View>
            </View>
        </Animated.View>
    );
}

export default EmojiSelector;

const styles = StyleSheet.create({
    emojisOuterWrapper: {
        overflow: 'hidden',
        width: '100%',
    },
    emojisBorderPadding: {
        paddingTop: 8,
        width: '100%',
    },
    emojisWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: 10,
        borderTopWidth: 1,
        borderTopColor: '#E2E9F4',
        flexWrap: 'wrap'
    },
    preInitialized: {
        position: "absolute",
        visibility: 0,
        pointerEvents: 'none',
        opacity: 0,

    },
    emoji: {
        flex: 1,
        paddingLeft: 5,
        paddingRight: 5,
    }
});
