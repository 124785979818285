import Http from "../utilities/Http";
import {API_ROOT} from "../config";

export default {
	async getStates() {
		let response = await Http().get(API_ROOT + '/locations/states/');

		return response.data.states;
	}
};
