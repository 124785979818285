import React, {useState} from 'react';
import {View, Dimensions, TextInput, ScrollView, Platform, Linking, ActivityIndicator} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import ScreenOverlay from './ScreenOverlay';
import ContentSection from './ContentSection';
import AppText from './AppText';
import AppButton from './AppButton';
import FilterOption from "./FilterOption";
import {basicStyles} from '../styles/basic';
import {destroyReviewResponse, setAiGenerated} from '../store/reviewResponse/reviewResponseActions';
import SurveysService from "../services/SurveysService";
import EStyleSheet from 'react-native-extended-stylesheet';
import RichTextEditor from '../components/RichTextEditor';

function ReviewResponse(props) {
	const {
		active,
		review,
		response,
		userId,
		reviewerName,
		organizationTitle,
		surveyResponseId,
		surveyResponseAnswerId,
		jobId,
		jobCustomerEmail,
		jobCustomerPhone,
		allowAiGeneratedResponse,
		isAiGeneratedResponse,
		wrapperStyle = {},
		displayJobButton = false
	} = props.reviewResponse;

	const [state, setState] = useState({
		notificationType: 'notify_none',
		responseText: undefined,
		isSubmitting: false,
		isGeneratingAiResponse: false,
		aiUpdate: false
	});

	const {destroyReviewResponse, setAiGenerated} = props;

	var contactOptions = [];

	const updateContactOptions = () => {

		contactOptions = [{
			label: 'Do not send',
			value: 'notify_none',
		}];

		if(jobCustomerEmail) {
			contactOptions.push({
				label: 'Email',
				value: 'notify_email',
			});
		}

		if(jobCustomerPhone) {
			contactOptions.push({
				label: 'Text',
				value: 'notify_text',
			});
		}
	};

	const cancel = () => {
		clearState();
		destroyReviewResponse();
	};

	const submit = async () => {
		if(!state.isSubmitting){
			//Prevent multiple sends
			updateIsSubmitting();

			//If value wasn't updated use previous value
			const responseText = (state.responseText ? state.responseText : response);

			//Submit update
			await SurveysService.updateReviewResponse(surveyResponseAnswerId, jobId, state.notificationType, responseText, userId, isAiGeneratedResponse);

			//Clear local state and update redux
			clearState();
			destroyReviewResponse();
		}
	};

	const clearState = () => {
		setState(prevState => {
			return Object.assign({}, {notificationType: 'notify_none', responseText: undefined, isSubmitting: false});
		});
	};

	const updateDropDown = (value) => {
		if(value != 'notify_email') {
			var responseText = htmlToText(state.responseText);
			updateResponseText(responseText);
		}

		setState(prevState => {
			return Object.assign({}, prevState, {notificationType: value});
		});
	};

	const updateResponseText = (value) => {
		setState(prevState => {
			return Object.assign({}, prevState, {responseText: value});
		});
	};

	const updateIsSubmitting = () => {
		setState(prevState => {
			return Object.assign({}, prevState, {isSubmitting: !state.isSubmitting});
		});
	};

	const htmlToText = (html) => {
		if(html) {
			var text = html.replace(/<[^>]+>/g, '');
			text = text.replaceAll('&nbsp;', '');
			return text;
		}

		return '';
	};

	const getEditor = () => {
		let responseText = htmlToText(response);

		if(state.notificationType == 'notify_email') {
			return (
					<RichTextEditor
						onUpdate={updateResponseText}
						value={state.responseText}
						defaultValue={responseText}
						aiUpdate={state.aiUpdate}
					/>
				   )
		} else {
			return (
					<TextInput style={styles.reviewResponseInput}
			                   multiline={true}
			                   numberOfLines={6}
			                   onChangeText={(text) => updateResponseText(text)}
			                   value={state.responseText}
			                   defaultValue={responseText}
					/>
				   )
		}
	};

	const openExternalLink = (url) => {
		if (Platform.OS === 'web') {
			window.open(url, '_blank');
		} else {
			Linking.canOpenURL(url).then(supported => {
				if (supported) {
					return Linking.openURL(url);
				}
			});
		}
	};

	const getAiGeneratedResponse = async () => {
		if(allowAiGeneratedResponse && !state.isGeneratingAiResponse) {
			setState(prevState => {
				return Object.assign({}, prevState, {isGeneratingAiResponse: true});
			});
			const data = await SurveysService.generateReviewResponse(surveyResponseId);
			setState(prevState => {
				return Object.assign({}, prevState, {responseText: data.response, isGeneratingAiResponse: false, aiUpdate: true});
			});
			setState(prevState => {
				return Object.assign({}, prevState, {aiUpdate: false});
			});
			setAiGenerated(true);
		}
	};

	if (active) {
		updateContactOptions();

		return (
			<ScreenOverlay>
				<ContentSection style={[wrapperStyle, styles.container, {maxHeight: Dimensions.get('window').height}]}>
					<View style={styles.topSection}>
						<AppText style={styles.header}>
							Respond to Review
						</AppText>
						<View style={{marginBottom: 20}}>
							<AppText style={styles.title}>Reviewer Screen Name: </AppText>
							<AppText style={styles.copy}>{reviewerName}</AppText>
						</View>
						<AppText style={styles.title}>Review</AppText>
						<ScrollView contentContainerStyle={styles.reviewCopyContainer}>
							<AppText style={styles.reviewCopy}>
								{review}
							</AppText>
						</ScrollView>
						{
							allowAiGeneratedResponse ?
								<View style={{marginTop: 10, flexDirection: 'row', alignItems: 'center'}}>
									<AppButton
										style={{marginRight: 10}}
										action={() => {
											getAiGeneratedResponse();
										}}
										label="Ask Fluid AI to respond"
									/>
									{
										state.isGeneratingAiResponse &&
										<ActivityIndicator size="large" color="#467AFF"/>
									}
								</View>
								: null
						}
					</View>
					<View style={styles.middleSection}>
						{
							getEditor()
						}
						<View style={{paddingTop: 20}}>
							<AppText style={[styles.title]}>Sending from: </AppText>
							<AppText style={styles.copy}>{organizationTitle}</AppText>
							{
								(
									jobId && displayJobButton ?
										<View style={{maxWidth: 130}}>
											<AppButton
												style={{marginTop: 10}}
												action={() => {
													openExternalLink(('/auth/jobs/edit/' + jobId))
												}}
												label='Open Job'
											/>
										</View>
										:
										null
								)
							}
						</View>

					</View>
					<View style={styles.bottomSection}>
						<View style={[basicStyles.flexRow, styles.bottomSectionInputContainer, styles.bottomSectionInput]}>
							<FilterOption
								options={contactOptions}
								dropDownFlush="right"
								title="Send response to reviewer by"
								value={state.notificationType}
								onChange={(value) => {
									updateDropDown(value);
								}}
								style={{marginRight: 15}}
							/>
							<View style={[basicStyles.flexRow, styles.bottomSectionInput]}>
								<AppButton
									style={styles.button}
									label="Submit"
									action={submit}
								/>
								<AppButton
									style={styles.button}
									label="Cancel"
									action={cancel}
								/>
							</View>
						</View>
					</View>
				</ContentSection>
			</ScreenOverlay>
		);
	}

	return (<></>);
}

const mapStateToProps = (state) => {
	const {reviewResponse} = state;
	return {reviewResponse};
};

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		destroyReviewResponse,
		setAiGenerated,
	}, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(ReviewResponse);

const styles = EStyleSheet.create({
	container: {
		maxWidth: 680,
		margin: 20,
	},
	topSection: {
		justifyContent: 'center',
		alignItems: 'flex-start',
		paddingTop: 30,
		paddingRight: 60,
		paddingLeft: 60,
		paddingBottom: 30,
	},
	middleSection: {
		paddingRight: 60,
		paddingLeft: 60,
		paddingBottom:30,
	},
	bottomSection: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'stretch',
		paddingTop: 30,
		paddingRight: 60,
		paddingLeft: 60,
		paddingBottom: 30,
		borderTopWidth: 1,
		borderTopColor: '#E2E9F4',
	},
	header: {
		fontSize: 28,
		textAlign: 'left',
		fontFamily: 'SourceSansPro-Light',
		paddingBottom: 20,
	},
	title: {
		fontWeight: 'bold',
		fontSize: 18,
		fontFamily: 'SourceSansPro-Light',
	},
	copy: {
		fontSize: 15,
		fontFamily: 'SourceSansPro-Light',
	},
	reviewCopyContainer: {
		maxHeight: 120,
	},
	reviewCopy: {
		fontSize: 15,
		textAlign: 'left',
		fontFamily: 'SourceSansPro-Light',
	},
	reviewResponseInput: {
		borderWidth: 1,
		borderColor: '#0065DB',
		borderRadius: 10,
		padding: 10,
	},
	bottomSectionInput: {
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	button: {
		marginLeft: 10,
	},
	'@media (max-width: 575)': {
		bottomSectionInputContainer: {
			flexDirection: 'column'
		},
		bottomSectionInput: {
			marginTop: 15,
		},
		topSection: {
			paddingRight: 30,
			paddingLeft: 30,
		},
		middleSection: {
			paddingRight: 30,
			paddingLeft: 30,
		},
		bottomSection: {
			paddingRight: 30,
			paddingLeft: 30,
			paddingTop: 15,
		}
	}
});
