import {StyleSheet, Platform} from 'react-native';

export const inputStyles = StyleSheet.create({
	inputWrapper: {
		padding: 15,
		borderWidth: 1,
		borderRadius: 5,
		borderColor: '#E2E9F4',
		backgroundColor: '#FFFFFF',
		height: 76,
		position: 'relative'
	},
	inputLabel: {
		fontSize: 14,
		color: '#9BA8CA',
		marginBottom: 5,
	},
	input: {
		fontSize: 16,
		fontFamily: 'SourceSansPro-Regular',
		color: '#000000',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		outlineWidth: 0
	}
});
