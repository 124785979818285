import React from 'react';
import {View} from 'react-native';
import {createStackNavigator} from '@react-navigation/stack';

import {basicStyles} from '../../styles/basic';
import CouponsList from './CouponsList';
import CouponSingle from './CouponSingle';

const Stack = createStackNavigator();

function CouponsStackNavigation(props) {
	return (
		<View style={basicStyles.flexScale}>
			<Stack.Navigator
				backBehavior="history"
				screenOptions={{
					header: () => null,
					cardStyle: {
						backgroundColor: '#F8FAFD'
					}
				}}
				initialRouteName="CouponsList"
			>
				<Stack.Screen
					name="CouponsList"
					component={CouponsList}
					options={{
						title: "Coupons"
					}}
				/>
				<Stack.Screen
					name="CouponAdd"
					component={CouponSingle}
					options={{
						title: "Coupons"
					}}
				/>
				<Stack.Screen
					name="CouponEdit"
					component={CouponSingle}
					options={{
						title: "Coupons"
					}}
				/>
			</Stack.Navigator>
		</View>
	);
}

export default CouponsStackNavigation;
