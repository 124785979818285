import React, {createRef} from 'react';
import {View} from 'react-native';
import Dropzone from 'react-dropzone';
import {basicStyles} from '../styles/basic';

function UploadOverlayHidden({callback}) {
	let uploadRef = createRef();
	const open = () => {
		uploadRef.current.open();
	};

	return (
		<View style={basicStyles.overlayHidden}>
			<Dropzone onDrop={callback} ref={uploadRef} multiple={false}>
				{({getRootProps, getInputProps}) => (
					<div {...getRootProps()}>
						<input {...getInputProps()} />
					</div>
				)}
			</Dropzone>
			<View onClick={() => open()} style={basicStyles.overlayHidden}>
			</View>
		</View>
	);
}

export default UploadOverlayHidden;
