import * as React from "react"
import Svg, { Path } from "react-native-svg"

function MapIconSvg({size = 34}) {
	return (
		<Svg viewBox="0 0 48 48" width={size} height={size}>
			<Path
				fill="#48b564"
				d="M35.76 26.36h.01S32 31.89 28.83 36c-2.74 3.55-3.54 6.59-3.77 8.06-.09.54-.53.94-1.06.94s-.97-.4-1.06-.94c-.23-1.47-1.03-4.51-3.77-8.06-.42-.55-.85-1.12-1.28-1.7L28.24 22l8.33-9.88c.92 1.93 1.43 4.09 1.43 6.38 0 2.9-.83 5.59-2.24 7.86z"
			/>
			<Path
				fill="#fcc60e"
				d="M28.24 22L17.89 34.3c-2.82-3.78-5.66-7.94-5.66-7.94h.01c-.3-.48-.57-.97-.8-1.48L19.76 15c-.79.95-1.26 2.17-1.26 3.5 0 3.04 2.46 5.5 5.5 5.5 1.71 0 3.24-.78 4.24-2z"
			/>
			<Path
				fill="#2c85eb"
				d="M28.4 4.74l-8.57 10.18-6.56-5.72C15.83 6.02 19.69 4 24 4c1.54 0 3.02.26 4.4.74z"
			/>
			<Path
				fill="#ed5748"
				d="M19.83 14.92l-.07.08-8.32 9.88C10.52 22.95 10 20.79 10 18.5c0-3.54 1.23-6.79 3.27-9.3l6.56 5.72z"
			/>
			<Path
				fill="#5695f6"
				d="M28.24 22c.79-.95 1.26-2.17 1.26-3.5 0-3.04-2.46-5.5-5.5-5.5-1.71 0-3.24.78-4.24 2L28.4 4.74c3.59 1.22 6.53 3.91 8.17 7.38L28.24 22z"
			/>
		</Svg>
	)
}

export default MapIconSvg
