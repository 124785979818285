import * as React from "react"
import Svg, { G, Rect } from "react-native-svg"

function UnconfirmSvg(props) {
	return (
		<Svg width={33} height={33} viewBox="0 0 33 33" {...props}>
			<G data-name="Rectangle 420" fill="#f8fafd" stroke="#e2e9f4">
				<Rect width={33} height={33} rx={16.5} stroke="none" />
				<Rect x={0.5} y={0.5} width={32} height={32} rx={16} fill="none" />
			</G>
		</Svg>
	)
}

export default UnconfirmSvg;