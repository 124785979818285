import Http from "../utilities/Http";
import {API_ROOT} from "../config";

export default {
	async getIntegrations() {
		let response = await Http().get(API_ROOT + '/integrations/');

		return response.data.integrations;
	},
	async getIntegration(id) {
		let response = await Http().get(API_ROOT + '/integrations/' + id);

		return response.data.integration;
	},
	async getOrganizationApiKey(organizationId) {
		let response = await Http().get(API_ROOT + '/integrations/get-organization-api-key/' + organizationId);

		return response.data.apiKey;
	},
	async setOrganizationTextlineEnabled(organizationId, value) {
		let response = await Http().post(
			API_ROOT + '/integrations/set-organization-textline-enabled/' + organizationId,
			{ enabled: value }
		);

		return response.data;
	},
	async getOrganizationTextlineGroups(organizationId, token) {
		let response = await Http().post(
			API_ROOT + '/integrations/get-organization-textline-groups/' + organizationId,
			{ token }
		);

		return response.data.groups;
	},
	async setOrganizationTextlineGroup(organizationId, groupUUID) {
		let response = await Http().post(
			API_ROOT + '/integrations/set-organization-textline-group/' + organizationId,
			{ group: groupUUID }
		);

		return response.data;
	},
	async setOrganizationTextlineToken(organizationId, token) {
		let response = await Http().post(
			API_ROOT + '/integrations/set-organization-textline-token/' + organizationId,
			{ token }
		);

		return response.data;
	},
	async getOrganizationTextlineGroup(organizationId) {
		let response = await Http().get(
			API_ROOT + '/integrations/get-organization-textline-group/' + organizationId,
		);

		return response.data;
	},
};
