import React, {useEffect, useState} from 'react';
import {ScrollView, View} from 'react-native';

import {basicStyles} from "../../styles/basic";
import AppText from "../../components/AppText";
import {textStyles} from "../../styles/text";
import PageTop from "../../components/PageTop";
import PageContentContainer from "../../components/PageContentContainer";
import FieldSet from "../../components/FieldSet";
import OrganizationsService from "../../services/OrganizationsService";
import ContentSection from "../../components/ContentSection";
import TouchView from "../../components/TouchView";
import CheckMarkBox from "../../components/CheckMarkBox";
import AppButton from "../../components/AppButton";
import EStyleSheet from "react-native-extended-stylesheet";
import {setWarning} from '../../store/warning/warningActions';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

function MakePayment(props) {
    const [amount, setAmount] = useState(0);
    const [note, setNote] = useState('');
    const [customerInfo, setCustomerInfo] = useState({
        cards: [],
        defaultCard: {},
        invoices: [],
    });
    const [paymentMethod, setPaymentMethod] = useState(null);
    const organizationId = props.route.params.organizationId;

    const {setWarning} = props;

    function warnSubmitPayment() {
        setWarning({
            confirmAction: async () => {
                await OrganizationsService.makePayment(organizationId, amount.replace('$', ''), paymentMethod, note);
                setAmount(0);
                setNote('');
            },
            confirmLabel: 'Confirm',
            rejectLabel: 'Cancel',
            warningMessage: 'Are you sure you want to make a payment of ' + amount,
            active: true
        });
    }

    useEffect(() => {
        const getCustomerInfo = async () => {
            const data = await OrganizationsService.getCustomerInfo(organizationId);

            setCustomerInfo({
                cards: data.cards,
                defaultCard: data.defaultCard,
                invoices: data.invoices,
            });
            setPaymentMethod(data.defaultCard);
        };
        getCustomerInfo();
    }, [organizationId]);

    return (
        <ScrollView contentContainerStyle={basicStyles.flexScale}>
            <PageTop style={[styles.header]}>
                <AppText style={[textStyles.pageTitle, textStyles.semiBold, styles.headerText]}>
                    Make a Payment
                </AppText>
            </PageTop>
            <PageContentContainer>
                <AppText style={[textStyles.pageSubTitle, textStyles.semiBold]}>
                    Enter Payment Amount
                </AppText>
                <FieldSet
                    style={{marginBottom: 50}}
                    chunkSize={1}
                    fields={[
                        {
                            type: 'money',
                            label: 'Payment Amount',
                            onChangeText: value => setAmount(value),
                            value: amount
                        },
                        {
                            type: 'text',
                            label: 'What is this payment for?',
                            onChangeText: value => setNote(value),
                            value: note
                        },
                    ]}
                />
                <AppText style={[textStyles.pageSubTitle, textStyles.semiBold]}>
                    Select Payment Method
                </AppText>
                <ContentSection style={{padding: 34}}>
                    {

                        customerInfo.cards.sort(card => {
                            return card.id === customerInfo.defaultCard ? 1 : 0;
                        }).map((card, cardIndex) => {
                            return (
                                <View
                                    key={card.id}
                                    style={[
                                        styles.cardContainer,
                                        ...(cardIndex === customerInfo.cards.length - 1 ? [] : [{marginBottom: 15}]),
                                    ]}
                                >
                                    <View
                                        style={[
                                            basicStyles.flexScale,
                                            basicStyles.flexRow,
                                            basicStyles.justifyContentSpaceBetween,
                                            basicStyles.alignContentCenter,
                                            styles.cardInfoContainer,
                                            styles.cardInfoDetails,
                                        ]}>
                                        <AppText>
                                            {card.brand}
                                        </AppText>
                                        <AppText>
                                            XXXX XXXX XXXX {card.last4}
                                        </AppText>
                                        <AppText>
                                            Exp: {card.exp_month + '/' + card.exp_year}
                                        </AppText>
                                    </View>
                                    <TouchView
                                        style={[
                                            basicStyles.alignContentCenter,
                                            basicStyles.justifyContentCenter,
                                            styles.cardInfoContainer,
                                            styles.defaultPaymentSelector,
                                            ...(customerInfo.defaultCard === card.id ? [{cursor: 'auto'}] : []),
                                        ]}
                                        action={() => {
                                            setPaymentMethod(card.id);
                                        }}
                                    >

                                        <CheckMarkBox
                                            style={styles.checkMarkMargin}
                                            active={paymentMethod === card.id}
                                        />
                                        <AppText>
                                            Use Card
                                        </AppText>
                                    </TouchView>
                                </View>
                            );
                        })
                    }
                </ContentSection>
                <AppButton
                    label="Submit Payment"
                    action={() => warnSubmitPayment()}
                    style={styles.surveySubmitButton}
                />
            </PageContentContainer>
        </ScrollView>
    );
}

const mapDispatchToProps = (dispatch) => (
    bindActionCreators({
        setWarning,
    }, dispatch)
);

export default connect(null, mapDispatchToProps)(MakePayment);

const styles = EStyleSheet.create({
    invoiceContainer: {
        paddingTop: 10,
        paddingLeft: 34,
        paddingRight: 34,
        paddingBottom: 10,
    },
    column: {
        paddingTop: 28,
        paddingBottom: 28,
        marginLeft: 5,
        marginRight: 5,
        justifyContent: 'center'
    },
    midColumn: {
        flex: 2,
    },
    columnHeadText: {
        fontSize: 12,
        color: '#9BA8CA',
    },
    columnHeadTextMobile: {
        display: 'none',
        fontSize: 12,
        color: '#9BA8CA',
    },
    row: {
        borderBottomWidth: 1,
        borderBottomColor: '#D9E8F8',
    },
    planTitle: {
        fontFamily: 'SourceSansPro-SemiBold',
    },
    second2LastColumn: {
        alignItems: 'center',
        textAlign: 'center',
    },
    lastColumn: {
        alignItems: 'flex-end',
        textAlign: 'right',
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    cardInfoContainer: {
        borderWidth: 1,
        borderColor: '#D9E8F8',
        borderRadius: 5,
        height: 75,
    },
    cardInfoDetails: {
        paddingLeft: 30,
        paddingRight: 30,
    },
    checkMarkMargin: {},
    defaultPaymentSelector: {
        paddingLeft: 10,
        paddingRight: 10,
        marginLeft: 20,
    },
    surveySubmitButton: {
        width: 145, alignSelf: 'center', marginTop: 50, height: 50
    },
    '@media (max-width: 800)': {
        invoiceContainer: {
            paddingLeft: 20,
            paddingRight: 20
        },
        header: {
            flexDirection: 'column',
            paddingLeft: 0,
            paddingRight: 0,
            alignItems: 'flex-start'
        },
        headerText: {
            paddingLeft: 45,
            paddingBottom: 30,
        },
        statusIconRow: {
            width: '100%',
            alignItems: 'center',
            paddingTop: 20,
            paddingLeft: 45,
            paddingRight: 45,
            borderTopWidth: 1,
            borderColor: '#D9E8F8'
        },
    },
    '@media (max-width: 768)': {
        surveySubmitButton: {
            width: '100%'
        }
    },
    '@media (max-width: 675)': {
        headerRow: {
            display: 'none'
        },
        columnHeadTextMobile: {
            display: 'flex'
        },
        row: {
            width: '100%',
            flexWrap: 'wrap'
        },
        column: {
            width: '50%',
            flex: 'inherit',
            marginLeft: 0,
            marginRight: 0,
            paddingTop: 15,
            paddingBottom: 15,
            justifyContent: 'center',
            alignItems: 'flex-start',
            textAlign: 'left'
        },
        midColumn: {
            flex: 'inherit'
        },
        second2LastColumn: {
            textAlign: 'left'
        },
        lastColumn: {
            textAlign: 'left'
        }
    },
    '@media (max-width: 575)': {
        cardInfoDetails: {
            flexDirection: 'column',
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 10,
            paddingRight: 10
        }
    },
    '@media (max-width:500)': {
        headerText: {
            paddingLeft: 30
        },
        statusIconRow: {
            flexDirection: 'column',
            paddingLeft: 30,
            paddingRight: 30
        },
        statusStyleWrapper: {
            justifyContent: 'flex-start'
        }
    },
    '@media (max-width:400)': {
        statusStyleWrapper: {
            width: '100%',
        },
        cardContainer: {
            flexDirection: 'column',
            width: '100%'
        },
        cardInfoContainer: {
            width: '100%'
        },
        defaultPaymentSelector: {
            marginLeft: 0,
            marginTop: 20,
            flexDirection: 'row'
        },
        checkMarkMargin: {
            marginRight: 10
        }
    }
});
