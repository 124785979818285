import React, {useState, useEffect, useRef} from 'react';
import {View, ScrollView} from 'react-native';
import {connect} from 'react-redux';
import {useDebouncedCallback} from 'use-debounce';
import moment from 'moment';

import {basicStyles} from '../../styles/basic';
import PageTop from '../../components/PageTop';
import Avatar from '../../components/Avatar';
import {API_ROOT, AVATAR_DEFAULT, AVATAR_PATH_ORG} from '../../config';
import AppText from '../../components/AppText';
import {textStyles} from '../../styles/text';
import ActivityService from '../../services/ActivityService';
import AppButton from '../../components/AppButton';
import EStyleSheet from 'react-native-extended-stylesheet';
import Pagination from "../../components/Pagination";
import {formatDate, getUserDisplayName} from "../../utilities/methods";
import ContentSection from "../../components/ContentSection";
import AppDatePicker from '../../components/AppDatePicker.web';
import AppPickerInput from '../../components/AppPickerInput';
import {useFocusEffect} from "@react-navigation/native";
import Http from "../../utilities/Http";
import {setProcessingIndicatorActive} from '../../store/miscellaneous/miscellaneousActions';
import {bindActionCreators} from "redux";


function Activity(props) {
	const [organization, setOrganization] = useState({...props.auth.organization});
	const [activities, setActivities] = useState([]);
	const [pageCount, setPageCount] = useState(1);
	const [paginationRange, setPaginationRange] = useState([1]);
	const {navigate} = props.navigation;
	const perPage = 10;
	const organizationId = props.route?.params?.organizationId;
	let startDate = props.route?.params?.startDate ? new Date(props.route.params.startDate).setHours(12) : null;
	let endDate = props.route?.params?.endDate ? new Date(props.route.params.endDate).setHours(12) : null;
	let sortBy = props.route?.params?.sortBy ? props.route.params.sortBy : 'desc';

	const popperMods = {
		flip: {
			behavior: ["bottom"] // https://github.com/Hacker0x01/react-datepicker/issues/1246
		},
		preventOverflow: {
			enabled: true,
			escapeWithReference: false,
			boundariesElement: "viewport"
		}
	};

	const [exportState, setExportState] = useState({
		downloadHref: null,
	});

	const downloadAnchorRef = useRef();

	let currentPage = props.route?.params?.currentPage ? parseInt(props.route.params.currentPage) : 1;

	const [getActivitiesDebounce] = useDebouncedCallback(() => {

		const getActivities = async () => {
			let activityData = await ActivityService.getActivitiesPaginated(
				props.route?.params?.organizationId,
				startDate ? moment(startDate).format('YYYY-MM-DD') : null,
				endDate ? moment(endDate).format('YYYY-MM-DD') : null,
				sortBy,
				perPage,
				currentPage
			);

			currentPage = activityData.currentPage;
			setPageCount(activityData.pageCount);
			setActivities(activityData.activities);
			setPaginationRange(activityData.paginationRange);
		};
		getActivities();
	}, 500);

	async function downloadCsvExport() {
		setProcessingIndicatorActive(true);

		let columns = [
			'organization_title',
			'profile_first_name',
			'profile_last_name',
			'activity_action',
			'activity_timestamp'
		];

		const dataUrl=(API_ROOT + '/organizations/' + organizationId + '/activities/download?csv&columns=' + encodeURIComponent(columns) + '&startDate=' + encodeURIComponent(startDate ? moment(startDate).format('YYYY-MM-DD') : '') + '&endDate=' + encodeURIComponent(endDate ? moment(endDate).format('YYYY-MM-DD') : '') + '&sortBy=' + sortBy);

		let windowUrl = window.URL || window.webkitURL;

		const data = await Http().get(dataUrl);
		let blob = new Blob([data.data], {type: 'text/csv'});
		setExportState(prevState => {
			return {
				downloadHref: windowUrl.createObjectURL(blob),
			};
		});
	}

	useFocusEffect(
		React.useCallback(() => {
			getActivitiesDebounce()
		}, [organizationId, startDate, endDate, sortBy, currentPage])
	);


	useEffect(() => {
		if(exportState.downloadHref) {
			downloadAnchorRef.current.click();
		}
		setProcessingIndicatorActive(false);
	}, [exportState.downloadHref])


	return (
		<ScrollView contentContainerStyle={basicStyles.flexScale}>
			<PageTop style={[
				basicStyles.flexRow,
				basicStyles.alignContentCenter,
				basicStyles.justifyContentSpaceBetween,
			]}>
				<View style={[
					basicStyles.flexRow,
					basicStyles.alignContentCenter,
					basicStyles.justifyContentSpaceBetween,
					styles.headerContainer
				]}>
					<View style={[
						basicStyles.flexRow,
						basicStyles.alignContentCenter,
						basicStyles.justifyContentSpaceBetween,
					]}>
						<Avatar
							source={{uri: organization.organization_thumbnail ? AVATAR_PATH_ORG + organization.organization_thumbnail : AVATAR_DEFAULT}}
							width={66}
							style={{marginRight: 20}}
						/>
						<AppText style={[textStyles.pageTitle, textStyles.semiBold]}>
							Activity
						</AppText>
					</View>
					<AppButton
						label="Download Report"
						action={() => downloadCsvExport()}
						style={styles.headerAppButton}
					/>
				</View>
			</PageTop>
			<View style={styles.filterContainer}>
				<View style={styles.filterTitleContainer}>
					<AppText style={styles.filterTitle}>Filter/Sort</AppText>
				</View>
				<View style={styles.filterInputContainer}>
					<AppDatePicker
						label="Start Date"
						selectedDate={startDate}
						onChange={(value) => {
							navigate('Activity', {
								...props.route?.params,
								currentPage: 1,
								startDate: moment(value).format('MM-DD-YYYY'),
							});
						}}
						wrapperStyle={[styles.filterInput, styles.filterStartDate]}
						popperMods={popperMods}
					/>
					<AppDatePicker
						label="End Date"
						selectedDate={endDate}
						onChange={(value) => {
							navigate('Activity', {
								...props.route?.params,
								currentPage: 1,
								endDate: moment(value).format('MM-DD-YYYY'),
							});
						}}
						wrapperStyle={[styles.filterInput,styles.filterEndDate]}
						popperMods={popperMods}
					/>
					<AppPickerInput
						wrapperStyle={[styles.filterInput, styles.filterSort]}
						label="Sort"
						selectedValue={sortBy}
						onValueChange={(value) => {
							navigate('Activity', {
								...props.route?.params,
								currentPage: 1,
								sortBy: value,
							});
						}}
						items={[
							{
								label: 'Newest to Oldest',
								value: 'desc',
							},
							{
								label: 'Oldest to Newest',
								value: 'asc',
							}
						]}
						labelExtractor="label"
						valueExtractor="value"
					/>
				</View>
			</View>
			<ContentSection style={[styles.sectionPadding, styles.activityContainerWrapper]}>
					<View
						key={'root'}
						style={[
							styles.activityContainer,
							styles.activityContainerHeader,
							{paddingTop: 0}
						]}
					>
						<View style={[
							basicStyles.flexRow,
							basicStyles.alignContentCenter,
							basicStyles.justifyContentCenter,
							styles.rowSection,
							styles.alignLeft,
							styles.firstColumn,
						]}>
							<AppText>
								User
							</AppText>
						</View>
						<View style={[
							basicStyles.flexRow,
							basicStyles.alignContentCenter,
							basicStyles.justifyContentCenter,
							styles.rowSection,
							styles.alignLeft,
						]}>
							<AppText>
								Action
							</AppText>
						</View>
						<View style={[
							basicStyles.flexRow,
							basicStyles.alignContentCenter,
							basicStyles.justifyContentFlexEnd,
							styles.rowSection,
							styles.alignRight,
							styles.lastColumn
						]}>
							<AppText>
								Date
							</AppText>
						</View>
					</View>
				{

					activities?.map((activity, activityIndex) => {
						return (
							<View
								key={activity.activity_id}
								style={[
									styles.activityContainer,
									(activityIndex === activities.length - 1 ? {borderBottomWidth: 0, paddingBottom: 0} : {}),
								]}
							>
								<View style={[
									basicStyles.flexRow,
									basicStyles.alignContentCenter,
									basicStyles.justifyContentCenter,
									styles.rowSection,
									styles.alignLeft,
									styles.firstColumn,
								]}>
									<AppText style={styles.altLabel}>
										User
									</AppText>
									<AppText>
										{getUserDisplayName(activity)}
									</AppText>
								</View>
								<View style={[
									basicStyles.flexRow,
									basicStyles.alignContentCenter,
									basicStyles.justifyContentCenter,
									styles.rowSection,
									styles.alignLeft,
									styles.middleColumn,
								]}>
									<AppText style={styles.altLabel}>
										Action
									</AppText>
									<AppText>
										{activity.activity_action}
									</AppText>
								</View>
								<View style={[
									basicStyles.flexRow,
									basicStyles.alignContentCenter,
									basicStyles.justifyContentFlexEnd,
									styles.rowSection,
									styles.alignRight,
									styles.lastColumn
								]}>
									<AppText style={styles.altLabel}>
										Date
									</AppText>
									<AppText>
										{formatDate(activity.activity_timestamp, 'MM.DD.YYYY')}
									</AppText>
								</View>
							</View>
						);
					})
				}
			</ContentSection>
			<Pagination screenName="Activity" pageCount={pageCount} pageIndex={currentPage} paginationRange={paginationRange} />
			<a ref={downloadAnchorRef}  href={exportState.downloadHref} download="activities.csv"/>
		</ScrollView>
	);
}

const mapStateToProps = (state) => {
	const {auth} = state;
	return {auth};
};

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		setProcessingIndicatorActive
	}, dispatch)
);

export default connect(mapStateToProps)(Activity);

const styles = EStyleSheet.create({
	sectionPadding: {
		padding: 24,
		margin: 24,
	},
	activityContainerWrapper: {
		marginBottom: 70
	},
	headerContainer: {
		width: '100%'
	},
	activityContainer: {
		paddingTop: 24,
		paddingBottom: 24,
		borderBottomWidth: 1,
		borderBottomColor: '#D9E8F8',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		flex: 1,
		flexWrap: 'wrap',
	},
	altLabel: {
		display: 'none',
		color: '#9BA8CA',
		fontSize: 12
	},
	rowSection: {
		width: 250,
		paddingTop: 15,
		paddingBottom: 15,
		paddingLeft: 30,
		paddingRight: 30,
	},
	firstColumn: {
		paddingLeft: 0,
	},
	middleColumn: {
		flex: 1
	},
	lastColumn: {
		paddingRight:0,
	},
	alignLeft: {
		alignItems: 'flex-start',
		justifyContent: 'flex-start'
	},
	alignRight: {
		alignSelf: 'flex-start',
		justifySelf: 'flex-end',
		flex: 1,
	},
	filterContainer: {
		flexDirection: 'row',
		padding: 24,
		alignItems: 'center',
		justifyContent: 'space-between',
		borderBottomWidth: 1,
		borderBottomColor: '#D9E8F8',
		zIndex: 1,
	},
	filterTitleContainer: {
		width: '25%',
	},
	filterTitle: {
		fontSize: 20,
	},
	filterInputContainer: {
		width: '75%',
		flexDirection: 'row',
		justifyContent: 'flex-end',
	},
	filterInput: {
		width: 250,
		height: 75
	},
	filterStartDate: {
		zIndex:3,
	},
	filterEndDate: {
		zIndex:2,
		marginLeft: 30,
		marginRight: 30
	},
	'@media (max-width: 1050)': {
		filterContainer: {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
		filterTitleContainer: {
			width: '100%',
			marginBottom: 30,
		},
		filterInputContainer: {
			width: '100%',
			justifyContent: 'space-between'
		},
		filterInput: {
			width: '32%'
		},
		filterInputMiddle: {
			marginLeft: 0,
			marginRight: 0,
		}
	},
	'@media (max-width: 950)': {
		firstColumn: {
			width: 150,
		}
	},
	'@media (max-width: 768)': {
		activityContainerWrapper: {
			paddingLeft: 15,
			paddingRight: 15,
		},
		rowSection: {
			paddingLeft: 15,
			paddingRight: 15,
		}
	},
	'@media (max-width: 720)': {
		headerContainer: {
			flexDirection: 'column',
			justifyContent: 'flex-start',
			alignItems: 'flex-start'
		},
		headerAppButton: {
			marginTop: 15,
		}
	},
	'@media (max-width: 650)': {
		rowSection: {
			width: 'auto',
			paddingLeft: 0,
			paddingRight: 0,
		},
		firstColumn: {
			width: '100%',
		},
		filterInputContainer: {
			flexWrap: 'wrap',
		},
		filterStartDate: {
			width: '49%',
		},
		filterEndDate: {
			width: '49%',
			marginRight: 0,
			marginLeft: 0,
		},
		filterSort: {
			width: '100%',
			marginTop: 10,
		}
	},
	'@media (max-width: 550)': {
		activityContainerHeader: {
			display: 'none',
		},
		altLabel: {
			display: 'flex',
		},
		rowSection: {
			width: '50%',
			flexDirection: 'column',
			alignItems: 'flex-start',
		}
	},
	'@media (max-width: 400)': {
		activityContainer: {
			paddingTop: 0,
			paddingBottom: 0,
		},
		sectionPadding: {
			padding: 15,
			margin: 15,
		},
		filterContainer: {
			padding: 15,
		},
		headerAppButton: {
			width: '100%'
		},
		firstColumn: {
			paddingBottom: 0,
		}
	}
});
