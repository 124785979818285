import React, {useState, useEffect} from 'react';
import {View, StyleSheet} from 'react-native';
import {bindActionCreators} from 'redux';
import {setSpreadSheetFilterOpen} from '../store/miscellaneous/miscellaneousActions';
import {connect} from 'react-redux';
import AppText from './AppText';
import TouchView from './TouchView';
import ChevronDownSvg from './svgs/ChevronDownSvg';
import ContentSection from './ContentSection';

function FilterOption({
	options = [],
	title = '',
	labelExtractor = 'label',
	valueExtractor = 'value',
	style={},
	value,
	onChange,
	dropDownFlush,
	...props
}) {
	const [dropDownOpen, setDropdownOpen] = useState(false);

	function checkOptionSelected(option) {
		return option[valueExtractor] == value;
	}

	function getOptionLabelFromValue(value) {
		if (value) {
			let selectedOption = options.find(option => {
				return checkOptionSelected(option);
			});

			if (selectedOption) {
				return selectedOption[labelExtractor];
			}
		}

		return '';
	}

	useEffect(() => {
		if (props.miscellaneous.pageClicked) {
			setDropdownOpen(false);
		}
	}, [props.miscellaneous.pageClicked]);

	return (
		<View style={[style, styles.filterContainer]}>
			<TouchView
				style={styles.selectedDisplay}
				action={() => {
					setTimeout(() => {
						setDropdownOpen(!dropDownOpen);
					});
				}}
			>
				<AppText>
					{title}{title && getOptionLabelFromValue(value) ? ':' : ''} {getOptionLabelFromValue(value)}
				</AppText>
				<ChevronDownSvg color="#0065DB" style={{marginLeft: 10}}/>
			</TouchView>
			<ContentSection style={[
				styles.dropDown,
				...(dropDownFlush === 'right' ? [{right: 0}] : []),
				...(dropDownFlush === 'left' ? [{left: 0}] : []),
				dropDownOpen ? {} : {opacity: 0, pointerEvents: 'none'}
			]}>
				{
					options.map((option, index) => {
						return (
							<TouchView
								key={index}
								action={(e) => {
									e.stopPropagation();
									onChange(option[valueExtractor]);
									setDropdownOpen(!dropDownOpen);
								}}
								style={{paddingTop: 5, paddingBottom: 5, marginBottom: 10}}
							>
								<AppText style={[
									styles.optionText,
									checkOptionSelected(option) ? styles.activeOption : {},
								]}>
									{option[labelExtractor]}
								</AppText>
							</TouchView>
						);
					})
				}
			</ContentSection>
		</View>
	);
}

const mapStateToProps = (state) => {
	const {miscellaneous} = state;
	return {miscellaneous};
};

export default connect(mapStateToProps)(FilterOption);

const styles = StyleSheet.create({
	filterContainer: {
		zIndex: 1,
		elevation: 1,
		overflow: 'visible',
	},
	selectedDisplay: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	dropDown: {
		position: 'absolute',
		top: '100%',
		minWidth: '100%',
		paddingTop: 15,
		paddingBottom: 15,
		paddingLeft: 20,
		paddingRight: 20
	},
	optionText: {
		whiteSpace: 'nowrap',
	},
	activeOption: {
		fontFamily: 'SourceSansPro-Bold',
	},
});
