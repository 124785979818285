import React, {useEffect, useState} from 'react';
import {ScrollView, View} from 'react-native';
import {Col, Row, Grid} from 'react-native-easy-grid';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import EStyleSheet from 'react-native-extended-stylesheet';

import AppText from '../../components/AppText';
import {basicStyles} from '../../styles/basic';
import OrganizationsService from '../../services/OrganizationsService';
import PageTop from '../../components/PageTop';
import {textStyles} from '../../styles/text';
import BriefcaseBlueSvg from '../../components/svgs/BriefcaseBlueSvg';
import StatusIconRow from '../../components/StatusIconRow';
import PageContentContainer from '../../components/PageContentContainer';
import ContentSection from '../../components/ContentSection';
import AppButton from '../../components/AppButton';
import {timestampToFormattedDate} from '../../utilities/methods';
import DownloadSvg from '../../components/svgs/DownloadSvg';
import StripeCardInput from '../../components/StripeCardInput';
import TouchView from '../../components/TouchView';
import {setWarning} from '../../store/warning/warningActions';
import CheckMarkBox from "../../components/CheckMarkBox";

function PaymentSettings(props) {
	const [addingCard, setAddingCard] = useState(false);
	const [submittingCard, setSubmittingCard] = useState(false);
	const [organization, setOrganization] = useState({});
	const [customerInfo, setCustomerInfo] = useState({
		cards: [],
		defaultCard: {},
		invoices: [],
	});
	const organizationId = props.route.params.organizationId;
	const {setWarning} = props;

	async function cardSubmit(cardToken) {
		const data = await OrganizationsService.registerCard(organizationId, cardToken);
		setOrganization(data.organization);
		setCustomerInfo({
			cards: data.cards,
			defaultCard: data.defaultCard,
			invoices: data.invoices,
		});
		setSubmittingCard(false);
		setAddingCard(false);
	}

	function warnSetCardDefault(cardToken) {
		setWarning({
			confirmAction: async () => {
				const data = await OrganizationsService.updateDefaultCard(organizationId, cardToken);
				setCustomerInfo(prevCustomerInfo => {
					return {
						...prevCustomerInfo,
						cards: data.cards,
						defaultCard: data.defaultCard,
					};
				});
			},
			confirmLabel: 'Confirm',
			rejectLabel: 'Cancel',
			warningMessage: 'Are you sure you want to update your default card?',
			active: true,
		});
	}

	useEffect(() => {
		const getOrganization = async () => {
			const orgData = await OrganizationsService.getOrganization(organizationId);
			console.log('orgData: ', orgData)
			setOrganization(orgData);
		};
		const getCustomerInfo = async () => {
			try {
				const data = await OrganizationsService.getCustomerInfo(organizationId);

				setCustomerInfo(data);
			} catch (error) {
				console.log('error:');
			}
		};

		getOrganization();
		getCustomerInfo();
	}, [organizationId]);

	return (
		<ScrollView contentContainerStyle={basicStyles.flexScale}>
			<PageTop style={[styles.header]}>
				<AppText style={[textStyles.pageTitle, textStyles.semiBold, styles.headerText]}>
					{organization.subscription_type_title ? organization.subscription_type_title + ' Plan' : 'Fluid Local Free Plan'}
				</AppText>
				<StatusIconRow
					style={styles.statusIconRow}
					styleWrapper={styles.statusStyleWrapper}
					styleAlternate={true}
					statusRows={[
						{
							icon: <BriefcaseBlueSvg/>,
							count: organization.activeMembersCount,
							status: 'Team Members',
						},
						{
							icon: <BriefcaseBlueSvg/>,
							count: organization.storeFrontsCount,
							status: 'Storefronts',
						},
					]}
				/>
			</PageTop>
			<PageContentContainer>
				<View style={[basicStyles.flexRow, basicStyles.alignContentCenter, basicStyles.justifyContentSpaceBetween, {marginBottom: 25}]}>
					<AppText style={[textStyles.pageSubTitle, textStyles.semiBold, {marginBottom: 0}]}>
						Payment Methods
					</AppText>
					{
						addingCard ?
							<AppButton
								label="Cancel"
								action={() => setAddingCard(false)}
							/>
							:
							<AppButton
								label="Add Payment Method"
								action={() => setAddingCard(true)}
							/>
					}
				</View>
				<ContentSection style={{padding: 34}}>
					{
						addingCard ?
							<View style={basicStyles.alignContentCenter}>
								<StripeCardInput
									submitEngaged={submittingCard}
									onSubmit={cardSubmit}
								/>
								<AppButton
									label="Submit"
									action={() => setSubmittingCard(true)}
									style={{marginTop: 20}}
								/>
							</View>
							:
							customerInfo.cards.sort(card => {
								return card.id === customerInfo.defaultCard ? 1 : 0;
							}).map((card, cardIndex) => {
								return (
									<View
										key={card.id}
										style={[
											styles.cardContainer,
											...(cardIndex === customerInfo.cards.length - 1 ? [] : [{marginBottom: 15}]),
										]}
									>
										<View
											style={[
												basicStyles.flexScale,
												basicStyles.flexRow,
												basicStyles.justifyContentSpaceBetween,
												basicStyles.alignContentCenter,
												styles.cardInfoContainer,
												styles.cardInfoDetails,
											]}>
											<AppText>
												{card.brand}
											</AppText>
											<AppText>
												XXXX XXXX XXXX {card.last4}
											</AppText>
											<AppText>
												Exp: {card.exp_month + '/' + card.exp_year}
											</AppText>
										</View>
										<TouchView
											style={[
												basicStyles.alignContentCenter,
												basicStyles.justifyContentCenter,
												styles.cardInfoContainer,
												styles.defaultPaymentSelector,
												...(customerInfo.defaultCard === card.id ? [{cursor: 'auto'}] : []),
											]}
											action={() => {
												if (customerInfo.defaultCard !== card.id) {
													warnSetCardDefault(card.id);
												}
											}}
										>

											<CheckMarkBox
												style={styles.checkMarkMargin}
												active={customerInfo.defaultCard === card.id}
											/>
											<AppText>
												Default Payment
											</AppText>
										</TouchView>
									</View>
								);
							})
					}
				</ContentSection>
				<AppText style={[textStyles.pageSubTitle, textStyles.semiBold, {marginTop: 70}]}>
					Invoices
				</AppText>
				<ContentSection style={styles.invoiceContainer}>
					<Grid>
						<Row style={[styles.row, styles.headerRow]}>
							<Col style={styles.column}>
								<AppText style={styles.columnHeadText}>
									Date
								</AppText>
							</Col>
							<Col style={[styles.column, styles.midColumn]}>
								<AppText style={styles.columnHeadText}>
									Plan
								</AppText>
							</Col>
							<Col style={[styles.column, styles.second2LastColumn]}>
								<AppText style={styles.columnHeadText}>
									Amount
								</AppText>
							</Col>
							<Col style={[styles.column, styles.lastColumn]}>
								<AppText style={styles.columnHeadText}>
									Download Invoice
								</AppText>
							</Col>
						</Row>
						{
							customerInfo.invoices.map((invoice, index) => {
								return (
									<Row
										key={invoice.id}
										style={[
											styles.row,
											...(customerInfo.invoices.length - 1 === index ? [{borderBottomWidth: 0}] : []),
										]}
									>
										<Col style={styles.column}>
											<AppText style={styles.columnHeadTextMobile}>
												Date
											</AppText>
											<AppText>
												{timestampToFormattedDate(invoice.date)}
											</AppText>
										</Col>
										<Col style={[styles.midColumn, styles.column]}>
											<AppText style={styles.columnHeadTextMobile}>
												Plan
											</AppText>
											<AppText>
												{invoice.lines.data[0].price.nickname} Plan
											</AppText>
										</Col>
										<Col style={[styles.second2LastColumn, styles.column]}>
											<AppText style={styles.columnHeadTextMobile}>
												Amount
											</AppText>
											<AppText>
												${invoice.amount_due / 100}
											</AppText>
										</Col>
										<Col style={[styles.lastColumn, styles.column]}>
											<TouchView action={() => window.open(invoice.invoice_pdf, '_blank')}>
												<DownloadSvg/>
											</TouchView>
										</Col>
									</Row>
								);
							})
						}
					</Grid>
				</ContentSection>
			</PageContentContainer>
		</ScrollView>
	);
}

const mapDispatchToProps = (dispatch) => (
	bindActionCreators({
		setWarning,
	}, dispatch)
);

export default connect(null, mapDispatchToProps)(PaymentSettings);

const styles = EStyleSheet.create({
	invoiceContainer: {
		paddingTop: 10,
		paddingLeft: 34,
		paddingRight: 34,
		paddingBottom: 10,
	},
	column: {
		paddingTop: 28,
		paddingBottom: 28,
		marginLeft: 5,
		marginRight: 5,
		justifyContent: 'center'
	},
	midColumn: {
		flex: 2,
	},
	columnHeadText: {
		fontSize: 12,
		color: '#9BA8CA',
	},
	columnHeadTextMobile: {
		display:'none',
		fontSize: 12,
		color: '#9BA8CA',
	},
	row: {
		borderBottomWidth: 1,
		borderBottomColor: '#D9E8F8',
	},
	planTitle: {
		fontFamily: 'SourceSansPro-SemiBold',
	},
	second2LastColumn: {
		alignItems: 'center',
		textAlign: 'center',
	},
	lastColumn: {
		alignItems: 'flex-end',
		textAlign: 'right',
	},
	header: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	cardContainer: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	cardInfoContainer: {
		borderWidth: 1,
		borderColor: '#D9E8F8',
		borderRadius: 5,
		height: 75,
	},
	cardInfoDetails: {
		paddingLeft: 30,
		paddingRight: 30,
	},
	checkMarkMargin: {},
	defaultPaymentSelector: {
		paddingLeft: 10,
		paddingRight: 10,
		marginLeft: 20,
	},
	'@media (max-width: 800)': {
		invoiceContainer: {
			paddingLeft: 20,
			paddingRight: 20
		},
		header: {
			flexDirection: 'column',
			paddingLeft: 0,
			paddingRight: 0,
			alignItems: 'flex-start'
		},
		headerText: {
			paddingLeft: 45,
			paddingBottom: 30,
		},
		statusIconRow: {
			width: '100%',
			alignItems: 'center',
			paddingTop: 20,
			paddingLeft: 45,
			paddingRight: 45,
			borderTopWidth: 1,
			borderColor: '#D9E8F8'
		},
	},
	'@media (max-width: 675)': {
		headerRow: {
			display: 'none'
		},
		columnHeadTextMobile: {
			display: 'flex'
		},
		row: {
			width: '100%',
			flexWrap: 'wrap'
		},
		column: {
			width: '50%',
			flex: 'inherit',
			marginLeft: 0,
			marginRight: 0,
			paddingTop: 15,
			paddingBottom: 15,
			justifyContent: 'center',
			alignItems: 'flex-start',
			textAlign: 'left'
		},
		midColumn: {
			flex: 'inherit'
		},
		second2LastColumn: {
			textAlign: 'left'
		},
		lastColumn: {
			textAlign: 'left'
		}
	},
	'@media (max-width: 575)': {
		cardInfoDetails: {
			flexDirection: 'column',
			paddingTop: 10,
			paddingBottom: 10,
			paddingLeft: 10,
			paddingRight: 10
		}
	},
	'@media (max-width:500)': {
		headerText: {
			paddingLeft: 30
		},
		statusIconRow: {
			flexDirection: 'column',
			paddingLeft: 30,
			paddingRight: 30
		},
		statusStyleWrapper: {
			justifyContent: 'flex-start'
		}
	},
	'@media (max-width:400)': {
		statusStyleWrapper: {
			width: '100%',
		},
		cardContainer: {
			flexDirection: 'column',
			width: '100%'
		},
		cardInfoContainer: {
			width: '100%'
		},
		defaultPaymentSelector: {
			marginLeft: 0,
			marginTop: 20,
			flexDirection: 'row'
		},
		checkMarkMargin: {
			marginRight: 10
		}
	}
});
