import React from 'react';
import {View} from 'react-native';

import AppText from './AppText';
import AppButton from './AppButton';
import {inputStyles} from '../styles/input';
import {basicStyles} from '../styles/basic';

function CopyField({label, value, wrapperStyle = {}, inputStyle = {}, labelStyle = {}}) {
	return (
		<View style={[
			inputStyles.inputWrapper,
			basicStyles.flexRow,
			basicStyles.alignContentCenter,
			wrapperStyle,
		]}
		>
			<View style={basicStyles.flexScale}>
				<AppText style={[inputStyles.inputLabel, labelStyle]}>
					{label}
				</AppText>
				<AppText
					style={[
						basicStyles.flexScale,
						inputStyles.input,
						inputStyle,
					]}>
					{value}
				</AppText>
			</View>
				<AppButton
					label="Copy to Clipboard"
					theme="green"
					action={() => {
						navigator.clipboard.writeText(value);
					}}
				/>
		</View>

	);
}

export default CopyField;
