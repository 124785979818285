import React from 'react';
import {View, StyleSheet} from 'react-native';
import {connect} from 'react-redux';
import AsyncStorage from '@react-native-community/async-storage';

import AppText from "./AppText";
import {getUserDisplayName} from "../utilities/methods";
import Avatar from "./Avatar";
import {AVATAR_DEFAULT, AVATAR_PATH_ORG, AVATAR_PATH_USER, ORGANIZATION_TYPE_IDS} from "../config";
import {basicStyles} from "../styles/basic";
import AppButton from "./AppButton";
import {bindActionCreators} from "redux";
import {stopEmulatingUser, setToken, setOrganization, setOrganizationRoleId, setPermissions} from "../store/auth/authActions";
import AuthService from "../services/AuthService";
import OrganizationsService from "../services/OrganizationsService";
import {navigate} from '../utilities/navigation';
import store from "../store";

function UserEmulationIndicator(props) {
    const {realToken, realOrganizationId, realOrganizationRoleId, user, organization} = props.auth;
    const {stopEmulatingUser, setToken} = props;

    const stopEmulation = async () => {
        try {
            let token = realToken;
            setToken(token);
            let user = await AuthService.validate();
            let organization = await OrganizationsService.getOrganization(realOrganizationId);
            let organizationRoleId = realOrganizationRoleId;
            let permissions = await AuthService.getPermissions(realOrganizationId);

            stopEmulatingUser({
                token,
                user,
                organization,
                organizationRoleId,
                permissions
            });

            await AsyncStorage.removeItem('emulateAuthToken');
            await AsyncStorage.removeItem('emulateOrganizationId');
            await AsyncStorage.removeItem('emulateOrganizationRoleId');

            if (organization.organization_type_id == ORGANIZATION_TYPE_IDS.CLIENT) {
                navigate('Jobs');
            } else {
                navigate('Clients');
            }
        } catch (error) {
            setToken(null);
            setOrganization(null);
            store.dispatch(setOrganizationRoleId(null));
            store.dispatch(setPermissions([]));

            await AsyncStorage.removeItem('authToken');
            await AsyncStorage.removeItem('organizationId');
            await AsyncStorage.removeItem('roleId');

            navigate('Unauthenticated');

        }
    };

    if (realToken && user && organization) {
        return (
            <View style={styles.wrapper}>
                <View style={[basicStyles.flexRow, basicStyles.alignContentCenter]}>
                    <AppText style={styles.text}>Emulating</AppText>
                    <Avatar
                        source={{uri: (user.user_avatar_file ? AVATAR_PATH_USER + user.user_avatar_file : (organization.organization_default_survey_image ? AVATAR_PATH_ORG + organization.organization_default_survey_image : AVATAR_DEFAULT))}}
                        width={30}
                        style={styles.avatar}
                    />
                    <AppText style={styles.text}>{getUserDisplayName(user)} at</AppText>
                    <Avatar
                        source={{uri: organization.organization_thumbnail ? AVATAR_PATH_ORG + organization.organization_thumbnail : AVATAR_DEFAULT}}
                        width={30}
                        style={styles.avatar}
                    />
                    <AppText style={styles.text}>{organization.organization_title}</AppText>
                </View>
                <AppButton
                    label="Stop Emulation"
                    action={() => {
                        stopEmulation();
                    }}
                />
            </View>
        )
    }

    return (<></>);
}

const mapStateToProps = (state) => {
    const {auth} = state;
    return {auth};
};

const mapDispatchToProps = (dispatch) => (
    bindActionCreators({
        stopEmulatingUser,
        setToken
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(UserEmulationIndicator);

const styles = StyleSheet.create({
    wrapper: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 10,
        paddingBottom: 10,
        borderBottomWidth: 1,
        borderBottomColor: 'rgba(226,233,244,.16)',
        backgroundColor: '#1CD084',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    text: {
        color: '#FFFFFF',
        fontFamily: 'SourceSansPro-SemiBold'
    },
    avatar: {
        marginLeft: 7,
        marginRight: 3
    }
});
