import * as React from "react"
import Svg, {Path} from "react-native-svg"

function EmojisSvg(props) {
    return (
        <Svg
            width={22}
            height={22}
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M5.98 13.847l1.05-.525c1.902 2.98 6.186 2.817 7.939-.005.354.176.71.355 1.067.534-.867 1.67-2.699 2.936-5.07 2.927-2.57-.01-4.339-1.563-4.987-2.93zM8.734 9.055c.012.486-.44.949-.939.962-.485.013-.949-.44-.963-.937-.013-.486.44-.95.939-.962.485-.013.95.439.963.937zM14.218 8.118c.486-.008.95.448.957.942.008.487-.447.95-.942.957-.486.008-.95-.448-.957-.943-.008-.486.447-.949.942-.956z"
                fill="#3CA6F5"
            />
            <Path
                d="M11.017 21.292C5.352 21.305.713 16.675.707 11.003.7 5.34 5.339.701 11.009.707 16.67.71 21.308 5.302 21.293 10.98c-.015 5.914-4.715 10.267-10.275 10.314l-.001-.002zm-.002-1.273c4.972-.004 9.02-4.059 9.014-9.03-.006-4.971-4.059-9.013-9.036-9.01-4.971.005-9.019 4.06-9.014 9.03.005 4.972 4.058 9.014 9.035 9.01h.002z"
                fill="#3CA6F5"
            />
        </Svg>
    )
}

export default EmojisSvg
