import React from 'react';
import {View, StyleSheet, Animated} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {removeNotification} from "../store/notifications/notificationActions";
import AppText from "./AppText";
import TouchView from "./TouchView";


function Notifications(props) {
    const {notifications} = props.notifications;
    const {removeNotification} = props;

    return (
        <View style={[
            styles.notificationsContainer,
            ...(notifications.length <= 0 ? [styles.inactive] : [{}])
        ]}>
            {
                notifications.map(notification => {
                    //create animated value for transition
                    const animatedY = new Animated.Value(60);
                    //start animation in
                    Animated.timing(animatedY, {
                        toValue: 0,
                        duration: 200,
                    }).start()
                    //function to animate out
                    const animateOut = () => {
                        Animated.timing(animatedY, {
                            toValue: 60,
                            duration: 200,
                        }).start(() => {
                            //delete notification after animate out
                            removeNotification(notification.key);
                        })
                    };
                    //timeout to animate out
                    const animateOutTimeout = setTimeout(() => {
                        animateOut();
                    }, 4000)
                    return (
                        <TouchView
                            key={notification.key}
                            style={[
                                styles.notification,
                                {
                                    transform : [{
                                        translateY : animatedY,
                                    }]
                                }
                            ]}
                            action={() => {
                                //clear time out and animate out now.
                                clearTimeout(animateOutTimeout);
                                animateOut();
                            }}
                        >
                            <AppText>
                                {notification.notification}
                            </AppText>
                        </TouchView>
                    );
                })
            }
        </View>
    );
}

const styles = StyleSheet.create({
    notificationsContainer: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%',
        zIndex: 10000,
        elevation: 10000,
        height: 60,
        justifyContent: 'center',
        alignItems: 'center',
    },
    notification: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: '100%',
        backgroundColor: '#FFFFFF',
        borderTopWidth: 1,
        borderTopColor: 'blue',
        justifyContent: 'center',
        alignItems: 'center'
    },
    inactive: {
        pointerEvents: 'none'
    }
});

const mapStateToProps = (state) => {
    const {notifications} = state;
    return {notifications};
};

const mapDispatchToProps = (dispatch) => (
    bindActionCreators({
        removeNotification
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
