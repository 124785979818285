import React from 'react';
import {View} from 'react-native';
import {createStackNavigator} from '@react-navigation/stack';

import Login from './Login';
import AcceptInvite from './AccountSetup';
import Survey from './Survey';
import PasswordReset from "./PasswordReset";
import OnBoarding from "./OnBoarding";

const Stack = createStackNavigator();

function Unauthenticated(props) {
	const redirect = props.route?.params?.redirect;
	return (
		<View style={{flex: 1}}>
			<Stack.Navigator
				backBehavior="history"
				screenOptions={{
					header: () => null,
					cardStyle: {
						backgroundColor: '#F8FAFD',
						flex: 1
					},
				}}
				initialRouteName="Login"
			>
				<Stack.Screen
					name="Login"
					component={Login}
					initialParams={{redirect}}
					options={{
						title: "Login"
					}}
				/>
				<Stack.Screen
					name="AcceptInvite"
					component={AcceptInvite}
					options={{
						title: "Accept Invite"
					}}
				/>
				<Stack.Screen
					name="Survey"
					component={Survey}
					options={{
						title: "Survey"
					}}
				/>
				<Stack.Screen
					name="PasswordReset"
					component={PasswordReset}
					options={{
						title: "Reset Password"
					}}
				/>
				<Stack.Screen
					name="OnBoarding"
					component={OnBoarding}
					options={{
						title: "On Boarding"
					}}
				/>
			</Stack.Navigator>
		</View>
	);
}


export default Unauthenticated;
