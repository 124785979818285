import {reviewResponseActionTypes} from './reviewResponseActions';

const initialState = {
	active: false,
	review: null,
	response: null,
	isAiGeneratedResponse: false,
	userId: null,
	reviewerName: null,
	organizationTitle: null,
	surveyResponseAnswerId: null,
	surveyResponseId: null,
	jobId: null,
	jobCustomerEmail: null,
	jobCustomerPhone: null,
	wrapperStyle: {},
	allowAiGeneratedResponse: false,
};

const reviewResponseReducer = (state = initialState, action) => {
	switch (action.type) {
		case reviewResponseActionTypes.SET_REVIEW_RESPONSE:
			return Object.assign({}, state, action.payload);
		case reviewResponseActionTypes.SET_AI_GENERATED:
			return Object.assign({}, state, {isAiGeneratedResponse: action.payload});
		case reviewResponseActionTypes.DESTROY_REVIEW_RESPONSE:
			return Object.assign({}, initialState);
		default:
			return state;
	}
};

export default reviewResponseReducer;
