import * as React from "react"
import Svg, { Path } from "react-native-svg"

function ErrorMiniSvg({style = {}, ...props}) {
	return (
		<Svg width={8.35} height={16} viewBox="0 0 8.35 16" style={style} {...props}>
			<Path
				data-name="Path 368"
				d="M3.48 16V9.74H0L5 0v6.26h3.35z"
				fill="#ea0024"
			/>
		</Svg>
	)
}

export default ErrorMiniSvg
