import React, {useState, useEffect} from 'react';
import {View, StyleSheet, TouchableOpacity} from 'react-native';

import AppText from './AppText';
import AppPickerInput from "./AppPickerInput";
import ConfirmSvg from "./svgs/ConfirmSvg";
import UnconfirmSvg from "./svgs/UnconfirmSvg";
import EStyleSheet from 'react-native-extended-stylesheet';

function NotificationTile({callback, style = {}, ...props}) {

	function updateNotificationFrequency(frequencyValue) {
		callback(props.notificationId, frequencyValue);
	}

	function invalidateFrequency(frequency) {
		return frequency == 'none' || !frequency;
	}

	function enableDisableNotification() {
		if(invalidateFrequency(props.selectedFrequency)) {
			callback(props.notificationId, props.defaultItem);
		} else {
			callback(props.notificationId, 'none');
		}
	}

	return (
		<View style={[styles.wrapper, style]}>
			<View style={styles.details}>
				<TouchableOpacity
					onPress={() => enableDisableNotification()}
				>
					{
						invalidateFrequency(props.selectedFrequency) ?
							<UnconfirmSvg style={{width:30}} /> :
							<ConfirmSvg style={{width:30}} />
					}

				</TouchableOpacity>
				<View style={styles.description}>
					<AppText style={styles.title}>{props.notificationTitle}</AppText>
					<AppText style={[styles.alert, styles.alertOn]}>
						{
							invalidateFrequency(props.selectedFrequency) ?
								'Alerts Disabled' :
								'Receiving Alerts'
						}
					</AppText>
				</View>
			</View>
			<View style={[styles.buttonContainer]}>
				{
					invalidateFrequency(props.selectedFrequency) ?
						null
						:
						<AppPickerInput
							label="Report Frequency"
							onValueChange={value => updateNotificationFrequency(value)}
							selectedValue={props.selectedFrequency}
							items={props.items}
							labelExtractor="label"
							valueExtractor="value"
						/>
				}
			</View>
		</View>
	);
}

export default NotificationTile;

const styles = EStyleSheet.create({
	wrapper: {
		flex: 1,
		backgroundColor: '#FFFFFF',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingLeft: 30,
		paddingRight: 30,
		paddingTop: 15,
		paddingBottom: 15,
		marginBottom: 20,
		borderWidth: 1,
		borderColor: '#E2E9F4',
		borderRadius: 8,
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 3,
		},
		shadowOpacity: 0.1,
		shadowRadius: 9.11,
	},
	details: {
		flexDirection: 'row',
		alignItems: 'center',
		height: 80
	},
	description: {
		paddingLeft: 15
	},
	title : {
		fontSize: 16,
		color: '#000',
		fontFamily: 'SourceSansPro-Regular'
	},
	alert: {
		fontSize: 12,
		fontFamily: 'SourceSansPro-Regular'
	},
	alertOn: {
		color: '#0065DB'
	},
	alertOff: {
		color: '#9BA8CA'
	},
	'@media (max-width: 550)': {
		wrapper: {
			flexDirection: 'column',
		},
		details: {
			width: '100%',
		},
		buttonContainer: {
			width: '100%',
		}
	}
});
