import React, {useState, useEffect} from 'react';
import {View, ScrollView, ActivityIndicator} from "react-native";
import {connect} from 'react-redux';
import {BarChart, Grid} from 'react-native-svg-charts';
import moment from "moment";
import EStyleSheet from 'react-native-extended-stylesheet';

import PageTop from "../../components/PageTop";
import {basicStyles} from "../../styles/basic";
import Avatar from "../../components/Avatar";
import {AVATAR_DEFAULT, AVATAR_PATH_ORG, AVATAR_PATH_USER, USA_LAT_LANG_CENTER} from "../../config";
import {Text} from "react-native-svg";
import AppText from "../../components/AppText";
import PageContentContainer from "../../components/PageContentContainer";
import OrganizationsService from "../../services/OrganizationsService";
import AppPickerInput from "../../components/AppPickerInput";
import UsersService from "../../services/UsersService";
import AppDateRangePicker from "../../components/AppDateRangePicker";
import {useDebouncedCallback} from "use-debounce";
import JobsService from "../../services/JobsService";
import HeatMap from "../../components/HeatMap";
import StarRangeInput from "../../components/StarsRangeInput";
import {getUserDisplayName} from "../../utilities/methods";


function Dashboard(props) {
    const [state, setState] = useState({
        surveyData: {
            surveys_sent: 0,
            surveys_completed: 0,
            survey_star_total: 0,
            survey_star_average: 0,
            star_rank: 0
        },
        surveyCompletePercent: '0%',
        reviewsVelocityData: [],
        loadingData: true
    });

    const isManager = props.auth.permissions.includes('view_account_reports');
    const [selectedUser, setSelectedUser] = useState(isManager ? 'all' : props.auth.user_id);
    const [users, setUsers] = useState([]);

    let today = new Date();
    let [startDate, setStartDate] = useState(new Date(new Date().setDate(today.getDate() - 30)).setHours(12, 0, 0, 0));
    let [endDate, setEndDate] = useState(today.setHours(12, 0, 0, 0));

    const [jobsState, setJobsState] = useState({
        jobs: [],
        jobCount: 0,
        jobsInitialized: false
    });

    const {organization} = props.auth;

    useEffect(() => {
        const getData = async () => {
            const data = await OrganizationsService.getDashboardReportData(
                organization.organization_id,
                selectedUser,
                startDate ? moment(startDate).format('YYYY-MM-DD') : null,
                endDate ? moment(endDate).format('YYYY-MM-DD') : null
            );
            setState(prevState => {
                return Object.assign({}, prevState, data, {loadingData: false})
            });
        };

        getData();
        getJobsDebounce();
        getTopThreeDebounce();
    }, [organization.organization_id, selectedUser, startDate, endDate]);

    useEffect(() => {
        const getUsers = async () => {
            let usersData = await UsersService.getUsersWithJobs(
                organization.organization_id,
                null,
                null,
                null,
                startDate ? moment(startDate).format('YYYY-MM-DD') : null,
                endDate ? moment(endDate).format('YYYY-MM-DD') : null
            );
            setUsers(usersData);
        };
        getUsers();
    }, [organization.organization_id, startDate, endDate]);

    const [getJobsDebounce] = useDebouncedCallback(() => {
        setJobsState(prevState => {
            return {...prevState, jobsInitialized: false}
        });
        JobsService.getJobs(
            organization.organization_id,
            null,
            'DESC',
            'job_timestamp',
            startDate ? moment(startDate).format('YYYY-MM-DD') : null,
            endDate ? moment(endDate).format('YYYY-MM-DD') : null,
            500,
            null,
            0,
            true,
            selectedUser
        ).then(jobsData => {
            setJobsState({
                jobs: jobsData.jobs,
                jobCount: jobsData.jobCount,
                jobsInitialized: true
            });
        });
    });

    const [getTopThreeDebounce] = useDebouncedCallback(() => {
        OrganizationsService.getDashboardReportDataTopThree(
            organization.organization_id,
            selectedUser,
            startDate ? moment(startDate).format('YYYY-MM-DD') : null,
            endDate ? moment(endDate).format('YYYY-MM-DD') : null
        ).then(data => {
            setState(prevState => {
                return Object.assign({}, prevState, data)
            });
        });
    });


    let reviewsVelocityDataFormatted = [
        {
            data: [],
            svg: {
                fill: '#578eba' //jobs count
            }
        },
        {
            data: [],
            svg: {
                fill: '#2eafd4' //reviews sent count
            }
        },
        {
            data: [],
            svg: {
                fill: '#7cc37d' //reviews completed count
            }
        },
    ];
    let reviewsVelocityLabels = [];
    for (let data of state.reviewsVelocityData) {
        if(data.week) {
            reviewsVelocityDataFormatted[0].data.push({value: parseInt(data.jobs_count)});
            reviewsVelocityDataFormatted[1].data.push({value: parseInt(data.surveys_sent_count)});
            reviewsVelocityDataFormatted[2].data.push({value: parseInt(data.surveys_completed_count)});

            reviewsVelocityLabels.push(moment(data.start_date).format('MM/DD/YY'));
        }
    }

    const maxValue = Math.max(...reviewsVelocityDataFormatted[0].data.concat(reviewsVelocityDataFormatted[1].data).concat(reviewsVelocityDataFormatted[2].data).map(({value}) => value))
    const CUT_OFF = maxValue - 10;

    const Labels = ({x, y, bandwidth, data}) => {
        let labels = (
            data.map((dataList, dataListIndex) => {
                let xModifier;
                switch (dataListIndex) {
                    case 0:
                        xModifier = 0 - (bandwidth * .333);
                        break;
                    case 1:
                        xModifier = 0;
                        break;
                    case 2:
                        xModifier = (bandwidth * .333);
                        break;
                }
                return dataList.data.map((item, index) => {
                    const value = item.value;
                    return (
                        <Text
                            key={dataListIndex + '_' + index}
                            x={(x(index) + (bandwidth / 2)) + xModifier}
                            y={value < CUT_OFF ? y(value) - 10 : y(value) + 15}
                            fontSize={14}
                            fill={value >= CUT_OFF ? 'white' : 'black'}
                            alignmentBaseline={'middle'}
                            textAnchor={'middle'}
                            style={{fontSize: 14, fontFamily: 'SourceSansPro-SemiBold'}}
                        >
                            {value > 0 ? value : ''}
                        </Text>
                    );
                })
            })
        )

        return [].concat.apply([], labels)
    };

    return (
        <ScrollView contentContainerStyle={basicStyles.flexScale}>
            <PageTop style={[
                basicStyles.flexRow,
                basicStyles.alignContentCenter,
                {zIndex: 1}
            ]}>
                <AppDateRangePicker
                    startDateValue={startDate}
                    endDateValue={endDate}
                    onChangeDates={(dates) => {
                        const [start, end] = dates;
                        setStartDate(start);
                        setEndDate(end);
                    }}
                />
                {
                    state.loadingData ?
                        <View style={[basicStyles.flexRow, basicStyles.flexCenterContent]}>
                            <AppText style={{marginLeft: 10, marginRight: 6}}>
                                <ActivityIndicator size="large" color="#467AFF"/>
                            </AppText>
                            <AppText>Gathering Data</AppText>
                        </View>
                        :
                        null
                }
                {
                    isManager ?
                        <AppPickerInput
                            label={'Company User'}
                            wrapperStyle={{marginLeft: 'auto', height: 40}}
                            onValueChange={(value) => setSelectedUser(value)}
                            selectedValue={selectedUser}
                            items={[
                                {user_display_name: 'Company Global', user_id: 'all'},
                                ...users?.filter((user) => user.user_organization_status === 'active')
                            ]}
                            labelExtractor={(user) => user?.user_display_name ?? getUserDisplayName(user)}
                            valueExtractor={(user) => user.user_id.toString()}
                            showLabel={false}
                        />
                        : null
                }
            </PageTop>
            <PageContentContainer>
                <View style={[
                    basicStyles.alignContentCenter,
                    {
                        marginBottom: 60,
                    }
                ]}>
                    <View  style={{height: 500, width: '100%'}}>
                        {
                            jobsState.jobsInitialized ?
                                <>
                                    <HeatMap
                                        centerLatLang={USA_LAT_LANG_CENTER}
                                        coordinates={
                                            jobsState.jobs.reduce((accumulated, job) => {
                                                if (job.google_place_latitude && job.google_place_longitude) {
                                                    accumulated.push({lat: parseFloat(job.google_place_latitude), lng: parseFloat(job.google_place_longitude)})
                                                }

                                                return accumulated
                                            }, [])
                                        }
                                        initialized={jobsState.jobs.length > 0}
                                        autoCenter={true}
                                    />
                                </>
                                : null
                        }
                    </View>
                    <View style={styles.dataWidgetWrapper}>
                        <View style={styles.dataWidget}>
                            <AppText style={styles.dataLabel}>
                                Total Jobs
                            </AppText>
                            <AppText style={styles.dataValue}>
                                {jobsState.jobCount}
                            </AppText>
                        </View>
                        <View style={styles.dataWidget}>
                            <AppText style={styles.dataLabel}>
                                Total Surveys Sent
                            </AppText>
                            <AppText style={styles.dataValue}>
                                {state.surveyData?.surveys_sent}
                            </AppText>
                        </View>
                        <View style={styles.dataWidget}>
                            <AppText style={styles.dataLabel}>
                                Total Surveys Completed
                            </AppText>
                            <AppText style={styles.dataValue}>
                                {state.surveyData?.surveys_completed}
                            </AppText>
                        </View>
                        <View style={styles.dataWidget}>
                            <AppText style={styles.dataLabel}>
                                Survey Star Average
                            </AppText>
                            <AppText style={styles.dataValue}>
                                {state.surveyData?.survey_star_average}
                            </AppText>
                        </View>
                        <View style={styles.dataWidget}>
                            <AppText style={styles.dataLabel}>
                                Surveys Completion Percentage
                            </AppText>
                            <AppText style={styles.dataValue}>
                                {state.surveyCompletePercent}
                            </AppText>
                        </View>
                        <View style={styles.dataWidget}>
                            <AppText style={styles.dataLabel}>
                                Company Ranking Total Stars
                            </AppText>
                            <AppText style={styles.dataValue}>
                                {state.surveyData?.star_rank}
                            </AppText>
                        </View>

                    </View>
                </View>

                <View>
                    <View style={[
                        basicStyles.flexRow,
                        basicStyles.justifyContentSpaceBetween,
                        basicStyles.alignContentCenter,
                        styles.reviewVelocity
                    ]}>
                        <View style={[
                            basicStyles.flexRow,
                            basicStyles.alignContentCenter,
                            styles.reviewVelocityTitle
                        ]}>
                            <AppText style={styles.barChartHeading}>
                                Weekly Review Velocity
                            </AppText>
                            <View style={styles.barChartHeadingDivider}/>
                            <AppText style={styles.barChartSubHeading}>
                                Last 6 Months
                            </AppText>
                        </View>
                        <View style={[basicStyles.flexRow, styles.reviewVelocityLegend]}>
                            <View style={{backgroundColor: '#578eba', padding: 5, margin: 5}}>
                                <AppText style={{color: '#FFFFFF'}}>Job Count</AppText>
                            </View>
                            <View style={{backgroundColor: '#2eafd4', padding: 5, margin: 5}}>
                                <AppText style={{color: '#FFFFFF'}}>Surveys Sent</AppText>
                            </View>
                            <View style={{backgroundColor: '#7cc37d', padding: 5, margin: 5}}>
                                <AppText style={{color: '#FFFFFF'}}>Completed Reviews</AppText>
                            </View>
                        </View>
                    </View>
                    <ScrollView
                        horizontal={true}
                        style={[styles.grid, styles.gridInversion]}
                        contentContainerStyle={{minWidth: '100%'}}
                        showsHorizontalScrollIndicator={true}
                    >
                        <View style={[styles.customChartWrapper, styles.gridInversion]}>
                            <BarChart
                                style={styles.customChart}
                                data={reviewsVelocityDataFormatted}
                                contentInset={{top: 10, bottom: 10}}
                                yAccessor={({item}) => item.value}
                                gridMin={0}
                            >
                                <Grid direction={Grid.Direction.HORIZONTAL}/>
                                <Labels/>
                            </BarChart>
                            <View style={styles.customChartXAxis}>
                                {
                                    reviewsVelocityLabels.map(label => {
                                        return (
                                            <View key={label} style={{width: 51}}>
                                                <AppText style={{fontSize: 12}}>
                                                    {label}
                                                </AppText>
                                            </View>
                                        )
                                    })
                                }
                            </View>
                        </View>
                    </ScrollView>
                </View>
                <View
                    style={[
                        styles.topThreeContainer,
                        basicStyles.justifyContentSpaceBetween,
                        {alignItems: 'stretch', width: '100%', marginTop: 60, gap: 30}
                    ]}
                >
                    {
                        state.topThreeUsers?.length > 0 && (
                            <View style={[styles.topThreeColumn]}>
                                <AppText style={[styles.topThreeLabel]}>Top 3 Users</AppText>
                                <View style={[styles.topThreeWrapper]}>
                                    {
                                        state.topThreeUsers.map((user) => {
                                            return (
                                                <View key={'top3User-' + user.user_display_name} style={[basicStyles.flexRow, basicStyles.alignContentCenter, styles.topThreeCard]}>
                                                    <View>
                                                        <Avatar
                                                            source={{uri: (user.user_avatar_file ? AVATAR_PATH_USER + user.user_avatar_file : (organization.organization_default_survey_image ? AVATAR_PATH_ORG + organization.organization_default_survey_image : AVATAR_DEFAULT))}}
                                                            width={70}
                                                        />
                                                    </View>
                                                    <View style={{marginLeft: 25}}>
                                                        <AppText style={[styles.topThreeAppTextHeader]}>{user.user_display_name}</AppText>
                                                        <AppText style={[styles.topThreeAppText]}><StarRangeInput
                                                            value={user.survey_star_average}
                                                            disabled={true}
                                                            starSize={22}
                                                        /></AppText>
                                                        <AppText style={[styles.topThreeAppText]}>Total Number of Stars: {user.survey_star_total}</AppText>
                                                        <AppText style={[styles.topThreeAppText]}>City Visited the Most: {user.city}</AppText>
                                                        <AppText style={[styles.topThreeAppText]}>Brand Serviced the Most: {user.brand}</AppText>
                                                    </View>
                                                </View>
                                            );
                                        })
                                    }
                                </View>
                            </View>
                        )
                    }
                    {
                        state.topThreeCities?.length > 0 && (
                            <View style={[styles.topThreeColumn]}>
                                <AppText style={[styles.topThreeLabel]}>Top 3 Cities</AppText>
                                <View style={[styles.topThreeWrapper]}>
                                    {
                                        state.topThreeCities.map((city) => {
                                            return (
                                                <View key={'top3City-' + city.google_place_city}style={[basicStyles.flexRow, basicStyles.alignContentCenter, basicStyles.justifyContentCenter, styles.topThreeCard, {textAlign: 'center'}]}>
                                                    <View>
                                                        <AppText style={[styles.topThreeAppTextHeader]}>{city.google_place_city}</AppText>
                                                        <AppText style={[styles.topThreeAppText, {marginLeft: 5}]}><StarRangeInput
                                                            value={city.survey_star_average}
                                                            disabled={true}
                                                            starSize={22}
                                                        /></AppText>
                                                        <AppText style={[styles.topThreeAppText]}>Total Stars for {city.google_place_city}: {city.survey_star_total}</AppText>
                                                        <AppText style={[styles.topThreeAppText]}>Total Jobs for {city.google_place_city}: {city.total_jobs}</AppText>
                                                    </View>
                                                </View>
                                            );
                                        })
                                    }
                                </View>
                            </View>
                        )
                    }

                    {
                        state.topThreeBrands?.length > 0 && (
                            <View style={[styles.topThreeColumn]}>
                                <AppText style={[styles.topThreeLabel]}>Top 3 Brands</AppText>
                                <View style={[styles.topThreeWrapper]}>
                                    {
                                        state.topThreeBrands.map((brand) => {
                                            return (
                                                <View key={'top3Brand-' + brand.job_product_brand} style={[basicStyles.flexRow, basicStyles.alignContentCenter, basicStyles.justifyContentCenter, styles.topThreeCard, {textAlign: 'center'}]}>
                                                    <View>
                                                        <AppText style={[styles.topThreeAppTextHeader]}>{brand.job_product_brand}</AppText>
                                                        <AppText style={[styles.topThreeAppText, {marginLeft: 5}]}><StarRangeInput
                                                            value={brand.survey_star_average}
                                                            disabled={true}
                                                            starSize={22}
                                                        /></AppText>
                                                        <AppText style={[styles.topThreeAppText]}>Total Jobs for {brand.job_product_brand}: {brand.total_jobs}</AppText>
                                                    </View>
                                                </View>
                                            );
                                        })
                                    }
                                </View>
                            </View>
                        )
                    }
                </View>
            </PageContentContainer>
        </ScrollView>
    )
}

const mapStateToProps = (state) => {
    const {auth} = state;
    return {auth};
};

export default connect(mapStateToProps)(Dashboard);

const styles = EStyleSheet.create({
    dataSectionWrapper: {
        width: '33.333%',
        paddingLeft: 15,
        paddingRight: 15,
        marginBottom: 15
    },
    dataSection: {
        borderBottomWidth: 1,
        borderBottomColor: '#D9E8F8',
        paddingBottom: 8,
    },
    dataValue: {
        flex: 3,
        fontSize: 22,
        fontWeight: 'bold',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    dataLabel: {
        flex: 1,
        fontSize: 14,
    },
    barChartHeading: {
        fontSize: 20,
        fontFamily: 'SourceSansPro-SemiBold',
    },
    barChartSubHeading: {
        fontSize: 20,
        fontFamily: 'SourceSansPro-Light'
    },
    barChartHeadingDivider: {
        width: 1,
        height: 20,
        backgroundColor: '#D9E8F8',
        marginRight: 10,
        marginLeft: 10,
    },

    customChartWrapper: {
        height: 325,
        paddingVertical: 16,
        width: '100%'
    },
    customChart: {
        flex: 1,
        width: '100%'
    },
    customChartXAxis: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
    topGraphs: {
        marginBottom: 30
    },
    topGraph: {
        width: '50%',
    },
    reviewVelocity: {
        paddingBottom: 30,
    },
    grid: {
        borderWidth: 1,
        borderColor: '#E2E9F4',
        borderRadius: 5,
        minWidth: '100%',
        width: '100%',
        paddingLeft: 30,
        paddingRight: 30,
    },
    gridInversion: {
        transform:[{rotateX:'180deg'}]
    },
    '@media (min-width: 900)': {
        topThreeColumn: {
            flex: 1
        },
    },
    '@media (max-width: 1300)': {},
    '@media (max-width: 900)': {
        topGraphs: {
            flexDirection: 'column',
            marginBottom: 30,
        },
        topGraph: {
            width: '100%',
            paddingRight: 0,
            paddingLeft: 0,
        },
        dataWidgetWrapper: {
            flexWrap: 'wrap'
        },
        dataWidget:  {
            flexBasis: '33%'
        },
        topThreeContainer: {
            flexDirection: 'column',
        },
        topThreeColumn: {
            marginBottom: 30
        },
    },
    '@media (max-width: 850)': {
        reviewVelocity: {
            flexDirection: 'column',
            alignItems: 'left'
        },
        reviewVelocityTitle: {
            marginLeft: 5,
        }
    },
    '@media (max-width: 600)': {
        dataSectionWrapper: {
            width: '50%'
        }
    },
    '@media (max-width: 400)': {
        dataSectionWrapper: {
            textAlign: 'center'
        },
        reviewVelocityTitle: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        barChartHeadingDivider: {
            display: 'none',
        },
        reviewVelocityLegend: {
            flexDirection: 'column',
        }
    },
    dataWidgetWrapper: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        gap: 20,
        width: '100%',
        alignItems: 'stretch',
        marginBottom: 20
    },
    dataWidget: {
        flex: 1,
        backgroundColor: '#FFFFFF',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.1,
        shadowRadius: 9.11,
        borderWidth: 1,
        borderColor: '#E2E9F4',
        borderRadius: 5,
        padding: 15,
        minHeight: 125,
    },
    topThreeLabel: {
        textAlign: 'center',
        fontSize: 20,
        marginBottom: 20
    },
    topThreeContainer: {
        flexDirection: 'row',
    },
    topThreeWrapper: {
      gap: 20,
    },
    topThreeCard: {
        flex: 1,
        backgroundColor: '#FFFFFF',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.1,
        shadowRadius: 9.11,
        borderWidth: 1,
        borderColor: '#E2E9F4',
        borderRadius: 5,
        padding: 20,
        minHeight: 125,
    },
    topThreeAppTextHeader: {
        fontSize: 20,
        marginBottom: 5
    },
    topThreeAppText: {
        fontSize: 16,
        marginTop: 5,
        marginBottom: 5
    }
});
