import React from 'react';
import {View, StyleSheet} from 'react-native';

function ScreenOverlay({children, onClick = () => {}, style = {}}) {
	return (
		<View style={[styles.overlay, style]} onClick={onClick}>
			{children}
		</View>
	)
}

export default ScreenOverlay;

const styles = StyleSheet.create({
	overlay: {
		position: 'absolute',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		width: '100%',
		height: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'rgba(155, 168, 202, .7)',
		zIndex: 1
	},
});
