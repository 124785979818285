import React, {useState} from 'react';
import {
	NavigationHelpersContext,
	useNavigationBuilder,
	TabRouter,
	createNavigatorFactory,
	CommonActions,
} from '@react-navigation/native';
import {View, StyleSheet} from 'react-native';

import AppText from './AppText';
import SubNavLink from './SubNavLink';
import SubNavMobileSelect from './SubNavMobileSelect';
import {getScreenMaxHeight} from '../utilities/methods';
import BackBar from './BackBar';

function SubNavigator({initialRouteName, children, screenOptions, backBehavior, tabBarStyle, contentStyle, backLink = null, backLinkParams = {}, backLinkLabel = null, mobileWidth = 550}) {
	const {state, navigation, descriptors} = useNavigationBuilder(TabRouter, {
		children,
		screenOptions,
		initialRouteName,
		backBehavior,
	});

	const [isSmall, setIsSmall] = useState(false);

	const handleLayout = ({nativeEvent}) => {
		const {width} = nativeEvent.layout;

		setIsSmall(width <= mobileWidth);
	};

	return (
		<NavigationHelpersContext.Provider value={navigation}>
			<View style={{flex: 1}} onLayout={handleLayout}>
				<View style={styles.tabsContainer}>
					{
						!isSmall ?
							state.routes.map(route => {
								const routeActive = state.routes[state.index].key === route.key;

								return (
									<SubNavLink
										key={route.key}
										to={'/' + route.name}
										action={() => {
											return CommonActions.navigate(route.name, {
												screen: route?.state?.routes[0]?.name
											});
										}}
										active={routeActive}
									>
										<AppText style={[styles.linkText, (routeActive ? styles.linkTextActive : {})]}>
											{descriptors[route.key].options.title || route.name}
										</AppText>
									</SubNavLink>
								);
							})
							:
							<SubNavMobileSelect
								style={styles.pickerStyle}
								selectedValue={state.routes[state.index].name}
								onValueChange={(itemValue, itemIndex) => {
									navigation.navigate(itemValue);
								}}
								items={
									state.routes.map(route => {
										return {
											key: route.key,
											value: route.name,
											label: (descriptors[route.key].options.title || route.name),
										};
									})
								}
							/>

					}
				</View>
				{
					backLink ?
					<BackBar
						action={CommonActions.navigate(backLink, backLinkParams)}
						label={backLinkLabel}
					/>
					: null
				}

				<View style={{flex: 1, maxHeight: getScreenMaxHeight()}}>
					{descriptors[state.routes[state.index].key].render()}
				</View>
			</View>
		</NavigationHelpersContext.Provider>
	);

}

export const createSubNavigator = createNavigatorFactory(SubNavigator);

const styles = StyleSheet.create({
	tabsContainer: {
		width: '100%',
		flexDirection: 'row',
		alignItems: 'center',
		backgroundColor: '#467AFF',
		height: 70,
		borderTopWidth: 1,
		borderTopColor: 'rgba(226,233,244,.16)',
		paddingLeft: 45,
		paddingRight: 45,
	},
	linkText: {
		color: '#FFFFFF',
		fontSize: 14,
	},
	linkTextActive: {
		fontFamily: 'SourceSansPro-SemiBold',
	},
	pickerStyle: {
		flex: 1,
	},
});
