import React, {useState} from 'react';
import {View, Linking, Platform, TextInput, StyleSheet} from 'react-native';
import PlacesAutocomplete, {geocodeByAddress} from 'react-places-autocomplete';
import EStyleSheet from 'react-native-extended-stylesheet';

import AppText from './AppText';
import AppButton from './AppButton';
import {inputStyles} from '../styles/input';
import {basicStyles} from '../styles/basic';
import {ScrollView} from 'react-native-web';

function AddressInput({
	label,
	onChangeAddress,
	onChangeState,
	value = '',
	wrapperStyle = {},
	labelStyle = {},
	inputStyle = {},
	editable = true,
	showDirections = true,
	alterZIndexOnFocus = false
}) {
	const [focused, setFocused] = useState(false);

	const handleButtonPress = () => {
		const url = 'https://google.com/maps/place/' + encodeURI(value);
		if (Platform.OS === 'web') {
			window.open(url, '_blank');
		} else {
			Linking.canOpenURL(url).then(supported => {
				if (supported) {
					return Linking.openURL(url);
				}
			});
		}
	};

	const handleChange = (address) => {
		onChangeAddress(address);
	};

	return (
		<PlacesAutocomplete
			value={value}
			onChange={handleChange}
			onSelect={handleChange}
			onError={(status, clearSuggestions) => {
				console.log('Google Maps API returned error with status: ', status);
				clearSuggestions();
			}}
		>
			{({getInputProps, suggestions, getSuggestionItemProps, loading}) => {
				return (
					<View style={[
						inputStyles.inputWrapper,
						basicStyles.flexRow,
						basicStyles.alignContentCenter,
						{position: 'relative'},
						focused && alterZIndexOnFocus ? styles.focused : {},
						wrapperStyle,
						styles.container,
					]}
					>
						<View style={[basicStyles.flexScale, styles.inputContainer]}>
							<AppText style={[inputStyles.inputLabel, labelStyle]}>
								{label}
							</AppText>
							<TextInput
								{...getInputProps()}
								editable={editable}
								style={[
									basicStyles.flexScale,
									inputStyles.input,
									inputStyle,
								]}
								onFocus={() => {
									setFocused(true);
								}}
								onBlur={() => {
									setFocused(false);
								}}
							/>
						</View>
						{
							value && showDirections?
								<AppButton
									label="Map"
									theme="green"
									action={() => handleButtonPress()}
									large={true}
									style={styles.buttonLayout}
								/>
								: null
						}
						{
							suggestions.length || loading ?
								<ScrollView style={styles.suggestionListContainer}>
									{
										suggestions.length ?
											suggestions.map(suggestion => {
												return (
													<View
														style={styles.suggestion}
														{...getSuggestionItemProps(suggestion)}
														key={suggestion.index}
													>
														<AppText>
															{suggestion.description}
														</AppText>
													</View>
												);
											})
											:
											(
												loading ?
													<AppText>Loading</AppText>
													: null
											)
									}
								</ScrollView>
								: null
						}
					</View>
				);
			}}

		</PlacesAutocomplete>
	);
}

export default AddressInput;

const styles = EStyleSheet.create({
	suggestionListContainer: {
		position: 'absolute',
		top: '100%',
		left: 0,
		right: 0,
		borderWidth: 1,
		borderRadius: 5,
		borderColor: '#E2E9F4',
		zIndex: 1,
		elevation: 1,
		backgroundColor: '#FFFFFF',
		maxHeight: 100,
	},
	suggestion: {
		borderBottomWidth: 1,
		borderBottomColor: '#E2E9F4',
		paddingLeft: 15,
		paddingRight: 15,
		paddingTop: 10,
		paddingBottom: 10,
		cursor: 'pointer',
	},
	focused: {
		zIndex: 1
	},
	'@media (max-width: 680)': {
		container: {
			flexDirection: 'column',
			height: 'auto'
		},
		inputContainer: {
			width: '100%'
		},
		buttonLayout: {
			width: '100%',
			marginTop: 15
		}
	}
});
