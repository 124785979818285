import React, {useState, useEffect, createRef} from 'react';
import {connect} from 'react-redux';
import {View, ScrollView, Platform, Text} from 'react-native';

import {basicStyles} from '../../styles/basic';
import AppText from '../../components/AppText';
import {textStyles} from '../../styles/text';
import JobsService from '../../services/JobsService';
import OrganizationsService from '../../services/OrganizationsService';
import PageContentContainer from '../../components/PageContentContainer';
import StatusIconRow from '../../components/StatusIconRow';
import BriefcaseBlueSvg from '../../components/svgs/BriefcaseBlueSvg';
import GreenCircleWhiteCheckSvg from '../../components/svgs/GreenCircleWhiteCheckSvg';
import YellowCircleInProgressSvg from '../../components/svgs/YellowCircleInProgressSvg';
import BriefcaseBlueAlt from '../../components/svgs/BriefcaseBlueAlt';
import MockUpSvg from '../../components/svgs/MockUpSvg';
import CreateOptionsSection from '../../components/CreateOptionsSection';
import UploadOverlayHidden from '../../components/UploadOverlayHidden';
import ImportConfiguration from "../../components/ImportConfiguration";
import {setProcessingIndicatorActive} from '../../store/miscellaneous/miscellaneousActions';
import {bindActionCreators} from "redux";
import EStyleSheet from 'react-native-extended-stylesheet';
import {formatUtcTimeToLocalTime} from "../../utilities/methods";
import AppButton from '../../components/AppButton';

function JobCreateOptions(props) {
    const {navigate} = props.navigation;
    const [jobs, setJobs] = useState([]);
    const [jobImportState, setJobImportState] = useState({
        columns: [],
        firstRow: [],
        availableColumns: [],
        defaultConfiguration: null,
        initialized: false,
        formData: null,
    });
    const [scheduledSurveyTime, setScheduledSurveyTime] = useState();

    const {setProcessingIndicatorActive} = props;


    useEffect(() => {
        const getJobs = async () => {
            let jobsData = await JobsService.getJobs(props.auth.organization.organization_id);
            setJobs(jobsData.jobs);
            let organizationData = await OrganizationsService.getOrganization(props.auth.organization.organization_id);
            parseScheduledTime(organizationData.scheduled_survey_time)
        };

        getJobs();
    }, [props.auth.organization.organization_id]);

    const completedJobs = jobs.filter(job => {
        return job.job_status_title === 'completed';
    });

    const inProgressJobs = jobs.filter(job => {
        return job.job_status_title === 'in-progress';
    });

    const parseScheduledTime = (scheduledTime) => {
        if(scheduledTime) {
            let localTime = formatUtcTimeToLocalTime(scheduledTime);
            let amPm = (localTime.amPm == 'am' ? 'AM' : 'PM');
            let displayTime = localTime.hour + ':' + localTime.minute + ' ' + amPm;
            setScheduledSurveyTime(displayTime);
        } else {
            setScheduledSurveyTime(null);
        }

    };

    const importJobs = async (columnsConfiguration) => {
        let data = null;
        let newFormData = Object.assign(jobImportState.formData);

        newFormData.append('columnsConfiguration', JSON.stringify(columnsConfiguration));

        try {
            setProcessingIndicatorActive(true);
            data = await JobsService.importJobs(props.auth.organization.organization_id, newFormData);
            setProcessingIndicatorActive(false);

            setJobImportState(prevState => {
                return {...prevState, initialized: false}
            });

            navigate('BatchQueue', {
                organization_id : props.auth.organization.organization_id,
                batchQueueId: data.batchQueueId,
            });

        } catch (error) {
            console.log('uploadError: ', error);
        }
    };

    const getJobsImportConfiguration = async (results) => {
        let file = results[0];
        let formData = new FormData();
        formData.append('file', file);
        let data = null;
        try {
            data = await JobsService.getJobsImportConfiguration(props.auth.organization.organization_id, formData);
            setJobImportState({
                columns: data.columns,
                firstRow: data.firstRow,
                availableColumns: data.availableColumns,
                defaultConfiguration: data.defaultConfiguration,
                initialized: true,
                formData
            });
        } catch (error) {
            console.log('uploadError: ', error);
        }
    }

    return (
        <>
            <ScrollView contentContainerStyle={basicStyles.flexScale}>
                <PageContentContainer>
                    <View style={basicStyles.centerContent}>
                        <AppText style={[
                            textStyles.pageTitle,
                            textStyles.semiBold,
                            {
                                paddingBottom: 40,
                                textAlign: 'center',
                            },
                        ]}>
                            Import Jobs
                        </AppText>
                    </View>
                    <StatusIconRow
                        statusRows={[
                            {
                                icon: <BriefcaseBlueSvg/>,
                                count: jobs.length,
                                status: 'Jobs',
                            },
                            {
                                icon: <GreenCircleWhiteCheckSvg/>,
                                count: completedJobs.length,
                                status: 'Completed',
                            },
                            {
                                icon: <YellowCircleInProgressSvg/>,
                                count: inProgressJobs.length,
                                status: 'In Progress',
                            },
                        ]}
                        style={styles.statusIconContainer}
                        styleWrapper={styles.statusIconItem}
                        styleCopy={styles.statusCopy}
                        styleNumber={styles.statusNumber}
                    />
                    <View style={[
                        basicStyles.flexRow,
                        styles.wrapper,
                    ]}>
                        <View style={[styles.sectionItem, styles.leftPane]}>
                            <CreateOptionsSection
                                icon={<MockUpSvg/>}
                                title="Post Multiple Jobs"
                                subTitle="Import via CSV file"
                                subTitleChildren={(
                                    scheduledSurveyTime ?
                                        <View style={{marginTop:-20, paddingBottom: 20}}>
                                            <AppText style={{marginBottom :10}}>Surveys are set to go out at {scheduledSurveyTime}</AppText>
                                            <AppButton
                                                label="Adjust time"
                                                action={() => {
                                                    navigate('AccountSettings', {
                                                        organizationId: props.auth.organization.organization_id,
                                                        screen: 'SurveySettings',
                                                        initial: false,
                                                    });
                                                }}
                                            />
                                        </View>
                                        :
                                        null
                                )}
                                label1="Upload CSV"
                                button1Children={
                                    <UploadOverlayHidden
                                        callback={getJobsImportConfiguration}
                                    />
                                }
                                label2={"View Import History"}
                                action2={() => {
                                    navigate('ImportHistoryNavigation', {
                                        screen: 'BatchQueue',
                                        params: {
                                            organization_id : props.auth.organization.organization_id
                                        }
                                    })
                                }}
                            />
                        </View>
                        <View style={[styles.sectionItem, styles.rightPane]}>
                            <CreateOptionsSection
                                icon={<BriefcaseBlueAlt/>}
                                title="Post Single Job"
                                subTitle="New Job"
                                label1="Post Job"
                                action1={() => navigate('JobAdd')}
                            />
                        </View>
                    </View>
                </PageContentContainer>
            </ScrollView>
            {
                jobImportState.initialized ?
                    <ImportConfiguration
                        columns={jobImportState.columns}
                        firstRow={jobImportState.firstRow}
                        availableColumns={jobImportState.availableColumns}
                        defaultConfiguration={jobImportState.defaultConfiguration}
                        onSubmit={importJobs}
                        onOffClick={() => setJobImportState(prevState => {
                            return {...prevState, initialized: false}
                        })}
                    />
                    : null
            }
        </>
    );
};

const mapStateToProps = (state) => {
    const {auth} = state;
    return {auth};
};


const mapDispatchToProps = dispatch => (
    bindActionCreators({
        setProcessingIndicatorActive,
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(JobCreateOptions);

const styles = EStyleSheet.create({
    wrapper: {
        marginTop: 60
    },
    sectionItem: {
        width: '50%',
    },
    leftPane: {
        paddingRight: 20
    },
    rightPane: {
        paddingLeft: 20
    },
    '@media (max-width: 768)': {
        wrapper: {
            flexDirection: 'column'
        },
        sectionItem: {
            width: '100%'
        },
        leftPane: {
            paddingRight: 0,
            paddingBottom: 20
        },
        rightPane: {
            paddingLeft: 0
        }
    },
    '@media (max-width: 650)': {
        statusIconContainer: {
            paddingTop: 15,
            paddingBottom: 15,
            paddingRight: 10,
            borderRadius: 5,
            borderColor: '#E2E9F4',
            borderWidth: 1,
        },
        statusIconItem: {
            flexDirection: 'column',
            borderTopWidth: 0,
            borderRightWidth: 0,
            borderBottomWidth: 0,
            borderLeftWidth: 0,
            textAlign: 'center',

        }
    },
    '@media (max-width: 400)': {
        statusCopy: {
            flexDirection: 'column',
            paddingBottom: 5,
        },
        statusNumber: {
            marginRight: 0
        }
    }
});