import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function CloseSvg(props) {
	return (
		<Svg width={10.417} height={10.417} viewBox="0 0 10.417 10.417" {...props}>
			<G data-name="Group 196" fill="none" stroke="#000">
				<Path data-name="Line 122" d="M.354.354l9.71 9.71" />
				<Path data-name="Line 123" d="M.354 10.064l9.71-9.71" />
			</G>
		</Svg>
	)
}

export default CloseSvg;
