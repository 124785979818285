import Http from "../utilities/Http";
import {API_ROOT} from "../config";

export default {
	async getUserOrganizations() {
		let response = await Http().get(API_ROOT + '/user-organizations/');

		return response.data.userOrganizations;
	},
};
