import React, {useEffect, useRef, useState} from 'react';
import {StyleSheet, ScrollView, View} from 'react-native';
import {connect} from 'react-redux';

import {
    AVATAR_DEFAULT,
    AVATAR_PATH_USER,
    AVATAR_PATH_ORG
} from '../../config';

import Avatar from '../../components/Avatar';
import AppText from '../../components/AppText';
import NotificationTile from '../../components/NotificationTile';
import {checkIfFluidUser, getScreenMaxHeight, getUserDisplayName} from '../../utilities/methods';

import {basicStyles} from '../../styles/basic';
import {textStyles} from '../../styles/text';
import PageTop from "../../components/PageTop";
import PageContentContainer from '../../components/PageContentContainer';
import {useDebouncedCallback} from "use-debounce";
import UsersService from "../../services/UsersService";
import EStyleSheet from 'react-native-extended-stylesheet';
import {
    setUser
} from '../../store/auth/authActions';
import {bindActionCreators} from "redux";
import UserNotifications from "../../components/UserNotifications";

function UserProfileNotifications(props) {

    const [state, setState] = useState({
		user: {...props.auth.user},
        organization: {...props.auth.organization},
	});

    const organizationId = props.route?.params?.organizationId || props.auth.organization.organization_id;
    const firstUpdate = useRef(true);
    const {setUser} = props;

    const scheduledNotificationItems = [
        {
            label: 'Daily',
            value: 'daily',
        },
        {
            label: 'Weekly',
            value: 'weekly',
        },
        {
            label: 'Monthly',
            value: 'monthly',
        },
        {
            label: 'Quarterly',
            value: 'quarterly',
        },
    ];

    const realtimeNotificationItems = [
        {
            label: 'Real-time',
            value: 'realtime',
        }
    ];

    const updateUser = (prop, value) => {
        setState(prevState => {
            return Object.assign({}, prevState, {user: {...prevState.user, [prop]: value}});
        });
    };

    const [updateUserDebounce] = useDebouncedCallback(async () => {
        if (state.user.user_id) {
            var user = await UsersService.updateUser(
                organizationId,
                state.user.user_id,
                state.user,
            );

            setUser(state.user);
        }
    }, 500);

    const updateNotification = async (notificationId, frequency) => {
        updateUser(notificationId, frequency);
    };

	useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        updateUserDebounce();

	}, [state.user, updateUserDebounce]);

    return (
        <ScrollView contentContainerStyle={[basicStyles.flexScale, {maxHeight: getScreenMaxHeight()}]}>
            <PageTop style={[basicStyles.flexRow, basicStyles.justifyContentSpaceBetween, basicStyles.alignContentCenter]}>
                <View style={styles.header}>
                    <View style={styles.headerUser}>
                        <Avatar
                            source={{uri: state.user.user_avatar_file ? AVATAR_PATH_USER + state.user.user_avatar_file : AVATAR_DEFAULT}}
                            width={50}
                        />
                        <AppText style={styles.userName}>
                            {
                                getUserDisplayName(state.user)
                            }
                        </AppText>
                    </View>
                    <View style={styles.headerTeam}>
                        <Avatar
                            source={{uri: state.organization.organization_thumbnail ? AVATAR_PATH_ORG + state.organization.organization_thumbnail : AVATAR_DEFAULT}}
                            width={30}
                        />
                        <AppText style={styles.teamMemberName}>
                            {state.organization.organization_title}
                        </AppText>
                    </View>
                </View>
            </PageTop>
            <PageContentContainer style={styles.content}>
                <View style={[styles.headerTeam, styles.headerTeamMobile]}>
                    <Avatar
                        source={{uri: state.organization.organization_thumbnail ? AVATAR_PATH_ORG + state.organization.organization_thumbnail : AVATAR_DEFAULT}}
                        width={30}
                    />
                    <AppText style={styles.teamMemberName}>
                        {state.organization.organization_title}
                    </AppText>
                </View>
                <AppText style={styles.contentHeader}>Alerts & Notifications</AppText>
                <UserNotifications
                    user={state.user}
                    updateNotification={updateNotification}
                    hasViewAccountReportsPermission={props.auth.permissions.includes('view_account_reports') || checkIfFluidUser()}
                    hasAdminTeamNotificationsPermission={props.auth.permissions.includes('admin_team_notifications') || checkIfFluidUser()}
                    hasAdminNotificationFailuresPermission={props.auth.permissions.includes('admin_notification_failures') || checkIfFluidUser()}
                />
            </PageContentContainer>
        </ScrollView>
    );
}


const mapStateToProps = (state) => {
	const {auth} = state;
	return {auth};
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
        setUser,
    }, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(UserProfileNotifications);


const styles = EStyleSheet.create({
    header: {
        flex:1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'space-between',
        //arginTop: 75,
        //marginBottom: 25,
    },
    headerUser: {
        flexDirection: 'row',
        alignItems: 'center'
    },
	userName: {
		color: '#000',
		fontSize: 28,
		fontFamily: 'SourceSansPro-SemiBold',
        paddingLeft: 10
	},
    headerTeam: {
        flexDirection: 'row',
        alignItems: 'center',
        borderColor: '#E2E9F4',
        borderWidth: 1,
        borderRadius: 8,
        padding: 20
    },
    teamMemberName: {
        fontSize: 16,
        fontFamily: 'SourceSansPro-Regular',
        color: '#8F95A5',
        paddingLeft: 10
    },
    content: {
        paddingTop: 85,
        paddingLeft: 30,
        paddingRight: 30
    },
    contentHeader: {
        paddingLeft: 30,
        paddingBottom: 40,
        fontFamily: 'SourceSansPro-SemiBold',
        fontSize: 20,
        color: '#000'
    },
    managerContentHeader: {
        paddingTop: 60
    },
    '@media (min-width: 700)': {
        headerTeamMobile: {
            display: 'none'
        }
    },
    '@media (max-width: 700)': {
        header: {
            flexDirection: 'column'
        },
        headerTeam: {
            display: 'none'
        },
        headerTeamMobile: {
            display: 'flex',
            marginBottom: 50
        },
        content: {
            paddingTop: 25
        }
    }
});
