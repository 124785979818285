import Http from "../utilities/Http";
import {API_ROOT} from "../config";

export default {
	async getBatchQueues(organizationId = null) {
		let response = await Http().get(API_ROOT + '/organizations/' + organizationId + '/batch-queue/');

		return response.data;
	},
	async getBatchQueuesPaginated(organizationId = null, perPage = null, currentPage = null) {
		let response = await Http().get(API_ROOT + '/organizations/' + organizationId + '/batch-queue/paginated/', {
			params: {
				perPage,
				currentPage
			}
		});

		return response.data;
	},
	async getBatchQueue(organizationId = null, batchQueueId) {
		let response = await Http().get(API_ROOT + '/organizations/' + organizationId + '/batch-queue/get/' + batchQueueId);

		return response.data;
	}
};
