import React from 'react';
import {View} from 'react-native';

import {JOB_STATUS_COLORS} from '../config';

function StatusIndicator({color, style = {}}) {
	return (
		<View
			style={[{
				width: 5,
				height: 5,
				borderRadius: 2.5,
				backgroundColor: color
			}, style]}
		/>
	);
}

export default StatusIndicator;
