import React from "react"
import Svg, { Path } from "react-native-svg"

function ChevronLeftBlueSvg(props) {
	return (
		<Svg width={5.326} height={8.37} viewBox="0 0 5.326 8.37" {...props}>
			<Path
				data-name="Path 26"
				d="M0 4.185L4.226 0l1.1 1.085-3.133 3.1 3.133 3.1-1.1 1.085z"
				fill="#0065db"
			/>
		</Svg>
	)
}

export default ChevronLeftBlueSvg;
