import * as React from "react"
import Svg, {
    Defs,
    LinearGradient,
    Stop,
    G,
    Circle,
    Path,
} from "react-native-svg"

function ListViewSvg(props) {
    return (
        <Svg width={26} height={26} viewBox="0 0 26 26" {...props}>
            <Defs>
                <LinearGradient
                    id="prefix__a"
                    x1={-0.195}
                    y1={0.5}
                    x2={1.173}
                    y2={0.5}
                    gradientUnits="objectBoundingBox"
                >
                    <Stop offset={0} stopColor="#1b8ce9" />
                    <Stop offset={1} stopColor="#467aff" />
                </LinearGradient>
            </Defs>
            <G data-name="Group 959">
                <G data-name="Group 689">
                    <Circle
                        data-name="Ellipse 24"
                        cx={13}
                        cy={13}
                        r={13}
                        fill="url(#prefix__a)"
                    />
                </G>
                <G data-name="Group 690" fill="#fff">
                    <Path data-name="Rectangle 232" d="M8 8.001h11v2H8z" />
                    <Path data-name="Rectangle 233" d="M8 12.001h11v2H8z" />
                    <Path data-name="Rectangle 234" d="M8 16.001h11v2H8z" />
                </G>
            </G>
        </Svg>
    )
}

export default ListViewSvg;
