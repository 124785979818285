import React from 'react';
import {View, StyleSheet} from 'react-native';

import LinkButton from './LinkButton';
import {basicStyles} from '../styles/basic';
import ChevronLeftBlueSvg from './svgs/ChevronLeftBlueSvg';
import AppText from './AppText';

function BackBar({action, label}) {
	return (

		<View style={styles.pageActionBar}>
			<LinkButton action={action} style={[basicStyles.flexRow, basicStyles.alignContentCenter]}>
				<ChevronLeftBlueSvg style={{marginRight: 10}}/>
				<AppText style={{fontSize: 14}}>
					{label}
				</AppText>
			</LinkButton>
		</View>
	);
}

export default BackBar;

const styles = StyleSheet.create({
	pageActionBar: {
		backgroundColor: '#FFFFFF',
		paddingTop: 15,
		paddingBottom: 15,
		paddingRight: 40,
		paddingLeft: 40,
		borderBottomWidth: 1,
		borderBottomColor: '#E2E9F4'
	},
});
