import {createStore, combineReducers} from 'redux';

import authReducer from './auth/authReducer';
import warningReducer from './warning/warningReducer';
import miscellaneousReducer from './miscellaneous/miscellaneousReducer';
import notificationReducer from "./notifications/notificationReducer";
import reviewResponseReducer from "./reviewResponse/reviewResponseReducer";

const store = createStore(combineReducers({
	auth: authReducer,
	warning: warningReducer,
	miscellaneous: miscellaneousReducer,
	notifications: notificationReducer,
	reviewResponse: reviewResponseReducer,
}), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default store;
