import Http from "../utilities/Http";
import {API_ROOT} from "../config";

export default {
    async getMessageFeeds(organizationId, filters = {}, page = 0, perPage = 20, cancelToken = null) {
        const response = await Http().get(`${API_ROOT}/organizations/${organizationId}/message-feeds`, {
            params: {
                perPage,
                page,
                ...filters
            },
            cancelToken
        });

        return response.data.messageFeeds;
    },
    async insertMessageFeed(organizationId, phoneNumber) {
        const response = await Http().post(`${API_ROOT}/organizations/${organizationId}/message-feeds/`, {
            phoneNumber
        });

        return response.data.messageFeed;
    },
    async getMessageFeed(organizationId, messageFeedId, cancelToken = null, params = {}) {
        const response = await Http().get(`${API_ROOT}/organizations/${organizationId}/message-feeds/${messageFeedId}`, {
            params,
            cancelToken
        });

        return response.data;
    },
    async resolveMessageFeed(organizationId, messageFeedId, cancelToken = null) {
        const response = await Http().post(`${API_ROOT}/organizations/${organizationId}/message-feeds/${messageFeedId}?messages=true`, {
            resolve: true
        },
            {cancelToken}
        );

        return response.data;
    },
    async updateMessageFeed(organizationId, messageFeedId, messageFeed, cancelToken = null) {
        const response = await Http().post(`${API_ROOT}/organizations/${organizationId}/message-feeds/${messageFeedId}`, {
                messageFeed
            },
            {cancelToken}
        );

        return response.data;
    },
    async sendNewMessage(organizationId, messageFeedId, message, attachments = []) {
        const response = await Http().post(`${API_ROOT}/organizations/${organizationId}/message-feeds/${messageFeedId}?messages=true`, {
            message,
            attachments
        });

        return response.data;
    },
    async validatePhoneNumber(phoneNumber, cancelToken = null) {
        const response = await Http().post(`${API_ROOT}/twilio/verify-phone-number`, {
                phoneNumber
            },
            {cancelToken}
        );

        return response.data.valid;
    }, async getMessageFeedJobs(organizationId, messageFeedId, page = 0, perPage = 20, cancelToken = null) {
        const response = await Http().get(`${API_ROOT}/organizations/${organizationId}/message-feeds/get-message-feed-jobs/${messageFeedId}`, {
            params: {
                perPage,
                page,
            },
            cancelToken
        });

        return response.data.jobs;
    },
    async toggleMessageFeedBlocked(organizationId, messageFeedId) {
        const response = await Http().post(`${API_ROOT}/organizations/${organizationId}/message-feeds/${messageFeedId}?messages=true`, {
            toggleBlocked: true
        });

        return response.data;
    },
    async addMessageFeedNote(organizationId, messageFeedId, note) {
        const response = await Http().post(`${API_ROOT}/organizations/${organizationId}/message-feeds/${messageFeedId}?messages=true`, {
            note
        });

        return response.data;
    },
    async uploadImage(organizationId, formData) {
        let response = await Http().post(API_ROOT + `/organizations/${organizationId}/message-feeds/upload-image`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });

        return response.data.fileName;
    },
    async getAutoReplies(organizationId) {
        const response = await Http().get(`${API_ROOT}/organizations/${organizationId}/auto-replies`);

        return response.data.autoReplies;
    },
    async insertAutoReply(organizationId, autoReply) {
        const response = await Http().post(`${API_ROOT}/organizations/${organizationId}/auto-replies`, {
            autoReply
        });

        return response.data.autoReply;
    },
    async updateAutoReply(organizationId, autoReplyId, autoReply, cancelToken = null) {
        const response = await Http().post(`${API_ROOT}/organizations/${organizationId}/auto-replies/${autoReplyId}`, {
                autoReply
            },
            {cancelToken}
        );

        return response.data.autoReply;
    },
    async deleteAutoReply(organizationId, autoReplyId) {
        await Http().delete(`${API_ROOT}/organizations/${organizationId}/auto-replies/${autoReplyId}`);
    },
    async getMessageScripts(organizationId, params) {
        const response = await Http().get(`${API_ROOT}/organizations/${organizationId}/message-scripts`, {
            params
        });

        return response.data.messageScripts;
    },
    async getMessageScript(organizationId, messageScriptId) {
        const response = await Http().get(`${API_ROOT}/organizations/${organizationId}/message-scripts/${messageScriptId}`);

        return response.data.messageScript;
    },
    async insertMessageScript(organizationId, messageScript) {
        const response = await Http().post(`${API_ROOT}/organizations/${organizationId}/message-scripts`, {
                messageScript
            }
        );

        return response.data.messageScript;
    },
    async updateMessageScript(organizationId, messageScriptId, messageScript, cancelToken = null) {
        const response = await Http().post(`${API_ROOT}/organizations/${organizationId}/message-scripts/${messageScriptId}`, {
                messageScript
            },
            {cancelToken}
        );

        return response.data.messageScript;
    },
    async deleteMessageScript(organizationId, messageScriptId) {
        const response = await Http().delete(`${API_ROOT}/organizations/${organizationId}/message-scripts/${messageScriptId}`, {});
    },
    async getScriptVariables(organizationId) {
        const response = await Http().get(`${API_ROOT}/organizations/${organizationId}/message-script-variables`);

        return response.data.messageScriptVariables;
    },
    async getScriptVariable(organizationId, messageScriptVariableId) {
        const response = await Http().get(`${API_ROOT}/organizations/${organizationId}/message-script-variables/${messageScriptVariableId}`);

        return response.data.messageScriptVariable;
    },
    async insertScriptVariable(organizationId, messageScriptVariable) {
        const response = await Http().post(`${API_ROOT}/organizations/${organizationId}/message-script-variables`, {
                messageScriptVariable
            }
        );

        return response.data.messageScriptVariable;
    },
    async updateScriptVariable(organizationId, messageScriptVariableId, messageScriptVariable) {
        const response = await Http().post(`${API_ROOT}/organizations/${organizationId}/message-script-variables/${messageScriptVariableId}`, {
                messageScriptVariable
            }
        );

        return response.data.messageScriptVariable;
    },
    async deleteScriptVariable(organizationId, messageScriptVariableId) {
        const response = await Http().delete(`${API_ROOT}/organizations/${organizationId}/message-script-variables/${messageScriptVariableId}`, {});
    },
    async getMessageScriptVariableTypes() {
        const response = await Http().get(`${API_ROOT}/message-script-variable-types`);

        return response.data.variableTypes;
    },
    async getScriptCategories(organizationId) {
        const response = await Http().get(`${API_ROOT}/organizations/${organizationId}/message-script-categories`);

        return response.data.messageScriptCategories;
    },
    async getScriptOutput(organizationId, messageScriptId, params, cancelToken = null) {
        const response = await Http().get(`${API_ROOT}/organizations/${organizationId}/message-scripts/get-message-script-output/${messageScriptId}`,
            {
                params,
                cancelToken
            }
        );

        return response.data.messageScriptOutput;
    },
    async setUserTyping(organizationId, messageFeedId, typing, cancelToken = null) {
        const response = await Http().post(`${API_ROOT}/organizations/${organizationId}/message-feeds/set-feed-typing/${messageFeedId}`, {
                typing
            },
            {cancelToken}
        );

        return response.data;
    },
}
