import * as React from "react"
import Svg, {Path} from "react-native-svg"

function ScriptsSvg(props) {
    return (
        <Svg
            width={22}
            height={16}
            viewBox="0 0 22 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M5.868 1.133c.152.499.307.98.44 1.468.042.153.028.322.028.483l.001 9.949c0 .827.435 1.472 1.148 1.718 1.158.399 2.322-.377 2.36-1.583.016-.533 0-1.068.004-1.6.005-.959.61-1.558 1.583-1.559 2.829-.002 5.658-.002 8.487 0 .974 0 1.587.6 1.581 1.557-.003.742 0 1.486-.056 2.226-.082 1.091-1.078 2.053-2.184 2.153-.347.032-.697.052-1.046.052-3.335.003-6.668.004-10.003 0-1.503 0-2.605-.827-2.934-2.203a3.697 3.697 0 01-.091-.848c-.006-3.325-.004-6.65-.005-9.977 0-.844-.44-1.483-1.175-1.725-1.142-.376-2.29.38-2.329 1.56-.028.847-.007 1.697-.007 2.544 0 .265.143.374.404.372.63-.003 1.26 0 1.91 0v1.132H3.01c-.34 0-.68.008-1.02-.002C1.215 6.827.582 6.324.546 5.583.496 4.567.464 3.538.57 2.53.741.957 1.915.006 3.533.004c3.373-.005 6.747-.004 10.12 0 .602 0 1.21-.024 1.805.049 1.502.184 2.503 1.239 2.532 2.736.04 2.087.01 4.174.01 6.26 0 .017-.011.036-.023.07H16.85v-.33-5.745c0-1.207-.722-1.91-1.96-1.91H5.868zm4.527 13.734h8.207c.928 0 1.562-.624 1.57-1.543.004-.561 0-1.123 0-1.686 0-.418-.083-.5-.51-.5H12.55c-.37 0-.739-.002-1.108 0-.336.003-.437.098-.44.421-.004.315-.007.629 0 .943.019.818-.019 1.621-.609 2.365h.002z"
                fill="#3CA6F5"
            />
        </Svg>
    )
}

export default ScriptsSvg
