import Http from "../utilities/Http";
import {API_ROOT} from "../config";

export default {
	async getUserInvite(inviteCode) {
		let response = await Http().get(API_ROOT + '/user-invites/get-user-invite/' + inviteCode);

		return response.data;
	},
	async acceptInvite(inviteCode, user) {
		let response = await Http().post(API_ROOT + '/user-invites/accept-invite/' + inviteCode, {
			user
		});

		return response.data;
	},
	async acceptInviteWithGoogle(inviteCode, token) {
		let response = await Http().post(API_ROOT + '/user-invites/accept-invite-with-google/' + inviteCode, {
			token
		});

		return response.data;
	}
};
