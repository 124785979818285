import React from 'react';
import {View, StyleSheet} from 'react-native';
import {connect} from 'react-redux';

import AppText from "./AppText";
import {FLUID_ROLE_IDS} from "../config";

function FluidAdminIndicator(props) {
    const {organizationRoleId} = props.auth;

    if(FLUID_ROLE_IDS.includes(organizationRoleId)) {
        return (
            <View style={styles.wrapper}>
                <AppText style={styles.text}>Fluid Admin</AppText>
            </View>
        );
    }

    return (<></>);
}

const mapStateToProps = (state) => {
    const {auth} = state;
    return {auth};
};

export default connect(mapStateToProps)(FluidAdminIndicator);

const styles = StyleSheet.create({
    wrapper: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 10,
        paddingBottom: 10,
        borderBottomWidth: 1,
        borderBottomColor: 'rgba(226,233,244,.16)',
        backgroundColor: 'rgb(0, 101, 219)',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    text: {
        color: '#FFFFFF',
        fontFamily: 'SourceSansPro-SemiBold'
    },
    avatar: {
        marginLeft: 7,
        marginRight: 3
    }
});
