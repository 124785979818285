import * as React from "react"
import Svg, { Rect, Path } from "react-native-svg"

function MessageFeedResolvedSvg({color = "#12B76A", ...props}) {
    return (
        <Svg
            width={12}
            height={13}
            viewBox="0 0 12 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Rect x={0.5} y={1} width={11} height={11} rx={5.5} stroke={color} />
            <Path
                d="M5.25 7.559L8.5 4.5l.5.47L5.25 8.5 3 6.382l.5-.47 1.75 1.647z"
                fill={color}
            />
        </Svg>
    )
}

export default MessageFeedResolvedSvg
