import Http from "../utilities/Http";
import {API_ROOT} from "../config";

export default {
	async getCoupons(organizationId = null) {
		let response = await Http().get(API_ROOT + '/coupons/', {
			params: {
				organizationId
			}
		});

		return response.data.coupons;
	},
	async getCouponsPaginated(organizationId = null, perPage = null, currentPage = null) {
		let response = await Http().get(API_ROOT + '/coupons/paginated', {
			params: {
				organizationId,
				perPage,
				currentPage
			}
		});

		return response.data;
	},
	async getCoupon(couponId) {
		let response = await Http().get(API_ROOT + '/coupons/' + couponId);

		return response.data.coupon;
	},
	async insertCoupon(coupon) {
		let response = await Http().post(API_ROOT + '/coupons/', {
			coupon
		});

		return response.data.coupon;
	},
	async updateCoupon(couponId, coupon) {
		let response = await Http().post(API_ROOT + '/coupons/' + couponId, {
			coupon
		});

		return response.data.coupon;
	}
};
