import Http from '../utilities/Http';
import {API_ROOT} from '../config';

export default {
	async getMarkets(organizationId, search, startDate, endDate, perPage = null, currentPage = null) {
		let response = await Http().get(API_ROOT + '/organizations/' + organizationId + '/markets', {
			params: {
				search,
				startDate,
				endDate,
				perPage,
				currentPage
			}
		});

		return response.data;
	}
};
