import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {ScrollView, View, StyleSheet} from 'react-native';
import {CommonActions} from '@react-navigation/native';
import {useDebouncedCallback} from 'use-debounce';

import OrganizationsService from '../../services/OrganizationsService';
import {basicStyles} from '../../styles/basic';
import PageTop from '../../components/PageTop';
import AppText from '../../components/AppText';
import {textStyles} from '../../styles/text';
import {AVATAR_DEFAULT, AVATAR_PATH_ORG, ORGANIZATION_TYPE_IDS} from '../../config';
import {capitalizeString} from '../../utilities/methods';
import PageContentContainer from '../../components/PageContentContainer';
import ActionRowWithAvatar from '../../components/ActionRowWithAvatar';
import Avatar from '../../components/Avatar';
import AppButton from '../../components/AppButton';
import SearchBar from '../../components/SearchBar';
import Pagination from "../../components/Pagination";
import EStyleSheet from 'react-native-extended-stylesheet';

function OrganizationsList(props) {
	const [organizations, setOrganizations] = useState([]);
	const [organizationTypeTitle, setOrganizationTypeTitle] = useState(null);
	const [pageCount, setPageCount] = useState(1);
	const [paginationRange, setPaginationRange] = useState([1]);
	const {navigate} = props.navigation;
	const organizationTypeId = parseInt(props.route.params?.organizationTypeId);
	const perPage = 10;

	let currentPage = props.route?.params?.currentPage ? parseInt(props.route.params.currentPage) : 1;
	let search = props.route?.params?.search ? props.route.params.search : '';

	const [getOrganizationDebounce] = useDebouncedCallback(() => {
		const getOrganizations = async () => {
			let organizationsData = await OrganizationsService.getOrganizationsPaginated(
				props.auth.organization.organization_id,
				organizationTypeId,
				search,
				perPage,
				currentPage
			);

			currentPage = organizationsData.currentPage;
			setPageCount(organizationsData.pageCount);
			setOrganizations(organizationsData.organizations);
			setPaginationRange(organizationsData.paginationRange);
		};

		getOrganizations();
	}, 500);

	useEffect(() => {
		getOrganizationDebounce();
	}, [props.auth.organization.organization_id, props.route?.params?.organizationTypeId, search, currentPage]);

	useEffect(() => {
		for (const organizationType in ORGANIZATION_TYPE_IDS) {
			if (ORGANIZATION_TYPE_IDS[organizationType] == organizationTypeId) {
				setOrganizationTypeTitle(capitalizeString(organizationType));
			}
		}

	}, [props.auth.organization.organization_id, props.route?.params?.organizationTypeId]);

	return (
		<ScrollView contentContainerStyle={basicStyles.flexScale}>
			<PageTop
				style={[
					basicStyles.flexRow,
					basicStyles.alignContentCenter,
					basicStyles.justifyContentSpaceBetween,
					styles.headerContent
				]}
			>
				<AppText style={textStyles.pageTitle}>
					{organizationTypeTitle} Organizations
				</AppText>
				<SearchBar
					placeholder="Search"
					onChangeText={(value) => {
						navigate('OrganizationList', {
							...props.route?.params,
							currentPage: 1,
							search: value
						});
					}}
					value={search}
					style={[basicStyles.flexScale, styles.searchBar]}
				/>
				<AppButton
					label={'Add ' + organizationTypeTitle}
					action={() => navigate('ClientPartnerAdd', {
						organizationTypeId,
					})}
					style={styles.addClientButton}
				/>
			</PageTop>
			<PageContentContainer>
				{organizations.map(organization => {
					return (
						<ActionRowWithAvatar
							key={organization.organization_id}
							action={
								props.auth.organization.organization_type_id == ORGANIZATION_TYPE_IDS.FLUID ?
									CommonActions.navigate('ClientPartnerSingleNavigation', {
										organizationId: organization.organization_id,
										screen: organizationTypeId === ORGANIZATION_TYPE_IDS.CLIENT ? 'OrganizationEdit' : 'ClientPartnersTeamStackNavigation',
									})
									:
									CommonActions.navigate('ClientCommissions', {
										organizationId: organization.organization_id,
									})
							}
							style={styles.actionRowContainer}
						>
							<View style={styles.mainColumns}>
								<View style={styles.avatarColumn}>
									<Avatar
										source={{uri: organization.organization_thumbnail ? AVATAR_PATH_ORG + organization.organization_thumbnail : AVATAR_DEFAULT}}
										width={66}
									/>
								</View>
								<View style={[basicStyles.actionRowColumn, styles.organizationColumn]}>
									<AppText style={basicStyles.columnLabel}>
										Organization
									</AppText>
									<AppText style={basicStyles.columnValue}>
										{organization.organization_title}
									</AppText>
								</View>
							</View>
							<View style={styles.secondaryColumns}>
								{
									organizationTypeId === ORGANIZATION_TYPE_IDS.CLIENT ?
										<View style={[basicStyles.actionRowColumn, styles.secondaryColumnItem]}>
											<AppText style={basicStyles.columnLabel}>
												Monthly Service
											</AppText>
											<AppText style={basicStyles.columnValue}>
												{organization.subscription_type_title}
											</AppText>
										</View>
										: null
								}
								<View style={[basicStyles.actionRowColumn, styles.secondaryColumnItem]}>
									<AppText style={basicStyles.columnLabel}>
										Active Team Members
									</AppText>
									<AppText style={basicStyles.columnValue}>
										{organization.activeMembersCount}
									</AppText>
								</View>
								{
									organizationTypeId === ORGANIZATION_TYPE_IDS.CLIENT ?
										<>
											<View style={[basicStyles.actionRowColumn, styles.secondaryColumnItem]}>
												<AppText style={basicStyles.columnLabel}>
													Posts Last 30 Days
												</AppText>
												<AppText style={basicStyles.columnValue}>
													{organization.jobsPostedLast30DaysCount}
												</AppText>
											</View>
											<View style={[basicStyles.actionRowColumn, styles.secondaryColumnItem]}>
												<AppText style={basicStyles.columnLabel}>
													Reviews Last 30 Days
												</AppText>
												<AppText style={basicStyles.columnValue}>
													{organization.surveyResponsesCompletedLast30DaysCount}
												</AppText>
											</View>
											<View style={[basicStyles.actionRowColumn, styles.secondaryColumnItem]}>
												<AppText style={basicStyles.columnLabel}>
													Partner
												</AppText>
												<AppText style={basicStyles.columnValue}>
													{organization?.partnerOrganization?.organization_title}
												</AppText>
											</View>
										</>
										: null
								}
							</View>
						</ActionRowWithAvatar>
					);
				})}
			</PageContentContainer>
			<Pagination screenName="OrganizationList" pageCount={pageCount} pageIndex={currentPage} paginationRange={paginationRange} />
		</ScrollView>
	);
}


const mapStateToProps = (state) => {
	const {auth} = state;
	return {auth};
};

export default connect(mapStateToProps)(OrganizationsList);

const styles = EStyleSheet.create({
	actionRowContainer: {
		flexDirection: 'row'
	},
	mainColumns: {
		flexDirection: 'row',
		alignItems: 'center',
		paddingRight: 40,
		width: '25%',
	},
	secondaryColumns: {
		flexDirection: 'row',
		width: '75%',
	},
	avatarColumn: {
		height: 100,
		paddingRight: 40,
		borderRightWidth: 1,
		borderRightColor: '#E2E9F4',
		justifyContent: 'center',
		alignItems: 'center',
		marginRight: 20,
	},
	organizationColumn: {
		width: 200,
	},
	searchBar: {
		marginRight: 20,
		marginLeft: 20
	},
	'@media (max-width: 1300)': {
		actionRowContainer: {
			flexDirection: 'column',
			height: 'auto',
			paddingBottom: 20,
			paddingLeft: 0,
			paddingRight: 0,
		},
		mainColumns: {
			width: '100%',
			borderBottomWidth: 1,
			borderBottomColor: '#E2E9F4',
			paddingLeft: 30,
			paddingRight: 30,
		},
		secondaryColumns: {
			width: '100%',
			paddingTop: 20,
			paddingLeft: 30,
			paddingRight: 30,
		},
		avatarColumn: {
			borderRightWidth: 0,
			paddingRight: 10,
		},
		organizationColumn: {
			width: 'auto'
		}
	},
	'@media (max-width: 1000)': {
		secondaryColumns: {
			flexWrap: 'wrap',
			flex: 1,
			width: '100%',
		},
		secondaryColumnItem: {
			flex: 'inherit',
			width: '50%',
			marginBottom: 15,
			height: 'auto',
		}
	},
	'@media (max-width: 800)': {
		headerContent: {
			flexDirection: 'column',
			alignItems: 'flex-start'
		},
		searchBar: {
			marginLeft: 0,
			width: '100%',
			marginTop: 20,
			marginBottom: 20,
		}
	},
	'@media (max-width: 400)': {
		headerContent: {
			alignItems: 'center',
			textAlign: 'center',
		},
		searchBar: {
			marginRight: 0,
		},
		addClientButton: {
			width: '100%'
		}
	}
});