import React, {useState, useEffect, useMemo} from 'react';
import {connect} from 'react-redux';
import {View, StyleSheet, ScrollView} from 'react-native';
import {CommonActions} from '@react-navigation/native';
import {useDebouncedCallback} from 'use-debounce';
import { useFocusEffect } from '@react-navigation/native';

import AppText from '../../components/AppText';
import JobsService from '../../services/JobsService';
import {basicStyles} from '../../styles/basic';
import {textStyles} from '../../styles/text';
import PageTop from '../../components/PageTop';
import PageContentContainer from '../../components/PageContentContainer';
import ActionRow from '../../components/ActionRow';
import FieldSet from '../../components/FieldSet';
import {getDate, getJobSurveyStatus, getUserDisplayName} from '../../utilities/methods';
import TouchView from '../../components/TouchView';
import ListViewSvg from '../../components/svgs/ListViewSvg';
import ThumbnailViewSvg from '../../components/svgs/ThumbnailViewSvg';
import BlueCircleChevronRightSvg from '../../components/svgs/BlueCircleChevronRightSvg';
import StatusIndicator from '../../components/StatusIndicator';
import {JOB_STATUS_COLORS} from '../../config';
import {hexToRgb} from '../../utilities/methods';
import SearchBar from '../../components/SearchBar';
import FilterOption from '../../components/FilterOption';
import Pagination from '../../components/Pagination';
import EStyleSheet from 'react-native-extended-stylesheet';

function JobsList(props) {
	const [jobs, setJobs] = useState([]);
	const [viewType, setViewType] = useState('large');
	const {navigate} = props.navigation;
	const perPage = 10;
	const [jobCount, setJobCount] = useState(0);
	const [fieldSetChunkSize, setFieldSetChunkSize] = useState(3);
	const [paginationRange, setPaginationRange] = useState([1]);
	let currentPage = props.route?.params?.currentPage ? parseInt(props.route.params.currentPage) : 1;
	let search = props.route?.params?.search ? props.route.params.search : '';
	let sortBy = props.route?.params?.sortBy ? props.route.params.sortBy : 'job_timestamp';
	let sortDirection = props.route?.params?.sortDirection ? props.route.params.sortDirection : 'DESC';

	const organizationId = useMemo(() => {
		return props.route.params?.organizationId || props.auth.organization.organization_id;
	}, []);

	const [getJobsDebounce] = useDebouncedCallback(() => {
		const getJobs = async () => {
			let jobsData = await JobsService.getJobs(
				organizationId,
				search,
				sortDirection,
				sortBy,
				null,
				null,
				perPage,
				currentPage
			);

			setJobs(jobsData.jobs);
			setJobCount(jobsData.jobCount);
			setPaginationRange(jobsData.paginationRange);
		};

		getJobs();
	}, 500);

	useFocusEffect(
		React.useCallback(() => {
			getJobsDebounce()
		}, [organizationId, props.auth.organizationRoleId, search, sortDirection, sortBy, currentPage])
	);

	const handleLayout = ({nativeEvent}) => {
		const {width} = nativeEvent.layout;

		if(width > 820) {
			setFieldSetChunkSize(3);
		} else if(width > 600) {
			setFieldSetChunkSize(2);
		} else {
			setFieldSetChunkSize(1);
		}
	};

	return (
		<ScrollView contentContainerStyle={basicStyles.flexScale} onLayout={handleLayout}>
			<PageTop style={[styles.headerContainer]}>
				<AppText style={[textStyles.pageTitle, textStyles.semiBold]}>
					Jobs
				</AppText>
				<View style={styles.headerInputContainer}>
					<SearchBar
						placeholder="Search"
						onChangeText={value => {
							navigate('JobsList', {
								...props.route?.params,
								currentPage: 1,
								search: value
							});
						}}
						value={search}
						style={[basicStyles.flexScale, styles.searchBar]}
					/>
					<View style={[basicStyles.flexRow, basicStyles.alignContentCenter]}>
						<AppText>
							View
						</AppText>
						<TouchView
							action={() => setViewType('large')}
							style={{
								marginLeft: 6, marginRight: 4, opacity: (viewType === 'large' ? 1 : .25),
							}}
						>
							<ThumbnailViewSvg/>
						</TouchView>
						<TouchView
							action={() => setViewType('small')}
							style={{opacity: (viewType === 'small' ? 1 : .25)}}
						>
							<ListViewSvg/>
						</TouchView>
					</View>
				</View>
			</PageTop>
			<PageContentContainer style={{paddingTop: 0}}>
				<View style={basicStyles.sortRow}>
					<AppText>
						{jobCount} Results
					</AppText>
					<View style={[basicStyles.flexRow]}>
						<FilterOption
							options={[
								{
									label: 'Post Date',
									value: 'job_timestamp',
								},
								{
									label: 'Job Number',
									value: 'job_number',
								},
								{
									label: 'Customer',
									value: 'job_customer_name',
								},
							]}
							dropDownFlush="right"
							title="Sort By"
							value={sortBy}
							onChange={(value) => {
								navigate('JobsList', {
									...props.route?.params,
									currentPage: 1,
									sortBy: value,
								});
							}}
							style={{marginRight: 15}}
						/>
						<FilterOption
							options={[
								{
									label: 'Descending',
									value: 'DESC',
								},
								{
									label: 'Ascending',
									value: 'ASC',
								},
							]}
							dropDownFlush="right"
							value={sortDirection}
							onChange={(value) => {
								navigate('JobsList', {
									...props.route?.params,
									currentPage: 1,
									sortDirection: value,
								});
							}}
						/>
					</View>
				</View>
				{
					jobs.map(job => {
						const surveyStatus = getJobSurveyStatus(job);
						if (viewType === 'large') {
							const statusRgb = hexToRgb(JOB_STATUS_COLORS[job.job_status_token]);
							return (
								<FieldSet
									chunkSize={fieldSetChunkSize}
									key={job.job_id}
									style={{marginBottom: 20}}
									header={(
										<TouchView
											action={() => {
												navigate('JobEdit', {
													id: job.job_id,
												});
											}}
											style={[
												basicStyles.flexRow,
												basicStyles.justifyContentSpaceBetween,
												basicStyles.alignContentCenter,
												basicStyles.flexScale,
												basicStyles.inputContainerPaddingHorizontal,
												{
													paddingTop: 30,
													paddingBottom: 30,
													borderBottomWidth: 1,
													borderBottomColor: JOB_STATUS_COLORS[job.job_status_token],
													backgroundColor: `rgba(${statusRgb.r}, ${statusRgb.g}, ${statusRgb.b}, .15)`,
												},
											]}>
											<AppText style={{fontFamily: 'SourceSansPro-SemiBold', fontSize: 22}}>
												{job.job_number}
											</AppText>
											<View style={[basicStyles.flexRow, basicStyles.alignContentCenter]}>
												<StatusIndicator color={JOB_STATUS_COLORS[job.job_status_token]}/>
												<AppText style={{marginLeft: 6}}>
													{job.job_status_title}
												</AppText>
												<BlueCircleChevronRightSvg style={{marginLeft: 20}}/>
											</View>
										</TouchView>
									)}
									fields={[
										{
											type: 'date',
											label: 'Date Created',
											value: job.job_timestamp || getDate(),
											editable: false,
										},
										{
											type: 'text',
											label: 'Team Member',
											value: getUserDisplayName(job),
											editable: false,
										},
										{
											type: 'text',
											label: 'Product Type',
											value: job.job_product_type || '',
											editable: false,
										},
										{
											type: 'text',
											label: 'Brand',
											value: job.job_product_brand || '',
											editable: false,
										},
										{
											type: 'text',
											label: 'Product Model Number',
											value: job.job_product_model_number || '',
											editable: false,
										},
										{
											type: 'text',
											label: 'Customer Name',
											value: job.job_customer_name || '',
											editable: false,
										},
										{
											type: 'address',
											label: 'Customer Address',
											value: job.job_customer_address || '',
											editable: false,
											style: styles.customerAddress
										},
										{
											type: 'text',
											label: 'Survey Status',
											disabled: true,
											value: surveyStatus,
											wrapperStyle: styles.surveyStatusWrapper,
											style: styles.surveyStatus,
											inputStyle: styles.surveyStatusInput
										},
									]}
								/>
							);
						}
						return (
							<ActionRow
								key={job.job_id}
								action={CommonActions.navigate('JobEdit', {
									id: job.job_id,
								})}
								slots={[
									{
										label: 'Job ID:',
										value: job.job_number,
									},
									{
										label: 'Job Status:',
										value: <><StatusIndicator style={{marginRight: 6, marginBottom: 2}} color={JOB_STATUS_COLORS[job.job_status_token]}/>{job.job_status_title}</>,
									},
									{
										label: 'Survey Status:',
										value: getJobSurveyStatus(job),
									},
								]}
								style={{
									borderBottomWidth: 1,
									borderBottomColor: JOB_STATUS_COLORS[job.job_status_token],
								}}
							/>
						);
					})
				}
			</PageContentContainer>
			<Pagination screenName="JobsList" pageCount={Math.ceil(jobCount / perPage)} pageIndex={currentPage} paginationRange={paginationRange} />
		</ScrollView>
	);
}

const mapStateToProps = (state) => {
	const {auth} = state;
	return {auth};
};

export default connect(mapStateToProps)(JobsList);


const styles = EStyleSheet.create({
	headerContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	customerAddress: {
		width: '66.666%',
	},
	surveyStatusWrapper: {
		flex: 1,
	},
	surveyStatus: {},
	surveyStatusInput: {
		width: '100%',
	},
	headerInputContainer: {
		flexDirection: 'row',
		flex: 1,
	},
	searchBar: {
		marginRight: 20,
		marginLeft: 20
	},
	'@media (max-width: 900)': {
		customerAddress: {
			width: '100%'
		},
		surveyStatusWrapper: {
			width: '100%',
			marginTop: 18
		},
		surveyStatus: {
			width: '100%',
		},
	},
	'@media (max-width: 680)': {
		surveyStatusWrapper: {
			marginTop: 0
		}
	},
	'@media (max-width: 535)': {
		headerContainer: {
			flexDirection: 'column',
			alignItems: 'flex-start',
			justifyContent: 'center',
			paddingLeft: 20,
			paddingRight: 20,
		},
		headerInputContainer: {
			width: '100%',
			marginTop: 15,
		},
		searchBar: {
			marginLeft: 0,
		},
	}
});
