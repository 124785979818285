/* eslint-disable */
import React, {useEffect} from 'react';
import {View, StyleSheet, findNodeHandle} from 'react-native';

import {STRIPE_PUBLIC_KEY} from '../config';

let stripe = Stripe(STRIPE_PUBLIC_KEY);
let stripeElements = stripe.elements();
let stripeCard = undefined;

function StripeCardInput({onSubmit, submitEngaged = false}) {

	let stripeRef = null;

	useEffect(() => {
		stripeCard = stripeElements.create('card');
		stripeCard.mount(findNodeHandle(stripeRef));
	}, [stripeRef]);

	useEffect(() => {
		if(submitEngaged) {
			stripe.createToken(stripeCard).then(response => {
				if(response.error) {
					//todo: show error
					console.log('create stripe card error!!: ', response.error);
					onSubmit(false)
				} else {
					let cardToken = response.token.id;
					onSubmit(cardToken);
					stripeCard.clear();
				}
			})
		}
	}, [submitEngaged]);

	useEffect(() => {
		return () => {
			stripeCard.destroy()
		};
	}, [])


	return (
		<View
			style={styles.stripeContainer}
			ref={instance => {
				stripeRef = instance;
			}}
		/>
	);
}

export default StripeCardInput;

const styles = StyleSheet.create({
	stripeContainer: {
		paddingTop: 17,
		paddingBottom: 17,
		paddingLeft: 24,
		paddingRight: 24,
		borderWidth: 1,
		borderColor: '#e2ecf2',
		borderRadius: 4,
		backgroundColor: '#f5f8fa',
		width: '100%',
	},
});
