import React, {useState, useEffect} from 'react';
import {View, ScrollView, Image, StyleSheet} from 'react-native';
import {connect} from 'react-redux';

import IntegrationsService from '../../services/IntegrationsService';
import {basicStyles} from '../../styles/basic';
import AppText from '../../components/AppText';
import {textStyles} from '../../styles/text';
import {IMAGE_PATH_INTEGRATION} from '../../config';
import FieldSet from '../../components/FieldSet';
import EStyleSheet from 'react-native-extended-stylesheet';

function WordpressIntegration(props) {
    const [integration, setIntegration] = useState({integrationImages: []});
    const [apiKey, setApiKey] = useState(null);
    const [buttonChunkSize, setButtonChunkSize] = useState(2);
    const handleLayout = ({nativeEvent}) => {
        const {width} = nativeEvent.layout;

        if (width > 840) {
            setButtonChunkSize(2);
        } else {
            setButtonChunkSize(1);
        }
    };

    useEffect(() => {
        const getIntegration = async () => {
            const integrationData = await IntegrationsService.getIntegration(props.route.params.id);

            setIntegration(integrationData);
        };

        const getApiKey = async () => {
            const apiKey = await IntegrationsService.getOrganizationApiKey(props.auth.organization.organization_id);
            setApiKey(apiKey);
        }

        getIntegration();
        getApiKey();
    }, [props.route.params.id, props.auth.organization.organization_id]);

    return (
        <ScrollView contentContainerStyle={basicStyles.flexScale} onLayout={handleLayout}>
            <View style={styles.contentWrapper}>
                <View style={[
                    basicStyles.alignContentCenter,
                    basicStyles.justifyContentSpaceBetween,
                    styles.contentContainer
                ]}>
                    <View style={[styles.contentLeft, basicStyles.alignContentCenter]}>
                        <View>
                            <AppText style={[
                                {fontSize: 28, marginBottom: 30},
                                textStyles.bold,
                            ]}>
                                {integration.integration_title}
                            </AppText>
                            <AppText style={{fontSize: 16, maxWidth: 465}}>
                                {integration.integration_description}
                            </AppText>
                        </View>
                    </View>
                    <View style={[styles.contentRight, basicStyles.alignContentCenter]}>
                        {
                            integration.integrationImages.length ?
                                <Image
                                    source={{uri: IMAGE_PATH_INTEGRATION + integration.integrationImages[0].integration_image_file}}
                                    style={styles.image} // todo: width or height HAS to be known in order for an image to scale...
                                    resizeMode="contain"
                                />
                                : null
                        }
                        <AppText style={{fontSize: '12', color: '#9BA8CA'}}>
                            {integration.integrationImages[0]?.integration_image_title}
                        </AppText>
                    </View>
                </View>
                <FieldSet
                    chunkSize={buttonChunkSize}
                    fields={[
                        {
                            type: 'copy',
                            label: 'API Key',
                            value: apiKey,
                            wrapperStyle: styles.buttonStyles,
                            inputStyle: styles.inputStyles,

                        },
                        {
                            type: 'download',
                            label: integration.integration_title,
                            name: integration.integration_file_title,
                            value: integration.integration_file,
                            wrapperStyle: styles.buttonStyles,
                            inputStyle: styles.inputStyles,
                        },
                        ...(integration.integration_additional_file_title && integration.integration_additional_file ?
                                [
                                    {
                                        type: 'download',
                                        label: 'Additional Materials',
                                        name: integration.integration_additional_file_title,
                                        value: integration.integration_additional_file,
                                        wrapperStyle: styles.buttonStyles,
                                        inputStyle: styles.inputStyles,
                                    }
                                ]
                                : []
                        )
                    ]}
                />
            </View>
        </ScrollView>
    );
}

const mapStateToProps = (state) => {
    const {auth} = state;
    return {auth};
};

export default connect(mapStateToProps)(WordpressIntegration);


const styles = EStyleSheet.create({
    contentWrapper: {
        paddingTop: 70,
        paddingBottom: 70,
        paddingLeft: 40,
        paddingRight: 40,
    },
    contentContainer: {
        flexDirection: 'row',
        paddingBottom: 60
    },
    contentLeft: {
        width: '50%',
        paddingRight: 40
    },
    contentRight: {
        width: '50%',
    },
    image: {
        width: 408,
        height: 315,
        marginBottom: 10,
    },
    '@media (max-width: 920)': {
        contentContainer: {
            flexDirection: 'column'
        },
        contentLeft: {
            width: '100%',
            paddingBottom: 50,
        },
        contentRight: {
            width: '100%'
        }
    },
    '@media (max-width: 408)': {
        contentWrapper: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        contentLeft: {
            paddingLeft: 20,
            paddingRight: 20,
        },
        image: {
            width: 350
        },
        inputStyles: {
            paddingBottom: 20,
        },
        buttonStyles: {
            height: 'auto', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'inherit'
        }
    },
    '@media (max-width: 350)': {
        contentLeft: {
            paddingBottom: 10,
        },
        image: {
            width: 280
        },
    }
});
