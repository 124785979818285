import React from "react"
import Svg, { Path } from "react-native-svg"

function ChevronRightNavySvg(props) {
	return (
		<Svg width={5.326} height={8.37} viewBox="0 0 5.326 8.37" {...props}>
			<Path
				data-name="Path 26"
				d="M5.326 4.185L1.1 8.37 0 7.285l3.133-3.1L0 1.085 1.1 0z"
				fill="#023d83"
			/>
		</Svg>
	)
}

export default ChevronRightNavySvg;
