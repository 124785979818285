import React, {useState} from 'react';
import {View, StyleSheet} from 'react-native';

import AuthService from '../services/AuthService';
import {basicStyles} from "../styles/basic";
import UnauthenticatedHeader from "../components/UnauthenticatedHeader";
import SiteIconSvg from "../components/svgs/SiteIconSvg";
import AppText from "../components/AppText";
import AppTextInput from "../components/AppTextInput";
import AppButton from "../components/AppButton";
import {useFocusEffect} from '@react-navigation/native';

function PasswordReset(props) {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [passwordCode, setPasswordCode] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [passwordCodeSent, setPasswordCodeSent] = useState(false);
    const [passwordCodeValidated, setPasswordCodeValidated] = useState(false);

    const routeParams = props.route?.params;
    let redirectScreen, redirectParams;
    if(routeParams != undefined) {
        redirectScreen = routeParams.redirectScreen;
        redirectParams = routeParams.redirectParams;
    }

    const {navigate} = props.navigation;

    useFocusEffect(
        React.useCallback(() => {
            setEmail('');
            setError('');
            setPasswordCode('');
            setPassword('');
            setPasswordConfirm('');
            setPasswordCodeSent(false);
            setPasswordCodeValidated(false);
        }, [])
    );

    async function sendPasswordCode() {
        try {
            await AuthService.sendPasswordCode(email);
        } catch (error) {
            if (error?.response?.data?.meta?.message) {
                setError(error.response.data.meta.message);
            }
            return;
        }

        setPasswordCodeSent(true);
    }

    async function validatePasswordCode() {
        try {
            await AuthService.validatePasswordCode(passwordCode);
        } catch (error) {
            if (error?.response?.data?.meta?.message) {
                setError(error.response.data.meta.message);
            }
            return;
        }

        setPasswordCodeValidated(true);
    }

    async function resetPassword() {
        try {
            await AuthService.resetPassword(passwordCode, password, passwordConfirm);
        } catch (error) {
            if (error?.response?.data?.meta?.message) {
                setError(error.response.data.meta.message);
            }
            return;
        }

        if(redirectScreen) {
            navigate(redirectScreen, redirectParams);
        } else {
            navigate('Login');
        }
    }

    let content;

    if (passwordCodeValidated) {
        content = (
            <>
                <View style={styles.loginInputsContainer}>
                    <AppTextInput
                        label="Password"
                        value={password}
                        onChangeText={value => setPassword(value)}
                        secureTextEntry={true}
                        wrapperStyle={{marginBottom: 20}}
                    />
                    <AppTextInput
                        label="Confirm Password"
                        value={passwordConfirm}
                        onChangeText={value => setPasswordConfirm(value)}
                        secureTextEntry={true}
                        wrapperStyle={{marginBottom: 25}}
                    />
                </View>
                <AppButton
                    label="Next"
                    style={{
                        marginBottom: 10,
                        width: 145,
                    }}
                    action={() => resetPassword()}
                />
            </>
        );
    } else if (passwordCodeSent) {
        content = (
            <>
                <View style={styles.loginInputsContainer}>
                    <AppTextInput
                        label="Enter Code"
                        value={passwordCode}
                        onChangeText={value => setPasswordCode(value)}
                        wrapperStyle={{marginBottom: 25}}
                    />
                </View>
                <AppButton
                    label="Next"
                    style={{
                        marginBottom: 10,
                        width: 145,
                    }}
                    action={() => validatePasswordCode()}
                />
            </>
        );
    } else {
        content = (
            <>
                <View style={styles.loginInputsContainer}>
                    <AppTextInput
                        label="Email"
                        value={email}
                        onChangeText={value => setEmail(value)}
                        wrapperStyle={{marginBottom: 25}}
                    />
                </View>
                <AppButton
                    label="Next"
                    style={{
                        marginBottom: 10,
                        width: 145,
                    }}
                    action={() => sendPasswordCode()}
                />
            </>
        );
    }

    return (
        <View style={basicStyles.flexScale}>
            <UnauthenticatedHeader/>
            <View style={styles.pageWrapper}>
                <View style={styles.contentWrapper}>
                    <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 40}}>
                        <SiteIconSvg style={{marginRight: 10}}/>
                        <AppText style={styles.heading}>Reset Password</AppText>
                    </View>
                    <AppText>{error}</AppText>
                    {content}
                </View>
            </View>
        </View>
    );
}

export default PasswordReset;

const styles = StyleSheet.create({
    logoText: {
        fontSize: 19,
        color: '#000000',
    },
    pageWrapper: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F8FAFD',
    },
    contentWrapper: {
        width: '100%',
        maxWidth: 852,
        backgroundColor: '#FFFFFF',
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#E2E9F4',
        alignItems: 'center',
        paddingTop: 60,
        paddingLeft: 100,
        paddingRight: 100
    },
    heading: {
        fontFamily: 'SourceSansPro-Bold',
        color: '#0B2774',
        fontSize: 34,
    },
    loginInputsContainer: {
        width: '100%',
        maxWidth: 560,
    },
    loginButton: {
        backgroundColor: '#0065DB',
        borderRadius: 4,
        height: 50,
        width: 145,
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 50,
    },
    loginButtonText: {
        color: '#FFFFFF',
        fontSize: 15,
    },
});

