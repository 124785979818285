import React, {useState, useEffect} from 'react';
import AppButton from './AppButton';

function SingleClickButton({label, action, style}) {
		const [buttonClicked, setButtonClicked] = useState(false);

		useEffect(() => {
			if(buttonClicked) {
				//Action is assumed to be an async method
				action().then((isSuccess) => {
					if(!isSuccess) {
						setButtonClicked(false);
					}
				});
			}
		}, [buttonClicked]);


		return <AppButton
					label={label}
					action={() => {setButtonClicked(true)}}
					style={style}
				/>;
}

export default SingleClickButton;

