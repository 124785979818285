import React from 'react';
import {View} from 'react-native';

import LinkButton from './LinkButton';
import AppText from './AppText';
import ChevronRightNavySvg from './svgs/ChevronRightNavySvg';
import TouchView from './TouchView';
import EStyleSheet from 'react-native-extended-stylesheet';

function ActionRow({
                       style = {},
                       action,
                       touchAction,
                       slots = [],
                       children = null,
                   }) {

    function getContent() {
        return (
            children ?
                (children)
                :
                <View style={[styles.actionRowContainer]}>
                    <View style={[
                        styles.rowData,
                        {width: '100%'},
                    ]}>
                        {
                            slots.length > 0 ?
                                <View style={[
                                    {width: `${(1 / slots.length) * 100}%`},
                                    styles.rowValueContainer,
                                    styles.firstRowData,
                                ]}>
                                    <AppText style={styles.rowLabel}>
                                        {slots[0].label}
                                    </AppText>
                                    <AppText stles={styles.rowValue}>
                                        {slots[0].value}
                                    </AppText>
                                    <View style={styles.mobileClickableIndicator}>
                                        <ChevronRightNavySvg/>
                                    </View>
                                </View>
                                :
                                null
                        }
                        {
                            slots.length > 1 ?
                                <View style={[
                                    {width: `${100 - ((1 / slots.length) * 100)}%`},
                                    styles.secondRowValueContainer,
                                ]}>
                                    {
                                        slots.slice(1).map((slot, index) => {
                                            return (
                                                <View
                                                    key={slot.label}
                                                    style={[
                                                        {width: `${(1 / slots.length) * 100}%`, flex: 1},
                                                        styles.rowValueContainer,
                                                        index > 0 ? styles.slotThreeContainer : {},
                                                    ]}>
                                                    <AppText style={styles.rowLabel}>
                                                        {slot.label}
                                                    </AppText>
                                                    <AppText stles={styles.rowValue}>
                                                        {slot.value}
                                                    </AppText>
                                                </View>
                                            );
                                        })
                                    }
                                    <View style={styles.clickableIndicator}>
                                        <ChevronRightNavySvg/>
                                    </View>
                                </View>
                                : null}
                    </View>
                </View>
        );
    }

    if (touchAction) {
        return (
            <TouchView
                style={[
                    styles.row,
                    style,
                ]}
                action={() => touchAction()}
            >
                {getContent()}
            </TouchView>
        );
    }

    return (
        <LinkButton
            style={[
                styles.row,
                style,
            ]}
            action={action}
        >
            {getContent()}
        </LinkButton>
    );
}

export default ActionRow;

const styles = EStyleSheet.create({
    actionRowContainer: {
        flexDirection: 'row',
        flex: 1,
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 1,
        borderColor: '#E2E9F4',
        borderRadius: 8,
        backgroundColor: '#FFFFFF',
        paddingRight: 35,
        paddingLeft: 40,
        marginBottom: 10,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.1,
        shadowRadius: 9.11,
        height: 100,
        width: '100%',
    },
    avatarWrapper: {
        height: 100,
        paddingRight: 40,
        borderRightWidth: 1,
        borderRightColor: '#E2E9F4',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 20,
    },
    rowTitle: {
        fontSize: 16,
        color: '#000000',
        fontFamily: 'SourceSansPro-SemiBold',
        whiteSpace: 'nowrap',
    },
    rowLabel: {
        color: '#9BA8CA',
        fontSize: 16,
        marginRight: 10,
        whiteSpace: 'no-wrap',
    },
    rowValue: {
        fontSize: 16,
        whiteSpace: 'no-wrap',
    },
    rowValueContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
    },
    rowData: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    secondRowValueContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    mobileClickableIndicator: {
        display: 'none'
    },
    '@media (max-width: 800)': {
        row: {
            paddingRight: 0,
            paddingLeft: 0,
            height: 'auto',
            paddingTop: 30,
            paddingBottom: 30
        },
        rowValueContainer: {
            paddingLeft: 20,
            paddingRight: 20
        },
        rowData: {
            flexDirection: 'column'
        },
        firstRowData: {
            width: '100%',
            borderBottomWidth: 1,
            borderBottomColor: '#E2E9F4',
            alignItems: 'flex-start',
            paddingBottom: 30
        },
        secondRowValueContainer: {
            paddingTop: 30,
            width: '100%'
        },
        clickableIndicator: {
            paddingRight: 20
        }
    },
    '@media (max-width: 650)': {
        secondRowValueContainer: {
            flexDirection: 'column',
            alignItems: 'flex-start'
        },
        rowValueContainer: {
            width: '100%',
            flexDirection: 'column',
            alignItems: 'flex-start',

        },
        clickableIndicator: {
            display: 'none'
        },
        slotThreeContainer: {
            paddingTop: 15
        },
        mobileClickableIndicator: {
            //display: 'flex'
        }
    }
});
