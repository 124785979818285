import React from 'react';
import {View, StyleSheet} from 'react-native';
import AppText from "./AppText";

function Footer() {
    let date = new Date();
    return (
        <View style={styles.footer}>
            <AppText>
                {date.getFullYear()} Fluid Local Version 1.0
            </AppText>
        </View>
    );
}

export default Footer;

const styles = StyleSheet.create({
    footer: {
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderTopWidth: 1,
        borderTopColor: '#E2E9F4'
    }
});
