import React from 'react';
import {View, StyleSheet} from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import {basicStyles} from "../styles/basic";
import CheckMarkSvg from "./svgs/CheckMarkSvg";

function CheckMarkBox({active, style = {}}) {
    return (
        <View style={[
            basicStyles.flexCenterContent,
            styles.checkMark,
            ...(active ? [styles.checkMarkActive] : []),
            style
        ]}>
            {
                active ?
                    <CheckMarkSvg/>
                    : null
            }
        </View>
    );
}

const styles = EStyleSheet.create({
    checkMark: {
        width: 30,
        height: 30,
        borderWidth: 1,
        borderColor: '#D9E8F8',
        borderRadius: 5,
    },
    checkMarkActive: {
        backgroundColor: '#0065DB',
    },
});

export default CheckMarkBox;
