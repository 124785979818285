import React, {useState, useEffect, useCallback, useRef} from 'react';
import {View, Switch, StyleSheet, ActivityIndicator} from "react-native";

import {basicStyles} from "../../../../../styles/basic";
import AppText from "../../../../../components/AppText";
import {textStyles} from "../../../../../styles/text";
import AppButton from "../../../../../components/AppButton";
import MessagesService from "../../../../../services/MessagesService";
import ActionRow from "../../../../../components/ActionRow";
import {CancelToken} from "axios";
import ScriptModal from "./components/ScriptModal";

function Scripts({organizationId, setScrollEnabled}) {
    const [scriptsFetched, setScriptsFetched] = useState(false);
    const [scripts, setScripts] = useState([]);
    const [modalState, setModalState] = useState({
        active: false,
        scriptId: null
    });
    const updateDataRequestSources = useRef([]);

    const updateScript = useCallback((index, prop, value) => {
        setScripts(current => {
            let update = [...current];
            update[index][prop] = value;

            return update;
        });

        if (updateDataRequestSources.current[index]) {
            updateDataRequestSources.current[index].cancel('Request Canceled');
        }
        updateDataRequestSources.current[index] = CancelToken.source();
        MessagesService.updateMessageScript(organizationId, scripts[index].message_script_id, scripts[index], updateDataRequestSources.current[index].token);

    }, [organizationId, scripts]);

    useEffect(() => {
        MessagesService.getMessageScripts(organizationId)
            .then(scripts => {
                setScripts(scripts);
                setScriptsFetched(true);
            })
    }, [organizationId]);


    return (
        <>
            <View
                style={[basicStyles.flexRow, basicStyles.justifyContentSpaceBetween, basicStyles.alignContentCenter, {marginBottom: 25}]}>
                <AppText style={[textStyles.pageSubTitle, textStyles.semiBold, {marginBottom: 0}]}>
                    Scripts
                </AppText>
                <AppButton
                    label="Add Script"
                    action={() => {
                        setModalState({
                            active: true,
                            scriptId: null
                        });
                        setScrollEnabled(false)
                    }}
                />
            </View>
            {
                !scriptsFetched ?
                    <ActivityIndicator size="large" color="#467AFF"/>
                    : null
            }
            {
                scripts.map((script, index) => {
                    return (
                        <ActionRow
                            key={script.message_script_id}
                            style={styles.scriptContainer}
                            touchAction={() => {
                                setModalState({
                                    active: true,
                                    scriptId: script.message_script_id
                                });
                                setScrollEnabled(false)
                            }}
                            slots={[
                                {
                                    label: 'Title',
                                    value: script.message_script_title
                                },
                                {
                                    label: 'Category',
                                    value: script.message_script_category_title
                                },
                                {
                                    label: 'Enabled',
                                    value: <Switch
                                        style={{marginTop: 6}}
                                        value={parseInt(script.message_script_active) === 1}
                                        onValueChange={value => {
                                            updateScript(index, 'message_script_active', value ? '1' : '0');
                                        }}
                                        trackColor={{false: "#DEDEDE", true: "#0065db"}}
                                        thumbColor={parseInt(script.message_script_active) === 1 ? "#467aff" : "#EAEAEA"}
                                        activeThumbColor='#467aff'
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                    />
                                },
                            ]}
                        />
                    );
                })
            }
            {
                scriptsFetched && scripts.length === 0 ?
                    <View style={[basicStyles.flexScale]}>
                        <AppText style={[styles.noScriptsText]}>
                            No Scripts Found
                        </AppText>
                    </View>
                    : null
            }
            <ScriptModal
                active={modalState.active}
                organizationId={organizationId}
                scriptId={modalState.scriptId}
                onSubmit={() => {
                    MessagesService.getMessageScripts(organizationId)
                        .then(scripts => {
                            setScripts(scripts);
                            setScrollEnabled(true);
                            setModalState({
                                active: false,
                                scriptId: null,
                            })
                        })
                }}
                onClose={() => {
                    setScrollEnabled(true);
                    setModalState({
                        active: false,
                        scriptId: null,
                    })
                }}/>
        </>
    );
}

export default Scripts;

const styles = StyleSheet.create({
    scriptContainer: {
        height: 55
    },
    noScriptsText: {
        color: 'rgba(158, 171, 204, 1)',
        textAlign: 'center',
        paddingRight: 15,
        paddingLeft: 15,
    }
});
