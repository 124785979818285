import React from 'react';
import {StyleSheet, View} from 'react-native';
import GoogleMapReact from 'google-map-react';

function HeatMap({
                     centerLatLang,
                     height = 500,
                     width = '100%',
                     wrapperStyle = {},
                     coordinates = [],
                     autoCenter = false
                 }) {

    return (
        <View style={{
            height,
            width,
            padding: 30,
            ...wrapperStyle,
        }}>
            <GoogleMapReact
                defaultZoom={4}
                defaultCenter={centerLatLang}
                options={{gestureHandling: 'cooperative'}}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({map, maps}) => {
                    const data = coordinates.map(coordinate => {
                        return new maps.LatLng(coordinate.lat, coordinate.lng);
                    });

                    let heatMap = new maps.visualization.HeatmapLayer({
                        data: data,
                        map,
                    });

                    const gradient = [
                        'rgba(0, 255, 255, 0)',
                        'rgba(0, 255, 255, 1)',
                        'rgba(0, 191, 255, 1)',
                        'rgba(0, 127, 255, 1)',
                        'rgba(0, 63, 255, 1)',
                        'rgba(0, 0, 255, 1)',
                        'rgba(0, 0, 223, 1)',
                        'rgba(0, 0, 191, 1)',
                        'rgba(0, 0, 159, 1)',
                        'rgba(0, 0, 127, 1)',
                        'rgba(63, 0, 91, 1)',
                        'rgba(127, 0, 63, 1)',
                        'rgba(191, 0, 31, 1)',
                        'rgba(255, 0, 0, 1)',
                    ];
                    heatMap.set('gradient', heatMap.get('gradient') ? null : gradient);

                    if (autoCenter && coordinates.length > 0) {
                        let bounds = new maps.LatLngBounds();
                        for (var i = 0; i < coordinates.length; i++) {
                            bounds.extend(coordinates[i]);
                        }
                        map.fitBounds(bounds);
                    }
                }}
            />
        </View>
    );
}

export default HeatMap;

const styles = StyleSheet.create({
    processingText: {
        color: '#FFFFFF',
        fontFamily: 'SourceSansPro-SemiBold',
        fontSize: 20,
        marginLeft: 20,
    },
});
