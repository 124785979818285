import * as React from "react"
import Svg, { Path } from "react-native-svg"

function MailSvg({fill = '#0065db', ...rest}) {
	return (
		<Svg width={18.645} height={15.981} viewBox="0 0 18.645 15.981" {...rest}>
			<Path
				data-name="Path 484"
				d="M.009 15.981L18.645 7.99.009 0 0 6.215l13.318 1.776L0 9.766z"
				fill={fill}
			/>
		</Svg>
	)
}

export default MailSvg;
