import React, {useEffect, useState} from 'react';
import {Provider} from 'react-redux';
import {
    StatusBar,
    SafeAreaView,
    TouchableWithoutFeedback,
    View
} from 'react-native';
import {NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import AsyncStorage from '@react-native-community/async-storage';
import EStyleSheet from 'react-native-extended-stylesheet';

import Unauthenticated from './views/Unauthenticated';
import Authenticated from './views/Authenticated';
import store from './store';
import {basicStyles} from './styles/basic';
import fullScreenStackTransitionCardStyleInterpolator from './utilities/full-screen-stack-transition-card-style-interpolator';
import {navigate, navigationRef, isReadyRef} from './utilities/navigation';
import Warning from './components/Warning';
import {setSpreadSheetFilterOpen, setPageClicked} from './store/miscellaneous/miscellaneousActions';
import {
    setUser,
    setToken,
    setOrganization,
    setOrganizationRoleId,
    setInitialized,
    setPermissions,
    setRealToken,
    setRealOrganizationId,
    setRealOrganizationRoleId,
} from './store/auth/authActions';
import AuthService from './services/AuthService';
import OrganizationsService from './services/OrganizationsService';
import ProcessingIndicatorOverlay from './components/ProcessingIndicatorOverlay';
import Notifications from "./components/Notifications";
import ReviewResponse from "./components/ReviewResponse";

const Stack = createStackNavigator();
EStyleSheet.build({
    $mainColor1: 'rgb(70, 122, 255)'
});
const App: () => React$Node = () => {

    const [appInitialized, setAppInitialized] = useState(false);
    const [initialPage, setInitialPage] = useState(window.location.href);

    const handlePageClick = () => {
        store.dispatch(setSpreadSheetFilterOpen(false));
        store.dispatch(setPageClicked(true));
        setTimeout(() => {
            store.dispatch(setPageClicked(false));
        })
    };

    const linking = {
        config: {
            Unauthenticated: {
                path: '/',
                screens: {
                    Login: {
                        path: '/',
                    },
                    AcceptInvite: {
                        path: '/accept-invite/:inviteCode',
                    },
                    Survey: {
                        path: '/take-survey/:surveyResponseCode',
                    },
                    PasswordReset: {
                        path: '/password-reset'
                    },
                    OnBoarding: {
                        path: '/on-boarding'
                    }
                },
            },

            Authenticated: {
                path: '/auth',
                screens: {
                    Jobs: {
                        path: '/jobs',
                        screens: {
                            JobIndexEditNavigation: {
                                path: '/',
                                screens: {
                                    JobsList: {
                                        path: '/',
                                    },
                                    JobEdit: {
                                        path: '/edit/:id',
                                        parse: {
                                            id: Number,
                                        },
                                    },
                                },
                            },
                            JobAddNavigation: {
                                path: '/add',
                                screens: {
                                    JobCreateOptions: {
                                        path: '/',
                                    },
                                    JobAdd: {
                                        path: '/single',
                                    },
                                },
                            },
                            ImportHistoryNavigation: {
                                path: '/import-history',
                                screens: {
                                    BatchQueue: {
                                        path: '/',
                                    },
                                    JobImportResults: {
                                        path: '/results'
                                    }
                                }
                            },
                            JobAdd: {
                                path: '/add-single',
                            },
                            CustomUploads: {
                                path: '/custom-uploads',
                            },
                        },
                    },

                    TeamMembers: {
                        path: '/team',
                        screens: {
                            TeamMemberIndexEditNavigation: {
                                path: '/',
                                screens: {
                                    TeamMembersList: {
                                        path: '/',
                                    },
                                    TeamMemberEdit: {
                                        path: '/edit/:id',
                                        parse: {
                                            id: Number,
                                        },
                                    },
                                },
                            },
                            TeamMemberAddNavigation: {
                                path: '/add',
                                screens: {
                                    TeamMemberCreateOptions: {
                                        path: '/',
                                    },
                                    TeamMemberAdd: {
                                        path: '/single',
                                    },
                                },
                            },
                        },
                    },

                    Clients: {
                        path: '/clients',
                        screens: {
                            OrganizationList: {
                                path: '/'
                            },
                            ClientPartnerAdd: {
                                path: '/add'
                            },
                            ClientPartnerSingleNavigation: {
                                path: '/:organizationId',
                                parse: {
                                    id: Number
                                },
                                screens: {
                                    OrganizationEdit: {
                                        path: '/'
                                    },
                                    JobIndexEditNavigation: {
                                        path: '/jobs',
                                        screens: {
                                            JobsList: {
                                                path: '/',
                                            },
                                            JobEdit: {
                                                path: '/edit/:id',
                                                parse: {
                                                    id: Number,
                                                },
                                            },
                                        },
                                    },
                                    ClientPartnersTeamStackNavigation: {
                                        path: '/team',
                                        screens: {
                                            TeamMembersList: {
                                                path: '/'
                                            },
                                            TeamMemberEdit: {
                                                path: ':id',
                                                parse: {
                                                    id: Number
                                                }
                                            }
                                        }
                                    },
                                    StoreFrontIndexEditNavigation: {
                                        path: '/store-fronts',
                                        screens: {
                                            StoreFrontsList: {
                                                path: '/',
                                            },
                                            StoreFrontEdit: {
                                                path: '/:id',
                                                parse: {
                                                    id: Number,
                                                },
                                            },
                                            StoreFrontAdd: {
                                                path: '/add',
                                            },
                                        },
                                    },
                                    SurveySettings: {
                                        path: '/survey-settings'
                                    },
                                    Activity: {
                                        path: '/activity'
                                    },
                                    CustomUploads: {
                                        path: '/custom-uploads',
                                    },
                                }
                            },
                            ClientCommissions: {
                                path: '/commissions-overview/:organizationId',
                                parse: {
                                    id: Number
                                },
                            }
                        }
                    },
                    Partners: {
                        path: '/partners',
                        screens: {
                            OrganizationList: {
                                path: '/'
                            },
                            ClientPartnerAdd: {
                                path: '/add'
                            },
                            ClientPartnerSingleNavigation: {
                                path: '/:organizationId',
                                parse: {
                                    id: Number
                                },
                                screens: {
                                    OrganizationEdit: {
                                        path: '/'
                                    },
                                    ClientPartnersTeamStackNavigation: {
                                        path: '/team',
                                        screens: {
                                            TeamMembersList: {
                                                path: '/'
                                            },
                                            TeamMemberEdit: {
                                                path: ':id',
                                                parse: {
                                                    id: Number
                                                }
                                            }
                                        }
                                    },
                                    StoreFrontIndexEditNavigation: {
                                        path: '/store-fronts',
                                        screens: {
                                            StoreFrontsList: {
                                                path: '/',
                                            },
                                            StoreFrontEdit: {
                                                path: '/:id',
                                                parse: {
                                                    id: Number,
                                                },
                                            },
                                            StoreFrontAdd: {
                                                path: '/add',
                                            },
                                        },
                                    },
                                    SurveySettings: {
                                        path: '/survey-settings'
                                    },
                                    Activity: {
                                        path: '/activity'
                                    }
                                }
                            },
                        }
                    },

                    Coupons: {
                        path: '/coupons',
                        screens: {
                            CouponsList: {
                                path: '/'
                            },
                            CouponEdit: {
                                path: '/:couponId',
                                parse: {
                                    couponId: Number
                                }
                            },
                            CouponAdd: {
                                path: '/add'
                            },
                        }
                    },
                    ClientsCommissions: {
                        path: '/payments'
                    },
                    Reports: {
                        path: '/reports',
                        screens: {
                            Dashboard: {
                                path: '/'
                            },
                            Customers: {
                                path: '/customers',
                            },
                            Reviews: {
                                path: '/reviews',
                                screens: {
                                    FluidReviews: {
                                        path: '/:surveyResponseId?'
                                    },
                                    ThirdPartyReviews: {
                                        path: 'third-party'
                                    }
                                }
                            },
                            Team: {
                                path: '/team',
                            },
                            Markets: {
                                path: '/markets',
                            },
                        },
                    },
                    AccountSettings: {
                        path: '/account-settings',
                        parse: {
                            id: Number,
                        },
                        screens: {
                            OrganizationEdit: {
                                path: '/',
                            },
                            StoreFrontIndexEditNavigation: {
                                path: '/store-fronts',
                                screens: {
                                    StoreFrontsList: {
                                        path: '/',
                                    },
                                    StoreFrontEdit: {
                                        path: '/:id',
                                        parse: {
                                            id: Number,
                                        },
                                    },
                                    StoreFrontAdd: {
                                        path: '/add',
                                    },
                                },
                            },
                            SurveySettings: {
                                path: '/survey-settings',
                            },
                            Activity: {
                                path: '/activity'
                            },
                            PaymentSettings: {
                                path: '/payment-settings'
                            },
                            ManageSubscription: {
                                path: '/manage-subscription'
                            },
                            MakePayment: {
                                path: '/make-a-payment'
                            },
                            MessageFlowSettings: {
                                path: '/message-flow-settings'
                            },
                        },
                    },
                    UserProfileSettings: {
                        path: '/user-profile-settings/',
                        screens: {
                            UserProfileIndexEditNavigation: {
                                path: '/',
                                screens: {
                                    UserProfile: {
                                        path: '/'
                                    },
                                    UserProfileChangePassword: {
                                        path: '/edit/:id',
                                        parse: {
                                            id: Number,
                                        },
                                    },
                                },
                            },
                            UserProfileNotifications: {
                                path: '/alerts-and-notifications'
                            },
                        },
                    },
                    MessageFlow: {
                        path: '/messages',
                        screens: {
                            MessageFlow: {
                                path: '/:messageFeedId',
                                parse: {
                                    messageId: Number,
                                },
                            }
                        }
                    }
                },
            },
        },
    };

    useEffect(() => {
        const validate = async () => {
            const authToken = await AsyncStorage.getItem('authToken');
            const organizationId = await AsyncStorage.getItem('organizationId');
            const roleId = await AsyncStorage.getItem('roleId');
            const emulateAuthToken = await AsyncStorage.getItem('emulateAuthToken');
            const emulateOrganizationId = await AsyncStorage.getItem('emulateOrganizationId');
            const emulateOrganizationRoleId = await AsyncStorage.getItem('emulateOrganizationRoleId');

            if (authToken !== null && organizationId !== null && roleId !== null) {
                store.dispatch(setToken(authToken));
                store.dispatch(setOrganizationRoleId(roleId));

                try {
                    if (emulateAuthToken) {
                        store.dispatch(setToken(emulateAuthToken));
                        store.dispatch(setRealToken(authToken));
                    }

                    if (emulateOrganizationRoleId) {
                        store.dispatch(setOrganizationRoleId(emulateOrganizationRoleId));
                        store.dispatch(setRealOrganizationRoleId(roleId));
                    }

                    if (emulateOrganizationId) {
                        store.dispatch(setOrganization(await OrganizationsService.getOrganization(emulateOrganizationId)));
                        store.dispatch(setPermissions(await AuthService.getPermissions(emulateOrganizationId)));
                        store.dispatch(setRealOrganizationId(organizationId));
                    } else {
                        store.dispatch(setOrganization(await OrganizationsService.getOrganization(organizationId)));
                        store.dispatch(setPermissions(await AuthService.getPermissions(organizationId)));
                    }

                    store.dispatch(setUser(await AuthService.validate()));

                } catch (error) {
                    store.dispatch(setToken(null));
                    store.dispatch(setRealToken(null));
                    store.dispatch(setOrganization(null));
                    store.dispatch(setOrganizationRoleId(null));
                    store.dispatch(setRealOrganizationRoleId(null));
                    store.dispatch(setPermissions([]));

                    await AsyncStorage.removeItem('authToken');
                    await AsyncStorage.removeItem('organizationId');
                    await AsyncStorage.removeItem('roleId');
                    await AsyncStorage.removeItem('emulateAuthToken');
                    await AsyncStorage.removeItem('emulateOrganizationId');
                    await AsyncStorage.removeItem('emulateOrganizationRoleId');
                    setAppInitialized(true);
                    setInitialPage(null);

                    return () => {
                        isReadyRef.current = false
                    };
                }
            }

            // setInitialPage(null)
            setAppInitialized(true);
            store.dispatch(setInitialized(true));

        };
        validate();
    }, []);

    useEffect(() => {
        if(isReadyRef.current && appInitialized) {
            if(!store.getState().auth.token) {
                navigate('Unauthenticated');
            }
        }
    }, [isReadyRef.current, appInitialized])

    return (
        <Provider store={store}>
            <StatusBar barStyle="dark-content"/>
            <SafeAreaView style={basicStyles.flexScale}>
                <TouchableWithoutFeedback
                    onPress={handlePageClick}>
                    <View style={{flex: 1}}>
                        {
                            appInitialized ?
                                <NavigationContainer
                                    linking={linking}
                                    ref={navigationRef}
                                    onReady={() => {
                                        isReadyRef.current = true;
                                    }}
                                >
                                    <Stack.Navigator
                                        backBehavior="history"
                                        screenOptions={{
                                            header: () => null,
                                            cardStyle: {
                                                backgroundColor: '#F8FAFD',
                                            },
                                            cardStyleInterpolator: fullScreenStackTransitionCardStyleInterpolator,
                                        }}
                                        initialRouteName="Unauthenticated"
                                    >
                                        <Stack.Screen
                                            name="Unauthenticated"
                                            component={Unauthenticated}
                                            options={{
                                                title: "Fluid Local"
                                            }}
                                        />
                                        <Stack.Screen
                                            name="Authenticated"
                                            component={Authenticated}
                                            initialParams={{redirect: initialPage}}
                                            options={{
                                                title: "Fluid Local"
                                            }}
                                        />
                                    </Stack.Navigator>
                                </NavigationContainer>
                                : null
                        }
                    </View>
                </TouchableWithoutFeedback>
                <div id="overlay-portal" />
                <div id="overlay-portal-alt" />
            </SafeAreaView>
            <Warning/>
            <ProcessingIndicatorOverlay/>
            <Notifications/>
            <ReviewResponse/>
        </Provider>
    );
};

export default App;
