import React from 'react';
import {View, StyleSheet} from 'react-native';
import {BarChart, XAxis, Grid} from 'react-native-svg-charts';
import { Text } from 'react-native-svg'
import * as scale from 'd3-scale'
import {basicStyles} from '../styles/basic';
import AppText from './AppText';

function AppBarChart({data, showLabels = true, labelPrecision = 2, preBarLabel = '$'}) {

	const maxValue = Math.max(...data.map(({value}) => value));
	const CUT_OFF = maxValue - 10;
	const Labels = ({ x, y, bandwidth, data }) => (
		data.map((item, index) => {
			const value = item.value;
			return (
				<Text
					key={ index }
					x={ x(index) + (bandwidth / 2) }
					y={ value < CUT_OFF ? y(value) - 10 : y(value) + 15 }
					fontSize={ 14 }
					fill={ value >= CUT_OFF ? 'white' : 'black' }
					alignmentBaseline={ 'middle' }
					textAnchor={ 'middle' }
					style={{fontSize: 14, fontFamily: 'SourceSansPro-SemiBold' }}
				>
					{preBarLabel}{value.toFixed(labelPrecision)}
				</Text>
			);
		})
	);

	return (
		<View style={styles.chartWrapper}>
			<BarChart
				style={styles.chart}
				data={data}
				svg={{ fill: '#2588EE' }}
				contentInset={{ top: 10, bottom: 10 }}
				yAccessor={({ item }) => item.value}
				gridMin={0}
			>
				<Grid direction={Grid.Direction.HORIZONTAL}/>
				{
					showLabels ?
					<Labels/>
					: null
				}
			</BarChart>
			<View style={[
				styles.xAxis,
			]}>
				{
					data.map((item, index) => {
						return (
							<AppText key={index}>
								{item.label}
							</AppText>
						);
					})
				}
			</View>
			{/*<XAxis*/}
			{/*	style={styles.xAxis}*/}
			{/*	data={data}*/}
			{/*	formatLabel={(value, index) => data[index].label}*/}
			{/*	contentInset={{ left: 10, right: 10 }}*/}
			{/*	svg={{ fontSize: 14, fill: 'black', fontFamily: 'SourceSansPro-Regular' }}*/}
			{/*/>*/}
		</View>
	)
}

export default AppBarChart;

const styles = StyleSheet.create({
	chartWrapper: {
		height: 325,
		paddingVertical: 16
	},
	chart: {
		flex: 1,
		width: '100%'
	},
	xAxis: {
		width: '100%',
		flexDirection: 'row',
		justifyContent: 'space-around',
	},
	// xAxis: {
	// 	width: '100%',
	// 	marginTop: 10,
	// 	justifyContent: 'space-around'
	// },
});
