import Http from "../utilities/Http";
import {API_ROOT} from "../config";

export default {
	async getSurveyWebsites() {
		let response = await Http().get(API_ROOT + '/survey-websites');

		return response.data.surveyWebsites;
	},
	async getOrganizationSurveyWebsites(organizationId) {
		let response = await Http().get(API_ROOT + '/survey-websites/get-organization-survey-websites/' + organizationId);

		return response.data.surveyWebsites;
	},
	async getStoreFrontWebsites(storeFrontId) {
		let response = await Http().get(API_ROOT + '/survey-websites/get-store-front-survey-websites/' + storeFrontId);

		return response.data.surveyWebsites;
	}
};
