import React from 'react';
import {View, StyleSheet} from 'react-native';

import AppText from './AppText';
import {basicStyles} from '../styles/basic';

function StatusIconRow({statusRows, style = {}, styleWrapper= {}, styleAlternate = false, styleCopy = {}, styleNumber = {}}) {
	return (
		<View style={[
			basicStyles.flexRow,
			basicStyles.alignContentCenter,
			style
		]}>
			{
				statusRows.map((statusRow, index) => {
					return (
						<View
							key={index}
							style={[
								basicStyles.flexScale,
								basicStyles.flexRow,
								basicStyles.flexCenterContent,
								styles.statusContainer,
								{
									marginRight: (index === (statusRows.length - 1) ? 0 : 20),
								},
								...(styleAlternate ? [styles.statusContainerAlt] : []),
								styleWrapper
							]}
						>
							<View style={styles.iconContainer}>{statusRow.icon}</View>
							<View style={[styles.statusDetails, styleCopy]}>
								<AppText style={[
									styles.countText,
									...(styleAlternate ? [styles.countTextAlt] : []),
									styleNumber
								]}>
									{statusRow.count}
								</AppText>
								<AppText style={styles.statusText}>
									{statusRow.status}
								</AppText>
							</View>
						</View>
					);
				})
			}
		</View>
	);
}

export default StatusIconRow;

const styles = StyleSheet.create({
	statusContainer: {
		borderRadius: 5,
		borderColor: '#E2E9F4',
		borderWidth: 1,
		height: 76,
	},
	statusContainerAlt: {
		paddingRight: 10,
		paddingLeft: 10,
		marginRight: 0,
		width: 220
	},
	iconContainer: {
		margin: 10
	},
	countText: {
		fontFamily: 'SourceSansPro-Bold',
		fontSize: 16,
		marginRight: 5,
	},
	countTextAlt: {
		marginLeft: 5
	},
	statusText: {
		fontSize: 16,
		color: '#9BA8CA',
	},
	statusDetails: {
		flexDirection: 'row'
	},
});
