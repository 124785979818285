import React from 'react';
import {View} from 'react-native';
import EStyleSheet from "react-native-extended-stylesheet";

import AppText from "../../../components/AppText";
import AppButton from "../../../components/AppButton";
import {basicStyles} from "../../../styles/basic";
import {textStyles} from "../../../styles/text";
import ScreenOverlay from "../../../components/ScreenOverlay";
import ContentSection from "../../../components/ContentSection";

function BlockModal ({messageFeedBlocked, handleSubmit, handleClose}) {
    //todo: need to have screen size specific style for overlay
    return (
        <ScreenOverlay
            style={{position: 'fixed', left: 80, width: 'auto'}}
            onClick={() => {
            handleClose();
        }}>
            <ContentSection
                style={[styles.contentWrapper]}
                onClick={e => {
                    e.stopPropagation();
                }}
            >
                <AppText style={[textStyles.pageTitle, {marginBottom: 10}]}>
                    {messageFeedBlocked ? 'Unblock' : 'Block'} Contact
                </AppText>
                <AppText>
                    Are you sure you want to {messageFeedBlocked ? 'Unblock' : 'Block'} this contact?
                </AppText>
                <View style={[basicStyles.flexRow, basicStyles.justifyContentFlexEnd, {marginTop: 20}]}>
                    <AppButton
                        label="Cancel"
                        action={handleClose}
                        style={{marginRight: 10}}
                        theme="transBlue"
                    />
                    <AppButton
                        label="Confirm"
                        action={handleSubmit}
                        style={{marginRight: 10}}
                    />
                </View>
            </ContentSection>
        </ScreenOverlay>
    );
}

export default BlockModal;

const styles = EStyleSheet.create({
    contentWrapper: {
        padding: 32,
        width: 400,
        maxWidth: '100%'
    }
});
