import React from 'react';
import {
    NavigationHelpersContext,
    useNavigationBuilder,
    TabRouter,
    createNavigatorFactory,
    CommonActions,
} from '@react-navigation/native';
import {View} from 'react-native';

import {basicStyles} from "../styles/basic";

function InteriorTabNavigator({initialRouteName, children, screenOptions, backBehavior, navTitle, navIcon, styleContainer = {}, styleHeader = {}, styleTitle = {}, styleLinkContainer = {}}) {
    const {state, navigation, descriptors} = useNavigationBuilder(TabRouter, {
        children,
        screenOptions,
        initialRouteName,
        backBehavior,
    });

    return (
        <NavigationHelpersContext.Provider value={navigation}>
            <View style={basicStyles.flexScale}>
                {descriptors[state.routes[state.index].key].render()}
            </View>
        </NavigationHelpersContext.Provider>
    );

}

export const createInteriorTabNavigator = createNavigatorFactory(InteriorTabNavigator);
