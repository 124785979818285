import * as React from "react"
import Svg, {Path} from "react-native-svg"

function AttachmentsSvg(props) {
    return (
        <Svg
            width={20}
            height={22}
            viewBox="0 0 20 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M1.938 15.055c0 3.224 2.777 5.768 5.972 5.46 1.368-.132 2.516-.736 3.48-1.7 2.193-2.194 4.385-4.39 6.578-6.582.119-.12.247-.236.388-.324.293-.184.566-.088.786.135.22.223.308.496.119.788-.079.121-.173.235-.275.336-2.216 2.222-4.422 4.455-6.655 6.659-2.09 2.061-4.58 2.738-7.329 1.676-4.555-1.759-5.931-7.252-2.84-10.864.056-.064.11-.131.17-.19C5.37 7.402 8.38 4.331 11.46 1.328c1.37-1.335 3.075-1.68 4.873-.964 1.82.724 2.873 2.121 3.128 4.076.185 1.414-.303 2.628-1.245 3.658-.63.688-1.305 1.334-1.962 1.995-2.124 2.142-4.247 4.286-6.376 6.423-1.048 1.052-2.367 1.314-3.582.734a2.99 2.99 0 01-1.01-4.657c.171-.204.362-.391.549-.58 2.123-2.127 4.246-4.253 6.37-6.378.207-.207.42-.42.75-.348.54.116.748.68.418 1.119-.08.106-.178.198-.272.292-2.176 2.194-4.352 4.386-6.525 6.583-.73.739-.785 1.653-.15 2.326.657.698 1.672.678 2.419-.064 1.203-1.196 2.399-2.399 3.598-3.599 1.524-1.526 3.053-3.046 4.57-4.58 1.274-1.289 1.431-3.106.407-4.496-1.163-1.577-3.302-1.877-4.851-.677a4.557 4.557 0 00-.435.386C9.308 5.404 6.48 8.232 3.658 11.063c-1.097 1.102-1.715 2.415-1.716 3.99l-.004.002z"
                fill="#3CA6F5"
            />
        </Svg>
    )
}

export default AttachmentsSvg
