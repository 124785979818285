import React, {useState} from 'react';
import {View, StyleSheet, ScrollView, TouchableWithoutFeedback} from 'react-native';

import ScreenOverlay from "./ScreenOverlay";
import ContentSection from "./ContentSection";
import AppText from "./AppText";
import {basicStyles} from "../styles/basic";
import StatusIndicator from "./StatusIndicator";
import ChevronDownSvg from "./svgs/ChevronDownSvg";
import ErrorMiniSvg from "./svgs/ErrorMiniSvg";
import AppPickerInput from "./AppPickerInput";
import AppButton from "./AppButton";
import {objectFlip} from "../utilities/methods";
import {IMPORT_CONFIGURATOR_DESCRIPTION} from "../config";

function ImportConfiguration({onSubmit, onOffClick, columns, firstRow, availableColumns, defaultConfiguration = null, description = IMPORT_CONFIGURATOR_DESCRIPTION}) {
    const [state, setState] = useState({
        selectedColumns: {
            ...(defaultConfiguration ? objectFlip(defaultConfiguration) : {})
        },
    });

    function setColumn(availableColumn, index) {
        setState(prevState => {
            let newState = {...prevState};
            newState.selectedColumns[index] = availableColumn;
            return newState;
        });
    }

    function getColumnTitleByName(name) {
        return availableColumns.find(column => column.name === name)?.title;
    }

    function columnSelected(column) {
        for(let prop in state.selectedColumns) {
            if(state.selectedColumns[prop] === column) {
                return true;
            }
        }

        return false;
    }

    return (
        <ScreenOverlay style={styles.screenWrapper}>
            <TouchableWithoutFeedback onPress={() => onOffClick()}>
                <View style={basicStyles.overlayHidden}/>
            </TouchableWithoutFeedback>
            <ContentSection style={styles.contentSection}>
                <View style={styles.headerWrapper}>
                    <View style={{flex: 1}}>
                        <AppText>
                            {description}
                        </AppText>
                    </View>
                    <View style={styles.legend}>
                        <View style={[basicStyles.flexRow, basicStyles.alignContentCenter, styles.legendItem]}>
                            <View style={styles.legendIcon}><StatusIndicator color="#47CC8A"/></View>
                            <AppText>Valid</AppText>
                        </View>
                        <View style={[basicStyles.flexRow, basicStyles.alignContentCenter, styles.legendItem]}>
                            <ErrorMiniSvg style={styles.legendIcon}/>
                            <AppText>Invalid</AppText>
                        </View>
                    </View>
                </View>
                <ScrollView
                    horizontal={true}
                    style={[styles.gridInversion, {marginBottom: 60}]}
                    contentContainerStyle={{minWidth: '100%', marginBottom: 30}}
                    showsHorizontalScrollIndicator={true}
                >
                    <View style={[basicStyles.flexRow, styles.tableWrapper, styles.gridInversion]}>
                        {
                            columns.map((column, columnIndex) => {
                                return (
                                    <View style={styles.columnWrapper} key={column + '-' + columnIndex}>
                                        <View style={[
                                            basicStyles.flexRow,
                                            basicStyles.alignContentCenter,
                                            basicStyles.justifyContentSpaceBetween,
                                            styles.cell,
                                            styles.columnHeading,
                                        ]}>
                                            {
                                                state.selectedColumns[columnIndex] ?
                                                    <StatusIndicator color="#47CC8A"/>
                                                    :
                                                    <ErrorMiniSvg/>
                                            }
                                            <AppText style={styles.columnHeadingText}>
                                                {state.selectedColumns[columnIndex] ? getColumnTitleByName(state.selectedColumns[columnIndex]) : column}
                                            </AppText>
                                            <ChevronDownSvg color="blue"/>
                                            <AppPickerInput
                                                selectedValue={state.selectedColumns[columnIndex] || ''}
                                                onValueChange={(value) => {
                                                    setColumn(value, columnIndex)
                                                }}
                                                labelExtractor="title"
                                                valueExtractor="name"
                                                items={availableColumns.filter(column => state.selectedColumns[columnIndex] === column.name || !columnSelected(column.name))}
                                                naked={true}
                                                title="Column"
                                                nullable={true}
                                                wrapperStyle={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                    left: 0,
                                                    opacity: 0,
                                                }}
                                            />
                                        </View>
                                        <View style={[styles.cell,]}>
                                            <AppText>
                                                {firstRow[columnIndex]}
                                            </AppText>
                                        </View>
                                    </View>
                                );
                            })
                        }
                    </View>
                </ScrollView>
                <AppButton action={() => onSubmit(objectFlip(state.selectedColumns))} theme="blue" label="Submit"/>
            </ContentSection>
        </ScreenOverlay>
    );
}

export default ImportConfiguration;

const styles = StyleSheet.create({
    screenWrapper: {
        paddingLeft: 60,
        paddingRight: 60
    },
    contentSection: {
        width: '100%',
        padding: 30,
    },
    tableWrapper: {
        borderWidth: 1,
        borderColor: '#e1e9f6',
        width: '100%'
    },
    cell: {
        padding: 20
    },
    columnValue: {
        whiteSpace: 'no-wrap'
    },
    columnHeading: {
        backgroundColor: '#f8fafe',
    },
    columnHeadingText: {
        fontFamily: 'SourceSansPro-Bold',
        marginRight: 20,
        marginLeft: 10,
    },
    columnWrapper: {
        borderRightWidth: 1,
        borderColor: '#e1e9f6',
        flexGrow: 1
    },
    headerWrapper: {
        marginBottom: 10,
        flexDirection: 'row',
        alignItems: 'top'
    },
    legend: {
        backgroundColor: 'rgba(221,221,221,.5)',
        borderRadius: 20,
        padding: 10,
        flexDirection: 'row',
        alignItems: 'center'
    },
    legendIcon: {
        marginRight: 10
    },
    legendItem: {
        marginRight: 20
    },
    gridInversion: {
        transform:[{rotateX:'180deg'}]
    },
});
