import React from 'react';
import {useLinkProps} from '@react-navigation/native';
import {View, TouchableOpacity, Platform, StyleSheet} from 'react-native';

function SubNavLink({to, action, children, active, ...rest}) {
    const {onPress, ...props} = useLinkProps({to, action});

    const [isHovered, setIsHovered] = React.useState(false);

    if (Platform.OS === 'web') {
        // It's important to use a `View` or `Text` on web instead of `TouchableX`
        // Otherwise React Native for Web omits the `onClick` prop that's passed
        // You'll also need to pass `onPress` as `onClick` to the `View`
        // You can add hover effects using `onMouseEnter` and `onMouseLeave`
        return (
            <View
                style={[styles.linkContainer, (active || isHovered ? {backgroundColor: 'rgba(0,0,0,.23)', borderColor: 'rgba(0,0,0,.23)'} : {})]}
                onClick={onPress}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                {...props}
                {...rest}
            >
                {children}
            </View>
        );
    }

    return (
        <TouchableOpacity
            style={[styles.linkContainer, (active ? {backgroundColor: 'rgba(0,0,0,.23)', borderColor: 'rgba(0,0,0,.23)'} : {})]}
            onPress={onPress} {...props} {...rest}
        >
            {children}
        </TouchableOpacity>
    );
}

export default SubNavLink;

const styles = StyleSheet.create({
    linkContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 28,
        paddingRight: 28,
        backgroundColor: 'transparent',
        borderWidth: 1,
        borderColor: 'rgba(255,255,255,.2)',
        borderRadius: 5,
        marginRight: 12,
    }
});
