import React from 'react';
import {View} from 'react-native';
import {connect} from 'react-redux';

import {createSubNavigator} from '../../components/SubNavigator';
import JobIndexEditNavigation from './JobIndexEditNavigation';
import JobAddNavigation from './JobAddNavigation';
import JobSingle from "./JobSingle";
import {basicStyles} from '../../styles/basic';
import BatchQueue from '../BatchQueue';
import ImportHistoryNavigation from './ImportHistoryNavigation';
import CustomUploads from './CustomUploads';

const SubNavigator = createSubNavigator();

function Jobs(props) {
    return (
        <View style={basicStyles.flexScale}>
            <SubNavigator.Navigator backBehavior="history" initialRouteName="JobIndexEditNavigation">
                <SubNavigator.Screen
                    name="JobIndexEditNavigation"
                    component={JobIndexEditNavigation}
                    options={{
                        title: 'Jobs'
                    }}
                />
                {
                    props.auth.permissions.includes('manage_account_jobs') ?
                        (
                            <>
                                {!!props.auth.organization?.subscription_type_id && (
                                    <SubNavigator.Screen
                                        name="JobAddNavigation"
                                        component={JobAddNavigation}
                                        options={{
                                            title: 'Post Job'
                                        }}
                                    />
                                )}
                                <SubNavigator.Screen
                                    name="ImportHistoryNavigation"
                                    component={ImportHistoryNavigation}
                                    options={{
                                        title: 'View Import History'
                                    }}
                                    initialParams={{
                                        organization_id : props.auth.organization.organization_id
                                    }}
                                />
                                <SubNavigator.Screen
                                    name="CustomUploads"
                                    component={CustomUploads}
                                    options={{
                                        title: 'Custom Uploads'
                                    }}
                                />
                            </>
                        )
                        :
                        !!props.auth.organization?.subscription_type_id
                            ?
                                <SubNavigator.Screen
                                    name="JobAdd"
                                    component={JobSingle}
                                    options={{
                                        title: 'Post Job'
                                    }}
                                />
                            : null
                }
            </SubNavigator.Navigator>
        </View>
    );
}

const mapStateToProps = (state) => {
    const {auth} = state;
    return {auth};
};

export default connect(mapStateToProps)(Jobs);
