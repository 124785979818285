export const miscellaneousActionTypes = {
	SET_SPREADSHEET_FILTER_OPEN: 'SET_SPREADSHEET_FILTER_OPEN',
	SET_PAGE_CLICKED: 'SET_PAGE_CLICKED',
	SET_PROCESSING_INDICATOR_ACTIVE: 'SET_PROCESSING_INDICATOR_ACTIVE',
	SET_IS_JOB_SINGLE_PAGE: 'SET_IS_JOB_SINGLE_PAGE'
};

export const setSpreadSheetFilterOpen = (spreadSheetFilterOpen) => (
	{
		type: miscellaneousActionTypes.SET_SPREADSHEET_FILTER_OPEN,
		payload: spreadSheetFilterOpen
	}
);

export const setPageClicked = (pageClicked) => (
	{
		type: miscellaneousActionTypes.SET_PAGE_CLICKED,
		payload: pageClicked
	}
);

export const setProcessingIndicatorActive = (processingIndicatorActive) => (
	{
		type: miscellaneousActionTypes.SET_PROCESSING_INDICATOR_ACTIVE,
		payload: processingIndicatorActive
	}
);


export const setIsJobSinglePage = (value) => ({
	type: miscellaneousActionTypes.SET_IS_JOB_SINGLE_PAGE,
	payload: value
});