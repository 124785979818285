import React from "react"
import Svg, {Path, Defs, LinearGradient, Stop, G, Circle} from "react-native-svg"

function ConfirmSvg(props) {
	return (
		<Svg width={43.724} height={43.724} viewBox="0 0 43.724 43.724" {...props}>
			<Defs>
				<LinearGradient
					id="prefix__a"
					x1={-0.195}
					y1={0.5}
					x2={1.173}
					y2={0.5}
					gradientUnits="objectBoundingBox"
				>
					<Stop offset={0} stopColor="#1b8ce9" />
					<Stop offset={1} stopColor="#467aff" />
				</LinearGradient>
			</Defs>
			<G data-name="Group 959" transform="translate(-702.073 -469.808)">
				<Circle
					data-name="Ellipse 32"
					cx={21.862}
					cy={21.862}
					transform="translate(702.073 469.808)"
					fill="url(#prefix__a)"
					r={21.862}
				/>
				<Path
					data-name="Path 317"
					d="M734.298 485.373l-2.054-2.054-12.3 12.3-4.594-4.581-2.043 2.054 6.625 6.627.011-.011.011.011z"
					fill="#fff"
					stroke="#fff"
				/>
			</G>
		</Svg>
	)
}

export default ConfirmSvg;
