import React from 'react';
import {Linking, Platform, View} from 'react-native';

import AppText from './AppText';
import AppButton from './AppButton';
import {inputStyles} from '../styles/input';
import {basicStyles} from '../styles/basic';
import {API_ROOT} from "../config";

function DownloadField({label, value, name, wrapperStyle = {}, inputStyle = {}, labelStyle = {}}) {

	const handleButtonPress = () => {
		let url = API_ROOT + '/downloads/' + value;
		if (Platform.OS === 'web') {
			window.open(url, '_blank');
		} else {
			Linking.canOpenURL(url).then(supported => {
				if (supported) {
					return Linking.openURL(url);
				}
			});
		}
	};

	return (
		<View style={[
			inputStyles.inputWrapper,
			basicStyles.flexRow,
			basicStyles.alignContentCenter,
			wrapperStyle,
		]}
		>
			<View style={basicStyles.flexScale}>
				<AppText style={[inputStyles.inputLabel, labelStyle]}>
					{label}
				</AppText>
				<AppText
					style={[
						basicStyles.flexScale,
						inputStyles.input,
						inputStyle,
					]}>
					{name}
				</AppText>
			</View>
			<AppButton
				label="Download"
				theme="green"
				action={() => handleButtonPress()}
			/>
		</View>

	);
}

export default DownloadField;
