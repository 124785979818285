import Http from "../utilities/Http";
import {API_ROOT} from "../config";

export default {
	async getActivities(organizationId, startDate = null, endDate = null, sortBy = null) {
		let response = await Http().get(API_ROOT + '/organizations/' + organizationId +'/activities/', {
			params: {
				startDate,
				endDate,
				sortBy
			}
		});

		return response.data.activities;
	},
	async getActivitiesPaginated(organizationId, startDate = null, endDate = null, sortBy = null, perPage = null, currentPage = null) {
		let response = await Http().get(API_ROOT + '/organizations/' + organizationId +'/activities/paginated', {
			params: {
				startDate,
				endDate,
				sortBy,
				perPage,
				currentPage
			}
		});

		return response.data;
	},
};
