import React, {useState, useEffect, useCallback} from 'react';
import { connect } from 'react-redux';
import {View} from 'react-native';
import {createSubNavigator} from '../../components/SubNavigator';
import WordpressIntegration from './WordpressIntegration';
import TextlineIntegration from './TextlineIntegration';
import IntegrationsService from '../../services/IntegrationsService';
import {basicStyles} from '../../styles/basic';

const SubNavigator = createSubNavigator();

function Integrations(props) {
	const [integrations, setIntegrations] = useState([]);
	useEffect(() => {
		const getIntegrations = async () => {
			let integrationsData = await IntegrationsService.getIntegrations();
			if (!props.auth.organization.subscription_type_id && props.auth.organization.organization_id !== '1') {
				integrationsData = integrationsData.filter(integration => integration.integration_type !== 'textline');
			}
			setIntegrations(integrationsData);
		};

		getIntegrations();
	}, []);

	const getIntegrationComponent = useCallback((type) => {
		switch (type) {
			case 'wordpress': {
				return WordpressIntegration;
			}
			case 'textline': {
				return TextlineIntegration;
			}
			default: {
				return null;
			}
		}
	}, []);

	return (
		<View style={basicStyles.flexScale}>
			{
				integrations.length ?
					<SubNavigator.Navigator back backBehavior="history" initialRouteName={integrations[0].integration_title}>
						{
							integrations.map(integration => {
								return (
									<SubNavigator.Screen
										key={integration.integration_id}
										name={integration.integration_title}
										component={getIntegrationComponent(integration.integration_type)}
										options={{
											title: integration.integration_title
										}}
										initialParams={{id: integration.integration_id}}
									/>
								);
							})
						}
					</SubNavigator.Navigator>
					: null
			}
		</View>
	);
}

const mapStateToProps = (state) => {
    const {auth} = state;
    return {auth};
};

export default connect(mapStateToProps)(Integrations);
