import React, {useEffect, useState} from 'react';
import {View, ScrollView} from 'react-native';
import {useDebouncedCallback} from 'use-debounce';

import SpreadSheet from '../../components/SpreadSheet';
import {basicStyles} from '../../styles/basic';
import PageTop from '../../components/PageTop';
import {textStyles} from '../../styles/text';
import AppText from '../../components/AppText';
import PageContentContainer from '../../components/PageContentContainer';
import ContentSection from '../../components/ContentSection';
import {connect} from 'react-redux';
import UsersService from '../../services/UsersService';
import Avatar from '../../components/Avatar';
import {AVATAR_DEFAULT, AVATAR_PATH_ORG, ORGANIZATION_TYPE_IDS, API_ROOT} from '../../config';
import moment from 'moment';
import ReportsSearchSection from '../../components/ReportsSearchSection';
import Pagination from "../../components/Pagination";
import {capitalizeString} from "../../utilities/methods";
import EStyleSheet from "react-native-extended-stylesheet";

function Team(props) {
	const [users, setUsers] = useState([]);
	const {organization} = props.auth;
	const perPage = 10;
	const [pageCount, setPageCount] = useState(1);
	const [paginationRange, setPaginationRange] = useState([1]);
	const [teamCount, setTeamCount] = useState(0);
	const [isLoading, setIsLoading] = useState(true);

	const {navigate} = props.navigation;

	let today = new Date();
	let startDate = props.route?.params?.startDate ? new Date(props.route.params.startDate).setHours(12) : new Date(new Date().setDate(today.getDate() - 30)).setHours(12, 0, 0, 0);
	let endDate = props.route?.params?.endDate ? new Date(props.route.params.endDate).setHours(12) : today.setHours(12, 0, 0, 0);
	let currentPage = props.route?.params?.currentPage ? parseInt(props.route.params.currentPage) : 1;
	let search = props.route?.params?.search ? props.route.params.search : '';
	let filterByColumns = props.route?.params?.filterByColumns ? props.route.params.filterByColumns : '';
	let filterByColumnValues = props.route?.params?.filterByColumnValues ? props.route.params.filterByColumnValues : '';

	let filterByColumnValuesExploded = filterByColumnValues.length > 0 ? filterByColumnValues?.split(',') : [];
	let filterByColumnsCombined = filterByColumns.length > 0 ? filterByColumns?.split(',')?.reduce((combined, column, currentIndex) => {
		return {
			...combined,
			[column]: filterByColumnValuesExploded[currentIndex]
		};
	}, {}) : [];

	const [getUsersDebounce] = useDebouncedCallback(() => {
		setIsLoading(true);
		const getUsers = async () => {
			let usersData = await UsersService.getUsersPaginated(
				organization.organization_id,
				search,
				null,
				null,
				startDate ? moment(startDate).format('YYYY-MM-DD') : null,
				endDate ? moment(endDate).format('YYYY-MM-DD') : null,
				perPage,
				currentPage,
				filterByColumns,
				filterByColumnValues
			);

			currentPage = usersData.currentPage;
			setUsers(usersData.users);
			setPageCount(usersData.pageCount);
			setTeamCount(usersData.usersCount);
			setPaginationRange(usersData.paginationRange);
			setIsLoading(false);
		};

		getUsers();
	}, 500);

	const columns = [
		...(
			organization.organization_type_id == ORGANIZATION_TYPE_IDS.FLUID || organization.organization_type_id == ORGANIZATION_TYPE_IDS.PARTNER ?
				[
					{
						key: 'organization_title',
						exportName: 'organization_title',
						label: 'Organization',
						type: 'text',
						disabled: true,
						valueExtractor: user => user.organization_title,
						onChangeText() {
						},
					}
				]
				: []
		),
		{
			key: 'user_email',
			exportName: 'user_email',
			label: 'Email',
			type: 'text',
			valueExtractor: user => user.user_email,
			onChangeText() {
			},
			disabled: true,
		},
		{
			key: 'user_display_name',
			exportName: 'user_display_name',
			label: 'Name',
			type: 'text',
			valueExtractor: user => user.user_display_name,
			onChangeText() {
			},
			disabled: true,
		},
		{
			key: 'store_front_title',
			exportName: 'store_front_title',
			label: 'Store Front',
			type: 'text',
			valueExtractor: user => user.store_front_title,
			onChangeText() {
			},
			disabled: true,
		},
		{
			key: 'surveys_sent',
			exportName: 'surveys_sent',
			label: 'Surveys Sent',
			type: 'text',
			valueExtractor: user => user.surveys_sent,
			onChangeText() {
			},
			disabled: true,
			sortCast: 'int',
		},
		{
			key: 'surveys_completed',
			exportName: 'surveys_completed',
			label: 'Surveys Completed',
			type: 'text',
			valueExtractor: user => user.surveys_completed,
			onChangeText() {
			},
			disabled: true,
			sortCast: 'int',
		},
		{
			key: 'surveys_completed_percent',
			exportName: 'surveys_completed_percent',
			label: 'Surveys Completion Percentage',
			type: 'text',
			valueExtractor: user => user.surveys_completed_percent,
			onChangeText() {
			},
			disabled: true,
		},
		{
			key: 'survey_star_total',
			exportName: 'survey_star_total',
			label: 'Surveys Star Total',
			type: 'text',
			valueExtractor: user => user.survey_star_total,
			onChangeText() {
			},
			disabled: true,
		},
		{
			key: 'survey_star_average',
			exportName: 'survey_star_average',
			label: 'Surveys Star Average',
			type: 'text',
			valueExtractor: user => user.survey_star_average,
			onChangeText() {
			},
			disabled: true,
		},
		{
			key: 'user_organization_status',
			exportName: 'user_organization_status',
			label: 'Employee Status',
			type:  'text',
			valueExtractor: user => capitalizeString(user.user_organization_status),
			onChangeText() {
			},
			disabled: true,
			filterByColumn: 'active',
			excluded: filterByColumnsCombined?.user_organization_status === 'active',
		},
		{
			key: 'user_plugin_visibility',
			exportName: 'user_plugin_visibility_title',
			label: 'Visible in Plugin?',
			type:  'text',
			valueExtractor: user => user.user_plugin_visibility_title,
			onChangeText() {
			},
			disabled: true,
			filterByColumn: '1',
			excluded: filterByColumnsCombined?.user_plugin_visibility === '1',
		},
		{
			key: 'company_ranking_total_stars',
			exportName: 'company_ranking_total_stars',
			label: 'Company Ranking Total Stars',
			type:  'text',
			valueExtractor: user => user.company_ranking_total_stars,
			onChangeText() {
			},
			disabled: true,
		}

	];

	useEffect(() => {
		getUsersDebounce();
	}, [organization.organization_id, search, startDate, endDate, currentPage, filterByColumns, filterByColumnValues]);

	return (
		<ScrollView contentContainerStyle={basicStyles.flexScale}>
			<PageContentContainer style={{zIndex: 1}}>
				<ContentSection style={[
					basicStyles.spreadSheetWrapper,
					basicStyles.flexScale,
				]}>
					<SpreadSheet
						rowCount={teamCount}
						rows={users.filter(user => user.organization_type_id != ORGANIZATION_TYPE_IDS.FLUID && user.organization_type_id != ORGANIZATION_TYPE_IDS.PARTNER)} // exclude Fluid users
						columns={columns}
						rowKeyExtractor="user_organization_id"
						exportUrl={API_ROOT + '/organizations/' + props.auth.organization.organization_id + '/users?search=' + encodeURIComponent(search) + '&joinedAfterDate=' + encodeURIComponent(startDate ? moment(startDate).format('YYYY-MM-DD') : '') + '&joinedBeforeDate=' + encodeURIComponent(endDate ? moment(endDate).format('YYYY-MM-DD') : '')}
						exportTitle="team.csv"
						initialDisabledColumns={columns.map(column => {
							if(column.excluded) {
								return column.key
							}
						})}
						searchElement={
							<ReportsSearchSection
								onChangeSearch={(value) => {
									navigate('Team', {
										...props.route?.params,
										currentPage: 1,
										search: value
									});
								}}
								searchValue={search}
								onChangeStartDate={(value) => {
									navigate('Team', {
										...props.route?.params,
										startDate: moment(value).format('MM-DD-YYYY'),
										currentPage: 1,
									})
								}}
								startDateValue={startDate}
								onChangeEndDate={(value) => {
									navigate('Team', {
										...props.route?.params,
										endDate: moment(value).format('MM-DD-YYYY'),
										currentPage: 1,
									})
								}}
								endDateValue={endDate}
								onChangeDates={(dates) => {
									const [start, end] = dates;
									navigate('Team', {
										...props.route?.params,
										startDate: moment(start).format('MM-DD-YYYY'),
										endDate: moment(end).format('MM-DD-YYYY'),
										currentPage: 1
									})
								}}
								isLoading={isLoading}
							/>
						}
					/>
				</ContentSection>
			</PageContentContainer>
			<Pagination screenName="Team" pageCount={pageCount} pageIndex={currentPage} paginationRange={paginationRange} />
		</ScrollView>
	);
}

const mapStateToProps = (state) => {
	const {auth} = state;
	return {auth};
};

export default connect(mapStateToProps)(Team);
