import React from 'react';
import {ScrollView, View} from 'react-native';
import {connect} from 'react-redux';

import {createInteriorTabNavigator} from "../../../components/InteriorTabNavigator";
import FluidReviews from "./FluidReviews";
import ThirdPartyReviews from "./ThirdPartyReviews";
import {basicStyles} from "../../../styles/basic";
import Avatar from "../../../components/Avatar";
import {AVATAR_DEFAULT, AVATAR_PATH_ORG, ORGANIZATION_TYPE_IDS} from "../../../config";
import LinesSvg from "../../../components/svgs/LinesSvg";
import MapSvg from "../../../components/svgs/MapSvg";
import EStyleSheet from 'react-native-extended-stylesheet';

const InteriorTabNavigator = createInteriorTabNavigator();

function Reviews(props) {
    const {organization} = props.auth;

    return (
        <View style={basicStyles.flexScale}>

            <ScrollView contentContainerStyle={basicStyles.flexScale}>
                <InteriorTabNavigator.Navigator
                    backbehavior="history"
                    navIcon={
                        <Avatar
                            source={{uri: organization.organization_thumbnail ? AVATAR_PATH_ORG + organization.organization_thumbnail : AVATAR_DEFAULT}}
                            width={66}
                        />
                    }
                    navTitle={(organization.organization_type_id == ORGANIZATION_TYPE_IDS.FLUID || organization.organization_type_id == ORGANIZATION_TYPE_IDS.PARTNER ? 'All' : organization.organization_title) + ' Reviews'}
                    styleContainer={styles.styleContainer}
                    styleTitle={styles.styleTitle}
                    styleLinkContainer={styles.styleLinkContainer}
                >
                    <InteriorTabNavigator.Screen
                        name="FluidReviews"
                        component={FluidReviews}
                        options={{
                            title: 'Fluid Reviews',
                            icon: <LinesSvg/>
                        }}
                    />
                    <InteriorTabNavigator.Screen
                        name="ThirdPartyReviews"
                        component={ThirdPartyReviews}
                        options={{
                            title: 'Other Reviews',
                            icon: <MapSvg/>
                        }}
                    />
                </InteriorTabNavigator.Navigator>
            </ScrollView>
        </View>
    );
}

const mapStateToProps = (state) => {
    const {auth} = state;
    return {auth};
};

export default connect(mapStateToProps)(Reviews);

const styles = EStyleSheet.create({
    styleLinkContainer: {
        marginTop:15,
        marginBottom: 15
    },
    '@media (max-width: 630)': {
        styleContainer: {
            justifyContent: 'center',
            alignItems: 'center',
        },
        styleLinkContainer: {
            marginTop:30,
        },
    },
    '@media (max-width: 450)': {
        styleTitle: {
            fontSize: 20,
        },

    }
});