import Http from "../utilities/Http";
import {API_ROOT} from "../config";

export default {
	async getStoreFronts(organizationId) {
		let response = await Http().get(API_ROOT + '/organizations/' + organizationId + '/store-fronts');

		return response.data.storeFronts;
	},
	async getStoreFrontsPaginated(organizationId, perPage = null, currentPage = null) {
		let response = await Http().get(API_ROOT + '/organizations/' + organizationId + '/store-fronts/paginated', {
			params: {
				perPage,
				currentPage
			}
		});

		return response.data;
	},
	async getStoreFront(organizationId, storeFrontId) {
		let response = await Http().get(API_ROOT + '/organizations/' + organizationId + '/store-fronts/' + storeFrontId);

		return response.data.storeFront;
	},
	async insertStoreFront(organizationId, storeFront) {
		let response = await Http().post(API_ROOT + '/organizations/' + organizationId + '/store-fronts/', {
			storeFront
		});

		return response.data.storeFront;
	},
	async updateStoreFront(organizationId, storeFrontId, storeFront) {
		let response = await Http().post(API_ROOT + '/organizations/' + organizationId + '/store-fronts/' + storeFrontId, {
			storeFront
		});

		return response.data.storeFront;
	},
	async uploadImage(organizationId, formData) {
		let response = await Http().post(API_ROOT + '/organizations/' + organizationId + '/store-fronts/upload-image', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		});

		return response.data.fileName;
	}
};
