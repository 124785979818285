import * as React from "react"
import Svg, { G, Rect, Path } from "react-native-svg"

function MenuIconMobileCloseSvg(props) {
	return (
		<Svg width={40} height={40} viewBox="0 0 40 40" {...props}>
			<G data-name="Group 981" transform="translate(-318 -21)">
				<Rect
					data-name="Rectangle 456"
					width={40}
					height={40}
					rx={20}
					transform="translate(318 21)"
					fill="#fff"
					opacity={0.131}
				/>
				<Path
					data-name="Line 159"
					fill="none"
					stroke="#fff"
					strokeWidth={1.25}
					d="M328.5 36.5h20"
				/>
				<Path
					data-name="Line 160"
					fill="none"
					stroke="#fff"
					strokeWidth={1.25}
					d="M328.5 41.5h20"
				/>
				<Path
					data-name="Line 161"
					fill="none"
					stroke="#fff"
					strokeWidth={1.25}
					d="M328.5 46.5h20"
				/>
			</G>
		</Svg>
	)
}

export default MenuIconMobileCloseSvg
