import * as React from "react"
import Svg, { Circle, Path } from "react-native-svg"

function GreenCircleWhiteCheck(props) {
	return (
		<Svg
			data-name="Group 160"
			width={37.34}
			height={37.34}
			viewBox="0 0 37.34 37.34"
			{...props}
		>
			<Circle
				data-name="Ellipse 33"
				cx={18.67}
				cy={18.67}
				fill="#00d895"
				opacity={0.278}
				r={18.67}
			/>
			<Circle
				data-name="Ellipse 32"
				cx={13.757}
				cy={13.757}
				transform="translate(4.913 4.913)"
				fill="#00d895"
				r={13.757}
			/>
			<Path
				data-name="Path 317"
				d="M25.191 14.707l-1.293-1.292-7.74 7.74-2.89-2.883-1.286 1.293 4.168 4.168.007-.007.007.007z"
				fill="#fff"
				stroke="#fff"
			/>
		</Svg>
	)
}

export default GreenCircleWhiteCheck;
