import * as React from "react"
import Svg, {Path} from "react-native-svg"

function NotesSvg(props) {
    return (
        <Svg
            width={18}
            height={18}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M12.207 11.34c-1.826 2.298-4.794 1.738-6.03.396C4.813 10.252 4.715 7.95 6 6.45a3.962 3.962 0 015.792-.231c.02-.057.04-.104.056-.153.088-.289.284-.448.54-.439.273.01.471.192.526.493.017.091.015.187.015.28 0 1.136-.006 2.272.002 3.408.008 1.024.62 1.77 1.591 1.964 1.188.236 2.258-.554 2.344-1.782.161-2.269-.367-4.348-1.864-6.105-1.366-1.604-3.115-2.543-5.217-2.726-3.005-.262-5.43.845-7.213 3.277C1.49 5.915 1.03 7.61 1.123 9.443c.186 3.67 3 6.785 6.617 7.334.397.06.8.078 1.2.112.462.04.704.366.558.762-.105.286-.345.356-.611.348-1.58-.05-3.076-.417-4.439-1.241-2.275-1.38-3.717-3.37-4.261-5.978C-.81 5.997 2.286 1.25 7.057.209c4.713-1.028 9.425 1.84 10.62 6.474a9.828 9.828 0 01.26 3.601c-.143 1.25-1.136 2.296-2.416 2.577-1.207.265-2.516-.276-3.177-1.313-.044-.068-.094-.134-.143-.206l.005-.002zM6.179 8.995c0 1.538 1.273 2.817 2.806 2.822a2.842 2.842 0 002.832-2.825c0-1.538-1.273-2.817-2.805-2.822a2.84 2.84 0 00-2.833 2.825z"
                fill="#3CA6F5"
            />
        </Svg>
    )
}

export default NotesSvg
