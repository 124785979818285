import React, {useState, useEffect, useRef} from 'react';
import {View, ScrollView} from 'react-native';
import {CommonActions} from '@react-navigation/native';
import {useDebouncedCallback} from 'use-debounce';
import EStyleSheet from 'react-native-extended-stylesheet';

import {ORGANIZATION_TYPE_IDS} from '../../config';
import CouponsService from '../../services/CouponsService';
import {basicStyles} from '../../styles/basic';
import AppText from '../../components/AppText';
import {textStyles} from '../../styles/text';
import OrganizationsService from '../../services/OrganizationsService';
import AppButton from '../../components/AppButton';
import PageTop from '../../components/PageTop';
import PageContentContainer from '../../components/PageContentContainer';
import {bindActionCreators} from 'redux';
import {setWarning} from '../../store/warning/warningActions';
import {connect} from 'react-redux';
import FieldSet from '../../components/FieldSet';
import BackBar from '../../components/BackBar';
import ContentSection from '../../components/ContentSection';
import AppPickerInput from '../../components/AppPickerInput';
import CloseSvg from '../../components/svgs/CloseSvg';
import TouchView from '../../components/TouchView';
import CheckMarkBox from "../../components/CheckMarkBox";

function CouponSingle(props) {
    const [coupon, setCoupon] = useState({
        stripe_data: {},
        partnerOrganizations: [],
    });
    const [organizations, setOrganizations] = useState([]);
    const [couponChunkSize, setCouponChunkSize] = useState(4);
    const couponId = props.route?.params?.couponId;
    const firstUpdate = useRef(true);
    const {navigate} = props.navigation;
    const [couponType, setCouponType] = useState('percent');

    const [updateCouponDebounce] = useDebouncedCallback(() => {
        if (couponId) {
            CouponsService.updateCoupon(couponId, coupon);
        }
    }, 500);

    function updateCoupon(prop, value) {
        setCoupon({...coupon, [prop]: value});
    }

    function updateCouponStripeData(prop, value) {
        updateCoupon('stripe_data', {...coupon.stripe_data, [prop]: value});
    }

    function addPartner() {
        setCoupon({...coupon, partnerOrganizations: [...coupon.partnerOrganizations, {}]});
    }

    function updatePartner(index, partnerOrganizationId) {
        let newPartner = organizations.find((organization) => {
            return organization.organization_id === partnerOrganizationId;
        });

        setCoupon(prevCoupon => {
            let newCoupon = {...prevCoupon};

            newCoupon.partnerOrganizations[index] = newPartner;

            return newCoupon;
        });
    }

    function removePartner(index) {
        setCoupon(prevCoupon => {
            let newCoupon = {...prevCoupon};

            newCoupon.partnerOrganizations.splice(index, 1);

            return newCoupon;
        });
    }

    function createCoupon() {
        const createCouponAsync = async () => {
            try {
                let couponData = await CouponsService.insertCoupon(coupon);
                setCoupon(couponData);
                navigate('CouponEdit', {
                    couponId: couponData.coupon_id
                })
            } catch (e) {
                console.log(e);
            }
        };

        createCouponAsync();
    }

    const handleLayout = ({nativeEvent}) => {
        const {width} = nativeEvent.layout;

        if (width > 980) {
            setCouponChunkSize(4);
        } else if (width > 768) {
            setCouponChunkSize(3);
        } else if (width > 450) {
            setCouponChunkSize(2);
        } else {
            setCouponChunkSize(1);
        }
    };

    useEffect(() => {
        if (couponId) {
            const getCoupon = async () => {
                const couponData = await CouponsService.getCoupon(couponId);
                setCoupon(couponData);
            };
            getCoupon();
        }
    }, [couponId]);

    useEffect(() => {
        const getPartnerOrganizations = async () => {
            const partnerOrganizationsData = await OrganizationsService.getOrganizations(
                props.auth.organization.organization_id,
                ORGANIZATION_TYPE_IDS.PARTNER,
            );

            setOrganizations(partnerOrganizationsData);
        };

        getPartnerOrganizations();
    }, [props.auth.organization.organization_id]);

    useEffect(() => {
        if (firstUpdate.current) {
            if (Object.keys(coupon).length) {
                firstUpdate.current = false;
            }
            return;
        }

        updateCouponDebounce();
    }, [coupon]);

    useEffect(() => {
        if(couponType === 'percent') {
            updateCouponStripeData('amount_off', null);
        } else {
            updateCouponStripeData('percent_off', null);
        }
    }, [couponType])

    return (
        <>
            <BackBar
                action={CommonActions.navigate('CouponsList')}
                label="Back to all Coupons"
            />
            <ScrollView contentContainerStyle={basicStyles.flexScale} onLayout={handleLayout}>
                <PageContentContainer>
                    <View style={[
                        basicStyles.flexRow,
                        basicStyles.alignContentCenter,
                        basicStyles.justifyContentSpaceBetween,
                        {marginBottom: 25},
                    ]}>
                        <View style={[basicStyles.flexRow, basicStyles.alignContentCenter]}>
                            <AppText style={[textStyles.pageSubTitle, textStyles.semiBold, {marginBottom: 0, marginRight: 20}]}>
                                Coupon
                            </AppText>
                        </View>
                        {
                            !couponId ?
                                <AppButton
                                    label={'Create Coupon'}
                                    action={() => createCoupon()}
                                />
                                : null
                        }
                    </View>
                    <FieldSet
                        header={(
                            !couponId ?
                                <View style={[
                                    styles.checkBoxContainer,
                                    {
                                        paddingVertical: 25,
                                        paddingHorizontal: 30,
                                    }
                                ]}>
                                    <TouchView
                                        action={() => setCouponType('percent')}
                                        style={[
                                            basicStyles.flexRow,
                                            basicStyles.alignContentCenter,
                                            styles.checkBox1Margin
                                        ]}
                                    >
                                        <CheckMarkBox
                                            style={{marginRight: 6}}
                                            active={couponType === 'percent'}
                                        />
                                        <AppText>Percentage Off</AppText>
                                    </TouchView>
                                    <TouchView
                                        action={() => setCouponType('amount')}
                                        style={[
                                            basicStyles.flexRow,
                                            basicStyles.alignContentCenter
                                        ]}
                                    >
                                        <CheckMarkBox
                                            style={{marginRight: 6}}
                                            active={couponType === 'amount'}
                                        />
                                        <AppText>Amount Off</AppText>
                                    </TouchView>
                                </View>
                                : null
                        )}
                        chunkSize={couponChunkSize}
                        fields={[
                            {
                                type: 'text',
                                label: 'Title',
                                onChangeText: value => updateCoupon('coupon_title', value),
                                value: coupon.coupon_title,
                            },
                            (
                                couponType === 'percent' ?
                                    {
                                        type: 'numeric',
                                        label: 'Percent Off',
                                        onChangeText: value => updateCouponStripeData('percent_off', value),
                                        value: coupon.stripe_data.percent_off,
                                        disabled: !!couponId,
                                    }
                                    :
                                    {
                                        type: 'numeric',
                                        label: 'Amount Off',
                                        onChangeText: value => updateCouponStripeData('amount_off', value),
                                        value: coupon.stripe_data.amount_off,
                                        disabled: !!couponId,
                                    }
                            ),
                            {
                                type: 'picker',
                                label: 'Duration',
                                onValueChange: value => updateCouponStripeData('duration', value),
                                selectedValue: coupon.stripe_data.duration,
                                disabled: !!couponId,
                                items: [
                                    {
                                        label: 'Once',
                                        value: 'once',
                                    },
                                    {
                                        label: 'Forever',
                                        value: 'forever',
                                    },
                                    {
                                        label: 'Repeating',
                                        value: 'repeating',
                                    },
                                ],
                                labelExtractor: 'label',
                                valueExtractor: 'value',
                            },
                            ...(
                                coupon.stripe_data.duration === 'repeating' ?
                                    [
                                        {
                                            type: 'numeric',
                                            label: 'Duration in Months',
                                            onChangeText: value => updateCouponStripeData('duration_in_months', value),
                                            value: coupon.stripe_data.duration_in_months,
                                            disabled: !!couponId,
                                        },
                                    ]
                                    :
                                    []
                            ),
                        ]}
                    />
                    <View style={[
                        basicStyles.flexRow,
                        basicStyles.alignContentCenter,
                        basicStyles.justifyContentSpaceBetween,
                        {marginBottom: 25, marginTop: 25},
                    ]}>
                        <AppText style={[textStyles.pageSubTitle, textStyles.semiBold, {marginBottom: 0}]}>
                            Partner Availability
                        </AppText>
                        <AppButton
                            label={'Add Partner'}
                            action={() => addPartner()}
                        />
                    </View>
                    <ContentSection style={{padding: 20}}>
                        {
                            coupon.partnerOrganizations.map((partnerOrganization, index) => {
                                return (
                                    <ContentSection
                                        key={index}
                                        style={
                                            [
                                                basicStyles.flexRow,
                                                {
                                                    paddingTop: 10,
                                                    paddingBottom: 10,
                                                    paddingLeft: 10,
                                                    ...(index === coupon.partnerOrganizations.length - 1 ? {} : {marginBottom: 20}),
                                                },
                                            ]
                                        }
                                    >
                                        <AppPickerInput
                                            title="Partner"
                                            selectedValue={partnerOrganization.organization_id}
                                            items={
                                                organizations.filter(organization => {
                                                    return organization.organization_id === partnerOrganization.organization_id ||
                                                        !(coupon.partnerOrganizations.findIndex((partnerOrg) => {
                                                            return organization.organization_id === partnerOrg.organization_id;
                                                        }) >= 0);
                                                })
                                            }
                                            onValueChange={value => updatePartner(index, value)}
                                            labelExtractor="organization_title"
                                            valueExtractor="organization_id"
                                            wrapperStyle={{
                                                height: 40,
                                                paddingTop: 0,
                                                paddingBottom: 0,
                                                flex: 1,
                                            }}
                                            showLabel={false}
                                        />
                                        <TouchView
                                            style={[basicStyles.flexCenterContent, {padding: 10, cursor: 'pointer'}]}
                                            action={() => removePartner(index)}
                                        >
                                            <CloseSvg/>
                                        </TouchView>
                                    </ContentSection>
                                );
                            })
                        }
                    </ContentSection>
                </PageContentContainer>
            </ScrollView>
        </>
    );
}

const styles = EStyleSheet.create({
    checkBoxContainer: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    checkBox1Margin: {
        marginRight: 20
    },
    '@media (max-width: 360)': {
        checkBoxContainer: {
            flexDirection: 'column',
            alignItems: 'flex-start'
        },
        checkBox1Margin: {
            marginRight: 0,
            marginBottom: 10
        },
    }
});

const mapStateToProps = (state) => {
    const {auth} = state;
    return {auth};
};

const mapDispatchToProps = (dispatch) => (
    bindActionCreators({
        setWarning,
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(CouponSingle);
