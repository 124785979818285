/**
 * @format
 */
/* eslint-disable import/first */

import {AppRegistry} from 'react-native';
import App from './App';
import {name as appName} from './app.json';
import iconFont from './assets/icon-fonts/fontello.ttf';
import SourceSansProRegular from './assets/fonts/SourceSansPro-Regular.ttf';
import SourceSansProItalic from './assets/fonts/SourceSansPro-Italic.ttf';
import SourceSansProLight from './assets/fonts/SourceSansPro-Light.ttf';
import SourceSansProSemiBold from './assets/fonts/SourceSansPro-SemiBold.ttf';
import SourceSansProSemiBoldItalic from './assets/fonts/SourceSansPro-SemiBoldItalic.ttf';
import SourceSansProBold from './assets/fonts/SourceSansPro-Bold.ttf';

generateFontForWeb('fontello', iconFont);
generateFontForWeb('SourceSansPro-Regular', SourceSansProRegular);
generateFontForWeb('SourceSansPro-Italic', SourceSansProItalic);
generateFontForWeb('SourceSansPro-Light', SourceSansProLight);
generateFontForWeb('SourceSansPro-SemiBold', SourceSansProSemiBold);
generateFontForWeb('SourceSansPro-Bold', SourceSansProBold);
generateFontForWeb('SourceSansPro-SemiBoldItalic', SourceSansProSemiBoldItalic);

AppRegistry.registerComponent(appName, () => App);
AppRegistry.runApplication(appName, {
	rootTag: document.getElementById('root'),
});

function generateFontForWeb(fontFamily, src) {
	const fontStyles = `@font-face {
	  src: url(${src});
	  font-family: "${fontFamily}";
	}`;

	// Create stylesheet
	const style = document.createElement('style');
	style.type = 'text/css';
	if (style.styleSheet) {
		style.styleSheet.cssText = fontStyles;
	} else {
		style.appendChild(document.createTextNode(fontStyles));
	}

// Inject stylesheet
	document.head.appendChild(style);
}

import "react-datepicker/dist/react-datepicker.css";
import './css/DatePickerStyleOverrides.css';
