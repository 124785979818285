import {StyleSheet} from 'react-native';

export const textStyles = StyleSheet.create({
	pageTitle: {
		fontSize: 28,
	},
	pageSubTitle: {
		fontSize: 20,
		marginBottom: 25
	},
	semiBold: {
		fontFamily: 'SourceSansPro-SemiBold',
	},
	light: {
		fontFamily: 'SourceSansPro-Light',
	},
	buttonText: {
		fontSize: 13,
	},
	removeButtonText: {
		color: '#EA0024'
	}
});

